/**
 * The test definition workflow status codes.
 * These are the same enum codes as in the backend enum:
 *    ca.utoronto.easi.ats.common.enumeration.WorkflowStatusCode).
 *
 * Note:
 *   We use the codes as they all have underscores
 *   and no spaces as the values of these enum on the backend.
 *   Working with codes containing spaces is error prone.
 */
export enum WorkflowStatusCode {

  // Test definition workflow statuses (based on CIS statuses)
  NEEDS_SUBMITTING,
  NEEDS_FILES,
  SUBMITTED_TO_ATS_NO_FILES,
  SUBMITTED_TO_ATS_WITH_FILES,

  // Test Booking workflow statuses
  REQUEST_APPROVED,
  REQUEST_PENDING,
  REQUEST_DENIED,
  CANCELLED_BY_ATS,
  CANCELLED_BY_STUDENT,
  COMPLETE
}
