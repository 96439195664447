<div class="card">
  <div class="card-body">
    <h3 class="card-title">Course Contacts</h3>
    <div class="row">
      <div class="col-12 card-text">
        <div class="row">
          <div class="col-6"><strong>Instructor</strong></div>
          <div class="col-6"><strong>Department</strong></div>
        </div>
        <div class="row">
          <div class="col-6">
            <div *ngFor="let instructor of instructors">
              {{instructor.fullName}}<br/>
              <a href="mailto:{{instructor.email}}">{{instructor.email}}</a><br/>
              <span *ngIf="instructor.phone">
                {{instructor.phone}}<br/>
              </span>
            </div>
          </div>
          <div class="col-6">Department</div>
        </div>
      </div>
    </div>
  </div>
</div>
