import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl, Form, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {DashboardColumns} from "../model/types/dashboard-columns";

@Component({
  selector: 'app-checkbox-drop-down',
  templateUrl: './checkbox-drop-down.component.html',
  styleUrls: ['./checkbox-drop-down.component.scss']
})
export class CheckboxDropDownComponent implements OnInit {

  @Input() public options: DashboardColumns[];
  @Input() selectAllOption: DashboardColumns;

  @Input()
  public debugSelections: boolean;

  public checkboxFormGroup: UntypedFormGroup;

  private selectAllFormControl: UntypedFormControl;


  constructor(private formBuilder: UntypedFormBuilder) {}

  ngOnInit() {

    const group: any = {};

    this.selectAllFormControl = new UntypedFormControl(this.selectAllOption.value);

    this.selectAllFormControl.valueChanges.subscribe(value => {

      if (!value) {
        // if all are set then turn them all off.  if some are unset then just toggle itself
        const data: CheckCount = this.countSelected();

        if (data.count < this.options.length) {
          // just toggle the switch
          return;
        }
        // else fall through
      }
      // set the options based on the new status of the select all option.
      this.options.forEach(option => {
        const fc: AbstractControl = this.checkboxFormGroup.get(option.key);
        fc.setValue(value);
      });
    });

    group[this.selectAllOption.key] = this.selectAllFormControl;

    this.options.forEach(option => {
      group[option.key] = new UntypedFormControl(option.value);
    });

    this.checkboxFormGroup = new UntypedFormGroup(group);
  }

  // tslint:disable-next-line:ban-types
  public getSelectionStatus() {

    if (this.selectAllFormControl.value === true) {
      return 'All ' + this.selectAllOption.displayName + 's';
    } else {

      const data: CheckCount = this.countSelected();

      if (data.count === 0) {
        return 'No ' + this.selectAllOption.displayName + ' Selected';
      } else if (data.count > 1) {
        return data.count + ' ' + this.selectAllOption.displayName + ' Selected';
      } else {
        return data.displayName;
      }

    }


    return 'Select';
  }

  private countSelected(): CheckCount {
    let count = 0;

    let displayName: string;

    this.options.forEach(option => {
      const fc: AbstractControl = this.checkboxFormGroup.get(option.key);
      if (fc.value === true) {
        count++;
        displayName = option.displayName;
      }
    });

    return new CheckCount(count, displayName);
  }

}
class CheckCount {
  count: number;
  displayName: string;


  constructor(count: number, displayName: string) {
    this.count = count;
    this.displayName = displayName;
  }
}
export class CheckOption {

  key: string;
  displayName: string;
  value = false;


  constructor(key: string, displayName: string) {
    this.key = key;
    this.displayName = displayName;
  }
}
