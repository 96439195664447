<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Delete Test Definition</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure you want to delete the following Test Definition:
  <ul>
    <li>Course, Section: {{testDefinition.course.code}} {{testDefinition.course.sectionCode}},
                                     {{testDefinition.course.teachMethod}} {{testDefinition.course.sectionNumber}}</li>
    <li>Exam ID: {{testDefinition.clockworkExamId}}</li>
    <li>ATS Test Definition ID: {{testDefinition.testDefId}}</li>
    <li>Class Writing Date: {{testDefinition.assessmentScheduleDetails.assessmentDate | date: 'MMM dd, YYYY'}}</li>
    <li>Class Writing Time: {{testDefinition.assessmentScheduleDetails.assessmentTime}}</li>
  </ul>
  <strong>This test definition will be permanently deleted. After deleting, you will not be
    able to undo this action or view this test definition within Accommodated Testing Services.</strong>
</div>
<!-- Buttons -->
<div class="modal-footer">
  <button type="button" class="btn btn-primary" (click)="activeModal.close('yes')">
    Yes, Delete Test Definition
  </button>&nbsp;
  <button type="button" class="btn btn-link" (click)="activeModal.close('no')">
    Cancel
  </button>&nbsp;
</div>

