import { Component, OnInit } from '@angular/core';
import { CheckOption } from '../../checkbox-drop-down/checkbox-drop-down.component';

@Component({
  selector: 'design-checkbox-drop-down-demo',
  templateUrl: './checkbox-drop-down-demo.component.html',
  styleUrls: ['./checkbox-drop-down-demo.component.scss']
})
export class CheckboxDropDownDemoComponent implements OnInit {

  public options: CheckOption[] = [
    new CheckOption ('1', 'option 1'),
    new CheckOption ('2', 'option 2'),
    new CheckOption ('3', 'option 3'),
    new CheckOption ('4', 'option 4'),
    new CheckOption ('5', 'option 5')
  ];

  public selectAllOption: CheckOption = new CheckOption('all', 'Option');

  constructor() { }

  ngOnInit(): void {
  }

}
