import {Component, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {Yes} from "../../types/yes.enum";
import {No} from "../../types/no.enum";

/**
 * When the user is trying to navigate away from a page with unsaved changes a modal window should be
 * displayed to let the user know about that. The tile, body and buttons of the modal window should be
 * generic in order to be used throughout the application. Those values are set in this component.
 */
@Component({
  selector: 'app-unsaved-changes',
  templateUrl: './unsaved-changes.component.html',
  styleUrls: ['./unsaved-changes.component.scss']
})
export class UnsavedChangesComponent implements OnInit {

  YES_VALUE: Yes = Yes.VALUE;
  NO_VALUE: No = No.VALUE;

  // Generic values
  title = 'Unsaved Changes';
  body = 'You have unsaved changes. Are you sure you want to leave this page? By doing so, all changes made are lost.';
  discardChanges = 'Discard Changes & Leave';
  continueEditing = 'Continue Editing';

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
