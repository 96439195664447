import {Component, Input, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {merge, Observable, Subject} from "rxjs";
import {LevelOfStudy} from "../level-of-study.enum";
import {debounceTime, distinctUntilChanged, filter, map, switchMap} from "rxjs/operators";
import {ModelService} from "../../model/model.service";
import {CourseByLevelOfInstr} from "../../model/types/course-by-level-of-instr";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {CourseLevelOfInstr} from "../../model/types/course-level-of-instr";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";

@Component({
  selector: 'app-course-code-typeahead',
  templateUrl: './course-code-typeahead.component.html',
  styleUrls: ['./course-code-typeahead.component.scss']
})
export class CourseCodeTypeaheadComponent {

  @Input()
  courseCtrl: FormControl;

  @Input()
  levelOfStudyCtrl: FormControl;

  @ViewChild('instance', { static: true })
  instance: NgbTypeahead;

  LevelOfStudy = LevelOfStudy;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private modelService: ModelService) {}


  search = (text$: Observable<string>): Observable<CourseLevelOfInstr[]> => {

    const debouncedText$: Observable<string> = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$: Subject<string> = this.focus$;
    const clicksWithClosedPopup$: Observable<any> = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      filter(text => text.length > 4), // emit only if the text entered is bigger than 5 chars
      switchMap(courseCodePrefix =>
        this.modelService.getCourseByLevelOfInstr(courseCodePrefix.toUpperCase())),
      map((val: CourseByLevelOfInstr) =>
        InstructorEmailingUtils.mapCourseCodeLevelOfInstr(val, this.levelOfStudyCtrl, this.LevelOfStudy))
    );
  }

  formatter = (result: CourseLevelOfInstr) => !result ? '' : `${result.course.trim()}`;

}
