import {AssessmentScheduleDetails} from "./assessment-schedule-details";
import {AssessmentLocationDetails} from "./assessment-location-details";
import {Course} from "./course";

export class TestDef {

  testDefId: number;
  testType: string;
  testSubtype: string;
  assessmentType: string;
  course: Course;
  assessmentLocationDetails: AssessmentLocationDetails;
  assessmentScheduleDetails: AssessmentScheduleDetails;

  testDefSource: string;

  division: string;
  unit: string;

  clockworkExamId: number;
  bookingAmount: number;
}
