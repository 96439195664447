import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestDef} from "../../model/types/test-def";
import {TypeUtils} from "../../common/types/type-utils";
import {Course} from "../../model/types/course";
import {Event} from "@angular/router";

@Component({
  selector: 'app-test-def-details-card',
  templateUrl: './test-def-details-card.component.html',
  styleUrls: ['./test-def-details-card.component.scss']
})
export class TestDefDetailsCardComponent implements OnInit {

  @Input()
  testDef: TestDef;

  @Input()
  isTypeEditable?: boolean;

  @Input()
  showEditButton: boolean;

  @Output()
  outputEvent = new EventEmitter<any>();

  typeNotEditableMessage = "";

  testType = "";

  testSubtype = "";

  faculty = "";

  courseAndSection = "";

  typeUtils = new TypeUtils();

  constructor() { }

  ngOnInit(): void {
    this.testType = this.typeUtils.typeLabel(this.testDef.testType);
    this.testSubtype = this.typeUtils.typeLabel(this.testDef.testSubtype);
    const course: Course = this.testDef.course;
    this.faculty = 'SGS' === course.primaryOrgCode ?
      (course.primaryOrgCode + '-' + course.coSecondaryOrgCode + '-' + course.secondaryOrgCode)
      : (course.primaryOrgCode + '-' + course.secondaryOrgCode);
    this.courseAndSection =
      course.code + ' ' + course.sectionCode + ', ' + course.teachMethod + ' ' + course.sectionNumber;
  }

  editType(): void {
    this.outputEvent.emit();
  }

}
