import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
import {Observable, Subject} from 'rxjs';
import {ModalDismissReasons, NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {LogService} from '../services/log-service.service';
import {INavigateAway} from '../types/inavigate-away';
import {Yes} from '../types/yes.enum';
import {UnsavedChangesComponent} from "../components/unsaved-changes/unsaved-changes.component";

/**
 * Name: UnsavedGuard
 * Desc:
 *    If the user tries to navigate away from an edited but not saved form, this guard
 *    will display a modal window asking if navigation away should be allowed without saving the data first.
 *
 */

@Injectable({
  providedIn: 'root'
})
export class UnsavedGuard  {

  constructor(private modalService: NgbModal, private logger: LogService) { }

  canDeactivate(
    component: INavigateAway,
    currentRoute: ActivatedRouteSnapshot,
    currentState: RouterStateSnapshot,
    nextState?: RouterStateSnapshot): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {

    const subject = new Subject<boolean>();

    this.logger.info(`=> Can leave page: ${component.canNavigateAway()}`, 'UnsavedGuard.canDeactivate()');

    // Navigate away is based on the user feedback from the popup modal.
    // i.e. Reason to not allow navigating away from the page: user edited a data in a form but forgot to save it.
    if (!component.canNavigateAway()) {
      const modalRef = this.modalService.open(UnsavedChangesComponent);
      modalRef.result.then(
        (result) => {
          this.logger.debug(`==> Button clicked: [${result}]`, this.constructor.name);

          if (result === Yes.VALUE) {
            subject.next(true);
            subject.complete();
          } else {
            subject.next(false);
            subject.complete();
          }
        },
        (reason) => {
          this.logger.debug(`==> Dismissed ${this.getDismissReason(reason)}`, this.constructor.name);
        });

      return subject;
    }

    return true;
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

}
