<div class="app-container">
  <!-- this is needed by the toast to show up; added here instead of to every individual page. -->
  <sis-toast-container></sis-toast-container>
  <!-- Header -->
  <app-header></app-header>
  <!-- Content -->
  <app-content></app-content>
  <!-- Footer -->
  <app-footer></app-footer>
</div>

<!-- The spinner component is conditionally shown, only when an HttpClient call is in progress -->
<app-spinner></app-spinner>
