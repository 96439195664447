<div class="card">
  <div class="card-body">
    <h3 class="card-title">Class Writing Schedule</h3>
    <div class="row">
      <div class="col-12 card-text">
        <div class="row">
          <div class="col"><strong>Start Date</strong></div>
          <div class="col"><strong>Start Time</strong></div>
          <div *ngIf="classWriting.endDate" class="col"><strong>End Date</strong></div>
          <div class="col"><strong>End Time</strong></div>
          <div class="col"><strong>Duration</strong></div>
        </div>
        <div class="row">
          <div class="col">{{classWriting.startDate}} </div>
          <div class="col">{{convertTimeStrToDate(classWriting.startTime) | date: 'hh:mm a'}}</div>
          <div *ngIf="classWriting.endDate" class="col">{{classWriting.endDate}}</div>
          <div class="col">{{convertTimeStrToDate(classWriting.endTime)  | date: 'hh:mm a'}}</div>
          <div class="col">
            <span class="underline-dotted"
                  [ngbTooltip]="timingIndTooltipMap.get(classWriting.assessmentTimingInd) || 'Not Available'"
                  placement="bottom">
              <span>{{duration}}</span>
            </span>
          </div>
        </div>
        <div class="row mt-2">
          <div class="col-6"><strong>Location</strong></div>
        </div>
        <div class="row">
          <div class="col-6">{{classWriting.location}}</div>
        </div>
      </div>
    </div>
  </div>
</div>
