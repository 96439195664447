<div>
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>{{title}}</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>

  <div>
    <strong>Note: </strong>All fields are required unless otherwise specified.
  </div>
  <div>The email will be sent to selected course contacts from test.exam@utoronto.ca.</div>

  <div id="selected-test-def-info">
    <div class="row">
    <div class="col-9 send-bulk-email-content margin-top-2-rem">
      <div>
        <h3>Selected Test Definition ({{currentTestDefIndex + 1}} out of {{maxTestDefIndex + 1}})</h3>
      </div>

      <!-- Test def info -->
      <div class="margin-top-2-rem">
        <app-test-def-info [testDefinition]="testDefinition"></app-test-def-info>
      </div>

      <div *ngIf="testDefHasContacts">
        <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER">
          <h4 class="margin-top-2-rem">1. Select Type of Reminder email</h4>
        </div>

        <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER">
          <div class="row">
            <div class="col-12">
              <app-type-of-reminder-email [emailReminderTypeCtrl]="currentEmailReminderTypeCtrl"></app-type-of-reminder-email>
            </div>
          </div>
        </div>

        <h4 class="margin-top-2-rem">
          <span *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER">2. </span>
          Choose Course Contacts & Select Sending Action
        </h4>

        <div class="row">
          <div class="col-10">
           {{Utils.getChooseCourseContactsText(emailType)}}
          </div>
        </div>

        <!-- Local Contacts course notes -->
        <div class="margin-top-2-rem">
          <div class="row">
            <div class="col-12">
              <strong>Course Contact Notes:</strong>
            </div>
            <div class="col-12">
              {{localContactsNotes || 'None'}}
            </div>
          </div>
        </div>

        <!-- Table with contacts -->
        <div class="margin-top-2-rem">
          <app-send-email-table [emailContactRec]="emailContactRec"></app-send-email-table>
        </div>

        <!-- Button: Send Email & Go to Next Test Definition -->
        <div class="margin-top-2-rem">
          <div *ngIf="currentTestDefIndex <= maxTestDefIndex + 1">
            <sis-button
              visualType="primary"
              size="medium"
              (clicked)="sendBulkEmail()"
              [popoverBody]="(emailType === EmailReminder.ONE_DAY_REMINDER && !currentEmailReminderTypeCtrl.valid) || sendingEmails ?
                    'To send the email, please ensure you’ve selected the reminder email you would like to send.' : ''">
              {{sendEmailButtonText}}
            </sis-button>
          </div>
        </div>
      </div>

      <!-- Info message -->
      <div *ngIf="!testDefHasContacts" class="margin-top-2-rem">
        <sis-notice
          type="information"
          introductoryText="{{this.Utils.infoMsg_TITLE_NO_CONTACTS_FOUND_FOR_TEST_DEF}}.">
          <span [innerHTML]="infoMsg"></span>

          <div class="margin-top-2-rem">
            <sis-button
              visualType="primary"
              size="small"
              (clicked)="sendBulkEmail()">
              {{sendEmailButtonText}}
            </sis-button>
          </div>
        </sis-notice>
      </div>
    </div>
  </div>
  </div>

  <!-- Error message -->
  <div *ngIf="errorMsg" class="margin-top-2-rem">
    <sis-notice
      type="warning"
      introductoryText="Something went wrong:">
      <span [innerHTML]="errorMsg"></span>
    </sis-notice>
  </div>

  <!-- Back to Instructor Emailing -->
  <div class="margin-top-2-rem">
    <a [routerLink]="['/instructor-emailing']">Back to Instructor Emailing Dashboard</a>
  </div>

</div>
