/*
 Copied from Timetable builder at: 3b651a5401b84d5b41b4411326462c04ec5c2bc8 2020-02-10
 */
import {Injectable, Injector} from "@angular/core";
import {HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from "@angular/common/http";
import {Observable, of} from "rxjs";
import {Environment} from "../../environment";

// commented out but kept to show format for importing json data.
// import * as currentSession from './data/currentSession.json';
// import * as matchingCourses from './data/matchingCourses.json';
// import * as courseDetails from './data/courseDetails.json';
// import * as timetable from "./data/timetable.json";
// import * as MATA22H3 from "./data/MATA22H3.json";
// import * as MATA23H3 from "./data/MATA23H3.json";
// import * as MATA29H3_20189 from "./data/MATA29H3-20189.json";
// import * as MATA29H3_20191 from "./data/MATA29H3-20191.json";
// import * as coursesKJ from "./data/coursesKJ.json";
// import * as MATD94H3 from "./data/MATD94H3.json";
// import * as tinyUrl from "./data/tinyUrl.json";

const responseMap = [
    {
        url: Environment.restUrl + "TtbService.CURRENT_SESSION_URL",
        json: "currentSession", // actually a variable loaded above like 'import * as currentSession from "./data/setup.json"'
        paramsMap: {}
    }
];

@Injectable()
export class DesignHttpRequestInterceptor implements HttpInterceptor {
    constructor(private injector: Injector) {
    }

    getResponse(request: HttpRequest<any>) {
        for (const element of responseMap) {
            if (request.url.startsWith(element.url)) {
                const param = request.params.keys().reduce((accumulator, key) => accumulator + request.params.get(key), "");
                if (param && element.paramsMap[param]) {
                    return of(new HttpResponse({status: 200, body: ((element.paramsMap[param]) as any).default}));
                }
                return of(new HttpResponse({status: 200, body: ((element.json) as any).default}));
            }
        }
        console.log('Could not find a match for the call to: ' + request.method + ' ' + request.url);
        return undefined;
    }

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        console.log('Intercepting the call to ' + request.method + ' ' + request.url);
        if (request.method === 'GET') {
            return this.getResponse(request);
        } else {
            console.log('Ignoring request calls with method: ' + request.method);
            console.log('Trying to find a matching response for : ' + request.url);
            return this.getResponse(request);
        }
    }


}
