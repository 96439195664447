import {Component, Inject, Input, OnInit} from '@angular/core';
import {DesignateAssignment} from "../../model/types/designate-assignment";
import {AssignmentLevel} from "../../common/types/assignment-level.enum";
import {HttpClient} from "@angular/common/http";
import {environment} from "../../../environments/environment";
import {PhonebookPerson, PhonebookPhone, PhonebookPosition} from "../../model/types/phonebook/PhonebookSearch";
import {DesignatesUtil} from "../designates-util.util";
import {PHONEBOOK_API, PhonebookApi} from "../../common/services/phonebook-api";
import {DATA_SOURCE} from "../../model/data-sources/data-source";

/**
 * Displays, read-only, the designate name and the org or course it is binding to.
 * i.e.
 *   Designate: Cameron Black
 *   Level: Undergraduate
 *   Division: Faculty of Arts & Science
 *   Department: Department of art History
 * or:
 *  Designate: Cameron Black
 *  Course Code: ARTSC
 *  Section Code: ANA
 *  Session Code: 20209
 *
 *  Used in CourseDesigateTableComponent and OrgDesigateTableComponent components to display read-only info.
 */
@Component({
  selector: 'app-desig-binding',
  templateUrl: './desig-binding.component.html',
  styleUrls: ['./desig-binding.component.scss']
})
export class DesigBindingComponent implements OnInit {

  @Input()
  desigAssignment: DesignateAssignment;

  // Enumeration
  assignmentLevel = AssignmentLevel;

  phone = "";

  constructor(@Inject(PHONEBOOK_API) private phonebookApi: PhonebookApi, private httpClient: HttpClient) {
  }

  ngOnInit(): void {
    if (this.desigAssignment.designates[0] && this.desigAssignment.designates[0].email) {
        this.phonebookApi.getPhonebookSearchByEmail(this.desigAssignment.designates[0].email).subscribe(phonebookSearch => {
        let phoneNumber = "";
        let departmentId;

        if (phonebookSearch.personList && phonebookSearch.personList.length) {
          const person: PhonebookPerson = phonebookSearch.personList[0];
          if (person.positionList && person.positionList.length) {

            // get phoneNumber
            person.positionList.some((position: PhonebookPosition) => {
              if (position.phoneList) {
                return position.phoneList.some((phonebookPhone: PhonebookPhone) => {
                  if (phonebookPhone.type === "Office") {
                    phoneNumber = phonebookPhone.phoneNum;
                    return true;
                  } else {
                    return false;
                  }
                });

              } else {
                return false;
              }
            });

            if (phoneNumber === "") {
              // get department id
              person.positionList.some((position: PhonebookPosition) => {
                if (position.departmentId !== null) {
                  departmentId = position.departmentId;
                  return true;
                } else {
                  return false;
                }
              });
              // get department phone number by department id
              if (departmentId !== null) {
                this.phonebookApi.getPhonebookDataByDepartmentId(departmentId)
                  .subscribe(value => {
                    if (value.details && value.details.length > 0) {
                      value.details.some(detail => {
                        if (detail.phoneList && detail.phoneList.length > 0) {
                          return detail.phoneList.some(eachPhone => {
                            if (eachPhone.type === 'Office') {
                              this.phone = eachPhone.phoneNum;
                              return true;
                            } else {
                              return false;
                            }
                          });
                        }
                      });
                    }

                  });
              }
            } else {
              this.phone = phoneNumber;
            }
          }
        }
      });
    }
  }

  getDesignateExpiryDateLabel(validUntilDate: Date): string {
    return DesignatesUtil.getDesignateExpiryDateLabel(validUntilDate);
  }
}
