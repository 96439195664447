import {Component, Input} from '@angular/core';
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {TestDef} from "../../model/types/test-def";
import {ToastService} from "@easi-sis/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {DeleteTestDefModalComponent} from "../delete-test-def-modal/delete-test-def-modal.component";
import {Router} from "@angular/router";
import {DeleteTestDefinitionError} from "../delete-test-definition-error.enum";

@Component({
  selector: 'app-delete-test-def',
  templateUrl: './delete-test-def.component.html',
  styleUrls: ['./delete-test-def.component.scss']
})
export class DeleteTestDefComponent {

  @Input()
  testDefinition: TestDef;

  // the number of test bookings this test definitions has
  @Input()
  numberOfBookings: number;

  // Holds the result returned by the backend call to delete test definition:
  // - null if the call succeeds,
  // - error string if the call fails
  deleteTestDefResult: string;

  // Possible errors when the test definition could not be deleted in Clockwork. Same enum as in back-end.
  CLOCKWORK_CALL_ERROR = DeleteTestDefinitionError.CLOCKWORK_DEL_TEST_DEF_CALL_ERR;
  TEST_DEF_HAS_BOOKING_ERROR = DeleteTestDefinitionError.CLOCKWORK_DEL_TEST_DEF_HAS_BOOKINGS_ERR;
  TEST_DEF_NOT_FOUND_IN_CLOCKWORK_ERR = DeleteTestDefinitionError.CLOCKWORK_DEL_TEST_DEF_NOT_IN_CLOCKWORK_ERR;
  TEST_DEF_NOT_CANNOT_BE_DELETED_ERR = DeleteTestDefinitionError.CLOCKWORK_DEL_TEST_CANNOT_DELETE_RELATED_INFO_ERR;

  constructor(private modelService: ModelService,
              private router: Router,
              private toastService: ToastService,
              private modalService: NgbModal,
              private logger: LogService) {}

  deleteTestDef() {
    this.logger.debug(`=> Deleting the test def with test def ID: ${this.testDefinition.testDefId}`,
      `${this.constructor.name}`);

    // Open a modal for user to confirm the test definition deletion
    const modalRef: NgbModalRef = this._openModal();

    // Process the user response
    modalRef.result.then(
      (result) => {
        this.logger.debug(`-> modal result: ${JSON.stringify(result)}`, `${this.constructor.name}`);
        // If user response was 'yes', delete the test definition
        if (result === 'yes') {
          this._deleteTestDefinition();
        }
      }
    );
  }

  _deleteTestDefinition(): void {
    this.modelService.deleteTestDef(this.testDefinition.testDefId)
      .subscribe( (result: string) => {
        this.deleteTestDefResult = result;

        if (result === null) {
          // -- successfully deleted the test def in ATS and Clockwork
          // Navigate to test definition dashboard
          this.router.navigateByUrl("test-definitions");

          // Display a Success toast
          const msg = `You have successfully deleted the test definition
          (Exam ID: ${this.testDefinition.clockworkExamId}, ATS Test Definition ID: ${this.testDefinition.testDefId}).`;
          this.toastService.show({type: 'success', action: msg});

        } else if (result === this.CLOCKWORK_CALL_ERROR) {
          // -- error on calling clockwork
          this.logger.debug(`=> Error in clockwork: delete clockwork test def call failure - [test def ID]:
                                ${this.testDefinition.testDefId}`, `${this.constructor.name}`);

        } else if (result === this.TEST_DEF_HAS_BOOKING_ERROR) {
          this.logger.debug(`=> Error in clockwork: cannot delete test def as it has bookings - [test def ID]:
                                ${this.testDefinition.testDefId}`, `${this.constructor.name}`);

        } else if (result === this.TEST_DEF_NOT_FOUND_IN_CLOCKWORK_ERR) {
          this.logger.debug(`=> Error in clockwork: cannot delete test def as it does not exist in clockwork - [test def ID]:
                                ${this.testDefinition.testDefId}`, `${this.constructor.name}`);
        } else if (result === this.TEST_DEF_NOT_CANNOT_BE_DELETED_ERR) {
          this.logger.debug(`=> Error in clockwork: cannot test def as it canot delete related info in dbo.OtherInfoInstructorPM in database - [test def ID]:
                                ${this.testDefinition.testDefId}`, `${this.constructor.name}`);
        }
      });
  }

  _openModal(): NgbModalRef {
    const modalRef = this.modalService.open(DeleteTestDefModalComponent);
    modalRef.componentInstance.testDefinition = this.testDefinition;
    modalRef.componentInstance.numberOfBookings = this.numberOfBookings;
    return modalRef;
  }
}
