import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {EmailReminderType} from "../email-reminder-type.enum";
import {EmailReminder} from "../email-reminder.enum";
import {FormControl, Validators} from "@angular/forms";

@Component({
  selector: 'app-type-of-reminder-email',
  templateUrl: './type-of-reminder-email.component.html',
  styleUrls: ['./type-of-reminder-email.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TypeOfReminderEmailComponent implements OnInit {

  @Input()
  // Radio-button control to choose the type of email reminder - mandatory
  // (Reminder to input CIS submission, Reminder to apply accommodations)
  emailReminderTypeCtrl: FormControl<string>;

  ngOnInit(): void {
    this.emailReminderTypeCtrl.setValidators(Validators.required)
  }

  protected readonly Utils = InstructorEmailingUtils;
  protected readonly EmailReminderType = EmailReminderType;
  protected readonly EmailReminder = EmailReminder;
}
