import {AbstractControl, ValidatorFn} from "@angular/forms";

/**
 * @description
 * Provides a set of custom validators that can be used by form controls.
 *
 * A validator is a function that processes a `FormControl` or collection of
 * controls and returns an error map or null. A null map means that validation has passed.
 *
 */
export class CustomValidators {

  /**
   * A regular expression that matches valid U of T e-mail addresses.
   */
  static EMAIL_REGEXP = /^([a-zA-Z0-9_.+-])+\@(utoronto.ca|.+\.utoronto.ca|toronto.edu|.+\.toronto.edu)$/;

  static DATE_REGEX = /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/;

  /**
   * @description
   * Determines if the supplied string represents a valid U of T e-mail address.
   *
   * At a high level, this regexp matches e-mail addresses of the format `local-part\@tld`,
   *   where:
   * - `local-part` consists of one or more of the allowed characters (alphanumeric and some punctuation symbols).
   * - `tld` consists of one or more `labels` separated by periods (`.`). For example `utoronto.ca`.
   * - A `label` consists of one or more of the allowed characters (alphanumeric, dashes (`-`) and periods (`.`)).
   *
   * Valid U of T email addresses are the following:
   *   `local-part`@utoronto.ca
   *   `local-part`@`label`.utoronto.ca
   *   `local-part`@toronto.edu
   *   `local-part`@utoronto.edu
   *
   * The validator exists only as a function and not as a directive.
   *
   * Evaluates using the below Regular Expression:
   *      /^([a-zA-Z0-9_.+-])+\@(utoronto.ca|.+\.utoronto.ca|toronto.edu|utoronto.edu)$/
   *
   * @returns A validator function that returns an error map with the
   *          `email` property if the validation check fails, otherwise `null`.
   *
   *
   * @param  A regular expression of the email address.
   */
  static email(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (CustomValidators._isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      return this.EMAIL_REGEXP.test(control.value) ? null : {email: true};
    };
  }

  /**
   * @description
   * Determines if the supplied date represents a valid date.
   *
   * A valid date should be in the following format:
   *     'YYYY-MM-DD' where MM is 1 to 12, DD is 1 to 31.
   *     Note: does not validate leap years months.
   *
   * The validator exists only as a function and not as a directive.
   *
   * Evaluates using the below Regular Expression:
   *      /^\d{4}\-(0?[1-9]|1[012])\-(0?[1-9]|[12][0-9]|3[01])$/
   *
   * @returns A validator function that returns an error map with the
   *          `date` property if the validation check fails, otherwise `null`.
   *
   *
   * @param  An AbstractControl object.
   */
  static date(): ValidatorFn | null {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (CustomValidators._isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      return this.DATE_REGEX.test(control.value) ? null : {date: true};
    };
  }

  /**
   * @description
   * Determines if the supplied phone number is valid based on its expressed number of digits.
   *
   * The validity of the string value will be determined by counting the number of digit characters
   * within the string (all non-digits are ignored).  If this count is exactly 10, the value is
   * considered a valid phone number; otherwise, the value is considered invalid.
   *
   * @returns A validator function that returns an error map with the
   *          `phone` property if the validation check fails, otherwise `null`.
   */
  static phone(): ValidatorFn {
    return (control: AbstractControl): {[key: string]: any} | null => {
      if (CustomValidators._isEmptyInputValue(control.value)) {
        return null; // don't validate empty values to allow optional controls
      }

      /* Validate phone numbers - we don't really care about the format, since the API requires a string
       * of 10 digits only (i.e. no other characters permitted, including -, etc.). So we'll just strip
       * out the non-digits. */
      const phone = control.value.trim().replace(/\D/g, "");

      return phone.length === 10 ? null : {phone: true};
    };

  }

  /**
   * Conditional validator will add a validator (standard or custom) when the  predicate execution returns true.
   * The condition usually is based on the the form radio-buttons or check-boxes.
   *
   * Example of conditional validator:
   *      course: ['', CustomValidators.conditionalValidator(
   *             () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.COURSE, Validators.required)]
   *  where:
   *     () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.COURSE  - is the predicate;
   *     Validators.required - is the standard 'required' validator;
   *
   *  In the above example, the Validators.required will be added to
   *  the course FormController only if the 'assignmentLevel' controller is a course.
   *
   * @param predicate  A function which when executing returns 'true' or 'false.
   *                   i.e. () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.COURSE
   * @param validator  A standard or custom validator (i.e. Validators.required)
   */
  static conditionalValidator(predicate, validator) {
    return (control: AbstractControl): {[key: string]: any} | null => {
      // this is conditional on parent; if there is no parent to not create any validator
      if (!control.parent) {
        return null;
      }
      if (predicate()) {
        return validator(control);
      }
      return null;
    };
  }

  private static _isEmptyInputValue(value: any) {
    // we don't check for string here so it also works with arrays
    return value == null || value.length === 0;
  }
}
