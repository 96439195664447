export class Org {

  id: number; // the organization id (org_id)

  /* Faculty code: ie. ARTSC */
  facultyCode: string;

  /* Department code: i.e. AE1 */
  departmentCode: string;

  /* If 'true' the faculty/dept is for Graduates, otherwise is for Undergraduates */
  graduateInd: boolean;

  version: number;

  constructor() {}
}
