import {DesignateStatusName} from "./designate-status-name.enum";
import {DesignateStatusValue} from "./designate-status-value.enum";

/**
 * The designate status.
 * Used in the 'Filter by Valid Status' drop-down.
 * Every object represents a designate status. An array of these objects will be used to show all valid designate statuses.
 */
export class DesignateStatus {

  static readonly ALL_DESIGNATES = new DesignateStatus(DesignateStatusName.ALL_DESIGNATES, DesignateStatusValue.ALL_DESIGNATES);
  static readonly CURRENTLY_VALID = new DesignateStatus(DesignateStatusName.CURRENTLY_VALID, DesignateStatusValue.CURRENTLY_VALID);
  static readonly EXPIRED = new DesignateStatus(DesignateStatusName.EXPIRED, DesignateStatusValue.EXPIRED);

  constructor(private label: string, public value: string) {
  }
}
