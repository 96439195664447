import {ChangeDetectionStrategy, Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {EmailReminder} from "../email-reminder.enum";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {TestDefinition} from "../../model/types/test-definition";
import {EmailReminderType} from "../email-reminder-type.enum";

@Component({
  selector: 'app-send-email-modal',
  templateUrl: './send-email-modal.component.html',
  styleUrls: ['./send-email-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SendEmailModalComponent implements OnInit {

  @Input()
  testDefinition: TestDefinition;

  @Input()
  emailType: EmailReminder;

  @Input()
  emailReminderType: EmailReminderType;

  modalTitle: string;
  testDefInfo: string;
  displayReminderType: string;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {
    this._createModalTitle();
    this._formatTestDefForDisplay();
    this._reminderType();
  }


  _createModalTitle(): void {
    this.modalTitle = `${this.Utils.emailReminderMap.get(this.EmailReminder[this.emailType])} Email`;
  }

  _formatTestDefForDisplay(): void {
    this.testDefInfo = `${this.Utils.formatTestDefinitionForSorting(this.testDefinition)}
           (Test Definition ID: ${this.testDefinition.testDefinitionId})`;
  }

  _reminderType() {
    // Type of reminder email. Only for 1-day: 'Reminder to input CIS submission' or 'Reminder to apply accommodations'.
    this.displayReminderType = (this.emailType === this.EmailReminder.ONE_DAY_REMINDER) ?
      `(${this.Utils.emailReminderTypeMap.get(this.emailReminderType)})` : '';
  }


  protected readonly EmailReminder = EmailReminder;
  protected readonly Utils = InstructorEmailingUtils;
}
