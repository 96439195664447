import {Component, EventEmitter, Input, Output} from '@angular/core';
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {CustomValidators} from "../../validator/custom-validators";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";

@Component({
  selector: 'app-contact-detail',
  templateUrl: './contact-detail.component.html',
  styleUrls: ['./contact-detail.component.scss']
})
export class ContactDetailComponent {

  @Input()
  contactDetailForm: FormGroup;

  @Input()
  contactArrayIndex: number;

  @Input()
  totalContacts: number;

  @Input()
  editContacts: boolean;

  @Output()
  deleteContactEvent: EventEmitter<number> = new EventEmitter();

  contactTypes = InstructorEmailingUtils.contactTypes

  constructor() {
  }

  get contactType(): FormControl {
    return this.contactDetailForm?.get('contactType') as FormControl;
  }
  get contactName(): FormControl {
    return this.contactDetailForm?.get('contactName') as FormControl;
  }

  get contactEmail(): FormControl {
    return this.contactDetailForm?.get('contactEmail') as FormControl;
  }

  get expiryDate(): FormControl {
    return this.contactDetailForm?.get('expiryDate') as FormControl;
  }

  static addContactDetails(): FormGroup {
    return new FormGroup<any>({
      contactType: new FormControl('', [Validators.required]),
      contactName: new FormControl('', [Validators.required]),
      contactEmail: new FormControl('', [Validators.required, Validators.email]),
      expiryDate: new FormControl('', [CustomValidators.date()])
    });
  }

  deleteContact(index: number): void {
    this.deleteContactEvent.next(index);
  }
}
