import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {ValidationService} from "../validation-service";

/**
 * Displays a standard validation error message if the supplied control (AbstractControl) contains errors.
 *
 * Inputs:
 *   control: AbstractControl  - Mandatory. The FormControl.
 *   warning: boolean          - Optional. If true, the validation message will be displayed as a warning.
 *   customErrorMsgs: {[key: string]: string}
 *                             - Optional. If supplied, the custom message will be returned.
 *                               The 'key' is the error message name and the value is the custom validation message.
 *                               i.e. { required: 'Invalid date selected' }
 * Usage example:
 * <app-validation-message [control]="datepickerForm.get('date')"
 *                         [warning]="true"
 *                         [customErrorMsgs]="customErrorMsgs">
 * </app-validation-message>
 */
@Component({
  selector: 'app-validation-message',
  template: `<div *ngIf="errorMessage !== null"
                  [ngClass]="warning === true ? 'warning' : 'error'">
               {{errorMessage}}
             </div>
            `,
  styleUrls: ['./validation-message.component.scss']
})
export class ValidationMessageComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() warning = false;
  @Input() customErrorMsgs: {[key: string]: string};

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Returns an validation message if the input control contains errors, null otherwise.
   */
  get errorMessage(): string | null {

    if (this.control && this.control.hasOwnProperty('errors')) {
      for (const propertyName in this.control.errors) {
        if (this.control.errors.hasOwnProperty(propertyName) && (this.control.dirty || this.control.touched) && this.control.errors) {
          // If custom error message was supplied, use that message, otherwise use the standard one
          if (this.customErrorMsgs && this.customErrorMsgs.hasOwnProperty(propertyName)) {
            return this.customErrorMsgs[propertyName];
          }
          return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }
    return null;
  }

}
