import {Accommodation} from "./accommodation";

export class StudentAccommodations {

  studentId: number;
  expiryDate: Date;
  consentDate: Date;
  options: Map<string, Accommodation[]>;

  constructor() {}
}
