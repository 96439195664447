
<!-- The StudentSearchModalComponent template -->

<div class="remove-designate">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Student Search</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Modal header -->
  <div class="modal-body">
    <div class="row">
      <!-- Student search -->
      <div class="col-10">
        <label class="form-label" for="filterInput">Search by Student Name, or Student Number</label>
        <div id="filterInput">
          <app-student-search [student]="student"></app-student-search>
        </div>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer d-flex justify-content-start">
    <!-- View Profile button -->
    <div>
      <button class="btn btn-sm btn-primary" [disabled]="!student.value.atsStudentId" (click)="viewProfile()">
        View Profile
      </button>
    </div>
    <!-- Cancel hyperlink -->
    <div>
      <button class="btn btn-sm btn-link" (click)="activeModal.close('')">
        Cancel
      </button>
    </div>
  </div>
</div>
