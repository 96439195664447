import {Component, OnInit, ViewChild} from '@angular/core';
import {StudentBooking} from '../../../model/types/student-booking';
import {ActivatedRoute, Router} from '@angular/router';
import {LogService} from '../../../common/services/log-service.service';
import {INavigateAway} from '../../../common/types/inavigate-away';
import {Observable} from 'rxjs';

import {ActivityLogAndNotesTabComponent} from '../activity-log-and-notes-tab/activity-log-and-notes-tab.component';
import {TestDetailsTabComponent} from '../test-details-tab/test-details-tab.component';
import {StudBookingTabComponent} from "../stud-booking-tab/stud-booking-tab.component";
import {ConsentMessage} from "../../../common/types/consent-message";
import {ActivityLogType} from "../../../common/types/activity-log-type.enum";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {CancelBookingModalComponent} from "../cancel-booking-modal/cancel-booking-modal.component";
import {ModelService} from "../../../model/model.service";
import {ToastService} from "@easi-sis/core";
import {UpdatedBookingStatus} from "../../../model/types/updated-booking-status";
import {FormControl} from "@angular/forms";
import {BookingStatus} from "../../types/booking-status.enum";
import {UpdateBookingFailureMsg} from "../../types/update-booking-failure-msg";

/**
 * Component: StudentBookingComponent
 * Desc:
 *    Shows booking data specific to a given student and course section.
 *    It contains other components like ScheduleCardComponent, CourseContactsCardComponent, StudentBookingTabComponent.
 * Note:
 *    It implements the INavigateAway used in guarding the route.
 */
@Component({
  selector: 'app-student-booking',
  templateUrl: './student-booking.component.html',
  styleUrls: ['./student-booking.component.scss']
})
export class StudentBookingComponent implements OnInit, INavigateAway {

  // Student Booking data retrieved from the back-end
  booking: StudentBooking;

  // The button user clicks to Cancel/Undo Cancellation.
  buttonText: string;
  // The error message to display if the changing of the booking status fails.
  errorMsg: string;

  // Booking status enum
  BOOKED: BookingStatus = BookingStatus.BOOKED;
  CANCELLED: BookingStatus = BookingStatus.CANCELLED;

  bookingNotes = new FormControl<string>('');

  // If updating booking status succeeds, this value will be null, otherwise will be the error name.
  updateBookingStatusResult: string;

  // Do not remove the tabs from the DOM when navigating between them.
  removeTabOnHide = false;

  // To show label "Issues: Accom. conflict"in header if conflicts found.
  hasConflict = false;

  querrusConsentMessage = ConsentMessage.Quercus_Consent_Yes;
  querrusNotConsentMessage = ConsentMessage.Quercus_Consent_No;

  activityLogType: ActivityLogType = ActivityLogType.TEST_BOOKING;

  constructor(private activeRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private toastService: ToastService,
              private modelService: ModelService,
              private logger: LogService) {
    /*
     * Booking data is retrieved by the resolver service [StudentBookingResolverService].
     * Use the ActivatedRoute 'data.model' properties to get the data.
     */
    this.activeRoute.data.subscribe(data => {
      this.booking = data.model;
      logger.debug(`=> Student booking: ${JSON.stringify(this.booking)}`, `${this.constructor.name}`);
    });
  }

  @ViewChild(StudBookingTabComponent)
  studentBookingTabComp: StudBookingTabComponent;

  @ViewChild(TestDetailsTabComponent)
  testDetailsTabComp: TestDetailsTabComponent;

  @ViewChild(ActivityLogAndNotesTabComponent)
  activityLogAndNotesTabComp: ActivityLogAndNotesTabComponent;

  ngOnInit(): void {
    if (this.booking.bookingStatus === this.BOOKED) {
      this.buttonText = 'Cancel Booking';

    } else if (this.booking.bookingStatus === this.CANCELLED) {
      this.buttonText = 'Undo Cancellation';

    } else {
      this.buttonText = 'N/A';
    }
  }

  /** INavigateAway interface method */
  canNavigateAway(): Observable<boolean> | Promise<boolean> | boolean {
    return this.studentBookingTabComp && this.studentBookingTabComp.canNavigateAway()
           && this.testDetailsTabComp && this.testDetailsTabComp.canNavigateAway()
           && this.activityLogAndNotesTabComp && this.activityLogAndNotesTabComp.canNavigateAway();
  }

  // Method called when Cancel/Undo Cancellation button is clicked
  updateBookingStatus(): void {
    this.logger.debug(`=> Updating booking status for booking with ID: ${this.booking.id}`, `${this.constructor.name}`);

    // Open a modal for user to confirm the test booking cancellation.
    const modalRef: NgbModalRef = this._openModal();

    // Process the user response
    modalRef.result.then(
      (result: string) => {
        this.logger.debug(`-> modal result: ${JSON.stringify(result)}`, `${this.constructor.name}`);

        // The user response, from the modal, will become the new booking status ('Cancelled' or 'Booked').
        if (result === this.BOOKED || result === this.CANCELLED) {
          this._updateBookingStatus(result);
        }
      },
      (reason) => {
        this.logger.debug(`=> Reason: ${JSON.stringify(reason)}`, `${this.constructor.name}`);
      }
    );
  }

  // Update the new booking status in the persistent store.
  _updateBookingStatus(newBookingStatus: string) {

    const bookingNotes = this.bookingNotes.value;
    const bookingStatus =
      new UpdatedBookingStatus(this.booking.id, newBookingStatus, bookingNotes);

    // Do a call to the backend to update the booking status.
    this.modelService.updateBookingStatus(bookingStatus).subscribe(
      (result: string) => {
        this.updateBookingStatusResult = result;

        if (result === null) {
          // Navigate to test definition dashboard
          this.router.navigateByUrl('/').then(r => {});

          // Display a Success toast
          let msg = (newBookingStatus === this.CANCELLED) ?
                                 `You have successfully cancelled this booking` :
                                  `You have successfully undone the cancellation for this booking`;
          msg = msg + ` (Booking ID: ${this.booking.id}, Appointment ID: ${this.booking.clockworkAppointmentId}).`;

          this.toastService.show({type: 'success', action: msg});

        } else {
          // If the result is not null, it means there was an error. Create the error msg to be shown on front-end.
          this.errorMsg = (new UpdateBookingFailureMsg()).createMsg(result, newBookingStatus);
        }
      });
  }

  // Open a modal and pass in the data to be displayed
  _openModal(): NgbModalRef {
    const modalRef = this.modalService.open(CancelBookingModalComponent);
    modalRef.componentInstance.booking = this.booking;
    modalRef.componentInstance.bookingNotes = this.bookingNotes;
    return modalRef;
  }

  hasConflicts($event: any) {
    this.hasConflict = $event;
  }

  updateBookingLog() {
    this.activityLogAndNotesTabComp.updateBookingLog();
  }
}
