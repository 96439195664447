import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {ModelService} from "../../model/model.service";
import {MatTableDataSource} from "@angular/material/table";
import {CourseContacts} from "../../model/types/course-contacts";
import {ContactDetails} from "../../model/types/contact-details";
import {CourseLevelOfInstr} from "../../model/types/course-level-of-instr";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {MatSort} from "@angular/material/sort";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {LogService} from "../../common/services/log-service.service";
import moment from "moment";
import {DateFormatConstants} from "../../common/types/dateFormatConstants";

export interface CourseContactRec {
  course: CourseLevelOfInstr;
  levelOfStudy: string;
  levelOfStudyVal: string;
  contactName: string;
  contactType: string;
  contactTypeVal: string;
  emailAddress: string;
  validUntil: string;
  notes: string;
  hide: boolean;
  expiredDate: boolean;
}

@Component({
  selector: 'app-course-contacts-dashboard',
  templateUrl: './course-contacts-dashboard.component.html',
  styleUrls: ['./course-contacts-dashboard.component.scss']
})
export class CourseContactsDashboardComponent implements OnInit,  AfterViewInit {

  displayColumns: string[] = ['Course', 'Level of Study', 'Contact Name', 'Contact Type', 'Email Address', 'Valid Until', 'Notes', ' '];

  dataSource: MatTableDataSource<CourseContactRec>;

  currentDate = moment().format(DateFormatConstants.YYYYMMDD);

  constructor(private router: Router,
              private model: ModelService,
              private logger: LogService) {
  }

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // Maps
  levelOfStudyMap: Map<string, string> = InstructorEmailingUtils.levelOfStudyMap;
  reversedLevelOfStudyMap: Map<string, string> = InstructorEmailingUtils.reversedLevelOfStudyMap;
  contactTypeMap: Map<string, string> = InstructorEmailingUtils.contactTypeMap;

  ngOnInit(): void {

    this.model.getCourseContactList().subscribe( (courseContactList: CourseContacts[]) => {
      const courseContactRecList: CourseContactRec[] = this._fillEmptyFieldsForEveryContact(courseContactList);
      this.dataSource = new MatTableDataSource<CourseContactRec>(courseContactRecList);

      this._processCourseContactsRec();
    });
  }

  ngAfterViewInit(): void {
    // this.dataSource.paginator = this.paginator;
  }

  addEmailContact() {
    // this.router.navigateByUrl('/instructor-emailing/email-contacts');

    this.router.navigate(['/instructor-emailing/email-contacts'],
      { queryParams: { edit: false }});
  }

  editContactDetails(courseContact: CourseContactRec) {
    const courseAsJson = JSON.stringify(courseContact.course);
    this.logger.debug(`=> courseAsJson: ${courseAsJson}`, `${this.constructor.name}`);

    this.router.navigate(['instructor-emailing/email-contacts'],
      { queryParams: { edit: true,
          course: courseContact.course?.course,
          sectionCode: courseContact.course?.sectionCode,
          teachMethod: courseContact.course?.teachMethod,
          sectionNumber: courseContact.course?.sectionNumber,
          levelOfStudy: this.reversedLevelOfStudyMap.get(courseContact.levelOfStudy)
        }
      });
  }

  _processCourseContactsRec() {

    this.dataSource.sortingDataAccessor = (data: CourseContactRec, sortHeaderId: string) => {
      if (sortHeaderId === 'Course') {
        return InstructorEmailingUtils.formatCourseForSorting(data.course);

      } else if (sortHeaderId === 'Level of Study') {
        return data.levelOfStudy;

      } else if (sortHeaderId === 'Contact Name') {
        return data.contactName;

      } else if (sortHeaderId === 'Contact Type') {
        return data.contactType;

      } else if (sortHeaderId === 'Email Address') {
        return data.emailAddress;

      } else if (sortHeaderId === 'Valid Until') {
        return data.validUntil;
      }
    }


    this.dataSource.sort = this.sort;
    this.dataSource.paginator = this.paginator;
  }

  _fillEmptyFieldsForEveryContact(courseContactList: CourseContacts[]): CourseContactRec[] {
    const courseContactRecList: CourseContactRec[] = new Array();

    for (const courseContact of courseContactList) {

      const contactDetails: ContactDetails[] = courseContact.contacts;

      let i = 0;
      for (const contactDetail of contactDetails) {
        ++i;

        const courseContactRec: CourseContactRec = {

          course: courseContact.course,
          levelOfStudy: this.levelOfStudyMap.get(courseContact.levelOfStudy),
          levelOfStudyVal: courseContact.levelOfStudy,
          contactName: contactDetail.contactName,
          contactType: this.contactTypeMap.get(contactDetail.contactType),
          contactTypeVal: contactDetail.contactType,
          emailAddress: contactDetail.contactEmail,
          validUntil: contactDetail.expiryDate || 'Indefinite',
          notes: courseContact.additionalInfo,
          expiredDate: contactDetail.expiryDate < this.currentDate,
          hide: i > 1
        };
        courseContactRecList.push(courseContactRec);
      }
    }

    return courseContactRecList;
  }
}
