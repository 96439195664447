import { Component, OnInit } from '@angular/core';
import {ModelService} from "../model/model.service";

@Component({
  selector: 'app-version',
  templateUrl: './version.component.html',
  styleUrls: ['./version.component.scss']
})
export class VersionComponent implements OnInit {

  version: any;

  constructor(private modelService: ModelService) { }

  ngOnInit(): void {
    this.modelService.getVersion().subscribe( version => this.version = version);
  }

}
