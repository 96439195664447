import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AssignmentLevel} from "../../common/types/assignment-level.enum";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DesignateAssignment} from "../../model/types/designate-assignment";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {ModalDismissReasons, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {Course} from "../../model/types/course";
import {Designate} from "../../model/types/designate";
import {Org} from "../../model/types/org";
import {AssignDesignateComponent} from "../assign-designate/assign-designate.component";
import {RemoveDesigModalComponent} from "../remove-desig-modal/remove-desig-modal.component";
import {Column} from "../../common/types/column";
import {ColumnName} from "../../common/types/columnName";
import {SingleDesignateAssignment} from "../../model/types/single-designate-assignment";
import {Subscription} from "rxjs";
import {DesignatesUtil} from "../designates-util.util";
import {DesignateStatus} from "../designate-status";
import {UntypedFormControl} from "@angular/forms";
import {DesignateStatusValue} from "../designate-status-value.enum";

@Component({
  selector: 'app-course-desigate-table',
  templateUrl: './course-desigate-table.component.html',
  styleUrls: ['./course-desigate-table.component.scss']
})
export class CourseDesigateTableComponent implements OnInit, OnDestroy {
  @Input()
  isUserDesginatesAdmin: boolean;

  // use a constant as it will be used to split columns when exporting data to Excel file
  nameUTORidColumnName = ColumnName.NAME_UTORid;

  // Course designates tables columns
  courseColumns: string[] = ["Course Code", "Section Code", "Session Code", "Designate", "Permissions", "Valid Until", "Edit", "Remove", "View"];

  designateStatuses = [DesignateStatus.ALL_DESIGNATES, DesignateStatus.CURRENTLY_VALID, DesignateStatus.EXPIRED];
  statusFilter = new UntypedFormControl('');

  // hold the input text for filter
  textFilter = '';

  // the columns that need to be filtered by text
  filteredColumns: Column[] = [
    new Column(ColumnName.COURSE_CODE),
    new Column(ColumnName.SECTION_CODE),
    new Column(ColumnName.SESSION_CODE),
    new Column(ColumnName.DESIGNATE),
    new Column(ColumnName.PERMISSIONS),
    new Column(ColumnName.VALID_UNTIL)
  ];

  // Enumerations
  assignmentLevelEnum = AssignmentLevel;

  courseDataSource: MatTableDataSource<SingleDesignateAssignment>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort: MatSort;

  private subscription: Subscription;

  constructor(private modalService: NgbModal,
              private modelService: ModelService,
              private logger: LogService) {}

  ngOnInit(): void {
    this.getCourseDesignateAssignments();

    // set the default value for Filter by Valid Status drop-down
    this.statusFilter.setValue(DesignateStatusValue.ALL_DESIGNATES);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getCourseDesignateAssignments() {
    this.subscription = this.modelService.getDesignateAssignments('C').subscribe(courseDesignateAssignments => {

      this.courseDataSource = new MatTableDataSource<SingleDesignateAssignment>(
        this.modelService.convertDesignateAssignmentsToSingleDesignateAssignments(courseDesignateAssignments));

      this.courseDataSource.filterPredicate = (data: SingleDesignateAssignment, filter: string): boolean => {
        const textFilter = filter.slice(0, this.textFilter.length);
        const statusFilter = filter.slice(this.textFilter.length, filter.length);

        const filterWordsArray: string[] = textFilter.split(' ');

        const textMatch: boolean = filterWordsArray.every((value) => {
          const matchExp: RegExp = new RegExp(value, 'i');
          return this._textFilter(data, matchExp);
        });

        return textMatch && DesignatesUtil.filterDesignateByStatus(data, statusFilter);
      };

      this.courseDataSource.sortingDataAccessor = (data: SingleDesignateAssignment, sortHeaderId: string) => {
        if (sortHeaderId === 'Course Code') {
          return data.course.code;
        }
        else {
          return '';
        }
      };

      this.courseDataSource.sort = this.sort;
      this.courseDataSource.paginator = this.paginator;
    });
  }

  editOrViewCourseDesig(course: Course, designate: Designate) {
    this.logger.debug(`=> course: ${JSON.stringify(course)}, designate: ${JSON.stringify(designate)}`,
      `${this.constructor.name}.editCourseDesig()`);

    const desigAssignment: DesignateAssignment = this._buildCourseDesigAssignment(course, designate);
    const modalRef = this._openEditDesigModal(desigAssignment);
    this._processModalResult(modalRef);
  }

  removeCourseDesig(course: Course, designate: Designate) {
    this.logger.debug(`=> course: ${JSON.stringify(course)}, designate: ${JSON.stringify(designate)}`,
      `${this.constructor.name}.removeCourseDesig()`);

    const desigAssignment: DesignateAssignment = this._buildCourseDesigAssignment(course, designate);
    const modalRef = this._openRemoveDesigModal(desigAssignment);
    this._processModalResult(modalRef);
  }

  getDesignExpiryDateLabel(validUntilDate: string): string {
    return DesignatesUtil.getDesignExpiryDateLabel(validUntilDate);
  }

  _refreshCollection() {
    // Refresh the course collection from which the designate binding was removed
    this.getCourseDesignateAssignments();
  }

  _processModalResult(modalRef: NgbModalRef) {
    modalRef.result.then(
      (result) => {
        this.logger.debug(`-> modal result: ${JSON.stringify(result)}`, `${this.constructor.name}`);
        // Refresh the collection (courses)
        this._refreshCollection();
      }, (reason) => {
        this.logger.debug(`Dismissed reason: ${this._getDissmissReason(reason)}`, `${this.constructor.name}`);
      }
    );
  }

  _openRemoveDesigModal(desigAssignment: DesignateAssignment): NgbModalRef {
    const modalRef = this.modalService.open(RemoveDesigModalComponent);
    // Pass data to the modal
    modalRef.componentInstance.desigAssignment = desigAssignment;
    return modalRef;
  }

  /* Open an edit modal and pass the data to display. */
  _openEditDesigModal(desigAssignment: DesignateAssignment): NgbModalRef {
    const modalRef = this.modalService.open(AssignDesignateComponent, { size: 'lg' });
    // Pass data to the edit modal
    modalRef.componentInstance.desigAssignment = desigAssignment;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.isUserDesginatesAdmin = this.isUserDesginatesAdmin;

    return modalRef;
  }

  _buildCourseDesigAssignment(course: Course, designate: Designate) {
    const desigAssignment: DesignateAssignment = new DesignateAssignment();
    desigAssignment.assignmentLevel = 'C';

    desigAssignment.org = new Org();
    desigAssignment.org.facultyCode = '';
    desigAssignment.org.departmentCode = '';
    desigAssignment.org.graduateInd = false;
    desigAssignment.org.id = null;
    desigAssignment.org.version = null;

    desigAssignment.course = course;
    desigAssignment.coursePrefix = '';
    desigAssignment.designates = [designate];
    return desigAssignment;
  }

  _getDissmissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  applyTextFilter($event: KeyboardEvent): void {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.textFilter = filterValue.trim();
    this.courseDataSource.filter = this.textFilter + this.statusFilter.value;
  }

  filterByDesignateStatus() {
    this.courseDataSource.filter = this.textFilter + this.statusFilter.value;
  }

  // get the value of the data's column in string, used for filter
  _getStringData(data: SingleDesignateAssignment, columnName: ColumnName): string {
    let result = '';
    switch (columnName) {
      case ColumnName.COURSE_CODE:
        result = data.course.code;
        break;
      case ColumnName.SECTION_CODE:
        result = data.course.sectionCode;
        break;
      case ColumnName.SESSION_CODE:
        result = data.course.sessionCode;
        break;
      case ColumnName.DESIGNATE:
        result = data.designate.utorId + data.designate.firstName + data.designate.lastName;
        break;
      case ColumnName.PERMISSIONS:
        result = DesignatesUtil.getPermissions(data);
        break;
      default:
        result = '';
    }
    return result;
  }

  // check if the data record include the text for filter
  _textFilter(data: SingleDesignateAssignment, filterExp: RegExp): boolean {
    for (const column of this.filteredColumns) {
      const match = filterExp.test(this._getStringData(data, column.name));
      if (match) {
        return true;
      }
    }
    return false;
  }
}
