import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {StudentBooking} from "../../../model/types/student-booking";
import {UntypedFormControl} from "@angular/forms";
import {BookingStatus} from "../../types/booking-status.enum";
import moment from "moment";

@Component({
  selector: 'app-cancel-booking-modal',
  templateUrl: './cancel-booking-modal.component.html',
  styleUrls: ['./cancel-booking-modal.component.scss']
})
export class CancelBookingModalComponent implements OnInit {

  @Input() booking: StudentBooking;

  // Reference to FormControl booking notes from parent (student-booking component).
  @Input() bookingNotes: UntypedFormControl;

  // Data to be shown in the modal
  modalTitle: string;
  cancelUncancelReason: string;
  placeholder: string;
  modalButton: string;
  modalButtonVal: string;

  // Booking status enum
  BOOKED: BookingStatus = BookingStatus.BOOKED;
  CANCELLED: BookingStatus = BookingStatus.CANCELLED;

  constructor(public activeModal: NgbActiveModal) {
  }

  ngOnInit(): void {

    if (this.booking.bookingStatus === this.BOOKED) {
      this.modalTitle = 'Cancel Booking';
      this.cancelUncancelReason = 'Please explain the reason for cancelling this booking, if it is known (Optional)';
      this.placeholder = 'E.g., Student accidentally created this booking and they emailed us to request cancellation on Jan 23, 2024';
      this.modalButton = 'Yes, Cancel Booking';
      this.modalButtonVal = this.CANCELLED; // this will be the new booking status

    } else if (this.booking.bookingStatus === this.CANCELLED) {
      this.modalTitle = 'Undo Cancellation';
      this.cancelUncancelReason = 'Please explain the reason for undoing the cancellation of this booking, if it is known (Optional)';
      this.placeholder = 'E.g., This booking was accidentally cancelled. It should remain booked';
      this.modalButton = 'Yes, Undo Cancellation';
      this.modalButtonVal = this.BOOKED; // this will be the new booking status

    } else {
      this.modalTitle = 'N/A';
      this.cancelUncancelReason = 'N/A';
      this.placeholder = '';
      this.modalButton = 'N/A';
      this.modalButtonVal = 'N/A';
    }
  }

  // Converts time, in the format: 'hh:mm:ss', to a Date and returns it.
  convertTimeToDate(aDate: Date) {

    if (!aDate) {
      return null;
    }

    const dateAsStr = aDate.toString();
    const timeArr: string[] =  dateAsStr.split(':');

    const now = moment();
    now.set('hour', Number(timeArr[0]));
    now.set('minutes', Number(timeArr[1]));
    now.set('seconds', Number(timeArr[2]));

    return new Date(now.toDate());
  }

}
