import {Component, OnInit} from '@angular/core';
import {AuthService} from "./common/services/auth.service";

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit{
  title = 'ats-front-end';

  constructor(private authServ: AuthService) {
    this.authServ.login();
  }

  ngOnInit(): void {
  }
}
