import {Component, Input, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {AssignmentLevel} from "../../common/types/assignment-level.enum";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {DesignateAssignment} from "../../model/types/designate-assignment";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {MatSort} from "@angular/material/sort";
import {ModalDismissReasons, NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {Org} from "../../model/types/org";
import {Designate} from "../../model/types/designate";
import {Course} from "../../model/types/course";
import {AssignDesignateComponent} from "../assign-designate/assign-designate.component";
import {RemoveDesigModalComponent} from "../remove-desig-modal/remove-desig-modal.component";
import {Column} from "../../common/types/column";
import {ColumnName} from "../../common/types/columnName";
import {SingleDesignateAssignment} from "../../model/types/single-designate-assignment";
import {Subscription} from "rxjs";
import {DesignatesUtil} from "../designates-util.util";
import {UntypedFormControl} from "@angular/forms";
import {DesignateStatus} from "../designate-status";
import {DesignateStatusValue} from "../designate-status-value.enum";

@Component({
  selector: 'app-org-desigate-table',
  templateUrl: './org-desigate-table.component.html',
  styleUrls: ['./org-desigate-table.component.scss']
})
export class OrgDesigateTableComponent implements OnInit, OnDestroy {

  @Input()
  isUserDesginatesAdmin: boolean;
  // use a constant as it will be used to split columns when exporting data to Excel file
  nameUTORidColumnName = ColumnName.NAME_UTORid;

  // Department designates tables columns
  departmentColumns: string[] = ["Level", "Division", "Department", "Prefix", "Designate", "Permissions", "Valid Until", "Edit", "Remove", "View"];

  designateStatuses = [DesignateStatus.ALL_DESIGNATES, DesignateStatus.CURRENTLY_VALID, DesignateStatus.EXPIRED];
  statusFilter = new UntypedFormControl('');

  // hold the input text for filter
  textFilter = '';

  // the columns that need to be filtered by text
  filteredColumns: Column[] = [
    new Column(ColumnName.LEVEL),
    new Column(ColumnName.DIVISION),
    new Column(ColumnName.DEPARTMENT),
    new Column(ColumnName.PREFIX),
    new Column(ColumnName.DESIGNATE),
    new Column(ColumnName.PERMISSIONS),
    new Column(ColumnName.VALID_UNTIL)
  ];

  // Enumerations
  assignmentLevelEnum = AssignmentLevel;

  departmentDataSource: MatTableDataSource<SingleDesignateAssignment>;

  @ViewChild(MatPaginator) paginator: MatPaginator;
  @ViewChild(MatSort, {static: true}) sort?: MatSort;

  private subscription: Subscription;

  constructor(private modalService: NgbModal,
              private modelService: ModelService,
              private logger: LogService) {}

  ngOnInit(): void {
    this.getOrgDesignateAssignments();

    // set the default value for Filter by Valid Status drop-down
    this.statusFilter.setValue(DesignateStatusValue.ALL_DESIGNATES);
  }

  ngOnDestroy(): void {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  getOrgDesignateAssignments() {
    this.subscription = this.modelService.getDesignateAssignments('D').subscribe(orgDesignateAssignments => {
      // this.logger.debug(JSON.stringify(orgDesignateAssignments), 'getOrgDesignateAssignments');

      this.departmentDataSource = new MatTableDataSource<SingleDesignateAssignment>(
        this.modelService.convertDesignateAssignmentsToSingleDesignateAssignments(orgDesignateAssignments));

      this.departmentDataSource.filterPredicate = (data: SingleDesignateAssignment, filter: string): boolean => {
        const textFilter = filter.slice(0, this.textFilter.length);
        const statusFilter = filter.slice(this.textFilter.length, filter.length);

        const filterWordsArray: string[] = textFilter.split(' ');

        const textMatch: boolean = filterWordsArray.every((value) => {
          const matchExp: RegExp = new RegExp(value, 'i');
          return this._textFilter(data, matchExp);
        });

        return textMatch && DesignatesUtil.filterDesignateByStatus(data, statusFilter);
      };

      this.departmentDataSource.sortingDataAccessor = (data: SingleDesignateAssignment, sortHeaderId: string) => {
        if (sortHeaderId === 'Level') {
          return data.org.graduateInd.toString();
        }
        if (sortHeaderId === 'Division') {
          return data.org.facultyCode;
        }
        else if (sortHeaderId === 'Department') {
          return data.org.departmentCode;
        }
        else {
          return '';
        }
      };

      this.departmentDataSource.sort = this.sort;
      this.departmentDataSource.paginator = this.paginator;
    });
  }

  editOrViewOrgDesig(org: Org, coursePrefix: string, designate: Designate) {
    this.logger.debug(`=> org: ${JSON.stringify(org)}, designate: ${JSON.stringify(designate)}`,
      `${this.constructor.name}.editOrgDesig()`);

    const desigAssignment: DesignateAssignment = this._buildOrgDesigAssignment(org, coursePrefix, designate);
    const modalRef = this._openEditDesigModal(desigAssignment);
    this._processModalResult(modalRef);
  }

  /**
   * result: {"assessmentLevel":"D"} or {"assessmentLevel":"c}
   */
  removeOrgDesig(org: Org, coursePrfix: string, designate: Designate) {
    this.logger.debug(`=> org: ${JSON.stringify(org)}, designate: ${JSON.stringify(designate)}`,
      `${this.constructor.name}.removeOrgDesig()`);

    const desigAssignment: DesignateAssignment = this._buildOrgDesigAssignment(org, coursePrfix, designate);
    const modalRef = this._openRemoveDesigModal(desigAssignment);
    this._processModalResult(modalRef);
  }

  getDesignExpiryDateLabel(validUntilDate: string): string {
    return DesignatesUtil.getDesignExpiryDateLabel(validUntilDate);
  }

  /* Open an edit modal and pass the data to display. */
  _openEditDesigModal(desigAssignment: DesignateAssignment): NgbModalRef {
    const modalRef = this.modalService.open(AssignDesignateComponent, { size: 'lg' });
    // Pass data to the edit modal
    modalRef.componentInstance.desigAssignment = desigAssignment;
    modalRef.componentInstance.showInModal = true;
    modalRef.componentInstance.isUserDesginatesAdmin = this.isUserDesginatesAdmin;

    return modalRef;
  }

  _processModalResult(modalRef: NgbModalRef) {
    modalRef.result.then(
      (result) => {
        this.logger.debug(`-> modal result: ${JSON.stringify(result)}`, `${this.constructor.name}`);
        // Refresh the collection (orgs) from which the designate binding was removed
        this._refreshCollection();
      }, (reason) => {
        this.logger.debug(`Dismissed reason: ${this._getDissmissReason(reason)}`, `${this.constructor.name}`);
      }
    );
  }

  _refreshCollection() {
    // Refresh the collection (orgs or courses) from which the designate binding was removed
      this.getOrgDesignateAssignments();
  }

  _buildOrgDesigAssignment(org: Org, coursePrefix: string, designate: Designate) {
    const desigAssignment: DesignateAssignment = new DesignateAssignment();
    desigAssignment.assignmentLevel = 'D';
    desigAssignment.org = org;
    desigAssignment.coursePrefix = coursePrefix;
    desigAssignment.course = new Course();
    desigAssignment.designates = [designate];
    return desigAssignment;
  }

  _openRemoveDesigModal(desigAssignment: DesignateAssignment): NgbModalRef {
    const modalRef = this.modalService.open(RemoveDesigModalComponent);
    // Pass data to the modal
    modalRef.componentInstance.desigAssignment = desigAssignment;
    return modalRef;
  }

  _getDissmissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  applyTextFilter($event: KeyboardEvent): void {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.textFilter = filterValue.trim();
    this.departmentDataSource.filter = this.textFilter + this.statusFilter.value;
  }

  filterByDesignateStatus() {
    this.departmentDataSource.filter = this.textFilter + this.statusFilter.value;
  }

  // check if the data record include the text for filter
  _textFilter(data: SingleDesignateAssignment, filterExp: RegExp): boolean {
    for (const column of this.filteredColumns) {
      const match = filterExp.test(this._getStringData(data, column.name));
      if (match) {
        return true;
      }
    }
    return false;
  }

  // get the value of the data's column in string, used for filter
  _getStringData(data: SingleDesignateAssignment, columnName: ColumnName): string {
    let result = '';
    switch (columnName) {
      case ColumnName.LEVEL:
        result = data.org.graduateInd === true ? 'Graduate' : 'Undergraduate';
        break;
      case ColumnName.DIVISION:
        result = data.org.facultyCode;
        break;
      case ColumnName.DEPARTMENT:
        result = data.org.departmentCode;
        break;
      case ColumnName.PREFIX:
        result = data.coursePrefix;
        break;
      case ColumnName.DESIGNATE:
        result = data.designate.utorId + data.designate.firstName + data.designate.lastName;
        break;
      case ColumnName.PERMISSIONS:
        result = DesignatesUtil.getPermissions(data);
        break;
      default:
        result = '';
    }
    return result;
  }
}
