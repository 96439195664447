<div>
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>{{title}}</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>

  <p>The email will be sent to selected course contacts from test.exam@utoronto.ca.</p>

  <div class="row">
    <div class="col-9 margin-top-2-rem send-bulk-email-content">
      <h3>Summary of Emails Sent ({{bulkEmailSummary.length}} Test Definitions)</h3>

      <p>
        You have sent {{title.split(' ').slice(1).join(' ')}}s for the following test definitions for
        <strong>{{emailDate | date:'yyyy-MM-dd'}}</strong>. You can view more details, including the email
        addresses of recipients, in the Activity Log for each test definition on the Instructor Emailing Dashboard.
      </p>

      <div *ngFor="let emailSummaryRec of bulkEmailSummary; let i = index">
        {{i+1}}. {{emailSummaryRec}}<br/>
      </div>
    </div>
  </div>

  <!-- Back to Instructor Emailing -->
  <div class="margin-top-2-rem">
    <a [routerLink]="['/instructor-emailing']">Back to Instructor Emailing Dashboard</a>
  </div>

</div>
