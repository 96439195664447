
<div class="desig-binding">
  <div *ngIf="desigAssignment.assignmentLevel === assignmentLevel.DEPARTMENT">
    <div class="row">
      <div class="col"><strong>Name:</strong> {{desigAssignment.designates[0].firstName}} {{desigAssignment.designates[0].lastName}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>UTORid:</strong> {{desigAssignment.designates[0].utorId}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Email:</strong> {{desigAssignment.designates[0].email}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Phone:</strong> {{phone}}</div>
    </div>
    <div class="row">
      <div class="col">
        <strong>Level:</strong> {{desigAssignment.org.graduateInd === true ? 'Graduate' : 'Undergraduate'}}
      </div>
    </div>
    <div class="row">
      <div class="col"><strong>Division:</strong> {{desigAssignment.org.facultyCode}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Unit:</strong> {{desigAssignment.org.departmentCode}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Prefix:</strong> {{desigAssignment.coursePrefix}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Valid Until:</strong> {{getDesignateExpiryDateLabel(desigAssignment.designates[0].endDate)}}</div>
    </div>
  </div>
  <div *ngIf="desigAssignment.assignmentLevel === assignmentLevel.COURSE">
    <div class="row">
      <div class="col"><strong>Name:</strong> {{desigAssignment.designates[0].firstName}} {{desigAssignment.designates[0].lastName}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>UTORid:</strong> {{desigAssignment.designates[0].utorId}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Email:</strong> {{desigAssignment.designates[0].email}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Phone:</strong> {{phone}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Course Code:</strong> {{desigAssignment.course.code}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Section Code:</strong> {{desigAssignment.course.sectionCode}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Session Code:</strong> {{desigAssignment.course.sessionCode}}</div>
    </div>
    <div class="row">
      <div class="col"><strong>Valid Until:</strong> {{getDesignateExpiryDateLabel(desigAssignment.designates[0].endDate)}}</div>
    </div>
  </div>
  <br/>
</div>
