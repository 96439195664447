<!-- Masthead -->
<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-8">
    <div class="masthead-header">
      <h1><span *ngIf="!toMoveBookings">Edit Test Definition</span><span *ngIf="toMoveBookings">Move Bookings</span> for {{courseAndSection}}</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools  col-4">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>

<!-- Page Content -->
<div [formGroup]="testDefForm" class="page-content">

  <p *ngIf="!toMoveBookings">From this page, you can edit the test type or assessment writing details, move bookings or export the test definition.</p>
  <p *ngIf="toMoveBookings">From this page, you can move bookings from Test Definition: Exam ID {{testDef.clockworkExamId}} to another test definition.</p>

  <!-- Delete test definition if it has no bookings -->
  <app-delete-test-def [testDefinition]="testDef"
                       [numberOfBookings]="bookingAmountOfTestDefinition">
  </app-delete-test-def>

  <!-- Cards -->
  <div class="row">
    <div class="col-12">
        <div class="card-deck" >
          <!-- Edit test definition type/subtype card -->
          <div class="card">
            <app-test-def-details-card *ngIf="testDef" [testDef]="testDef" [isTypeEditable]="isTypeEditable"
                [showEditButton]="notStartChange()" (outputEvent)="editType()"></app-test-def-details-card>
          </div>
          <!-- Edit test definition details card -->
          <div class="card">
            <app-class-writing-schedule-card *ngIf="testDef" [testDef]="testDef"
               [showEditButton]="notStartChange()" (outputEvent)="editDetails()"></app-class-writing-schedule-card>
          </div>
        </div>
    </div>
  </div>
  <div class="row margin-top-2-rem" *ngIf="notStartChange()">
    <div class="col-12">
      <div class="card-deck">
        <!-- Move bookings between test definition card -->
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Bookings</h3>
            <div class="card-text">
              <div><strong>Number of Bookings: &nbsp;</strong>{{bookingAmountOfTestDefinition}}</div>
              <p class="margin-top-15-px">Move bookings from this test definition to another existing test definition.</p>
            </div>
            <div *ngIf="!toEditDetails && !toEditType">
              <button class="btn btn-sm btn-primary" (click)="moveBookings()" [disabled]="bookingAmountOfTestDefinition == 0" type="submit"
                      [ngClass]="{'disabled': bookingAmountOfTestDefinition == 0}">
                <span *ngIf="bookingAmountOfTestDefinition == 0"
                      ngbTooltip="There are no bookings associated with this test definition or the test definition was not exported to clockwork." placement="auto">Move Bookings</span>
                <span *ngIf="bookingAmountOfTestDefinition > 0">Move Bookings</span>
              </button>
            </div>
          </div>
        </div>
        <!-- Export test definition to clockwork card -->
        <div class="card">
          <app-export-test-def-card [testDefinition]="testDef"></app-export-test-def-card>
        </div>
      </div>
    </div>
  </div>
  <br/>

  <div *ngIf="toEditType">
    <div class="margin-top-4-rem">
      <h2>Existing Student Bookings ({{bookingAmountOfTestDefinition}})</h2>
      <p class="margin-top-2-rem" *ngIf="bookingAmountOfTestDefinition > 0">The following are student bookings for Exam Id {{clockworkExamId}}</p>
    </div>
    <mat-table [dataSource]="bookingsDataSource" matSort class="table-size mat-elevation-z8 col-6 margin-top-40-px table-bookings">
        <ng-container matColumnDef="appointmentId">
          <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Clockwork Appointment ID</th>
          <td class="mat-column-position" *matCellDef="let element">{{element.clockworkAppointmentId}}</td>
        </ng-container>
        <ng-container matColumnDef="bookingId">
          <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>ATS Booking ID</th>
          <td class="mat-column-position" *matCellDef="let element">{{element.bookingId}}</td>
        </ng-container>
        <ng-container matColumnDef="studentName">
          <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Student</th>
          <td class="mat-column-position" *matCellDef="let element">{{element.studentInfo.firstName}} {{element.studentInfo.lastName}}</td>
        </ng-container>
        <ng-container matColumnDef="studentRosiNo">
          <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Student No.</th>
          <td class="mat-column-position" *matCellDef="let element">{{element.studentInfo.rosiStudentId}}</td>
        </ng-container>
        <tr mat-header-row *matHeaderRowDef="bookingsColumns; sticky: true"></tr>
        <tr mat-row *matRowDef="let row; columns: bookingsColumns"></tr>
    </mat-table>

    <div class="margin-top-4-rem">
      <div class="row">
        <div class="col">
          <div class="font-weight-bold">Select Test Type</div>
          <div *ngFor="let type of types">
            <div class="form-check">
              <input class="form-check-input" type="radio" id="{{type}}"
                     [value]="type" formControlName="testType" (change)="findSubtypes(type)">
              <label for="{{type}}" class="form-check-label">{{typeUtils.typeLabel(type)}}</label>
            </div>
          </div>
        </div>
      </div>
      <div class="row ml-4">
        <div class="col">
          <div class="font-weight-bold margin-top-2-rem">Select Type of Request</div>
          <div *ngFor="let testSubtype of subtypes">
            <div class="form-check">
              <input class="form-check-input" type="radio" id="{{testSubtype}}"
                     [value]="testSubtype" formControlName="testSubtype">
              <label for="{{testSubtype}}" class="form-check-label">{{typeUtils.typeLabel(testSubtype, testDefForm.controls.testType.value)}}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="margin-top-4-rem" *ngIf="toEditDetails">
    <h4>
    </h4>
    <div formGroupName="assessmentLocationDetails">
      <h5>Assessment Location Details</h5>
      <!-- Select Assessment Location -->
      <div class="form-group">
        <div class="row">
          <div class="col">
            <div class="font-weight-bold mt-4">Select Assessment Location</div>
            <!-- On Campus (In-Person) -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="onCampusInd"
                     [value]="true" formControlName="onCampusInd">
              <label for="onCampusInd" class="form-check-label">{{typeUtils.ON_CAMPUS}}</label>
            </div>
            <!-- Off Campus (Remote) -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="offCampusInd"
                     [value]="false" formControlName="onCampusInd">
              <label for="offCampusInd" class="form-check-label">{{typeUtils.OFF_CAMPUS}}</label>
            </div>
            <!-- Unknown -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="unknownInd"
                     [value]="null" formControlName="onCampusInd">
              <label for="unknownInd" class="form-check-label">Unknown</label>
            </div>
          </div>
        </div>
      </div>
      <!-- Enter Class Location (Optional) -->
      <div class="form-group">
        <div class="row">
          <div class="col-3">
            <div class="font-weight-bold">Enter Class Location (Optional)</div>
            <input class="form-control" type="text" id="classLocationId"
                   formControlName="classLocation">
          </div>
        </div>
      </div>
    </div>
    <div formGroupName="assessmentScheduleDetails">
      <h5>Assessment Writing Details</h5>

      <!-- Assessment Date -->
      <div class="form-group mt-3">
        <div class="row">
          <div class="col-3">
            <label class="form-label" for="assessmentDateId">Assessment Date</label>
            <app-rf-datepicker id="assessmentDateId" formControlName="assessmentDate"></app-rf-datepicker>
          </div>
          <app-validation-message [control]="testDefForm.get('assessmentScheduleDetails.assessmentDate')"></app-validation-message>
        </div>
      </div>

      <!-- Assessment Time -->
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="assessmentTimeId">Assessment Time</label>
            <app-rf-timepicker id="assessmentTimeId" formControlName="assessmentTime"></app-rf-timepicker>
          </div>
        </div>
      </div>

      <!-- Assessment Writing Time Limit -->
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="assessmentDurationId">Assessment Writing Time Limit</label>
            <app-rf-durationpicker id="assessmentDurationId" formControlName="assessmentDuration"></app-rf-durationpicker>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="toEditType || toEditDetails" class="form-group mt-5">
    <button class="btn btn-primary" [ngStyle]="{'background-color': testDefinitionBeingCommitted || (toEditDetails &&
     !testDefForm.get('assessmentScheduleDetails').valid) ? 'grey' : ''}"
            (click)="applyChanges()" [disabled]="testDefinitionBeingCommitted || (toEditDetails &&
     !testDefForm.get('assessmentScheduleDetails').valid)" >
      Apply Changes
    </button>
    <div class="margin-top-4-rem">
      <a class="underline" (click)="reload()">Back to Edit Test Definition</a>
    </div>
  </div>

  <!-- move bookings -->
  <div *ngIf="toMoveBookings">
    <!--Step 1 and Step 2-->
    <div>
      <h2>Step 1: Select Bookings to Move</h2>
      <p class="margin-top-2-rem">The following are student bookings for Exam ID {{clockworkExamId}}. Select the bookings you want
        to move from this test definition to another test definition.</p>
      <h3 class="margin-top-2-rem">Student Bookings for Test Definition: Exam ID {{clockworkExamId}} ({{bookingAmountOfTestDefinition}})</h3>
      <div class="margin-top-2-rem">
        <app-selectable-bookings-table *ngIf="bookingsDataSource" [bookingsDataSource]="bookingsDataSource"
                                       [selectable]="true" (outputEvent)="receiveBookingsToBeMoved($event)"></app-selectable-bookings-table>
      </div>
      <p class="margin-top-2-rem">You selected {{bookingListToBeMoved.length}} bookings to move to another test definition.</p>
      <div class="col-4 margin-top-2-rem" *ngIf="invalidBookings.length > 0">
        <div class="card" >
          <div class="card-body card-warning">
            <div class="card-text ">
              <p class="margin-bottom-0"><strong>Something went wrong. </strong>The bookings you are attempting to move already exist in
                the destination test definition for the following student:</p>
              <ul *ngFor="let booking of invalidBookings">
                <li>{{booking.studentInfo.firstName}} {{booking.studentInfo.lastName}}</li>
              </ul>
              <p>Unselect these bookings in Step1 to continue. You can review the details of Test Definiton Exam ID: {{destinationTestDef.clockworkExamId}} below.</p>
            </div>
          </div>
        </div>
      </div>
      <div class="col-4 margin-top-2-rem" *ngIf="notEnrolledStudents.length > 0">
        <div class="card" >
          <div class="card-body card-warning">
            <div class="card-text ">
              <p class="margin-bottom-0"><strong>Bookings cannot be moved. </strong>The following students you
                selected are not enrolled in the section for the destination Test Definition:</p>
              <ul *ngFor="let student of notEnrolledStudents">
                <li>{{student.firstName}} {{student.lastName}} ({{student.rosiStudentId}})</li>
              </ul>
              <p>Please unselect the bookings for these students in Step 1.</p>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!--Step 2-->
    <div class="margin-top-4-rem" [formGroup]="destinationExam">
      <h2>Step 2: Enter Destination Test Definition</h2>
      <p class="margin-top-2-rem">Enter the test definition for which you want to move the selected bookings above.</p>
      <div class="row margin-top-2-rem align-items-center">
        <div class="col-2">
          <label class="form-label" for="destinationExamID">Test Definition (Clockwork Exam ID)</label>
        </div>
      </div>
      <div class="row align-items-top align-items-center">
        <div class="col-2">
          <input #destinationExamID id="destinationExamID" class="form-control" type="number" placeholder="E.g: 123753" formControlName="destinationExamID">
        </div>
        <div class="col-2">
          <button type="button" class="btn btn-primary reset-button" (click)="getDestinationTestDef()">Search Test Definition</button>
        </div>
        <div class="col-2">
          <button type="button" class="btn btn-outline-primary btn-block reset-button" (click)="resetSearchTestDefinition()">Reset Search</button>
        </div>
      </div>
      <div class="margin-top-2-rem" *ngIf="destinationExam.controls.destinationExamID.invalid">
        <h5>It looks like you haven't entered anything in the search field. Here's a tip:</h5>
        <ul>
          <li>Try entering a complete Clockwork Exam ID</li>
        </ul>
      </div>
      <div class="col-4 margin-top-2-rem" *ngIf="destinationTestDefCourseNotMatch">
        <div class="card" >
          <div class="card-body card-warning">
            <div class="card-text ">
              <p class="margin-bottom-0"><strong>Test Definition courses do not match. </strong>You cannot move bookings to a Test Definition of an
              assessment for a different course. Please search for another Test Definition of an assessment for the same course.</p>
            </div>
          </div>
        </div>
      </div>

      <div class="margin-top-2-rem" *ngIf="foundDestinationTestDef === false">
        <h5>We couldn't find any test definitions that match your search. Here are some tips:</h5>
        <ul>
          <li>Ensure the Test Definition exists for the Exam ID entered. </li>
          <li>Ensure the Test Definition has been exported to Clockwork.</li>
          <li>Double check to ensure you have the correct Exam ID.</li>
          <li>Reset your search if you want to start again.</li>
        </ul>
      </div>
    </div>

    <!--Step 3 and Step 4-->
    <div *ngIf="foundDestinationTestDef === true && !destinationTestDefCourseNotMatch">
      <!--Step 3-->
      <div class="margin-top-4-rem">
        <h2>Step 3: Review Destination Test Definition Details</h2>

        <!-- Cards -->
        <div class="row margin-top-4-rem">
          <div class="col-12">
            <div class="card-deck" >
              <div class="card">
                <app-test-def-details-card [testDef]="destinationTestDef" [showEditButton]=false></app-test-def-details-card>
              </div>
              <div class="card">
                <app-class-writing-schedule-card [testDef]="destinationTestDef" [showEditButton]=false></app-class-writing-schedule-card>
              </div>
            </div>
          </div>
        </div>

        <!--bookings-->
        <div *ngIf="bookingsOfDestinationTestDef && bookingsOfDestinationTestDef.length > 0" class="margin-top-4-rem">
          <p class="margin-top-4-rem">The following are student bookings for the destination Test Definition: Exam ID {{destinationTestDef.clockworkExamId}}.
           The selected bookings in Step 1 will be moved to this test definition.</p>
          <h3 class="margin-top-2-rem">Student Bookings for Test Definition: Exam ID {{destinationTestDef.clockworkExamId}} ({{bookingsOfDestinationTestDef.length}})</h3>
          <div class="margin-top-2-rem">
            <app-selectable-bookings-table *ngIf="bookingsOfDestinationTestDefDataSource" class="margin-top-2-rem"
                 [bookingsDataSource]="bookingsOfDestinationTestDefDataSource" [selectable]="false"></app-selectable-bookings-table>
          </div>
        </div>
      </div>

      <!--Step 4-->
      <div>
        <h2 class="margin-top-4-rem">Step 4: Review Selection and Move Bookings</h2>
        <div *ngIf="bookingListToBeMoved.length > 0">
          <p class="margin-top-2-rem margin-bottom-0">You have selected the following bookings to be moved from Test Definition: <Strong>Exam ID: {{testDef.clockworkExamId}}</Strong> to
            Test Definition <strong>Exam ID: {{destinationTestDef.clockworkExamId}}</strong>.</p>
          <p>Time based accommodations for each student will be reapplied based on the new test definition.</p>
          <h3>Selected Bookings to Move from Test Definition: Exam ID {{testDef.clockworkExamId}} to Test Definition: Exam ID {{destinationTestDef.clockworkExamId}}</h3>
          <div class="margin-top-2-rem">
            <app-selectable-bookings-table class="margin-top-2-rem" [bookingsDataSource]="bookingsToBeMovedDataSource"
                                           [selectable]="false" ></app-selectable-bookings-table>
          </div>
        </div>
        <div *ngIf="bookingListToBeMoved.length === 0">
          <p class="margin-top-2-rem">No booking(s) were selected. Select the booking(s) in Step 1 to continue.</p>
        </div>
        <div class="margin-top-4-rem">
          <button class="btn btn-primary" [ngStyle]="{'background-color': moveBookingsDisabled()? 'grey' : ''}"
                  (click)="moveBookingsToOtherTestDefinitions()" [disabled]="moveBookingsDisabled()" >Move Bookings
          </button>
        </div>
      </div>

    </div>
    <div class="margin-top-4-rem">
      <a class="underline" routerLink="/test-definitions">Back to Test Definition Dashboard</a>
    </div>
  </div>
</div>

