import { Injectable } from '@angular/core';
import {NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';

/**
 * This Service handles how the date is rendered and parsed from keyboard i.e. in the bound input field.
 */
@Injectable({
  providedIn: 'root'
})
export class DatepickerParserFormatterService extends NgbDateParserFormatter {
  readonly DELIMITER = '-';

  constructor() {
    super();
  }

  // pad with 0 in front if the val contaions only one digit
  pad = (val: number) => val && val < 10 ? '0' + val : val;

  format(date: NgbDateStruct): string {
    return date ? this.pad(date.year) + this.DELIMITER + this.pad(date.month) + this.DELIMITER + this.pad(date.day) : null;
  }

  parse(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    const date = value.split(this.DELIMITER);

    return {
      year: parseInt(date[0], 10),
      month: parseInt(date[1], 10),
      day: parseInt(date[2], 10)
    };
  }

}
