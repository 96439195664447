import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {merge, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, filter, map, switchMap, tap} from "rxjs/operators";
import {ModelService} from "../../model/model.service";
import {AbstractControl, UntypedFormControl, Validators} from "@angular/forms";
import {LogService} from "../../common/services/log-service.service";
import {StudentName} from '../../model/types/student-name';
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

/**
 * Component: StudentSearchComponent
 * Desc:
 *    Typeahead component which selects the students based on the user entered characters.
 *    The search starts after the user enters at least 3 characters. Getting students
 *     based on the search criteria is done through an asynchronous back-end call.
 * Input:
 *    None
 * Output:
 *    selectedItem: EventEmitter<Person>  - An event with Person payload is issued.
 */

@Component({
  selector: 'app-student-search',
  templateUrl: './student-search.component.html',
  styleUrls: ['./student-search.component.scss']
})
export class StudentSearchComponent implements OnInit {

  @Input()
  student: AbstractControl;

  @Output()
  studentSelected = new EventEmitter<StudentName>();

  @ViewChild('instance', {static: true})
  instance: NgbTypeahead;

  searching = false;
  searchFailed = false;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  constructor(private modelService: ModelService, private logger: LogService) {
    this.student = new UntypedFormControl('', Validators.required);
  }

  ngOnInit(): void {
  }

  /**
   * Filters the students to be shown in typeahead based on the user entered text.
   * Type-ahead tasks:
   *   - listen for data from input;
   *   - debounce (as not to send off API requests for every keystroke, but instead wait for a break in keystroke);
   *   - don't send a request if the value stays the same (rapidly hit a character, then backspace, for instance);
   *   - send the the back-end only if at list 3 search characters were entered;
   *   - do a back-end request (switchMap()) when data comes back convert Person[] to a string[] to be displayed in type-ahead.
   *
   * @param text$  The user input search string.
   */
  searchForStudent = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(x => x.length === 0 ? '99999' : x),
      filter(x => /\d+/.test(x) ? x.length > 2 : x.length > 1),
      tap(() => this.searching = true),
      switchMap(term => this.modelService.getAccommodatedStudentNames(term).pipe(
        tap(() => this.searchFailed = false),
        catchError(() => {
          this.searchFailed = true;
          return of([]);
        })
        ),
      ),
      tap(() => this.searching = false)
    );
  }

  /**
   * A function that converts an item from the result list to a string.
   * In the case of [inputFormatter] typeahead directive the string is displayed in the <input> field.
   * In the case og [resultFormatter] typeahead directive the string is displayed in the popup.
   *
   * @param section  The Person object to be converted to a string.
   */
  studentFormatter = (student: StudentName) =>
    !student ? '' : student.firstName?.trim() + " " + student.lastName?.trim() + " (" + student.rosiStudentId?.trim() + ")"

  /**
   * Event handler - triggered when a student is selected from the typeahead suggestions.
   * @param item   An object containing the selected Person object.
   */
  onSelectedStudent(item: any) {
    this.logger.debug(`=> Typeahead selected student: ${JSON.stringify(item)}`, `${this.constructor.name}.onSelectedStudent()`);
    const selectedStudentObj: StudentName = item.item;
    this.student.setValue(selectedStudentObj);

    // Emit an event for the parent
    this.studentSelected.emit(selectedStudentObj);
  }

}
