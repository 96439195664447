import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {StudentInfo} from "../../model/types/studentInfo";
import {LogService} from "../../common/services/log-service.service";
import {ModelService} from "../../model/model.service";
import {StudentAccommodations} from "../../model/types/studentAccommodations";
import {StudentSearch} from "../../model/types/student-search";
import {Course} from "../../model/types/course";
import {Accommodation} from "../../model/types/accommodation";
import {Bookings} from "../../model/types/bookings";
import {Booking} from "../../model/types/booking";
import {CourseBookings} from "../../model/types/course-bookings";

@Component({
  selector: 'app-student-accommodations-tabs',
  templateUrl: './app-student-accommodations-tabs.component.html',
  styleUrls: ['./app-student-accommodations-tabs.component.scss']
})
export class AppStudentAccommodationsTabsComponent implements OnChanges {

  @Input()
  studentInfo: StudentInfo;

  studentAccommodations: StudentAccommodations;

  courseBookingsList: CourseBookings[];


  bookingsOfCourse: Booking[];


  displayedColumns: string[] = ["status", "scheduleDate", "scheduleTime", "seat"];

  active = 1;

  accommodations: Accommodation[];

  courseSpecificAccommodations: Map<string, Accommodation[]>;

  constructor(private modelService: ModelService, private logger: LogService) {

  }

  ngOnChanges(changes: SimpleChanges): void {

    // get accommodations based on studentId of student info
    this.modelService.getAccommodationsByStudentId(this.studentInfo.atsStudentId).subscribe(data => {
      if (!data) {
        // TODO: should we allow to be created students without accommodations?
        // If there are no accommodations assign empty objects for now!
        this.studentAccommodations = new StudentAccommodations();
        this.courseSpecificAccommodations = new Map();
      } else {
        this.studentAccommodations = Object.assign(new StudentAccommodations(), data);
        this.courseSpecificAccommodations = new Map(Object.entries(this.studentAccommodations.options));
      }
    });

    const studentSearch: StudentSearch = new StudentSearch();
    // tslint:disable-next-line:radix
    studentSearch.rosiStudentId = parseInt(this.studentInfo.rosiStudentId);

    // get bookings based on studentId of student info
    this.modelService.getBookingsGroupsByStudentId(this.studentInfo.atsStudentId).subscribe(data => {
      this.courseBookingsList = data;
    });
  }


  formatSeat(booking: Booking): string {
    if (booking.space === null || booking.space.code === null) {
      return 'None';
    }
    else {
      return booking.space.code + ', ' + booking.space.roomCode;
    }

  }

  formatScheduledDate(booking: Booking): string {
    if (booking.scheduledStartDate === null ||
        booking.scheduledEndDate === null ||
        booking.scheduledStartDate === booking.scheduledEndDate) {

      return booking.scheduledStartDate === null ? '' : booking.scheduledStartDate.toString();


    }
    else {
      const startDate: string = booking.scheduledStartDate === null ? '' : booking.scheduledStartDate.toString();
      const endDate: string = booking.scheduledEndDate === null ? '' : booking.scheduledEndDate.toString();

      return startDate + " - " + endDate;
    }
  }

  selectBookings(courseBookings: CourseBookings): void {
    this.getCourseSpecificAccommodations(courseBookings);
  }


  getCourseSpecificAccommodations(courseBookings: CourseBookings) {

    this.accommodations = [];

    const courseKey: string = courseBookings.sessionCode + '-' + courseBookings.code + '-' + courseBookings.sectionCode;

    this.courseSpecificAccommodations.forEach((value, key) => {
      if (key.startsWith(courseKey)) {
        this.accommodations = value;
      }
    });

  }
}
