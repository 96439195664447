<div class="row  align-items-end mb-3 course-designate-table">

  <!-- Filter by Course Code, Designate -->
  <div class="form group col-4">
    <label class="form-label" for="textInput">Filter by Course Code, Designate</label>
    <input type="text" id="textInput" class="form-control" placeholder='E.g: "EC0100" "John Flag"'
           autocomplete="off" (keyup)="applyTextFilter($event)">
  </div>

  <!-- Filter by Valid Status drop-down -->
  <div class="form group col-4">
    <label class="form-label" for="designateStatus">Filter by Valid Status</label>
    <select id="designateStatus" class="form-select"
            [formControl]="statusFilter"
            (change)="filterByDesignateStatus()">
      <option *ngFor="let status of designateStatuses" [ngValue]="status.value">{{status.label}}</option>
    </select>
  </div>

</div>

<div class="course-designate-table">
  <mat-table class="table table-container" [dataSource]="courseDataSource" matSort matTableExporter>
    <ng-container matColumnDef="Course Code">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Course Code</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.course.code}}</td>
    </ng-container>

    <ng-container matColumnDef="Section Code">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Section Code</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.course.sectionCode}}</td>
    </ng-container>

    <ng-container matColumnDef="Session Code">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Session Code</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.course.sessionCode}}</td>
    </ng-container>

    <ng-container matColumnDef="Designate">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>{{nameUTORidColumnName}}</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.designate.firstName}} {{element.designate.lastName}} ({{element.designate.utorId}})
      </td>
    </ng-container>

    <ng-container matColumnDef="Permissions">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Permissions</th>
      <td class="mat-column-position" *matCellDef="let element">
        <!-- Email preference: All Communications or Late Communication -->
        <div style="border-bottom:  1px solid lightgrey" [ngSwitch]="element.designate.emailPreference">
          <div *ngSwitchCase="'A'"><i class="fas fa-inbox"></i> All Communication</div>
          <div *ngSwitchCase="'L'"><i class="fas fa-envelope"></i> Late Communication</div>
        </div>
        <!-- Role: DA or Instructor -->
        <div [ngSwitch]="element.designate.daRoleInd">
          <div *ngSwitchCase="true"><i class="fas fa-book"></i> Accommodation Admin Role</div>
          <div *ngSwitchCase="false"><i class="fas fa-edit"></i> Instructor Role</div>
        </div> <br/>
      </td>
    </ng-container>

    <ng-container matColumnDef="Valid Until">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Valid Until</th>
      <td class="mat-column-position" *matCellDef="let element">
        <div [ngSwitch]="getDesignExpiryDateLabel(element.designate.endDate)">
          <div *ngSwitchCase="'Indefinite'">Indefinite</div>
          <div *ngSwitchCase="'Expired'"><span class="expired-date">{{element.designate.endDate}} <br/>(Expired)</span></div>
          <div *ngSwitchDefault>{{element.designate.endDate}}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Edit">
      <th class="mat-column-position" [hidden]="!isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>Edit</th>
      <td class="mat-column-position" [hidden]="!isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-primary"
                  (click)="editOrViewCourseDesig(element.course, element.designate)">
            Edit
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Remove">
      <th class="mat-column-position" [hidden]="!isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>Remove</th>
      <td class="mat-column-position" [hidden]="!isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-link"
                  (click)="removeCourseDesig(element.course, element.designate)">
            Remove
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="View">
      <th class="mat-column-position" [hidden]="isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>View</th>
      <td class="mat-column-position" [hidden]="isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-primary"
                  (click)="editOrViewCourseDesig(element.course, element.designate)">
            View
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="courseColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: courseColumns"></tr>
  </mat-table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
