export class CourseSection {

  constructor(public code: string,
              public sectionCode: string,
              public sessionCode: string,
              public teachMethod: string,
              public sectionNo: string,
              public deliveryMode: string,
              public campus: string,
              public primaryOrgCode: string,
              public secondaryOrgCode: string,
              public coSecondaryOrgCode: string,
              public subSessionCode: string) {}
}
