import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {Router} from "@angular/router";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {TestDefinition} from "../model/types/test-definition";
import {MatSort} from "@angular/material/sort";
import {Column} from "../common/types/column";
import {ColumnName} from "../common/types/columnName";
import {StatusDesc} from "../model/types/statusDesc";
import {DateType} from "../common/types/DateType";
import {DateOption} from "../common/types/dateOption";
import {UntypedFormControl, Validators} from "@angular/forms";
import {DatePattern} from "../common/types/date-pattern";
import moment from "moment";
import {DateFormatConstants} from "../common/types/dateFormatConstants";
import {SpinnerService} from "../common/services/spinner.service";
import {ModelService} from "../model/model.service";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {PeriodCourseSelector} from "../model/types/periodCourseSelector";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {STORAGE_SERVICE, StorageService} from "../common/services/storage.service";
import {DashboardStoredData} from "../common/types/dashboard-stored-data";
import {TestDefinitions} from "../model/types/test-definitions";
import {StorageProperty} from "../model/types/storage-property";

@Component({
  selector: 'app-app-test-definition-dashboard',
  templateUrl: './app-test-definition-dashboard.component.html',
  styleUrls: ['./app-test-definition-dashboard.component.scss']
})
export class AppTestDefinitionDashboardComponent implements OnInit, AfterViewInit {

  testDefinitionsDataSource: MatTableDataSource<TestDefinition>;

  testDefinitionsColumns = ['courseSection', 'type', 'examId', 'testDefinitionId', 'startDate', 'startTime',  'endTime', 'timingType', 'assessmentLocation',
    'numberOfBookings', 'divisionUnit', 'classLocation', 'source',  'view', 'edit' ];

  constructor(private spinnerService: SpinnerService, private router: Router,
              private model: ModelService, @Inject(STORAGE_SERVICE) private storage: StorageService) {}

  @ViewChild('columnsToSearch') columnsToSearch;
  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;

  // for date range filter
  // used to show in datepicker
  dateType = DateType;
  public dateOptions: DateOption[] = [DateOption.TODAY, DateOption.NEXT_DAY,
    DateOption.CURRENT_WEEK, DateOption.CURRENT_MONTH, DateOption.CUSTOM_DATE_RANGE];

  public dateOptionSelected: DateOption;

  // the default date in 2 datepickers
  public startDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));
  public endDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));

  defaultDisplayedColumns: Column[] = [
    new Column(ColumnName.COURSE_SECTION),
    new Column(ColumnName.TYPE),
    new Column(ColumnName.CLASS_START_DATE),
    new Column(ColumnName.CLASS_START_TIME),
    new Column(ColumnName.CLASS_END_TIME),
    new Column(ColumnName.TIMING_TYPE),
    new Column(ColumnName.ASSESSMENT_LOCATION),
    new Column(ColumnName.NUMBER_OF_BOOKINGS),
    new Column(ColumnName.DIVISION_UNIT),
    new Column(ColumnName.CLASS_LOCATION),
    new Column(ColumnName.SOURCE),
    new Column(ColumnName.EXAM_ID),
    new Column(ColumnName.TEST_DEFINITION_ID)

  ];

  displayedColumns: Column[] = this.defaultDisplayedColumns;

  textFilter = '';
 /* regularStatusFilter = Status.getIDsInString();
  iconStatusFilter = '';

  regularStatuses: Status[];
  iconStatuses: Status[];*/

  statusDesc = StatusDesc;
  storedData: DashboardStoredData = new DashboardStoredData();

  testDefinitionsAmountExceedLimit = false;
  testDefinitionsAmount = 0;


  ngOnInit(): void {

    const storedData = this.storage.getSessionStorageDashboardFilters(StorageProperty.TEST_DEFINITIONS_DASHBOARD_FILTERS);
    // get value of text filter and display columns
    if (storedData) {
      this.storedData = storedData;
      if (storedData.textFilter) {
        this.textFilter = storedData.textFilter;
      }
      if (storedData.displayedColumns) {
        this.displayedColumns = storedData.displayedColumns;
      }
    }
    // get bookings based on stored date, course, student selector if there is , otherwise get today's bookings
    this.dateOptionSelected = DateOption.TODAY;
    this.startDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));
    this.endDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));
    if (storedData && storedData.periodCourseSelector) {
      this.model.getTestDefinitions(storedData.periodCourseSelector).subscribe( x => {
        this.processTestDefinitions(x);
      });
    } else {
      this.model.getTodayTestDefinitions().subscribe(testDefinitions => {
        if (testDefinitions) {
          this.processTestDefinitions(testDefinitions);
        }
      });
    }



    // set default values
    /*this.dateOptionSelected = DateOption.TODAY;
    this.startDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));
    this.endDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));

    this.textFilter = '';
    this.displayedColumns = this.defaultDisplayedColumns;*/


    /*this.regularStatusFilter = Status.getIDsInString();

    this.regularStatuses = Status.regularStatuses;*/

    // get data from local storage if there is, otherwise create an object used to hold data that user input for filter later
    /*const localStoredData = this.localStorage.getDashboardFilters(LocalStorageProperty.TEST_DEFINITIONS_DASHBOARD_FILTERS);
    if (localStoredData) {
      this.dateOptionSelected = localStoredData.dateOption;
      if (this.dateOptionSelected && this.dateOptionSelected.id === 4) {
        this.startDateDisplay.setValue(this.dateOptionSelected.startEndDate[0]);
        this.endDateDisplay.setValue(this.dateOptionSelected.startEndDate[1]);
      } else {
        this.startDateDisplay.setValue(moment().format(DateFormatConstants.YYYYMMDD));
        this.endDateDisplay.setValue(moment().format(DateFormatConstants.YYYYMMDD));
      }

      if (localStoredData.textFilter) {
        this.textFilter = localStoredData.textFilter;
      }

      if (localStoredData.displayedColumns) {
        this.displayedColumns = localStoredData.displayedColumns;
      }

      if (localStoredData.regularStatusFilter) {
        this.regularStatusFilter = localStoredData.regularStatusFilter;
      }

      if (localStoredData.iconStatusFilter) {
        this.iconStatusFilter = localStoredData.iconStatusFilter;
      }

      Status.markAsChecked(this.regularStatuses, this.regularStatusFilter);
      Status.markAsChecked(this.iconStatuses, this.iconStatusFilter);
    } else {
      this.storedData =  {
        dateOption: DateOption.CURRENT_WEEK,
        textFilter: '',
        displayedColumns: this.defaultDisplayedColumns,
        regularStatusFilter: Status.getIDsInString(),
      //  issueStatusFilter: '',
        iconStatusFilter: ''
      };
    }*/

   /* this.activatedRoute.data.subscribe(x => {
      this.processTestDefinitions(x.testDefinitions);
    });*/

  }

  processTestDefinitions(testDefinitions: TestDefinitions): void {
      this.testDefinitionsAmountExceedLimit = testDefinitions.testDefinitionsAmountExceedLimit;
      this.testDefinitionsAmount = testDefinitions.testDefinitions.length;

      this.testDefinitionsDataSource = new MatTableDataSource(testDefinitions.testDefinitions);

      this.testDefinitionsDataSource.filter = this.textFilter;

      this.testDefinitionsDataSource.filterPredicate = (data: TestDefinition, filter: string): boolean => {

        const textFilter = filter.slice(0, this.textFilter.length).trim();
        // tslint:disable-next-line:max-line-length
        /* const regularStatusFilter = filter.slice(this.textFilter.length, this.textFilter.length + this.regularStatusFilter.length).trim();
         const iconStatusFilter = filter.slice(this.textFilter.length + this.regularStatusFilter.length,
           filter.length).trim();*/

        const filterParts: string[] = textFilter.split(' ');
        /*const regularStatusParts: string[] = regularStatusFilter.split(' ');
        const iconStatusParts: string[] = iconStatusFilter.split(' ');*/

        const textMatch: boolean = filterParts.every((value) => {

          const matchExp: RegExp = new RegExp(value, 'i');

          return this.filterText(data, matchExp);
        });
        return textMatch ;
        //  return textMatch && this.filterRegularStatus(data, regularStatusParts) && this.filterIconStatus(data, iconStatusParts);

      };

      this.testDefinitionsDataSource.sortingDataAccessor = (data: TestDefinition, sortHeaderId: string) => {
        if (sortHeaderId === 'courseSection') {
          return this.getCourseSection(data);
        }
        else if (sortHeaderId === 'type') {
          return data.assessmentType;
        }
        else if (sortHeaderId === 'startDate') {
          return data.classStartDate === null ? '' : data.classStartDate.toString();
        }
        else if (sortHeaderId === 'startTime') {
          return data.classStartTime === null ? '' : data.classStartTime.toString();
        }
        else if (sortHeaderId === 'endTime') {
          return data.classEndTime === null ? '' : data.classEndTime.toString();
        }
        else if (sortHeaderId === 'timingType') {
          return data.assessmentTimingIndicator;
        }
        else if (sortHeaderId === 'assessmentLocation') {
          return this.getAssessmentLocation(data);
        }
        else if (sortHeaderId === 'numberOfBookings') {
          return data.numberOfApprovedBookings;
        }
        else if (sortHeaderId === 'divisionUnit') {
          return data.division + "-" + data.unit;
        }
        else if (sortHeaderId === 'classLocation') {
          return data.classLocation;
        }
        else if (sortHeaderId === 'source') {
          return data.testDefSource;
        }
        else if (sortHeaderId === 'examId') {
          return data.examId;
        }
        else if (sortHeaderId === 'testDefinitionId') {
          return data.testDefinitionId;
        }
        else {
          return '';
        }
      };

      this.testDefinitionsDataSource.sort = this.sort;
      this.testDefinitionsDataSource.paginator = this.paginator;
  }


  ngAfterViewInit() {
  }


  getCourseSection(testDefinition: TestDefinition): string {
    return testDefinition.academicActivityCode + " "
      + testDefinition.sectionCode + ", " + testDefinition.teachMethod + " " + testDefinition.sectionNr;
  }

  getType(testDefinition: TestDefinition): string {
    return testDefinition.testSubType + " " +  testDefinition.assessmentType;
  }

  getAssessmentLocation(testDefinition: TestDefinition): string {
    let location = "";

    if (testDefinition.onCampusInd === true) {
      location = "On Campus";
    } else if (testDefinition.onCampusInd === false) {
      location = "Off Campus";
    }
    return location;
  }

  getTestDefinitionsByPeriod(selector: PeriodCourseSelector) {
    this.testDefinitionsAmountExceedLimit = false;

    this.spinnerService.attachSpinner();

    this.model.getTestDefinitions(selector).subscribe(testDefinitions => {
      this.testDefinitionsAmountExceedLimit = testDefinitions.testDefinitionsAmountExceedLimit;
      this.testDefinitionsAmount = testDefinitions.testDefinitions.length;
      this.testDefinitionsDataSource.data = testDefinitions.testDefinitions;
      this.testDefinitionsDataSource.paginator = this.paginator;
      this.spinnerService.removeSpinner();
    });

    this.storedData.periodCourseSelector = selector;

    this.storage.saveSessionStorageDashboardFilters(StorageProperty.TEST_DEFINITIONS_DASHBOARD_FILTERS, this.storedData);
  }

  GetTestDefinitionsForSelectedDates() {
    const dateRangeStartDate = moment(this.startDate.value);
    const dateRangeEndDate = moment(this.endDate.value);

    // call api to get bookings
    if (dateRangeStartDate <= dateRangeEndDate) {
      const startEndDate = [];
      const startDate = dateRangeStartDate.format(DateFormatConstants.YYYYMMDD);
      const endDate = dateRangeEndDate.format(DateFormatConstants.YYYYMMDD);
      startEndDate.push(startDate);
      startEndDate.push(endDate);

      this.spinnerService.attachSpinner();

      this.model.getTestDefinitionsByDateRange(startEndDate).subscribe( testDefinitions => {
        this.testDefinitionsAmountExceedLimit = testDefinitions.testDefinitionsAmountExceedLimit;
        this.testDefinitionsDataSource.data = testDefinitions.testDefinitions;
        this.spinnerService.removeSpinner();
      });
    }
  }


  filterText(data: TestDefinition, filterExp: RegExp): boolean {
    for (const column of this.displayedColumns) {
      if (column.isInclude) {
        const match = filterExp.test(this.getStringData(data, column.name));
        if (match) {
          return true;
        }
      }
    }
    return false;
  }

 /* filterRegularStatus(data: TestDefinition, statusParts: string[]): boolean {
    for (const statusInString of statusParts) {
      // tslint:disable-next-line:radix
      if (data.status === Status.getDescription(parseInt(statusInString))) {
        return true;
      }
    }
    return false;
  }*/

 /* filterIconStatus(testDefinition: TestDefinition, statusParts: string[]): boolean {
    // By default no icon status is checked, so return true to show this booking
    if (this.iconStatusFilter.length === 0) {
      return  true;
    }
    // Otherwise this booking will be filtered
    // As Selecting multiple in this filter is treated as an OR operator, if the booking's status meet any status selected in the widget,
    // this booking will be selected
    for (const status of statusParts) {
      if (status === "200") {
        if (this.isFileAttached(booking) === true ) {
          return true;
        }
      }

      if (status === "201") {
        if (this.isToBeCompletedOnline(booking) === true ) {
          return true;
        }
      }

      if (status === "202") {
        if (this.isOnlineToBeCompletedInPerson(booking) === true ) {
          return true;
        }
      }

      if (status === "203") {
        if (this.isQuercusAccessGranted(booking) === true ) {
          return true;
        }
      }

      if (status === "204") {
        if (this.isQuercusAccessDenied(booking) === true ) {
          return true;
        }
      }
    }

   // return false;
    return true;
  }*/

  getStringData(data: TestDefinition, columnName: ColumnName): string {
    let result: string;
    switch (columnName) {
      case ColumnName.COURSE_SECTION:
        result = this.getCourseSection(data);
        break;
      case ColumnName.TYPE:
        result = this.getType(data);
        break;
      case ColumnName.CLASS_START_DATE:
        result = data.classStartDate ? data.classStartDate.toString() : '';
        break;
      case ColumnName.CLASS_START_TIME:
        result = data.classStartTime ? data.classStartTime.toString() : '';
        break;
      case ColumnName.CLASS_END_TIME:
        result = data.classEndTime ? data.classEndTime.toString() : '';
        break;
      case ColumnName.TIMING_TYPE:
        result = data.assessmentTimingIndicator;
        break;
      case ColumnName.ASSESSMENT_LOCATION:
        result = this.getAssessmentLocation(data);
        break;
      case ColumnName.NUMBER_OF_BOOKINGS:
        result = String(data.numberOfApprovedBookings);
        break;
      case ColumnName.DIVISION_UNIT:
        result = data.division + "-" + data.unit;
        break;
      case ColumnName.CLASS_LOCATION:
        result = data.classLocation;
        break;
      case ColumnName.SOURCE:
        result = data.testDefSource;
        break;
      case ColumnName.EXAM_ID:
        result = data.examId + '';
        break;
      case ColumnName.TEST_DEFINITION_ID:
        result = data.testDefinitionId + '';
        break;
      default:
        result = '';
    }
    return result;
  }

  applyTextFilter($event: KeyboardEvent): void {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.textFilter = filterValue.trim();
   /* this.storedData.textFilter = this.textFilter;
    // note: only if user input value in the text filter, the selected columns will be saved in local storage
    this.storedData.displayedColumns =  this.displayedColumns;*/
    this.applyFilter();
  }

  // common function for the filters
  applyFilter() {
    const filter = this.textFilter;

  //  const filter = this.textFilter + this.regularStatusFilter  + this.iconStatusFilter;
    this.testDefinitionsDataSource.filter = filter;
    this.storedData.textFilter = filter;
    this.storedData.displayedColumns =  this.displayedColumns;
    this.storage.saveSessionStorageDashboardFilters(StorageProperty.TEST_DEFINITIONS_DASHBOARD_FILTERS, this.storedData);  }

  /*applyRegularStatusFilter() {
    const statusSet = new Set();
    this.regularStatuses.forEach(status => {
      if (status.isChecked) {
        statusSet.add(status.id);
      }
    });
    let statusIdInString = '';
    statusSet.forEach(status => {
      statusIdInString = statusIdInString + ' ' + status;

    });
    this.regularStatusFilter = statusIdInString.trim();
    this.storedData.regularStatusFilter = this.regularStatusFilter;
    this.applyFilter();
  }

  applyIconStatusFilter() {
    let statusIdInString = '';
    this.iconStatuses.forEach( status => {
      if (status.isChecked) {
        statusIdInString = statusIdInString + ' ' + status.id;
      }
    });
    this.iconStatusFilter = statusIdInString.trim();
    this.storedData.iconStatusFilter = this.iconStatusFilter;
    this.applyFilter();
  }*/

  reset(){
    this.textFilter = '';
    // call child component's reset method
    this.columnsToSearch.reset();
    this.applyFilter();
   /* this.regularStatuses.forEach(status => {
      status.isChecked = true;
    });
    this.iconStatuses.forEach(status => {
      status.isChecked = false;
    });
    this.applyRegularStatusFilter();
    this.applyIconStatusFilter();*/

   /* this.storedData =  {
      dateOption: this.dateOptionSelected,
      textFilter: '',
      displayedColumns: this.defaultDisplayedColumns,
      regularStatusFilter: Status.getIDsInString(),
    //  issueStatusFilter: '',
      iconStatusFilter: ''
    };
    this.localStorage.storeDashboardFilters(LocalStorageProperty.TEST_DEFINITIONS_DASHBOARD_FILTERS, this.storedData);*/
  }

  editTestDefinition(testDefinitionId: number){
    const url = "edit-test-def/" + testDefinitionId;
    this.router.navigateByUrl(url);
  }

  viewTestDefinition(testDefinitionId: number){
    const url = "/test-definition/" + testDefinitionId;
    this.router.navigateByUrl(url);
  }

  createTestDefinition(){
    this.router.navigateByUrl("add-test-def");
  }

  uploadTestDefinition(){
    this.router.navigateByUrl("upload-test-def");
  }

  tableDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.testDefinitionsColumns, event.previousIndex, event.currentIndex);
  }
}
