<div class="date-picker">
  <ng-container>
    <div class="input-group">
      <input class="form-control"
             [formControl]="date"
             ngbDatepicker #d="ngbDatepicker"
             [minDate]="minDate"
             [maxDate]="maxDate"
             (blur)="touched()"
             placeholder="yyyy-mm-dd"  />
      <button class="btn btn-outline-secondary" type="button" (click)="d.toggle()">
        <i class="fa fa-calendar" aria-hidden="true"></i>
      </button>
    </div>
  </ng-container>
</div>
