import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {FormBuilder, FormControl, UntypedFormControl, Validators} from "@angular/forms";
import {ModelService} from "../../model/model.service";
import {TestDef} from "../../model/types/test-def";
import {TypeUtils} from "../../common/types/type-utils";
import {LogService} from "../../common/services/log-service.service";
import {Course} from "../../model/types/course";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {SimpleBooking} from "../../model/types/simple-booking";
import {MatSort} from "@angular/material/sort";
import {INavigateAway} from "../../common/types/inavigate-away";
import {SelectionModel} from "@angular/cdk/collections";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BasicModalComponent} from "../../common/components/basic-modal/basic-modal.component";
import {BasicModalContent} from "../../common/types/basic-modal-content";
import {Button} from "../../common/types/button";
import {Yes} from "../../common/types/yes.enum";
import {No} from "../../common/types/no.enum";
import {ToastService} from "@easi-sis/core";
import {StudentInfo} from "../../model/types/studentInfo";
// import {NoticeComponent} from "@easi-sis/core";

@Component({
  selector: 'app-edit-test-def',
  templateUrl: './edit-test-def.component.html',
  styleUrls: ['./edit-test-def.component.scss']
})
export class EditTestDefComponent implements OnInit, AfterViewInit, INavigateAway {
  testDefId: string;

  testDef: TestDef;

  typeUtils: TypeUtils;

  // Test Definition Details fields
  courseAndSection: string;
  clockworkExamId: number;
  testType: string;
  testSubtype: string;
  faculty: string;

  types: string[];
  subtypes: string[];

  // Assessment writing details
  assessmentDate: Date;
  assessmentTime: Date;
  assessmentLocation: string;
  assessmentDuration: number;
  classLocation: string;

  doesTestDefinitionHasBooking = false;
  bookingAmountOfTestDefinition = 0;
  isTypeEditable = false;
  typeNotEditableMessage: string;

  toEditType = false;
  toEditDetails = false;
  toMoveBookings = false;

  destinationTestDef: TestDef;
  foundDestinationTestDef: boolean;
  destinationTestDefCourseNotMatch = false;
  bookingsOfDestinationTestDef: SimpleBooking[] = [];
  bookingsOfDestinationTestDefDataSource: MatTableDataSource<SimpleBooking>;

  bookingListToBeMoved: SimpleBooking[] = [];
  bookingsToBeMovedDataSource: MatTableDataSource<SimpleBooking> = new MatTableDataSource<SimpleBooking>();
  bookingsBeingMoved = false;
  invalidBookings: SimpleBooking[] = [];
  notEnrolledStudents: StudentInfo[] = [];

  bookingsColumns = ["appointmentId", "bookingId", "studentName", "studentRosiNo"];

  isFormSubmitted = false;

  testDefinitionBeingCommitted = false;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private fb: FormBuilder,
              private toastService: ToastService,
              private modelService: ModelService,
              private modalService: NgbModal,
              private logger: LogService) {


    this.typeUtils = new TypeUtils();
  }

  testDefForm = this.fb.group({
    testType: [''],
    testSubtype: [''],
    assessmentLocationDetails: this.fb.group({
      onCampusInd: [],
      classLocation: ['']
    }),
    assessmentScheduleDetails: this.fb.group({
      assessmentDate: [],
      assessmentTime: [],
      assessmentDuration: []
    })
  });

  destinationExam = this.fb.group(
    {
      destinationExamID: new FormControl<number>(null, Validators.required),
    }
  );

  @ViewChild('destinationExamID') destinationExamID: ElementRef;

  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  bookingsDataSource: MatTableDataSource<SimpleBooking>;
  selection = new SelectionModel<SimpleBooking>(true, []);


  ngOnInit(): void {
    this.route.paramMap.subscribe(
      (params: ParamMap) => {
        this.testDefId = params.get('id');
        console.log("Got test def Id: " + this.testDefId);

        this.getTestDef();
      });
    this.initMatTable();
  }

  ngAfterViewInit() {
  }

  /**
   * NavigateAway interface method.
   * Returns true if there is no unsaved data, false otherwise.
   */
  canNavigateAway(): boolean {
    return this.isFormSubmitted || !this.testDefForm.dirty;
  }

  initMatTable(): void {
    const bookings: SimpleBooking[] = [];
    this.bookingsDataSource = new MatTableDataSource(bookings);
    this.bookingsDataSource.sortingDataAccessor = (data: SimpleBooking, sortHeaderId: string) => {
      if (sortHeaderId === 'appointmentId') {
        return data.clockworkAppointmentId ? data.clockworkAppointmentId.toString() : "";
      } else if (sortHeaderId === 'bookingId') {
        return data.bookingId.toString();
      } else if (sortHeaderId === 'studentName') {
        return data.studentInfo.firstName + " " + data.studentInfo.lastName;
      } else if (sortHeaderId === 'studentRosiNo') {
        return data.studentInfo.rosiStudentId;
      } else {
        return "";
      }
    };

    this.bookingsDataSource.sort = this.sort;
  }

  getTestDef() {
    const testDefIdAsNum = parseInt(this.testDefId, 10);
    console.log("=> testDefIdAsNum: " + testDefIdAsNum);
    this.modelService.getTestDef(testDefIdAsNum).subscribe((res: TestDef) => {
      this.testDef = res;

      const course: Course = res.course;

      // Test Definition Details fields
      this.courseAndSection =
                course.code + ' ' + course.sectionCode + ', ' + course.teachMethod + ' ' + course.sectionNumber;
      this.clockworkExamId = res.clockworkExamId;
      this.testType = this.typeUtils.typeLabel(res.testType);
      this.testSubtype = this.typeUtils.typeLabel(res.testSubtype);

      this.initTestDef(res);
    });
  }

  initTestDef(testDef: TestDef) {
    this.testDefForm.get('testType').setValue(testDef.testType);
    this.testDefForm.get('testSubtype').setValue(testDef.testSubtype);

    // Assessment location details
    this.testDefForm.get('assessmentLocationDetails.onCampusInd')
      .setValue(testDef.assessmentLocationDetails.onCampusInd);
    this.testDefForm.get('assessmentLocationDetails.classLocation')
      .setValue(testDef.assessmentLocationDetails.classLocation);

    // Assessment Schedule Details
    this.testDefForm.get('assessmentScheduleDetails.assessmentDate')
      .setValue(testDef.assessmentScheduleDetails.assessmentDate);
    this.testDefForm.get('assessmentScheduleDetails.assessmentDate')
      .addValidators(Validators.required);
    this.testDefForm.get('assessmentScheduleDetails.assessmentTime')
      .setValue(testDef.assessmentScheduleDetails.assessmentTime);
    this.testDefForm.get('assessmentScheduleDetails.assessmentDuration')
      .setValue(testDef.assessmentScheduleDetails.assessmentDuration);
    this.testDefForm.get('assessmentScheduleDetails.assessmentDuration')
      .addValidators(Validators.required);

    // tslint:disable-next-line:radix
    this.modelService.getBookingsByTestDefinitionId(parseInt(this.testDefId)).subscribe(bookings => {
      if (bookings && bookings.length > 0) {
        this.doesTestDefinitionHasBooking = true;
        this.bookingAmountOfTestDefinition = bookings.length;
        this.bookingsDataSource.data = bookings;
      }
      this.setupTestTypeOptions();
    });
  }

  editType(): void {
    this.toEditType = true;
    this.toEditDetails = false;
    this.toMoveBookings = false;
  }

  editDetails(): void {
    this.toEditType = false;
    this.toEditDetails = true;
    this.toMoveBookings = false;
  }

  moveBookings(): void {
    this.toEditType = false;
    this.toEditDetails = false;
    this.toMoveBookings = true;
  }

  notStartChange(): boolean {
    return !this.toEditType && !this.toEditDetails && !this.toMoveBookings;
  }

  setupTestTypeOptions(): void {
    if (this.doesTestDefinitionHasBooking) {
      if (this.testDef.testType === this.typeUtils.DB_TYPE_TEST) {
        if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_STANDARD) {
          this.isTypeEditable = true;
          this.types = [];
          this.types.push(this.typeUtils.DB_TYPE_TEST);
          this.types.push(this.typeUtils.DB_TYPE_FINAL_EXAM);

          this.subtypes = [];
          this.subtypes.push(this.typeUtils.DB_SUBTYPE_STANDARD);
          this.subtypes.push(this.typeUtils.DB_SUBTYPE_MAKEUP);
        } else if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_MAKEUP) {
          this.isTypeEditable = true;
          this.types = [];
          this.types.push(this.typeUtils.DB_TYPE_TEST);

          this.subtypes = [];
          this.subtypes.push(this.typeUtils.DB_SUBTYPE_STANDARD);
          this.subtypes.push(this.typeUtils.DB_SUBTYPE_MAKEUP);
        } else if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_POP_QUIZ) {
          this.isTypeEditable = false;
          this.typeNotEditableMessage = "Changes cannot be made to deferred test definitions.";
        } else if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_DEFERRED) {
          this.isTypeEditable = false;
          this.typeNotEditableMessage = "Changes cannot be made for pop quizzes.";
        }
      } else if (this.testDef.testType === this.typeUtils.DB_TYPE_FINAL_EXAM) {
        if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_STANDARD) {
          this.isTypeEditable = true;
          this.types = [];
          this.types.push(this.typeUtils.DB_TYPE_TEST);
          this.types.push(this.typeUtils.DB_TYPE_FINAL_EXAM);

          this.subtypes = [];
          this.subtypes.push(this.typeUtils.DB_SUBTYPE_STANDARD);
        } else if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_DEFERRED) {
          this.isTypeEditable = false;
          this.typeNotEditableMessage = "Changes cannot be made for deferred test definitions.";
        }
      }
    } else {
      this.isTypeEditable = true;
      this.types = this.typeUtils.types;
      this.modelService.getTestTypes().subscribe((data) => {
        this.typeUtils.getTestTypes(data);
        this.typeUtils.findSubtypes(this.testDef.testType);
        this.subtypes = this.typeUtils.subtypes;
      });
    }
  }

  findSubtypes(type: string): void {
    if (this.doesTestDefinitionHasBooking) {
      if (this.testDef.testType === this.typeUtils.DB_TYPE_TEST) {
        if (this.testDef.testSubtype === this.typeUtils.DB_SUBTYPE_STANDARD) {
          if (type === this.typeUtils.DB_TYPE_TEST) {
            this.subtypes = [];
            this.subtypes.push(this.typeUtils.DB_SUBTYPE_STANDARD);
            this.subtypes.push(this.typeUtils.DB_SUBTYPE_MAKEUP);
          } else if (type === this.typeUtils.DB_TYPE_FINAL_EXAM) {
            this.subtypes = [];
            this.subtypes.push(this.typeUtils.DB_SUBTYPE_STANDARD);
          }
        }
      }
    }else {
      this.typeUtils.findSubtypes(type);
      this.subtypes = this.typeUtils.subtypes;
    }
  }

  applyChanges() {
    this.testDefinitionBeingCommitted = true;
    const testDef: TestDef = Object.assign(new TestDef(), this.testDefForm.value);
    // set the test definition ID carried by the object retrieved from backend
    testDef.testDefId = this.testDef.testDefId;
    testDef.bookingAmount = this.bookingAmountOfTestDefinition;
    testDef.clockworkExamId = this.testDef.clockworkExamId;

    // Set default Assessment type
    if (this.testDefForm.get('testType').value === this.typeUtils.DB_TYPE_TEST) {
      testDef.assessmentType = this.typeUtils.DB_TEST_ASSESSMENT_TYPE;
    } else {
      testDef.assessmentType = this.typeUtils.DB_EXAM_ASSESSMENT_TYPE;
    }

    if (this.toEditType) {
      this.modelService.updateTestDefWithTypes(testDef).subscribe( () => {
          this.testDefinitionBeingCommitted = true;
          this.logger.debug("test definition is updated with new test type or new test subtype successfully.");
          this.toastService.show({type: 'success', action: 'You have successfully updated the test definition'});
          location.reload();
        },
        (error) => {
          this.testDefinitionBeingCommitted = false;
          this.logger.error("Failed to update test definition with error ", JSON.stringify(error));
        }
      );

    } else {
      if (this.testDefForm.get('assessmentLocationDetails.onCampusInd').value === null) {
        testDef.assessmentLocationDetails.onCampusInd = null;
      }

      this.modelService.updateTestDef(testDef).subscribe(() => {
          this.testDefinitionBeingCommitted = true;
          this.toastService.show({ type: 'success', action: 'Successfully updated the test definition' });
          this.isFormSubmitted = true;
          this.logger.debug("Test Definition is updated successfully.");
          location.reload();
        },
        (error) => {
          this.testDefinitionBeingCommitted = false;
          this.logger.error("Failed to update test definition with error ", JSON.stringify(error));
        }
      );
    }
  }

  getDestinationTestDef(): void {
    this.reset();

    if (this.destinationExam.controls.destinationExamID.valid) {
      this.modelService.getTestDefByExamId(this.destinationExam.controls.destinationExamID.value).subscribe((testDef) => {
        this.destinationTestDef = testDef;

        if (testDef) {
          this.foundDestinationTestDef = true;
          if ( testDef.course.id !== this.testDef.course.id ) {
            this.destinationTestDefCourseNotMatch = true;
            this.logger.error("Trying to move bookings to a test definition for different course");
          } else {
            if (testDef.testDefId) {
              this.modelService.getBookingsByTestDefinitionId(testDef.testDefId).subscribe(bookings => {
                if (bookings && bookings.length > 0) {
                  this.bookingsOfDestinationTestDef = bookings;
                  this.bookingsOfDestinationTestDefDataSource = new MatTableDataSource<SimpleBooking>(bookings);
                }
              });
            }
          }
        } else {
          this.foundDestinationTestDef = false;
        }
      },
      (error => {
        this.foundDestinationTestDef = false;
        this.logger.error("Error occurs when looking for destination test definition. ", JSON.stringify(error));
      }));
    }
  }

  resetSearchTestDefinition() {
    this.reset();
    this.destinationExam.controls.destinationExamID.reset();
  }

  reset() {
    this.bookingsBeingMoved = false; // enable "moving booking button" if it is disabled
    this.foundDestinationTestDef = undefined;
    this.destinationTestDef = undefined;
    this.destinationTestDefCourseNotMatch = false;
    this.bookingsOfDestinationTestDef = [];
    this.bookingsOfDestinationTestDefDataSource = undefined;
    this.notEnrolledStudents = [];
  }

  receiveBookingsToBeMoved(bookings: SimpleBooking[]): void {
    // enable "moving booking button" if it is disabled
    this.bookingsBeingMoved = false;
    this.notEnrolledStudents = [];
    this.bookingListToBeMoved = bookings;
    this.bookingsToBeMovedDataSource.data = bookings;
  }

  moveBookingsDisabled(): boolean {
    return this.bookingListToBeMoved.length === 0 || this.bookingsBeingMoved;
  }

  moveBookingsToOtherTestDefinitions(): void {

    this.bookingsBeingMoved = true;
    this.invalidBookings = [];
    this.notEnrolledStudents = [];

    this.bookingListToBeMoved.forEach(booking => {
      // check if the student already has the booking(s) of the destination test definition
      if (this.bookingsOfDestinationTestDef && this.bookingsOfDestinationTestDef.length > 0) {
        this.bookingsOfDestinationTestDef.forEach(bookingOfDestinationTestDef => {
          if (bookingOfDestinationTestDef.studentInfo.atsStudentId === booking.studentInfo.atsStudentId) {
            booking.notValid = true;
            this.invalidBookings.push(booking);
          }
        });
      }
      booking.destinationTestDefinitionId = this.destinationTestDef.testDefId;
    });

    if (this.invalidBookings.length > 0 ) {
      this.destinationExamID.nativeElement.focus();
    }

    if (this.invalidBookings.length === 0) {

      const modalRef = this.modalService.open(BasicModalComponent);
      const modalContent = new BasicModalContent("Move Bookings");
      modalContent.body = "Are you sure you want to move " + this.bookingListToBeMoved.length + " booking(s) from Test Definition: Exam ID "
        + this.testDef.clockworkExamId + " to Test Definition: Exam ID " + this.destinationTestDef.clockworkExamId + " ? ";
      modalContent.buttons = [ new Button("Yes, Move Bookings", Yes.VALUE), new Button("Cancel", No.VALUE)];
      modalRef.componentInstance.modalContent = modalContent;

      modalRef.result.then(
        // If the user click "Yes, Move bookings", call API method
        (res) => {
          if (res === Yes.VALUE) {
            this.modelService.moveBookingsToOtherTestDefinitions(this.bookingListToBeMoved)
              .subscribe((notEnrolledStudents: StudentInfo[]) => {
                this.notEnrolledStudents = notEnrolledStudents;
                if ( notEnrolledStudents.length > 0 ) {
                  this.bookingsBeingMoved = false;
                  this.destinationExamID.nativeElement.focus();
                } else {
                  location.reload();
                }
              },
              (error) => {
                this.bookingsBeingMoved = false;
                this.logger.error("Failed to move bookings with error ", JSON.stringify(error));
              }
            );
          } else {
            // If the user click "Cancel", just enable the "Move Bookings" in the "Edit booking" page
            this.bookingsBeingMoved = false;
          }
        },
        // If the user close the modal, just enable the "Move Bookings" in the "Edit booking" page
        () => {
          this.bookingsBeingMoved = false;
        }
      );

    }
  }

  reload() {
    location.reload();
  }

}
