import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor, HttpHeaders
} from '@angular/common/http';
import { Observable } from 'rxjs';
import {OAuthService} from "angular-oauth2-oidc";
import {LogService} from "../services/log-service.service";
import {Environment} from "../../../environment";

@Injectable()
export class AuthTokenInterceptor implements HttpInterceptor {

  constructor(private oAuthService: OAuthService, private logger: LogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {

    // this.logger.debug("@@@=> accessToken: " + this.oAuthService.getAccessToken());
    // this.logger.debug("@@@=> idToken: " + this.oAuthService.getIdToken());

    const authRequest = this.addHeaders(request);

    return next.handle(authRequest);
  }

  addHeaders(request: HttpRequest<any>): HttpRequest<any> {

    // NOTE: DO NOT attach the Authorization header to the call to PhoneSearch as the call WILL FAIL!
    if (request.url.includes('PhonebookSearchRESTSvc')) {
      return request;
    }

    const idToken = this.oAuthService.getIdToken();

    let clonedHttpRequest: HttpRequest<any> = null;
    if (Environment.isRelease) {
      clonedHttpRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + idToken).set('Api-Version', 'release')
      });
    } else {
      clonedHttpRequest = request.clone({
        headers: request.headers.set('Authorization', 'Bearer ' + idToken)
      });
    }

    return clonedHttpRequest;
  }

}
