import {AfterViewInit, Component, Inject, OnInit, ViewChild} from '@angular/core';
import {MatSort} from '@angular/material/sort';
import {MatLegacyTableDataSource as MatTableDataSource} from '@angular/material/legacy-table';
import {ModelService} from '../model/model.service';
import {Booking} from '../model/types/booking';
import {ActivatedRoute, Router} from '@angular/router';
import {DateOption} from "../common/types/dateOption";
import moment from "moment";
import {DateFormatConstants} from "../common/types/dateFormatConstants";
import {DateType} from "../common/types/DateType";
import {Column} from "../common/types/column";
import {ColumnName} from "../common/types/columnName";
import {SpinnerService} from "../common/services/spinner.service";
import {StatusDesc} from "../model/types/statusDesc";
import {UntypedFormControl, Validators} from "@angular/forms";
import {DatePattern} from "../common/types/date-pattern";
import {MatLegacyPaginator as MatPaginator} from "@angular/material/legacy-paginator";
import {PeriodCourseSelector} from "../model/types/periodCourseSelector";
import {CdkDragDrop, moveItemInArray} from "@angular/cdk/drag-drop";
import {DashboardStoredData} from "../common/types/dashboard-stored-data";
import {StorageProperty} from "../model/types/storage-property";
import {STORAGE_SERVICE, StorageService} from "../common/services/storage.service";
import {Bookings} from "../model/types/bookings";

/**
 * @title Table with pagination
 */
@Component({
  selector: 'app-homepage',
  templateUrl: './app-homepage.component.html',
  styleUrls: ['./app-homepage.component.scss']
})

export class AppHomepageComponent implements OnInit, AfterViewInit {

  constructor(private spinnerService: SpinnerService, private activatedRoute: ActivatedRoute, private router: Router,
              private model: ModelService,  @Inject(STORAGE_SERVICE) private storage: StorageService) { }

  bookingsDataSource: MatTableDataSource<Booking>;

  bookingsColumns = ['courseSection', 'type', 'clockworkAppointmentId', 'examId', 'classStartDate',
                             'classStartTime', 'scheduledStartDate', 'scheduledStartTime', 'scheduledEndDate',
                             'scheduledEndTime', 'duration', 'breakMinutes', 'location', 'student', 'studentNumber',
                             'testDefinitionId', 'bookingId', 'bookingStatus', 'view'];

  @ViewChild('columnsToSearch') columnsToSearch;
  @ViewChild(MatSort, {static: true}) sort?: MatSort;
  @ViewChild(MatPaginator) paginator: MatPaginator;


  // for date range filter
  // used to show in datepicker
  dateType = DateType;
  public dateOptions: DateOption[] = [DateOption.TODAY, DateOption.NEXT_DAY,
    DateOption.CURRENT_WEEK, DateOption.CURRENT_MONTH, DateOption.CUSTOM_DATE_RANGE];

  public dateOptionSelected: DateOption;

  // the default date in 2 datepickers
  public startDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));
  public endDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));

  // for text filter and status filter
  defaultDisplayedColumns: Column[] = [
    new Column(ColumnName.COURSE_SECTION),
    new Column(ColumnName.CLASS_START_DATE),
    new Column(ColumnName.CLASS_START_TIME),
    new Column(ColumnName.CLASS_END_TIME),
    new Column(ColumnName.SCHEDULED_START_DATE),
    new Column(ColumnName.SCHEDULED_START_TIME),
    new Column(ColumnName.SCHEDULED_END_DATE),
    new Column(ColumnName.SCHEDULED_END_TIME),
    new Column(ColumnName.BREAK_MINUTES),
    new Column(ColumnName.LOCATION),
    new Column(ColumnName.STUDENT_NAME),
    new Column(ColumnName.STUDENT_NUMBER),
    new Column(ColumnName.TYPE),
    new Column(ColumnName.CLOCKWORK_APPOINTMENT_ID),
    new Column(ColumnName.EXAM_ID),
    new Column(ColumnName.TEST_DEFINITION_ID),
    new Column(ColumnName.BOOKING_ID),
  ];

  textFilter = '';
  displayedColumns: Column[] = this.defaultDisplayedColumns;

  statusDesc = StatusDesc;

  bookingsAmountExceedLimit = false;

  bookingsAmount = 0;

//  showAccomDetails: boolean;

  // for seat-selector widget
//  public rooms: Room[];

//  accomColumnSortDisable = false;

  storedData: DashboardStoredData = new DashboardStoredData();
//  storedSelector: PeriodCourseSelector;

  ngOnInit() {

    const storedData = this.storage.getSessionStorageDashboardFilters(StorageProperty.BOOKINGS_DASHBOARD_FILTERS);
    // get value of text filter and display columns
    if (storedData) {
      this.storedData = storedData;
      if (storedData.textFilter) {
        this.textFilter = storedData.textFilter;
      }
      if (storedData.displayedColumns) {
        this.displayedColumns = storedData.displayedColumns;
      }
    }
    // get bookings based on stored date, course, student selector if there is , otherwise get today's bookings
    this.dateOptionSelected = DateOption.TODAY;
    this.startDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));
    this.endDate.setValue(moment().format(DateFormatConstants.YYYYMMDD));
    if (storedData && storedData.periodCourseSelector) {
      this.model.getBookings(storedData.periodCourseSelector).subscribe( x => {
        this.processBookings(x);
      });
    } else {
      this.model.getTodayBookings().subscribe(bookings => {
        if (bookings) {
          this.processBookings(bookings);
        }
      });
    }
  }

  processBookings(bookings: Bookings): void {
      this.bookingsAmountExceedLimit = bookings.bookingsAmountExceedLimit;
      this.bookingsAmount = bookings.bookings.length;

    /* x.bookings.bookings.forEach(y => y.courseInstructorsFullNames =
          Array.isArray(y.instructors) ? y.instructors.map(z =>  z.firstName + ' ' + z.lastName).join(', ') : '');*/

      this.bookingsDataSource = new MatTableDataSource(bookings.bookings);
      // this.bookingsDataSource = new MatTableDataSource(this._putErrorBookingsFirst(x.bookings.bookings));

      // before user enter any filter, use values from local storage or default values to filter data
      this.bookingsDataSource.filter = this.textFilter;

      this.bookingsDataSource.filterPredicate = (data: Booking, filter: string): boolean => {

        const textFilter = this.textFilter;
        const filterParts: string[] = textFilter.split(' ');
        const textMatch: boolean = filterParts.every((value) => {
          const matchExp: RegExp = new RegExp(value, 'i');
          return this.filterText(data, matchExp);
        });
        return textMatch;
      };

      //  const localStoredData = this.localStorage.getDashboardFilters(LocalStorageProperty.BOOKINGS_DASHBOARD_FILTERS);

      this.bookingsDataSource.sortingDataAccessor = (data: Booking, sortHeaderId: string) => {
        if (sortHeaderId === 'courseSection') {
          return data.courseSection;
        }
        else if (sortHeaderId === 'classStartDate') {
          return data.classStartDate === null ? '' : data.classStartDate.toString();
        }
        else if (sortHeaderId === 'classStartTime') {
          return data.classStartTime === null ? '' : data.classStartTime.toString();
        }
        else if (sortHeaderId === 'classEndTime') {
          return data.classEndTime === null ? '' : data.classEndTime.toString();
        }
        else if (sortHeaderId === 'scheduledStartDate') {
          return data.scheduledStartDate === null ? '' : data.scheduledStartDate.toString();
        }
        else if (sortHeaderId === 'scheduledStartTime') {
          return data.scheduledStartTime === null ? '' : data.scheduledStartTime.toString();
        }
        else if (sortHeaderId === 'scheduledEndDate') {
          return data.scheduledEndDate === null ? '' : data.scheduledEndDate.toString();
        }
        else if (sortHeaderId === 'scheduledEndTime') {
          return data.scheduledEndTime === null ? '' : data.scheduledEndTime.toString();
        }
        else if (sortHeaderId === 'duration') {
          return data.duration;
        }
        else if (sortHeaderId === 'breakMinutes') {
          return data.clockworkBreaksDuration;
        }
        else if (sortHeaderId === 'location') {
          return this.getLocation(data);
        }
        else if (sortHeaderId === 'student') {
          return data.student.firstName + ' ' + data.student.lastName;
        }
        else if (sortHeaderId === 'type') {
          return this.getBookingTestType(data);
        }
        else if (sortHeaderId === 'studentNumber') {
          return data.student.rosiStudentId === null ? '' : data.student.rosiStudentId.toString();
        }
        else if (sortHeaderId === 'clockworkAppointmentId') {
          return data.clockworkExamId === null ? '' : data.clockworkAppointmentId;
        }
        else if (sortHeaderId === 'examId') {
          return data.clockworkExamId === null ? '' : data.clockworkExamId;
        }
        else if (sortHeaderId === 'testDefinitionId') {
          return data.testDefinitionId === null ? '' : data.testDefinitionId;
        }
        else if (sortHeaderId === 'bookingId') {
          return data.id === null ? '' : data.id;
        }
        else if (sortHeaderId === 'bookingStatus') {
          return data.bookingStatus === null ? '' : data.bookingStatus;
        }
        else {
          return '';
        }
      };
      this.bookingsDataSource.sort = this.sort;
      this.bookingsDataSource.paginator = this.paginator;

  }

  ngAfterViewInit() {
  }

  getBookingsByPeriod(period: PeriodCourseSelector) {
    this.bookingsAmountExceedLimit = false;

    this.spinnerService.attachSpinner();

    this.model.getBookings(period).subscribe( bookings => {
      this.bookingsAmountExceedLimit = bookings.bookingsAmountExceedLimit;
      this.bookingsAmount = bookings.bookings.length;
      this.bookingsDataSource.data = bookings.bookings;
      this.bookingsDataSource.paginator = this.paginator;
      this.spinnerService.removeSpinner();
    });

    this.storedData.periodCourseSelector = period;

    this.storage.saveSessionStorageDashboardFilters(StorageProperty.BOOKINGS_DASHBOARD_FILTERS, this.storedData);
  }

  GetBookingsForSelectedDates() {
    const dateRangeStartDate = moment(this.startDate.value);
    const dateRangeEndDate = moment(this.endDate.value);

    if (dateRangeStartDate <= dateRangeEndDate) {
      const startEndDate = [];
      const startDate = dateRangeStartDate.format(DateFormatConstants.YYYYMMDD);
      const endDate = dateRangeEndDate.format(DateFormatConstants.YYYYMMDD);
      startEndDate.push(startDate);
      startEndDate.push(endDate);

      this.spinnerService.attachSpinner();

      this.model.getBookingsByDateRange(startEndDate).subscribe( bookings => {
        this.bookingsAmountExceedLimit = bookings.bookingsAmountExceedLimit;
        this.bookingsDataSource.data = bookings.bookings;
        this.spinnerService.removeSpinner();
      });
    }

  }

  // common function for the filters
  applyFilter() {
    const filter = this.textFilter;
    this.bookingsDataSource.filter = filter;
    this.storedData.textFilter = filter;
    this.storedData.displayedColumns =  this.displayedColumns;
    this.storage.saveSessionStorageDashboardFilters(StorageProperty.BOOKINGS_DASHBOARD_FILTERS, this.storedData);
  }

  applyTextFilter($event: KeyboardEvent): void {
    const filterValue = ($event.target as HTMLInputElement).value;
    this.textFilter = filterValue.trim();
    this.applyFilter();
  }

  filterText(data: Booking, filterExp: RegExp): boolean {
    for (const column of this.displayedColumns) {
      if (column.isInclude) {
        const match = filterExp.test(this.getStringData(data, column.name));
        if (match) {
          return true;
        }
      }
    }
    return false;
  }





  getStringData(data: Booking, columnName: ColumnName): string {
    let result: string;
    switch (columnName) {
      case ColumnName.COURSE_SECTION:
        result = data.courseSection;
        break;
      case ColumnName.CLASS_START_DATE:
        result = data.classStartDate ? data.classStartDate.toString() : '';
        break;
      case ColumnName.CLASS_START_TIME:
        result = data.classStartTime ? data.classStartTime.toString() : '';
        break;
      case ColumnName.CLASS_END_TIME:
        result = data.classEndTime ? data.classEndTime.toString() : '';
        break;
      case ColumnName.SCHEDULED_START_DATE:
        result = data.scheduledStartDate ?  data.scheduledStartDate.toString() : '';
        break;
      case ColumnName.SCHEDULED_START_TIME:
        result = data.scheduledStartTime ? data.scheduledStartTime.toString() : '';
        break;
      case ColumnName.SCHEDULED_END_DATE:
        result = data.scheduledEndDate ? data.scheduledEndDate.toString() : '';
        break;
      case ColumnName.SCHEDULED_END_TIME:
        result = data.scheduledEndTime ? data.scheduledEndTime.toString() : '';
        break;
      case ColumnName.DURATION:
        result = data.duration ? data.duration.toString() : '';
        break;
      case ColumnName.BREAK_MINUTES:
        result = data.clockworkBreaksDuration ? data.clockworkBreaksDuration.toString() : '';
        break;
      case ColumnName.LOCATION:
        result = this.getLocation(data);
        break;
      case ColumnName.STUDENT_NAME:
        result = data.student.firstName + ' ' + data.student.lastName;
        break;
      case ColumnName.TYPE:
        result = this.getBookingTestType(data);
        break;
      case ColumnName.STUDENT_NUMBER:
        result = data.student.rosiStudentId;
        break;
      case ColumnName.CLOCKWORK_APPOINTMENT_ID:
        result = !data.clockworkAppointmentId ? '' : data.clockworkAppointmentId.toString();
        break;
      case ColumnName.EXAM_ID:
        result = !data.clockworkExamId ? '' : data.clockworkExamId.toString();
        break;
      case ColumnName.TEST_DEFINITION_ID:
        result = !data.testDefinitionId ? '' : data.testDefinitionId.toString();
        break;
      case ColumnName.BOOKING_ID:
        result = !data.id ? '' : data.id.toString();
        break;
      case ColumnName.BOOKING_STATUS:
        result = !data.bookingStatus ? '' : data.bookingStatus;
        break;
      default:
        result = '';
    }
    return result;
  }

  getLocation(booking: Booking) {
    if (booking.inExamCentre) {
      return "Exam Centre";
    } else {
      return "Elsewhere";
    }
  }



  /*setSpace(space: Space, bookingId: number) {
    this.model.updateBookingSpace(new SpaceOrOnline(bookingId, space, false)).subscribe(result => {
      this.toastService.show({type: 'success', action: 'The space is changed successfully.'});
      this.updateBookingSpace(result);
    });
  }*/

 /* setOnline(bookingId: number) {
    this.logger.debug('setIsOnline() of student-booking-tab is called');
    this.model.updateBookingSpace(new SpaceOrOnline(bookingId, new Space(), true)).subscribe(result => {
      this.toastService.show({type: 'success', action: 'The booking is changed to be online.'});
      this.updateBookingSpace(result);
    });
  }*/

  /*resetSpace(bookingId: number) {
    this.model.updateBookingSpace(new SpaceOrOnline(bookingId, new Space(), false)).subscribe(result => {
      this.toastService.show({type: 'success', action: 'The space is reset successfully.'});
      this.updateBookingSpace(result);
    });
  }*/

  /*getSpaceString(booking: Booking): string {
    if (booking.online) {
      return "Online";
    } else if (booking.space.id) {
      return booking.space.code + booking.space.roomCode;
    } else {
      return  "None";
    }
  }*/

  /*getAccomHead(accommodations: Accommodation[]) {
    return accommodations ? accommodations.length + ' accom' : "None";
  }*/

  /*getAccomDescsString(accommodations: Accommodation[]): string {
    let result = '';
    for (const accommodation of accommodations ) {
      result = result + ' ' + accommodation.description + ' ';
    }
    return result;
  }*/

  /*switchShowOrHideAccom() {
    // disable sorting
    this.accomColumnSortDisable = true;

    this.showAccomDetails = !this.showAccomDetails;
  }*/

  /*enableSort(){
    this.accomColumnSortDisable = false;
  }*/

  /*showOrHideAccomString() {
    if (this.showAccomDetails) {
      return "Hide Details";
    } else {
      return "Show Details";
    }
  }*/

  /*updateBookingSpace(spaceOrOnline: SpaceOrOnline){
    this.bookingsDataSource.data.forEach(booking => {
      if (booking.id === spaceOrOnline.bookingId) {
        booking.space = spaceOrOnline.space;
        booking.online = spaceOrOnline.online;
      }
    });
  }*/

  // reorder the bookings and put the bookings with errors at the first part and bookings without errors at the second part
  /*_putErrorBookingsFirst(bookings: Booking[]): Booking[] {
    const firstBookings = [];
    const secondBookings = [];

    bookings.forEach(booking => {
      if (booking.conflicts.length > 0) {
        firstBookings.push(booking);
      } else {
        secondBookings.push(booking);
      }
    });

    return firstBookings.concat(secondBookings);
  }*/

  /* isFileAttached(booking: Booking): boolean {
    return booking.statusDesc === 'Files Received';
  }*/

  /* isToBeCompletedOnline(booking: Booking): boolean {
    return booking.onlineFromTestDefinition && (booking.inExamCentre != null && !booking.inExamCentre);
  }

  isOnlineToBeCompletedInPerson(booking: Booking): boolean {
    return ((booking.onlineFromTestDefinition && booking.inPersonFromTestDefinition )
      || (booking.inExamCentre && !booking.online))
      && (!booking.space || !booking.space.id );
  }

  isQuercusAccessGranted(booking: Booking): boolean {
    return booking.quercusPermissionToAtsInd;
  }

  isQuercusAccessDenied(booking: Booking): boolean {
    return booking.quercusPermissionToAtsInd != null && !booking.quercusPermissionToAtsInd;
  }*/


  getBookingTestType(booking: Booking): string {
    let result = "";
    if (booking.testSubType && booking.testSubType.length > 0) {
      result = booking.testSubType + ' ' + booking.assessmentType;
    }
    else {
      result = "Standard " + booking.assessmentType;
    }
    return  result;

  }


  /*sortAccommodations(accommodationsList: Accommodation[]): Accommodation[] {
    return accommodationsList.sort( (a1, a2) => {
      if (a1.category > a2.category) {
        return 1;
      } else {
        return  -1;
      }
    });
  }*/

  createBooking(){
    this.router.navigateByUrl("manual-booking");
  }

  viewBooking(bookingId: number){
    const url = "/student-booking/" + bookingId;
    this.router.navigateByUrl(url);
  }

  reset() {
    this.textFilter = '';
    this.columnsToSearch.reset();
    this.applyFilter();
  }

  tableDrop(event: CdkDragDrop<string[]>) {
    moveItemInArray(this.bookingsColumns, event.previousIndex, event.currentIndex);
  }
}
