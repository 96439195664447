import { Pipe, PipeTransform } from '@angular/core';
import moment from "moment";
@Pipe({name: 'convertFrom24To12Format'})
export class TimeFormat implements PipeTransform {
  transform(time: any): any {
    if (time) {
      return moment(time, 'HH:mm:ss').format("h:mm A");
    }
    return null;
  }
}
