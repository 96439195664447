export class Course {

  id: number;
  postCode: string;
  sessionCode: string;
  code: string;
  sectionCode: string;
  primaryTeachMethod: string;
  primarySectionNo: string;
  secondaryTeachMethod1: string;
  secondarySectionNo1: string;
  secondaryTeachMethod2: string;
  secondarySectionNo2: string;
  title: string;
  deliveryMode: string;
  campusCode: string;
  primaryOrgCode: string;
  secondaryOrgCode: string;
  coSecondaryOrgCode: string;
  attendanceStatus: string;
  subSessionCode: string;
  regSessionCode1: string;
  regSessionCode2: string;
  regSessionCode3: string;

  teachMethod: string;
  sectionNumber: string;

  constructor() {}
}
