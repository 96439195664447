<div class="email-contacts">

  <form [formGroup]="emailContactsForm" (ngSubmit)="saveCourseContacts()">

    <h1>{{editContacts ? 'Edit Course Contact Details' : 'Add Course Contacts'}}</h1>

    <div *ngIf="editContacts === false">
      <strong>Note:</strong> All fields are required unless otherwise specified
      <br/><br/>
    </div>

    <div *ngIf="editContacts === true">
      <div class="identify mb-3">
        You are editing the course contacts for the following:
        <br/>
        <strong>Subject Area, Course Code, or Meeting Section</strong>: {{formattedCourse}}
        <br/>
        <strong>Level of Study:</strong> {{levelOfStudyMap.get(courseContacts?.levelOfStudy)}}

        <br/><br/>
        You can edit contact details, add another course contact or remove existing course contacts. To edit the
        subject area, course or meeting section, please remove existing contacts and add a new course contact.
      </div>
    </div>

    <!-- ADD -->
    <div *ngIf="editContacts === false">
      <!-- 1. Identify the Course -->
      <div class="identify mb-3">
      <h2>1. Identify the Course</h2>

      <p>
        You can add new course contacts for subject areas, courses, or meeting sections. If a contact already exists for
        a subject area, course, or meeting section, you can add another contact to it by editing the existing contact
        details.
      </p>
      <div class="mb-2">
        <strong>
          Are you adding contacts for a subject area, specific course, or a course meeting section?
        </strong>
      </div>

      <!-- Course level radio-buttons -->
      <div class="course-level" id="courseArea">
        <div class="form-check">
          <input class="form-check-input" type="radio" id="subjectArea"
                 formControlName="courseLevel"
                 value="{{CourseLevel.SUBJECT_AREA}}">
          <label class="form-check-label" for="subjectArea">
            Subject Area (E.g., all ECO courses)
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="courseCode"
                 formControlName="courseLevel"
                 value="{{CourseLevel.COURSE_CODE}}">
          <label class="form-check-label" for="courseCode">
            Course (E.g., ECO101H)
          </label>
        </div>
        <div class="form-check">
          <input class="form-check-input" type="radio" id="courseMeetingSection"
                 formControlName="courseLevel"
                 value="{{CourseLevel.COURSE_MEETING_SECTION}}">
          <label class="form-check-label" for="courseMeetingSection">
            Course Meeting Section (E.g., ECO101H1 F LEC1010)
          </label>
        </div>
        <app-validation-message [control]="courseLevel"></app-validation-message>
      </div>

      <!-- Level of Study radio-buttons -->
      <div class="course-level mt-3 margin-left-2rem">
        <div *ngIf="courseLevel.value">
          <div><strong>Level of Study</strong></div>
          <div class="form-check">
            <input class="form-check-input" type="radio" id="undergrad"
                   formControlName="levelOfStudy"
                   value="{{LevelOfStudy.UNDERGRADUATE}}">
            <label class="form-check-label" for="undergrad">
              Undergraduate
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" id="grad"
                   formControlName="levelOfStudy"
                   value="{{LevelOfStudy.GRADUATE}}">
            <label class="form-check-label" for="grad">
              Graduate
            </label>
          </div>
          <div class="form-check">
            <input class="form-check-input" type="radio" id="both"
                   formControlName="levelOfStudy"
                   value="{{LevelOfStudy.BOTH}}">
            <label class="form-check-label" for="both">
              Both
            </label>
          </div>
          <app-validation-message [control]="levelOfStudy"></app-validation-message>
        </div>
      </div>

      <div class="mt-3 margin-left-4rem">
        <div *ngIf="levelOfStudy.value">
          <div [ngSwitch]="courseLevel.value">
            <!-- Subject Area -->
            <div *ngSwitchCase="CourseLevel.SUBJECT_AREA">
              <div><strong>Subject Area</strong></div>
              <div>This refers to the first 3 characters of a Course Code</div>
              <app-course-prefix-typeahead [courseCtrl]="course"
                                           [coursePrefixList]="coursePref"></app-course-prefix-typeahead>
              <app-validation-message [control]="course"></app-validation-message>
            </div>
            <!-- Course Code -->
            <div *ngSwitchCase="CourseLevel.COURSE_CODE">
              <div><strong>Course Code</strong></div>
              <app-course-code-typeahead [courseCtrl]="course"
                                         [levelOfStudyCtrl]="levelOfStudy"></app-course-code-typeahead>
              <app-validation-message [control]="course"></app-validation-message>
            </div>
            <!-- Course Code & Meeting Section Code -->
            <div *ngSwitchCase="CourseLevel.COURSE_MEETING_SECTION">
              <div><strong>Course Meeting Section Code</strong></div>
              <app-course-meeting-sec-typeahead [courseCtrl]="course"
                                                [levelOfStudyCtrl]="levelOfStudy"></app-course-meeting-sec-typeahead>
              <app-validation-message [control]="course"></app-validation-message>
            </div>
            <!-- Display error msg if the save course contacts failed -->
            <div *ngIf="courseAlreadyExistsErrorMsg" class="error-message">{{courseAlreadyExistsErrorMsg}}</div>

            <!-- No course level selected yet -->
            <div *ngSwitchDefault>
            </div>
          </div>
        </div>
      </div>
    </div>
    </div>

    <!-- 2. Add/Edit Contact Details -->
    <div class="add-contact-details mb-3">
      <h2>{{editContacts ? '1. Edit' : '2. Add'}} Contact Details</h2>
      You can add up to 4 contacts for each subject area, course, or meeting section. <br/>

      <ng-container *ngFor="let contactDetailForm of contactArray.controls; let i = index">
        <!-- Child component: displays contact details -->
        <app-contact-detail [contactDetailForm]="contactDetailForm"
                            [contactArrayIndex]="i"
                            [editContacts]="editContacts"
                            [totalContacts]="contactArray?.length"
                            (deleteContactEvent)="deleteContact($event)">

        </app-contact-detail>
      </ng-container>

      <!-- Add contact button -->
      <div *ngIf="contactArray?.length < 4">
        <sis-button
          visualType="secondary"
          size="small"
          (clicked)="addContact()"
          [popoverBody]="!contactArray.valid ? 'To add another contact, please ensure all required fields under “2. Add Contact Details” have been completed.' : ''">
          Add Another Contact
        </sis-button>
      </div>

    </div>

    <br/>

    <!-- 3. Additional Information -->
    <div class="additional-information mb-3">
      <h2>{{editContacts ? '2.' : '3.'}} Additional Information</h2>

      <!-- Notes (Optional) -->
      <div class="">
        <div><strong>Notes for All Contacts (Optional)</strong></div>
        <div>
          Please add any additional notes that are specific to all the contacts for the subject area, course,
          or meeting section selected.
        </div>
        <textarea class="form-control" width="700px"
                  rows="5"
                  placeholder="E.g.: Email the instructor and CC the program coordinator."
                  area-label="Type ahead"
                  formControlName="additionalInfo">
            </textarea>
      </div>
    </div>

    <div class="mt margin-top-4-rem">
      <button class="btn btn-primary" type="submit"
              [ngClass]="{'disabled': !emailContactsForm.valid}"
              [disabled]="!emailContactsForm.valid">
        <span *ngIf="!emailContactsForm.valid"
              ngbTooltip='To save the course contacts, please ensure all required fields have been upated.'
              placement="auto">Save Course Contacts</span>
        <span *ngIf="emailContactsForm.valid">Save Course Contacts</span>
      </button>

<!--      <br/>-->
<!--      <sis-button-->
<!--        type="submit"-->
<!--        visualType="primary"-->
<!--        size="medium"-->
<!--        [popoverBody]="!emailContactsForm.valid? 'To save the course contacts, please ensure all required fields have been updated.' : ''">-->
<!--        Save Course Contacts-->
<!--      </sis-button>-->
    </div>

    <!-- Back to Manage Course Contacts -->
    <div class="mt-3">
      <a [routerLink]="['/instructor-emailing/manage-course-contacts']">Back to Manage Course Contacts</a>
    </div>
  </form>
</div>
