
<div class="student-page">
  <!-- Masthead -->
  <div class="page-masthead row page-head">
    <!-- Masthead Header -->
    <div class="page-header col-7 ">
      <div>
        <app-breadcrumb [booking]="booking"></app-breadcrumb>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>
  <div>
    <div class="masthead-header">
      <h2 class="font-weight-bold"> {{booking.testType}}
        {{booking.scheduledWriting.startDate ? ( "(" + (booking.scheduledWriting.startDate | date: 'yyyy-MM-dd')): '' }}
        {{booking.scheduledWriting.endDate ? ( " to " + (booking.scheduledWriting.endDate | date: 'yyyy-MM-dd')) + ")" : ')'}}
        : {{booking.studentInfo.firstName}} {{booking.studentInfo.lastName}}</h2>
      <h5 class="font-weight-bold"> {{booking.courseString}}</h5>
      <div class="mb-4"><strong>Status:</strong> {{booking.bookingStatus}}</div>

      <!-- Button: Cancel/Uncancel Booking -->
      <div class="col-2 mb-2">
        <button type="button" class="btn btn-outline-secondary btn-block reset-button font-size-16px height-4rem"
                (click)="updateBookingStatus()"
                [ngClass]="{'disabled': !booking.clockworkAppointmentId}"
                [disabled]="!booking.clockworkAppointmentId">
          <span *ngIf="!booking.clockworkAppointmentId"
                ngbTooltip="This booking cannot be cancelled in ATS because it hasn't been exported to Clockwork yet.
                The export to Clockwork occurs every hour on the hour. Please wait until then and try again."
                placement="auto">{{buttonText}}</span>
          <span *ngIf="booking.clockworkAppointmentId">{{buttonText}}</span>
        </button>
      </div>

      <div *ngIf="errorMsg" class="form-group mb-4">
        <sis-notice type="warning"
                    introductoryText="Something went wrong.">
          {{errorMsg}} <a href="https://uoft.me/ats-support" sis-external-link>Enterprise Service Center form</a>.
        </sis-notice>
      </div>

      <div *ngIf="hasConflict"><strong>Issues:</strong><span style="color: #F38829"> Accom. conflict</span></div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="page-content">
    <!-- Schedule and Course Contacts cards -->
    <div class="row">
      <div class="col-6">
        <!-- Class Writing Schedule card -->
        <app-schedule-card [classWriting]="booking.classWriting"
                           [scheduledWriting]="booking.scheduledWriting">
        </app-schedule-card>
      </div>
      <div class="col-6">
        <!-- Course Contacts card -->
        <app-course-contacts-card [instructors]="booking.courseSectionInstructors"
                                  [department]="booking.department">
        </app-course-contacts-card>
      </div>
    </div>
    <!-- Booking tabs -->
    <div class="row mt-3">
      <div class="col-12">
        <!-- Start of nav-booking tabs -->
        <div class="nav-booking">
          <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="true">
            <!-- Tab: Student Booking -->
            <li [ngbNavItem]="1" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Student Booking</a>
              <ng-template ngbNavContent>
                <app-stud-booking-tab [booking]="booking" (hasConflict)="hasConflicts($event)" (updateBookingLog)="updateBookingLog()">
                </app-stud-booking-tab>
              </ng-template>
            </li>
            <!-- Tab: Test details -->
            <li [ngbNavItem]="2" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Test Details</a>
              <ng-template ngbNavContent>
                <app-test-details-tab></app-test-details-tab>
              </ng-template>
            </li>
            <!-- Tab: Activity Log & Staff Notes -->
            <li [ngbNavItem]="3" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Activity Log & Staff Notes</a>
              <ng-template ngbNavContent>
                <app-activity-log-and-notes-tab [activityLogType]="activityLogType"></app-activity-log-and-notes-tab>
              </ng-template>
            </li>
            <!-- Tab: Booking Notes -->
            <li [ngbNavItem]="4" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Booking Notes</a>
              <ng-template ngbNavContent>
                <app-booking-notes-tab [notes]="booking.notes"></app-booking-notes-tab>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
        <!-- End of nav-booking tabs -->
      </div>
    </div>
  </div>
</div>

