
<!-- Masthead -->
<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7">
    <div class="masthead-header">
      <h1>Manual Test Definition Creation</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools  col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>

<!-- Page Content -->
<div class="page-content">

  <p>Note: All fields are required unless otherwise specified.</p>

  <div [formGroup]="testDefForm">

    <!-- Select Test Type -->
    <div class="form-group">
      <div class="row">
        <div class="col">
          <div class="font-weight-bold">Select Test Type</div>
          <div *ngFor="let type of typeUtils.types; index as i">
            <div class="form-check">
              <input class="form-check-input" type="radio" id="{{type}}-{{i}}"
                     [value]="type" formControlName="testType" (change)="typeUtils.findSubtypes(type)">
              <label for="{{type}}-{{i}}" class="form-check-label">{{i + 1}}. {{typeUtils.typeLabel(type)}}</label>
            </div>
          </div>
          <app-validation-msg [control]="testDefForm.get('testType')"></app-validation-msg>
        </div>
      </div>
    </div>

    <!-- Select Type of Request -->
    <!-- Test Subtypes -->
    <div class="ml-4" *ngIf="testDefForm.controls.testType.value">
        <div class="form-group">
          <div class="row">
            <div class="col">
              <div class="font-weight-bold">Select Type of Request</div>
              <div *ngFor="let testSubtype of typeUtils.subtypes; index as i">
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="{{testSubtype}}-{{i}}"
                         [value]="testSubtype" formControlName="testSubtype">
                  <label for="{{testSubtype}}-{{i}}" class="form-check-label">{{i + 1}}. {{typeUtils.typeLabel(testSubtype, testDefForm.controls.testType.value)}}</label>
                </div>
              </div>
              <app-validation-msg [control]="testDefForm.get('testSubtype')"></app-validation-msg>
          </div>
        </div>
      </div>
    </div>

    <!-- Search for Course -->
    <div class="ml-4">
      <div class="form-group ml-5">
        <div class="row">
          <div class="col-3">
            <label class="form-label" for="filterInput1">Search for Course</label>
            <div id="filterInput1">
              <app-http-course-search [course]="testDefForm.controls.course"
                                      (courseSelected)="onCourseSelected($event)">
              </app-http-course-search>
            </div>
          </div>
        </div>
      </div>
    </div>

    <h2>{{this.courseInfo}}</h2>
    <h3>{{this.primaryOrgFac}}</h3>

    <div class="mt-2" formGroupName="assessmentScheduleDetails">
      <h5>Assessment Schedule Details</h5>

      <!-- Assessment Date -->
      <div class="form-group mt-3">
        <div class="row">
          <div class="col-3">
            <label class="form-label" for="assessmentDateId">Assessment Date</label>
            <app-rf-datepicker id="assessmentDateId" formControlName="assessmentDate"></app-rf-datepicker>
          </div>
          <app-validation-message [control]="testDefForm.get('assessmentScheduleDetails.assessmentDate')"></app-validation-message>
        </div>
      </div>

      <!-- Assessment Time -->
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="assessmentTimeId">Assessment Time</label>
            <app-rf-timepicker id="assessmentTimeId" formControlName="assessmentTime"></app-rf-timepicker>
          </div>
        </div>
      </div>

      <!-- Assessment Writing Time Limit -->
      <div class="form-group">
        <div class="row">
          <div class="col-12">
            <label class="form-label" for="assessmentDurationId">Assessment Writing Time Limit</label>
            <app-rf-durationpicker id="assessmentDurationId" formControlName="assessmentDuration"></app-rf-durationpicker>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-4" formGroupName="assessmentLocationDetails">
      <h5>Assessment Location Details</h5>

      <!-- Select Assessment Location -->
      <div class="form-group">
        <div class="row">
          <div class="col">
            <div class="font-weight-bold">Select Assessment Location</div>
            <!-- On Campus (In-Person) -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="onCampusInd"
                     [value]="true" formControlName="onCampusInd">
              <label for="onCampusInd" class="form-check-label">{{typeUtils.ON_CAMPUS}}</label>
            </div>
            <!-- Off Campus (Remote) -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="offCampusInd"
                     [value]="false" formControlName="onCampusInd">
              <label for="offCampusInd" class="form-check-label">{{typeUtils.OFF_CAMPUS}}</label>
            </div>
            <!-- Unknown -->
            <div class="form-check">
              <input class="form-check-input" type="radio" id="unknownInd"
                     [value]="null" formControlName="onCampusInd">
              <label for="unknownInd" class="form-check-label">{{typeUtils.UNKNOWN}}</label>
            </div>
          </div>
        </div>
      </div>

      <!-- Enter Class Location (Optional) -->
      <div class="form-group">
        <div class="row">
          <div class="col-3">
            <div class="font-weight-bold">Enter Class Location (Optional)</div>
            <input class="form-control" type="text" id="classLocation"
                   formControlName="classLocation">
          </div>
        </div>
      </div>

    </div>

    <div class="form-group mt-5">
      <button class="btn btn-primary" [ngStyle]="{'background-color': testDefinitionBeingCommitted || !testDefForm.valid ? 'grey' : ''}"
              (click)="createDefinition()" [disabled]="testDefinitionBeingCommitted || !testDefForm.valid">
        Create Definition
      </button>
    </div>

  </div>
</div>
