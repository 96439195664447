
<div >
  <!-- Masthead -->
  <div class="page-masthead row page-head">
    <!-- Masthead Header -->
    <div class="page-header col-7 ">
      <div>
       <!-- <app-breadcrumb [booking]="booking"></app-breadcrumb>-->
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>
  <div>
    <div class="masthead-header">
      <h2 class="font-weight-bold"> {{getCourseDesc()}} {{testDefinition.testType}}
        {{testDefinition.classStartDate ? ( "(" + (testDefinition.classStartDate | date: 'yyyy-MM-dd')): '' }}
        {{testDefinition.classEndDate ? ( " to " + (testDefinition.classEndDate | date: 'yyyy-MM-dd')) + ")" : ')'}}
      </h2>
      <h5 class="font-weight-bold"> {{getCourseDesc()}}</h5>
      <div><strong>Status:</strong> {{testDefinition.status}}</div>
      <div *ngIf="testDetails"><strong>Online Platform:</strong> {{testDetails.onlineAssessmentTypeId ? testDetails.onlineAssessmentTypeId : ""}}
        <span *ngIf="testDetails.quercusPermissionToAtsInd === true">{{querrusConsentMessage}}</span>
        <span *ngIf="testDetails.quercusPermissionToAtsInd === false">{{querrusNotConsentMessage}}</span>
      </div>
    </div>
  </div>

  <!-- Page Content -->
  <div class="page-content">
    <!-- Schedule and Course Contacts cards -->
    <div class="row">
      <div class="col-6">
        <app-schedule-card [classWriting]="classWriting">
        </app-schedule-card>
      </div>
      <div class="col-6">
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Schedule for Students Writing with ATS</h3>
            <div class="row">
              <div class="col-12 card-text">
                  <div class="col"><strong>Number Of Students</strong></div>
              </div>
            </div>
            <div class="row">
              <div class="col-12 card-text">
                <div class="col"><strong>Schedule</strong></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-12">
        <!--<app-course-contacts-card [instructors]="booking.courseSectionInstructors"
                                  [department]="booking.department">
        </app-course-contacts-card>-->
        <div class="card">
          <div class="card-body">
            <h3 class="card-title">Course Contact</h3>
            <div class="row">
              <div class="col-3 card-text">
                <div class="col"><strong>Instructor</strong></div>
              </div>
              <div class="col-3 card-text">
                <div class="col"><strong>Accommodations Admin</strong></div>
              </div>
              <div class="col-3 card-text">
                <div class="col"><strong>Assessment Contact</strong></div>
              </div>
              <div class="col-3 card-text">
                <div class="col"><strong>Course Coordinator</strong></div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!--  tabs -->
    <div class="row mt-3">
      <div class="col-12">
        <div class="nav-booking">
          <ul ngbNav #nav="ngbNav" class="nav-tabs" [destroyOnHide]="true">
            <!-- Tab: Test details -->
            <li [ngbNavItem]="1" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Test Details</a>
              <ng-template ngbNavContent>
                <app-test-details-tab [testDetails]="testDetails" [NotShowBookingInfo]="true"></app-test-details-tab>
              </ng-template>
            </li>
            <!-- Tab: Activity Log & Staff Notes -->
            <li [ngbNavItem]="2" [destroyOnHide]="removeTabOnHide">
              <a ngbNavLink>Activity Log & Staff Notes</a>
              <ng-template ngbNavContent>
                <app-activity-log-and-notes-tab [activityLogType]="activityLogType"></app-activity-log-and-notes-tab>
              </ng-template>
            </li>
          </ul>
          <div [ngbNavOutlet]="nav"></div>
        </div>
      </div>
    </div>
  </div>
</div>


