import { Component, OnInit } from '@angular/core';
import {ToastService} from "@easi-sis/core";


@Component({
  // tslint:disable-next-line:component-selector
  selector: 'design-design-module-space',
  templateUrl: './design-module-space.component.html',
  styleUrls: ['./design-module-space.component.scss']
})
export class DesignModuleSpaceComponent implements OnInit {

  constructor(private toastService: ToastService) {
  }

  ngOnInit(): void {
  }

  showSuccess() {
    this.toastService.show({type: 'success', action: 'You successfully saved changes to this booking'});
  }

}
