import {Component, Input} from '@angular/core';
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {EmailContactRec} from "../email-contact-rec.intf";
import {FormGroup} from "@angular/forms";

@Component({
  selector: 'app-send-email-table',
  templateUrl: './send-email-table.component.html',
  styleUrls: ['./send-email-table.component.scss']
})
export class SendEmailTableComponent {

  @Input()
  sendEmailFormGroup: FormGroup;

  @Input()
  emailContactRec: EmailContactRec[];

  protected readonly Utils = InstructorEmailingUtils;
}
