
<!-- Template for StudentSearchComponent. (Search for Student typeahead) -->
<div class="student-search">
  <div>
    <input type="text" class="form-control" id="filterInput" #studentNameAndNumber [formControl]="student"
           [ngbTypeahead]="searchForStudent" [resultFormatter]="studentFormatter" [inputFormatter]="studentFormatter"
           (selectItem)="onSelectedStudent($event)"
           (focus)="focus$.next($any($event).target.value)"
           (click)="click$.next($any($event).target.value)"
           #instance = "ngbTypeahead"
           placeholder="Filter">
  </div>
  <!-- Display a spin while waiting for the student async call or a message if there are no results returned -->
  <div *ngIf="searching">
    <i class="fa fa-spinner fa-spin fa-x fa-fw" aria-hidden="true"></i>
    <span class="sr-only">Searching</span>
  </div>
  <div *ngIf="searchFailed">
    <span class="sr-only">Sorry, suggestions could not be loaded.</span>
  </div>
</div>
