
<div>
  <div class="form-group mb-4">
    <button type="button" class="btn btn-outline-secondary"
            (click)="deleteTestDef()"
            [ngClass]="{'disabled': numberOfBookings}"
            [disabled]="numberOfBookings">
      <span *ngIf="numberOfBookings"
            ngbTooltip="In order to delete this test definition, please ensure that there are no bookings assigned."
            placement="auto">Delete Test Definition</span>
      <span *ngIf="!numberOfBookings">Delete Test Definition</span>
    </button>
  </div>

  <div *ngIf="deleteTestDefResult === CLOCKWORK_CALL_ERROR" class="form-group mb-4">
    <sis-notice type="warning"
                introductoryText="Something went wrong.">
      The delete action failed to export to Clockwork, and the test definition could not be deleted.
      Please try again.
    </sis-notice>
  </div>

  <!--  Test definition cannot be deleted in clockwork as it has bookings -->
  <div *ngIf="deleteTestDefResult === TEST_DEF_HAS_BOOKING_ERROR" class="form-group mb-4">
    <sis-notice type="warning"
                introductoryText="Something went wrong.">
      The delete action failed because there are bookings attached to the test definition in Clockwork.
      If you want to delete this test definition, please delete the bookings from the test definition, and try again.
    </sis-notice>
  </div>

  <!--  Test definition cannot be deleted in clockwork as it cannot be found -->
  <div *ngIf="deleteTestDefResult === TEST_DEF_NOT_FOUND_IN_CLOCKWORK_ERR" class="form-group mb-4">
    <sis-notice type="warning"
                introductoryText="Something went wrong.">
      The delete action failed because the test definition could not be found in Clockwork. This could be due to it
      being manually deleted in Clockwork. Please contact the technical staff for assistance.
    </sis-notice>
  </div>

  <!--  Test definition cannot be deleted in clockwork as related info cannot be deleted -->
  <div *ngIf="deleteTestDefResult === TEST_DEF_NOT_CANNOT_BE_DELETED_ERR" class="form-group mb-4">
    <sis-notice type="warning"
                introductoryText="Something went wrong.">
      The delete action failed in Clockwork. Please contact the technical staff for assistance.
    </sis-notice>
  </div>

</div>
