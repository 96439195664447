import {Injectable} from '@angular/core';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {Observable} from 'rxjs';
import {ModelService} from "../../model/model.service";
import {TestType} from "../../model/types/testType";

@Injectable({
  providedIn: 'root'
})
export class AssessmentTypesResolver  {

  constructor(private modelService: ModelService) {}
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<TestType[]> {
    return this.modelService.getTestTypes();
  }
}
