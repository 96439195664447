import {Designate} from "./designate";
import {Org} from "./org";
import {Course} from "./course";

export class DesignateAssignment {

  /* Assignment level: C-Course or D-Department */
  assignmentLevel: string;

  /* Course to assign designate(s) to */
  course: Course;

  coursePrefix: string;

  /* The organization (faculty/department) to assign designate(s) to */
  org: Org;

  /* The designates assigned to course section(s) or a department */
  designates: Designate[];
}
