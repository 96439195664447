import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {UntypedFormGroup} from "@angular/forms";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {merge, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap} from "rxjs/operators";
import {Course} from "../../model/types/course";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

@Component({
  selector: 'app-async-course-search',
  templateUrl: './async-course-search.component.html',
  styleUrls: ['./async-course-search.component.scss']
})
export class AsyncCourseSearchComponent implements OnInit {

  // This FormGroup has the 'course' FormControl used in this component
  @Input()
  parentForm: UntypedFormGroup;

  @Input()
  validationMessage: string;

  @ViewChild('instance', {static: true})
  instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  searching = false;
  searchFailed = false;

  constructor(private modelService: ModelService, private logger: LogService) {}

  ngOnInit(): void {
  }

  searchForCourse = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    // - show the popup when clicking in the input box
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    // Note: we don't want to merge focus$ as there will be too many calls to the backend
    return merge(debouncedText$, clicksWithClosedPopup$).pipe(
      filter(x => x.length > 3),
      tap(() => this.searching = true),
      switchMap(term => this.modelService.getRosiCourses(term.toUpperCase())
        .pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        )
      ),
      tap(() => this.searching = false)
    );
  }

  /**
   * A function that converts an item from the result list to a string.
   * In the case of [inputFormatter] typeahead directive the string is displayed in the <input> field.
   * In the case og [resultFormatter] typeahead directive the string is displayed in the popup.
   *
   * @param course  The Course object to be converted to a string.
   */
  courseFormatter = (course: Course) => {
    if (!course || !course.code || !course.sectionCode) {
      return '';
    }
    return !course ? '' : course.code.trim() + ' ' + course.sectionCode.trim();
  }

  /**
   * Event handler - triggered when a course is selected from the typeahead suggestions.
   * @param item  An object containing the selected Course object (selectedCourse: Course = item.item)
   */
  onSelectedCourse(item: any) {}

}
