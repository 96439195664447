<div class="card card-body" >
  <h2 class="mt-4" >Student Information</h2>
  <div class="row mt-4">
    <div class="col-12 card-text">
      <div class="row">
        <div class="col"><strong>UTORid</strong></div>
        <div class="col"><strong>Home Phone</strong><span *ngIf="homePhone && homePhone.preferred">(Preferred)</span></div>
        <div class="col"><strong>Work Phone</strong><span *ngIf="workPhone && workPhone.preferred">(Preferred)</span></div>
        <div class="col"><strong>Mailing Address</strong></div>
      </div>
      <div class="row">
        <div class="col">{{studentInfo.utorId}}</div>
        <div class="col"><span *ngIf="homePhone" [innerHTML]="getContent(homePhone)"></span></div>
        <div class="col"><span *ngIf="workPhone" [innerHTML]="getContent(workPhone)"></span></div>
        <div class="col">{{mailingAddress}}
        </div>
      </div>
      <div class="row mt-2">
        <div class="col"><strong>Email</strong></div>
        <div class="col"><strong>Mobile Phone</strong><span *ngIf="mobilePhone && mobilePhone.preferred">(Preferred)</span></div>
        <div class="col"><strong>Other Phone</strong><span *ngIf="otherPhone && otherPhone.preferred">(Preferred)</span></div>
        <div class="col"><strong>Permanent Address</strong></div>
      </div>
      <div class="row">
        <div class="col"><a href="mailto:{{studentInfo.email}}">{{studentInfo.email}}</a></div>
        <div class="col"><span *ngIf="mobilePhone" [innerHTML]="getContent(mobilePhone)"></span></div>
        <div class="col"><span *ngIf="otherPhone" [innerHTML]="getContent(otherPhone)"></span></div>
        <div class="col">{{permanentAddress}}</div>
      </div>
    </div>
  </div>
</div>

