import { Component, OnInit } from '@angular/core';
import {SpinnerService} from '../../services/spinner.service';

/**
 * Component: SpinnerComponent
 * Desc:
 *    This component is basically a spinner (see the template).
 *    The component template, and thus the spinner, is shown during the in-progress
 *    HttpClient calls. Once there are no more calls in-progress the component is removed.
 *    It uses the SpinnerService 'showSpinner' property to decide when the component should be shown.
 * Note:
 *    This is done automatically. The asynchronous caller do not have to do anything.
 */
@Component({
  selector: 'app-spinner',
  templateUrl: './spinner.component.html',
  styleUrls: ['./spinner.component.scss']
})
export class SpinnerComponent implements OnInit {

  constructor(public spinnerService: SpinnerService) {}

  ngOnInit(): void {
  }
}
