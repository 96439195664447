<!-- Masthead -->
<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7">
    <div class="masthead-header">
      <h1>Test Definitions Dashboard</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>
<div class="row margin-top-spacer-4">
  <div class="col-2">
    <button id="createTestDefinition" class="btn btn-primary"
            (click)="createTestDefinition()">Create Test Definition</button>
  </div>
  <div class="margin-left-2rem col-2">
    <button id="uploadTestDefinition" class="btn btn-primary btn-block font-size-16px height-4rem" (click)="uploadTestDefinition()">Upload Test Definition</button>
  </div>
</div>

<div class="margin-top-spacer-4">
  <app-period-selector [selectDateLabel]="'Select Class Writing Start Date(s)'" [studentIdNeeded]="false" [storedSelector]="storedData.periodCourseSelector"
                       (outer)="getTestDefinitionsByPeriod($event)"></app-period-selector>
</div>

<div  *ngIf="testDefinitionsAmountExceedLimit">
  <div class="margin-top-40-px">
    <h2>Test Definitions (0)</h2>
  </div>
  <div class="margin-top-40-px">
    <p class="font-weight-bold">
      It looks like there were too many results. Please try the following:
    </p>
    <div>
      <ul>
        <li>Narrow down your results by reducing the date range selected.</li>
      </ul>
    </div>
  </div>
</div>

<!--can't use *ngIf below as the sorting will not work-->
<div [hidden]="testDefinitionsAmountExceedLimit">
  <div class="margin-top-40-px">
    <h2>Test Definitions ({{testDefinitionsAmount}})</h2>
  </div>

  <div class="row align-items-top align-items-center">
    <div class="col-4 text-filter pl-0">
      <label class="form-label" for="textInput">Filter by One or More Keyword(s)</label>
      <div class="input-group mr-2">
        <input type="text" id="textInput"  class="form-control" [value]="textFilter"
               autocomplete="off" (keyup)="applyTextFilter($event)" placeholder="E.g: PSY312 scribe">
        <app-columns-to-search #columnsToSearch [activeColumns]="displayedColumns" (changeColumns)="applyFilter()"></app-columns-to-search>
      </div>
    </div>
    <div class="col-2 reset-filter">
      <button type="button" class="btn btn-outline-secondary btn-block reset-button font-size-16px height-4rem" (click)="reset()">Reset Filters</button>
    </div>
  </div>

  <mat-table  matTableExporter [dataSource]="testDefinitionsDataSource" matSort
              cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="tableDrop($event)"
              class="table table-container table-size mt-4 mat-elevation-z8">
    <!--<ng-container matColumnDef="status" sticky>
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Status</th>
      <td class="mat-column-position" mat-cell *matCellDef="let element">
        <a routerLink="/test-definition/{{element.testDefinitionId}}">{{element.status}}</a>
      </td>
    </ng-container>-->

    <ng-container matColumnDef="courseSection">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Course, Section</th>
      <td class="mat-column-position" *matCellDef="let element">{{getCourseSection(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="type">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Type</th>
      <td class="mat-column-position" *matCellDef="let element">{{getType(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="examId">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Exam Id</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.examId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="testDefinitionId">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">ATS Test Definition ID</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.testDefinitionId}}
      </td>
    </ng-container>

    <ng-container matColumnDef="startDate">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class Start Date</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classStartDate}}</td>
    </ng-container>

    <ng-container matColumnDef="startTime">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class Start Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classStartTime | convertFrom24To12Format}}</td>
    </ng-container>

    <!--<ng-container matColumnDef="endDate">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Class End Date</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classEndDate}}</td>
    </ng-container>-->

    <ng-container matColumnDef="endTime">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class End Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classEndTime | convertFrom24To12Format}}</td>
    </ng-container>

    <ng-container matColumnDef="timingType">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Timing Type</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.assessmentTimingIndicator}}</td>
    </ng-container>

    <ng-container matColumnDef="assessmentLocation">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Assessment Location</th>
      <td class="mat-column-position" *matCellDef="let element">{{getAssessmentLocation(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="numberOfBookings">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Number of Bookings</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.numberOfApprovedBookings}}</td>
    </ng-container>

    <ng-container matColumnDef="divisionUnit">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Division-Unit</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.division}} - {{element.unit}}</td>
    </ng-container>

<!--    <ng-container matColumnDef="unit">-->
<!--      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header-->
<!--          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Unit</th>-->
<!--      <td class="mat-column-position" *matCellDef="let element">{{element.unit}}</td>-->
<!--    </ng-container>-->

    <ng-container matColumnDef="classLocation">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class Location</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classLocation}}</td>
    </ng-container>

    <ng-container matColumnDef="source">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Source</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.testDefSource}}</td>
    </ng-container>

    <ng-container matColumnDef="view" [sticky]="true" [stickyEnd]="true">
      <th class="mat-column-position"  mat-header-cell *matHeaderCellDef></th>
      <td class="mat-column-position"  *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-primary" (click)="viewTestDefinition(element.testDefinitionId)">
            View
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="edit" [sticky]="true" [stickyEnd]="true">
      <th class="mat-column-position"  mat-header-cell *matHeaderCellDef></th>
      <td class="mat-column-position"  *matCellDef="let element">
        <div>
          <button class="btn btn-sm btn-primary" (click)="editTestDefinition(element.testDefinitionId)">
            Edit
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="testDefinitionsColumns; sticky: true " ></tr>
    <tr mat-row *matRowDef="let row; columns: testDefinitionsColumns"></tr>

  </mat-table>
  <div class="margin-top-80-px">
    <mat-paginator
      [pageSize]="25"
      [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons>
    </mat-paginator>
  </div>
</div>

