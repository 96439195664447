import {Course} from "./course";
import {Org} from "./org";
import {Designate} from "./designate";

export class SingleDesignateAssignment {


  constructor(public assignmentLevel: string, public course: Course, public coursePrefix: string,
              public org: Org, public designate: Designate) {
  }

  getRole(): string {
    let result = '';

    if (this.designate.emailPreference === 'A'){
      result = "All Communication";
    }
    if (this.designate.emailPreference === 'L'){
      result = "Late Communication";
    }
    if (this.designate.daRoleInd){
      result = result + " " + "Accommodation Admin Role";
    }
    if (!this.designate.daRoleInd){
      result = result + " " + "Instructor Role";
    }
    result = result.trim();

    return result;
  }
}
