import { Injectable } from '@angular/core';
import {NgbDateAdapter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {LogService} from './log-service.service';
import {DatepickerParserFormatterService} from "./datepicker-parser-formatter.service";

/**
 * Service: DatepickerAdapterService.
 * Desc:
 *   This service is used by the [DatepickerComponent] for converting
 *   the data model to and from the format required by the ngbDatepicker.
 */
@Injectable({
  providedIn: 'root'
})
export class DatepickerAdapterService implements NgbDateAdapter<string> {

  constructor(private dateUtil: DatepickerParserFormatterService, private logger: LogService) { }

  // from your model -> internal model
  fromModel(value: string): NgbDateStruct {
    if (!value) {
      return null;
    }

    // this.logger.debug(`=> fromModel: date: ${value}`, `${this.constructor.name}`);
    // convert date string ('YYYY-mm-dd') to NgbDateStruct ({year: [year], month: [month], day: [day-of-month]}
    return this.dateUtil.parse(value);
  }

  // from internal model -> your model
  toModel(date: NgbDateStruct): string {
    if (!date) {
      return  null;
    }

    // this.logger.debug(`=> toModel: date: ${JSON.stringify(date)}`, `${this.constructor.name}`);
    // convert date NgbDateStruct ({year: [year], month: [month], day: [day-of-month]} to string of format: 'YYYY-mm-dd'
    return this.dateUtil.format(date);
  }
}
