
export class StudentSearch {
  public rosiStudentId: number;
  public studentName: string;
  public sessionCodes: string[];

  public currentSessionInd: boolean;

  constructor() {
  }
}
