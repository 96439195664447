<div class="row  align-items-end mb-3 org-designate-table">

  <!-- Filter by Level, Division, Unit, Designates -->
  <div class="form group col-4">
    <label class="form-label" for="textInput">Filter by Level, Division, Unit, Designates</label>
    <input type="text" id="textInput" class="form-control" placeholder='E.g: "Medicine" "Jane Doe"'
           autocomplete="off" (keyup)="applyTextFilter($event)">
  </div>

  <!-- Filter by Valid Status drop-down -->
  <div class="form group col-4">
    <label class="form-label" for="designateStatus">Filter by Valid Status</label>
    <select id="designateStatus" class="form-select"
            [formControl]="statusFilter"
            (change)="filterByDesignateStatus()">
      <option *ngFor="let status of designateStatuses" [ngValue]="status.value">{{status.label}}</option>
    </select>
  </div>

</div>

<div class="org-designate-table">
  <mat-table class="table table-container" [dataSource]="departmentDataSource" matSort matTableExporter>

    <ng-container matColumnDef="Level">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Level</th>
      <td class="mat-column-position" *matCellDef="let element">
        {{element.org.graduateInd === true ? 'Graduate' : 'Undergraduate'}}
      </td>
    </ng-container>

    <ng-container matColumnDef="Division">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Division</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.org.facultyCode}}</td>
    </ng-container>

    <ng-container matColumnDef="Department">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Unit</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.org.departmentCode}}</td>
    </ng-container>

    <ng-container matColumnDef="Prefix">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Prefix</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.coursePrefix}}</td>
    </ng-container>

    <ng-container matColumnDef="Designate">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>{{nameUTORidColumnName}}</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.designate.firstName}} {{element.designate.lastName}} ({{element.designate.utorId}})
      </td>
    </ng-container>

    <ng-container matColumnDef="Permissions">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Permissions</th>
      <td class="mat-column-position" *matCellDef="let element">
        <!-- Email preference: All Communications or Late Communication -->
        <div style="border-bottom:  1px solid lightgrey" [ngSwitch]="element.designate.emailPreference">
          <div *ngSwitchCase="'A'"><i class="fas fa-inbox"></i> All Communication</div>
          <div *ngSwitchCase="'L'"><i class="fas fa-envelope"></i> Late Communication</div>
        </div>
        <!-- Role: DA or Instructor -->
        <div [ngSwitch]="element.designate.daRoleInd">
          <div *ngSwitchCase="true"><i class="fas fa-book"></i> Accommodation Admin Role</div>
          <div *ngSwitchCase="false"><i class="fas fa-edit"></i> Instructor Role</div>
        </div> <br/>
      </td>
    </ng-container>

    <ng-container matColumnDef="Valid Until">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef>Valid Until</th>
      <td class="mat-column-position" *matCellDef="let element">
        <div [ngSwitch]="getDesignExpiryDateLabel(element.designate.endDate)">
          <div *ngSwitchCase="'Indefinite'">Indefinite</div>
          <div *ngSwitchCase="'Expired'"><span class="expired-date">{{element.designate.endDate}} <br/>(Expired)</span></div>
          <div *ngSwitchDefault>{{element.designate.endDate}}</div>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Edit" >
      <th class="mat-column-position" [hidden]="!isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>Edit</th>
      <td class="mat-column-position" [hidden]="!isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-primary"
                  (click)="editOrViewOrgDesig(element.org, element.coursePrefix, element.designate)">
            Edit
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="Remove" >
      <th class="mat-column-position" [hidden]="!isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>Remove</th>
      <td class="mat-column-position" [hidden]="!isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-link"
                  (click)="removeOrgDesig(element.org, element.coursePrefix, element.designate)">
            Remove
          </button>
        </div>
      </td>
    </ng-container>

    <ng-container matColumnDef="View"  >
      <th class="mat-column-position" [hidden]="isUserDesginatesAdmin" mat-header-cell *matHeaderCellDef>View</th>
      <td class="mat-column-position" [hidden]="isUserDesginatesAdmin" *matCellDef="let element">
        <div class="ut-margin-top-20px">
        <button class="btn btn-sm btn-primary"
                (click)="editOrViewOrgDesig(element.org, element.coursePrefix, element.designate)">
          View
        </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="departmentColumns"></tr>
    <tr mat-row *matRowDef="let row; columns: departmentColumns">{{departmentColumns}}</tr>

  </mat-table>

  <mat-paginator [pageSizeOptions]="[10, 25, 50]" showFirstLastButtons></mat-paginator>
</div>
