import {ColumnName} from "./columnName";


export class Column {
  name: ColumnName;

  isInclude = true;

  constructor(name: ColumnName) {
    this.name = name;
  }

  excludeFromSearch(): void {
    this.isInclude = false;
  }

  includeInSearch(): void {
    this.isInclude = true;
  }
}
