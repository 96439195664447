
<!-- Template for CourseSearchComponent. (Search for Course typeahead) -->
<div>
  <input type="text" id="filterInput1" class="form-control" [formControl]="course" [ngClass]="{highlight: !course.valid}"
         [ngbTypeahead]="searchForCourse" [resultFormatter]="courseSectionFormatter" [inputFormatter]="courseSectionFormatter"
         (selectItem)="onSelectedCourse($event)"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         #instance="ngbTypeahead"
         placeholder="Filter">
</div>
<app-validation-msg [control]="course"></app-validation-msg>
