
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{modalContent.title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{modalContent.body}}
</div>
<div class="modal-footer" *ngIf="modalContent.buttons.length">
  <!-- Buttons -->
  <div>
    <span *ngFor="let button of modalContent.buttons">
      <button type="button" class="btn btn-outline-dark" (click)="activeModal.close(button.value)">
        {{button.label}}
      </button>&nbsp;
    </span>
  </div>
</div>


