<div class="page-masthead row page-head">

  <div class="page-header col-7">
    <div>
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>

<!-- Masthead Header -->
<div class="page-header">
  <div class="masthead-header">
    <h1>Manual Booking Creation</h1>
    <p class="mt-4">Note: All fields are required unless otherwise specified</p>
  </div>
</div>

<div [formGroup]="manualBookingForm" (ngSubmit)="onSubmit()">

  <!-- Student search -->
  <div class="mb-4">
    <label class="form-label" for="filterInput">Search for Student</label>
    <div id="filterInput">
      <app-student-search [student]="manualBookingForm.controls.student"
                          (studentSelected)="onStudentSelected($event)">
      </app-student-search>
    </div>
  </div>

  <!-- Select Session (current or not) -->
  <div class="mb-4" *ngIf="manualBookingForm.controls.student.valid">
    <div [formGroup]="basicTestDefinition">
      <label class="form-label">Select Session</label>
      <div class="currentSession">
        <div>
          <input type="radio" formControlName="currentSessionInd" id="currentSessionInd-Y"
                 [value]="true" (change)="retrieveCourses()">
          <label for="currentSessionInd-Y" class="input-label">Current</label>
        </div>
        <div>
          <input type="radio" formControlName="currentSessionInd" id="currentSessionInd-N"
                 [value]="false" (change)="retrieveCourses()">
          <label class="form-label" for="currentSessionInd-N" class="input-label">Other</label>
        </div>
      </div>
    </div>
  </div>

  <!-- Select Test Type ('Test' or 'Final Exam') -->
  <div class="mb-4" *ngIf="manualBookingForm.controls.student.valid">
    <div [formGroup]="basicTestDefinition">
      <label class="form-label">Select Test Type</label>
      <div class="typeOfTest">
        <div *ngFor="let type of types; index as i">
          <input type="radio" formControlName="testType" id="{{type}}-{{i}}" [value]="type"
                 (change)="findSubtypes(type)">
          <label for="{{type}}-{{i}}" class="input-label">
            {{type === 'Test' ? 'Term Assessment' : (type === 'Final Exam' ? 'Final Exam' : 'NA')}}
          </label>
        </div>
      </div>
    </div>
  </div>

  <!-- Select Type of Request -->
  <div class="mb-4" *ngIf="manualBookingForm.controls.student.valid && basicTestDefinition.controls.testType.value">
    <label class="form-label">Select Type of Request</label>
    <div class="typeOfRequest" [formGroup]="basicTestDefinition">
      <div *ngFor="let testSubtype of subtypes; index as i">
        <input type="radio" formControlName="testSubtype" id="{{testSubtype}}-{{i}}" [value]="testSubtype">
        <label for="{{testSubtype}}-{{i}}" class="input-label">{{i + 1}}. {{getSubtypeLabel(testSubtype)}}</label>
      </div>
    </div>
  </div>

  <!-- Select Course -->
  <div class="mb-5" *ngIf="manualBookingForm.controls.student.valid && (basicTestDefinition.controls.testSubtype.value
                                                            || basicTestDefinition.controls.testSubtype.value === '')">
    <label class="form-label" for="filterInput1">Search for Course for the Booking</label>
    <div id="filterInput1">
      <app-course-search [courses]="courses"
                         [selectedCourse]="manualBookingForm.controls.course"
                         (courseSelected)="onCourseSelected($event)">
      </app-course-search>
    </div>
  </div>

  <div class="" *ngIf="manualBookingForm.controls.course.valid && manualBookingForm.controls.student.valid">
    <h2>{{courseInfo}}</h2>
    <h6 class="mt-2"><span class="font-weight-bold">Instructor: </span>{{instructorsFullNames}}</h6>
    <p class="mt-4 mb-4 form-label">Select from existing test definitions
      <span> or create a new one</span></p>
      <label class="form-label" for="SelectTest">Test Definitions</label>
      <div class="row">
        <div class="col-2">
          <select class="form-select" id="SelectTest"
                  [formControl]="testDefinitionId"
                  (change)="onSelectedExistingTestDefinition()">
            <option [defaultSelected]="true" value="">Select Option</option>
            <option *ngFor="let test of testDefinitionsFilteredByTestSubType" [value]="test.testDefinitionId">
              {{test.clockworkExamId}} {{test.assessmentType}} {{test.testDate}} {{test.testStartTime}} {{test.duration}}min {{test.workflowStatus}}
            </option>
          </select>
        </div>
        <div class="col-1 form-inline ml-1">OR</div>

        <div class="col-2">
          <button class="btn btn-link new-test-definition" (click)="onCreateNewTestDefinition()">Create New Test Definition</button>
        </div>
      </div>

    <div class="mt-4" [formGroup]="basicTestDefinition" [ngbCollapse]="!newTestDefinition">
      <div>
        <h3>Fill out the Test Definition Detail</h3>
      </div>

      <div class="row">
        <div class="col-2 mt-4">
          <label class="form-label" for="SendToCisDatePicker">Assessment Date</label>
          <app-rf-datepicker id="SendToCisDatePicker" formControlName="requestedAssessmentDate"></app-rf-datepicker>
        </div>
        <app-validation-message [control]="basicTestDefinition.get('requestedAssessmentDate')"></app-validation-message>

      </div>

      <div class="row">
        <div class="col-12 mt-4">
          <label class="form-label" for="assessmentTime">Assessment Time</label>
          <app-rf-timepicker id="assessmentTime" formControlName="requestedAssessmentStartTime"></app-rf-timepicker>
        </div>
      </div>

      <div class="row">
        <div class="col-12 mt-4">
          <label class="form-label" for="assessmentDuration">Assessment Writing Time Limit</label>
          <app-rf-durationpicker id="assessmentDuration" formControlName="requestedAssessmentDuration"></app-rf-durationpicker>
        </div>
      </div>

    </div>

    <!-- Assessment Location -->
    <div class="mt-4 mb-4" *ngIf="testDefinitionId.valid || basicTestDefinition.valid">
      <div class="mb-4">
        <h3>Fill out the Assessment Location Details</h3>
      </div>
      <div class="mb-4">
        <label class="form-label">Select Assessment Location</label>
        <div class="assessmentLocation">
          <div>
            <input type="radio" formControlName="assessmentOnCampus" name="assessmentOnCampus" id="assessment-on-campus"
                   [value]="true">
            <label for="assessment-on-campus" class="input-label">{{typeUtils.ON_CAMPUS}}</label>
          </div>
          <div>
            <input type="radio" formControlName="assessmentOnCampus" name="assessmentOnCampus" id="assessment-off-campus"
                   [value]="false">
            <label for="assessment-off-campus" class="input-label">{{typeUtils.OFF_CAMPUS}}</label>
          </div>
        </div>
      </div>

    </div>

    <div class="mt-5" *ngIf="this.manualBookingForm.controls.assessmentOnCampus.valid" [formGroup]="basicTestDefinition">
      <app-accom #accommoComponent [expiryDate]="expireDate" [nonOptionalAccommodations]="nonOptionalAccommodations"
                          [optionalAccommodations]="optionalAccommodations"></app-accom>
    </div>

  </div>
  <div class="ml-2 mt-5">
    <button class="btn btn-primary" [ngStyle]="{'background-color': isSubmitBookingButtonDisable() ? 'grey' : ''}"
            [disabled]="isSubmitBookingButtonDisable()" (click)="onSubmit()">Create Booking</button>
  </div>
</div>


