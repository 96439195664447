import {Observable} from "rxjs";
import {PhonebookSearch} from "../../model/types/phonebook/PhonebookSearch";
import {InjectionToken} from "@angular/core";

export const PHONEBOOK_API = new InjectionToken<PhonebookApi>("phonebookAPI");



export interface PhonebookApi {
  getPhonebookSearchByEmail(email: string): Observable<PhonebookSearch>;

  getPhonebookDataByDepartmentId(departmentId: string): Observable<any>;

}
