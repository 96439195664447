import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest} from '@angular/common/http';
import {Observable} from 'rxjs';
import {tap} from 'rxjs/operators';
import {LogService} from '../services/log-service.service';
import {ToastrService} from 'ngx-toastr';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";

/**
 * Hypertext Transfer Protocol (HTTP) response status codes.
 * @see {@link https://en.wikipedia.org/wiki/List_of_HTTP_status_codes}
 */
// enum HttpStatusCode {
//   /**
//    * Standard response for successful HTTP requests.
//    * The actual response will depend on the request method used.
//    * In a GET request, the response will contain an entity corresponding to the requested resource.
//    * In a POST request, the response will contain an entity describing or containing the result of the action.
//    */
//   OK = 200,
//
//   /**
//    * The server successfully processed the request and is not returning any content.
//    */
//   NO_CONTENT = 204,
//
//   /**
//    * The input is not valid or duplicated, or break constrains
//    */
//   NOT_ACCEPTABLE = 406,
//
//   /**
//    * Indicates that the request could not be processed because of conflict in the request,
//    * such as an edit conflict between multiple simultaneous updates.
//    */
//   CONFLICT = 409
// }

/**
 * Application error codes as defined in the back-end.
 */
// enum AppErrorCode {
//   OPTIMISTIC_LOCKING_EXCEPTION = 'ATS.1010',
//   TEST_DEFINITION_EXIST_WHEN_CHANGING_TYPE = 'ATS.1008'
// }

enum TitleTag {
  START = "<title>",
  END = "</title>"
}

/**
 * Interceptor: ErrorHandlerInterceptor
 * Desc:
 *    Intercepts the backend async HTTP calls and display any errors on a toaster.
 *
 */

@Injectable()
export class ErrorHandlerInterceptor implements HttpInterceptor {

  constructor(private toastrService: ToastrService,
              private modalService: NgbModal, private logger: LogService) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(request).pipe(tap(event => {

    }, error => {
      let msg: string;
      if (error instanceof HttpErrorResponse) {
        if (error.error instanceof ErrorEvent) {
          // - client-side error: ErrorEvent (client failed to generate the request; an EvenError is thrown in this case)
          this.logger.error(`=> Client-side error: Error Event`, `${this.constructor.name}`);
          msg = `Client-side error: ${error.error.message}`;

          // *** PS
          this.toastrService.error(msg, "Error", { onActivateTick: true});
          // ***

        } else {
          // - server-side error
          this.logger.error(`=> Server-side error: name: ${error.name}, status: ${error.status}, statusText: ${error.statusText},
                                                   URL: ${error.url}`, `${this.constructor.name}`);

          msg = `Server-side error: ${error.status}: ${error.statusText}`;

          // *** PS
          if (error.error.message) {
            const msgObj = this.getMsg(error.error.message);
            this.toastrService.error(msgObj.msg, msgObj.title, { onActivateTick: true });
          } else {
            this.toastrService.error(msg, "Error", { onActivateTick: true});
          }
          // ***
        }
      } else {
        this.logger.error(`=>  Error Other than HttpErrorResponse`, `${this.constructor.name}`);
        msg = `Unknown error`;

        // *** PS
        this.toastrService.error(msg, "Error", { onActivateTick: true});
        // ***
      }

      // // Errors to be rethrown to be caught by the specific component to display
      // if (error.error.code === AppErrorCode.TEST_DEFINITION_EXIST_WHEN_CHANGING_TYPE) {
      //   const msgObj = this.getMsg(error.error.message);
      //
      //   this.toastrService.error(msgObj.msg, msgObj.title, { onActivateTick: true });
      // }
      // // Show the error messages in a toaster
      // // - app optimistic locking exception or not acceptable exception
      // if ((error.status === HttpStatusCode.CONFLICT && error.error.code === AppErrorCode.OPTIMISTIC_LOCKING_EXCEPTION)
      //   || error.status === HttpStatusCode.NOT_ACCEPTABLE) {
      //   this.toastrService.error(error.error.message ? error.error.message : ErrorType.getMessage(error.error.code),
      //     "Error", { onActivateTick: true});
      // } else {
      //   // - general app exception
      //   this.toastrService.error(msg, "Error", { onActivateTick: true});
      // }
    }));
  }

  /**
   * Get the title from the message string.
   * The message string has the following format: "<title>[title]</title>[message]"
   */
  getMsg(str: string): MsgObj {

    const msgObj: MsgObj = {
      title: 'Error',
      msg: ''
    };

    const startTitleIdx = str.indexOf(TitleTag.START);
    const endTitleIdx = str.indexOf(TitleTag.END);

    // if the string doesn't contain title the whole string represents the message
    if (startTitleIdx === -1 || startTitleIdx === -1) {
      msgObj.msg = str;

    } else {
      const pos = startTitleIdx + TitleTag.START.length;
      msgObj.title = str.substring(pos, endTitleIdx);
      msgObj.msg = str.substring(endTitleIdx + TitleTag.END.length);
    }

    return msgObj;
  }
}

type MsgObj = {
  title: string;
  msg: string;
};
