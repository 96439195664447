import {Component, Input, OnInit} from '@angular/core';
import {ModelService} from "../../model/model.service";
import {TestDef} from "../../model/types/test-def";
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {BasicModalComponent} from "../../common/components/basic-modal/basic-modal.component";
import {BasicModalContent} from "../../common/types/basic-modal-content";
import {LogService} from "../../common/services/log-service.service";
import {ToastService} from "@easi-sis/core";

@Component({
  selector: 'app-export-test-def-card',
  templateUrl: './export-test-def-card.component.html',
  styleUrls: ['./export-test-def-card.component.scss']
})
export class ExportTestDefCardComponent implements OnInit {

  @Input()
  testDefinition: TestDef;

  constructor(private modelService: ModelService,
              private toastService: ToastService,
              private modalService: NgbModal,
              private logger: LogService) { }

  ngOnInit(): void {
  }

  getModalContent(): BasicModalContent {
    const modalContent = new BasicModalContent("Export to Clockwork Failed");
    modalContent.body = 'The export to Clockwork failed for this test definition. Try again by clicking on "Export".';
    modalContent.buttons = [];

    return modalContent;
  }

  exportTestDefToClockwork(): void {
    this.logger.debug(`=> Exporting test def with test def ID: ${this.testDefinition.testDefId}`, `${this.constructor.name}`);

    this.modelService.exportTestDefToClockwork(this.testDefinition.testDefId)
      .subscribe((clockworkExamId: number) => {
        this.logger.debug(`=> Exported test def clockworkExamId: ${clockworkExamId}`, `${this.constructor.name}`);

        if (clockworkExamId > 0) {
          this.toastService.show({type: 'success', action: 'The test definition was successfully exported to Clockwork.'});
          this.testDefinition.clockworkExamId = clockworkExamId;

          location.reload();

        } else {
          // A clockwork exam ID = 0 means the test definition was not exported successfully as it did not get a valid exam ID
          const modalRef = this.modalService.open(BasicModalComponent);
          modalRef.componentInstance.modalContent = this.getModalContent();
        }
    });
  }

}
