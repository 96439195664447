<div class="course-contacts-filter">
  <!-- Row 1 -->
  <div class="row margin-top-2-rem align-items-center">
    <!--  Filter by Subject Area, Course, or Meeting section -->
    <div class="col-3">
      <label class="form-label" for="course">Filter by Subject Area, Course, or Meeting section</label>
    </div>
    <!--  Filter by Level of Study -->
    <div class="col-3">
      <label class="form-label" for="levelOfStudy">Filter by Level of Study</label>
    </div>
  </div>
  <div class="row align-items-top align-items-center">
    <div class="col-3">
      <input id="course" class="form-control" type="text" [formControl]="courseCtrl"
             (keyup)="applyTextFilter($event)"
             placeholder="E.g.: ECO, ECO101H or ECO101H LEC0101">
    </div>
    <!-- Level of Study drop-down -->
    <div class="col-3">
      <select class="form-select" area-label="Select options" [formControl]="levelOfStudyCtrl" id="levelOfStudy">
        <option value=''>All Level of Studies</option>
        <option *ngFor="let levelOfStudy of InstructorEmailingUtils.levelOfStudies" [ngValue]="levelOfStudy.value">
          {{levelOfStudy.label}}
        </option>
      </select>
    </div>
  </div>

  <!-- Row 2 -->
  <div class="row margin-top-2-rem align-items-center">
    <!-- Filter by Contact Name or Email Address -->
    <div class="col-3">
      <label class="form-label" for="contactAndEmail">Filter by Contact Name or Email Address</label>
    </div>
    <!-- Filter by Contact Type -->
    <div class="col-3">
      <label class="form-label" for="contactType">Filter by Contact Type</label>
    </div>
    <!-- Filter by Valid Status -->
    <div class="col-2">
      <label class="form-label" for="validStatus">Filter by Valid Status</label>
    </div>
  </div>
  <div class="row align-items-top align-items-center">
    <!-- Contact Name or Email Address -->
    <div class="col-3">
      <input id="contactAndEmail" class="form-control" type="text" [formControl]="contactAndEmailCtrl" placeholder="E.g.: ECO Undergraduate Office">
    </div>
    <!-- Contact Type drop-down -->
    <div class="col-3">
      <select class="form-select" area-label="Select options" [formControl]="contactTypeCtrl" id="contactType">
        <option selected value=''>All Contact Types</option>
        <option *ngFor="let contactType of InstructorEmailingUtils.contactTypes" [ngValue]="contactType.value">
          {{contactType.label}}
        </option>
      </select>
    </div>
    <!-- Valid Status -->
    <div class="col-2">
      <select class="form-select" area-label="Select options" [formControl]="validStatusCtrl" id="validStatus">
        <option *ngFor="let validStatus of InstructorEmailingUtils.validStatuses" [ngValue]="validStatus.value">
          {{validStatus.label}}
        </option>
      </select>
    </div>
  </div>

  <!-- Row 3 -->
  <div class="row margin-top-2-rem align-items-center">
    <!-- Filter by Contact Name or Email Address -->
    <div class="col-2">
      <sis-button visualType="secondary" size="small" (click)="resetFilters()">Reset Filters</sis-button>
    </div>
  </div>
</div>
