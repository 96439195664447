import {UpdateBookingFailureReason} from "./update-booking-failure-reason.enum";
import {BookingStatus} from "./booking-status.enum";

export class UpdateBookingFailureMsg {

  // Booking status enum
  BOOKED: BookingStatus = BookingStatus.BOOKED;
  CANCELLED: BookingStatus = BookingStatus.CANCELLED;

  // Booking status error - from back-end
  BOOKING_STATUS_COULD_NOT_BE_UPDATED = UpdateBookingFailureReason.BOOKING_STATUS_COULD_NOT_BE_UPDATED;
  BOOKING_NOTE_COULD_NOT_BE_ADDED = UpdateBookingFailureReason.BOOKING_NOTE_COULD_NOT_BE_ADDED;
  BOOKING_NOTE_COULD_NOT_BE_UPDATED = UpdateBookingFailureReason.BOOKING_NOTE_COULD_NOT_BE_UPDATED;
  INVALID_STATUS = UpdateBookingFailureReason.INVALID_STATUS;
  UNKNOWN_REASON = UpdateBookingFailureReason.UNKNOWN_REASON;

  /**
   * Creates a message to be shown to the user, based on the reason failure and the new booking status
   * which failed to be updated. Most probably the failure comes from Clockwork.
   * @param failureReason      The back-end call failure reason
   * @param newBookingStatus  The new booking status (i.e. 'Booked', 'Cancelled')
   */
  createMsg(failureReason: string, newBookingStatus: string): string {

    let errorMsg = '';

    const errorMsgStart = (newBookingStatus === this.CANCELLED) ?
      'The booking could not be cancelled' : 'The cancellation of this booking could not be undone';

    // If the result is not null means there was an error. Create the error to be shown on front-end.
    if (failureReason === this.BOOKING_STATUS_COULD_NOT_BE_UPDATED) {
      errorMsg = errorMsgStart + ' because the status could not be updated in Clockwork.';

    } else if (failureReason === this.BOOKING_NOTE_COULD_NOT_BE_ADDED) {
      errorMsg = errorMsgStart + ' because the Booking Note could not be created in Clockwork.';

    } else if (failureReason === this.BOOKING_NOTE_COULD_NOT_BE_UPDATED) {
      errorMsg = errorMsgStart + ' because the Booking Note could not be updated in Clockwork.';

    } else if (failureReason === this.INVALID_STATUS) {
      errorMsg = errorMsgStart + ' because of an invalid status.';

    } else if (failureReason === this.UNKNOWN_REASON) {
      errorMsg = errorMsgStart + ' because there was a problem exporting it to Clockwork.';
    }
    // The external link will be attached at the end in HTML>
    errorMsg += ' Please try again. If the error persists, please submit a ticket via the';

    return errorMsg;
  }
}
