export class CourseLevelOfInstr {

  course: string;
  sectionCode: string;
  teachMethod: string;
  sectionNumber: string;

  // constructor(course: string, sectionCode: string, teachMethod: string, sectionNumber: string) {
  // }
}
