import {TestType} from "../../model/types/testType";

export class TypeUtils {

  // Types values as stored in the database
  readonly DB_TYPE_TEST = 'Test';
  readonly DB_TYPE_FINAL_EXAM = 'Final Exam';

  // Subtype values as stored in the database.
  readonly DB_SUBTYPE_STANDARD = 'Standard';
  readonly DB_SUBTYPE_MAKEUP = 'Makeup';
  readonly DB_SUBTYPE_DEFERRED = 'Deferred';
  readonly DB_SUBTYPE_POP_QUIZ = 'Pop Quiz';

  readonly DB_TEST_ASSESSMENT_TYPE = 'Test';
  readonly DB_EXAM_ASSESSMENT_TYPE = 'Exam';

  readonly EXAM_DEFERRED_TYPE_REGULAR = 'Regular';
  readonly EXAM_DEFERRED_TYPE_SPECIAL = 'Special';

  readonly ON_CAMPUS = 'On Campus (In-Person)';
  readonly OFF_CAMPUS = 'Off Campus (Remote)';
  readonly UNKNOWN = 'Unknown';

  // Test subtypes for Test or Final Exam types (i.e. Standard, Make-up, Deferred, Pop Quiz).
  subtypes: string[];

  // Test types (Test, Final Exam).
  types: string[] = [ this.DB_TYPE_TEST, this.DB_TYPE_FINAL_EXAM ];

  // Assessment types (Test, Exam, Quiz, Other).
  assessmentTypes: string[];

  // Test subtypes for Test type
  testSubtypes: string[];

  // Test subtypes for Final Exam type
  finalExamSubtypes: string[];

  // Assessment types for Test type
  testAssessmentTypes: string[];

  // Assessment Types for Final Exam type
  finalExamAssessmentTypes: string[];

  typeLabel(subtype: string, testType?: string): string {
    let label = '';

    if (subtype === this.DB_TYPE_TEST) {
      label = "Term Assessment";
    } else if (subtype === this.DB_TYPE_FINAL_EXAM) {
      label = "Final Exam";
    } else if (subtype === this.DB_SUBTYPE_STANDARD) {
      label = testType === 'Final Exam' ? 'Standard Exam' : 'Standard Assessment';

    } else if (subtype === this.DB_SUBTYPE_MAKEUP) {
      label = 'Make-up Assessment';

    } else if (subtype === this.DB_SUBTYPE_DEFERRED) {
      label = testType === 'Final Exam' ? 'Deferred Exam' : 'Deferred Assessment';

    } else if (subtype === this.DB_SUBTYPE_POP_QUIZ) {
      label = 'Pop Quiz';

    }
    return label;
  }

  getTestTypes(assessmentTypes: TestType[]) {

    // Test types (i.e. 'Test' or 'Final Exam')
    this.types = assessmentTypes.map(type => type.testType);
    // - sort to display 'Test' first.
    this.types = this.types.sort((a, b) => a > b ? -1 : 1);

    // Test subtypes (i.e. Standard, Make-up, Deferred, Pop Quiz)
    // and Assessment Types (i.e. Standard, Make-up, Deferred, Pop Quiz)
    assessmentTypes.forEach(type => {
      if (type.testType === this.DB_TYPE_TEST) {
        this.testSubtypes = type.testSubTypes;
        this.testAssessmentTypes = type.assessmentTypes;

      } else if (type.testType === this.DB_TYPE_FINAL_EXAM) {
        this.finalExamSubtypes = type.testSubTypes;
        this.finalExamAssessmentTypes = type.assessmentTypes;
      }
    });
  }

  /**
   * Finds the subtypes based on the selected type ('Test' or 'Final Exam').
   * i.e. of subtypes: Standard, Make-up, Deferred, Pop Quiz.
   * @param type: string   The type of test (Test or Final Exam).
   */
  findSubtypes(type: string) {

    if (!type) {
      this.subtypes = [];
      this.assessmentTypes = [];

    } else if (type === this.DB_TYPE_TEST) {
      this.subtypes = this.testSubtypes;
      this.assessmentTypes = this.testAssessmentTypes;

    } else if (type === this.DB_TYPE_FINAL_EXAM) {
      this.subtypes = this.finalExamSubtypes;
      this.assessmentTypes = this.finalExamAssessmentTypes;
    }
  }
}
