import {Component, OnInit} from '@angular/core';
import {FormBuilder, Validators} from "@angular/forms";
import {ModelService} from "../../model/model.service";
import {Course} from "../../model/types/course";
import {TypeUtils} from "../../common/types/type-utils";
import {ActivatedRoute} from "@angular/router";
import {LogService} from "../../common/services/log-service.service";
import {TestDef} from "../../model/types/test-def";
import {TestDefinitionSource} from "../../common/types/test-definition-source";
import {ToastService} from "@easi-sis/core";

@Component({
  selector: 'app-add-test-def',
  templateUrl: './add-test-def.component.html',
  styleUrls: ['./add-test-def.component.scss']
})
export class AddTestDefComponent implements OnInit {

  // Retrieved student courses (from back-end)
  courses: Course[] = [];

  typeUtils: TypeUtils;

  courseInfo: string;
  primaryOrgFac: string;

  constructor(private fb: FormBuilder,
              private activatedRoute: ActivatedRoute,
              private toastService: ToastService,
              private modelService: ModelService,
              private logger: LogService) {

    this.typeUtils = new TypeUtils();
  }

  testDefForm = this.fb.group({
    testType: ['', Validators.required],
    testSubtype: ['', Validators.required],
    pastSession: [''],
    course: ['', Validators.required],
    assessmentLocationDetails: this.fb.group({
      onCampusInd: [true],
      classLocation: ['']
    }),
    assessmentScheduleDetails: this.fb.group({
      assessmentDate: ['', Validators.required],
      assessmentTime: [''],
      assessmentDuration: ['', Validators.required]
    })
  });

  testDefinitionBeingCommitted = false;

  ngOnInit(): void {

    // Get data from the resolver (comes as a Data object; get your data by using the field name from path).
    this.activatedRoute.data.subscribe((data) => {
      this.typeUtils.getTestTypes(data.assessmentTypes);
    });

  }

  courseSectionFormatter = (course: Course) =>
    !course ? '' : [course.code, course.sectionCode?.trim() + ",", course.teachMethod, course.sectionNumber].join(' ')

  onCourseSelected(course: Course) {
    this.logger.debug(`=> Typeahead selected course: ${JSON.stringify(course)}`,
      `${this.constructor.name}.onStudentSelected()`);

    this.courseInfo = !course ? '' : [course.code, course.sectionCode?.trim() + ",", course.teachMethod, course.sectionNumber].join(' ');
    this.primaryOrgFac = 'SGS' === course.primaryOrgCode ?
                             (course.primaryOrgCode + '-' + course.coSecondaryOrgCode + '-' + course.secondaryOrgCode)
                                         : (course.primaryOrgCode + '-' + course.secondaryOrgCode);
  }

  createDefinition() {
    this.testDefinitionBeingCommitted = true;
    console.log("Create test def data: " + JSON.stringify(this.testDefForm.value));

    const testDef: TestDef = Object.assign(new TestDef(), this.testDefForm.value);

    // Add test def source:
    testDef.testDefSource = TestDefinitionSource.ATS_STAFF;

    // Assessment type
    if (this.testDefForm.get('testType').value === this.typeUtils.DB_TYPE_TEST) {
      testDef.assessmentType = "Test";
    } else {
      testDef.assessmentType = "Exam";
    }

    if (this.testDefForm.get('assessmentLocationDetails.onCampusInd').value === null) {
      testDef.assessmentLocationDetails.onCampusInd = null;
    }

    this.modelService.createTestDef(testDef).subscribe(() => {
        this.testDefinitionBeingCommitted = true;
        this.toastService.show({type: 'success', action: 'Successfully created Test Definition'});
        this.logger.debug("Test Definition is created successfully.");
      },
      (error) => {
        this.testDefinitionBeingCommitted = false;
        this.logger.error("Failed to create Test Definition with error ", JSON.stringify(error));
      });
  }
}
