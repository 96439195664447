
<br/><br/>
<div class="activity-log">
  <div class="table-area mat-elevation-z8x">
    <h3 class="margin">Activity Log</h3>
    <table mat-table [dataSource]="dataSource">
      <!-- Date Column -->
      <ng-container matColumnDef="date" >
        <th mat-header-cell *matHeaderCellDef><strong>Date</strong></th>
        <td mat-cell *matCellDef="let element"> {{element.date | date: 'yyyy-MM-dd'}} at {{element.date | date:'h:mm:ss a'}}</td>
      </ng-container>

      <!-- User Column -->
      <ng-container matColumnDef="user" >
        <th mat-header-cell *matHeaderCellDef><strong>User</strong></th>
        <td mat-cell *matCellDef="let element">{{element.user}}</td>
      </ng-container>

      <!-- Description Column -->
      <ng-container matColumnDef="description" >
        <th mat-header-cell *matHeaderCellDef><strong>Description</strong></th>
        <td mat-cell *matCellDef="let element">{{element.description}}
          <a *ngFor = "let booking of element.conflictBookings; last as isLast" routerLink="/student-booking/{{booking.id}}">{{booking.desc}}<span *ngIf="!isLast">, </span> </a>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
      <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
    </table>

    <mat-paginator [pageSizeOptions]="[10, 20, 50, 100]" showFirstLastButtons></mat-paginator>
  </div>

  <div class="notes-area" *ngIf="activityLogsForTestBooking">
    <form [formGroup]="staffNotesForm">
      <div class="flex-container">
        <div>
          <textarea class="form-control" formControlName="note"
                    placeholder="Type your note here" maxlength="500">
          </textarea>
        </div>
        <div>
          <button class="btn btn-primary" (click)="saveStaffNotes()">Submit</button>
        </div>
      </div>
    </form>
  </div>
</div>

