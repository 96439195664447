import {ChangeDetectionStrategy, Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TestDefinition} from "../../model/types/test-definition";
import {Observable} from "rxjs";
import {NotificationLog} from "../notification-log";

@Component({
  selector: 'app-email-log-modal',
  templateUrl: './email-log-modal.component.html',
  styleUrls: ['./email-log-modal.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class EmailLogModalComponent {

  @Input()
  testDefinition: TestDefinition;

  @Input()
  notificationLog$: Observable<NotificationLog[]>;

  constructor(public activeModal: NgbActiveModal) {}
}
