import moment, {Moment} from "moment";

export class AcademicSessionDates {

  sessionStartDate: Moment;
  sessionEndDate: Moment;

  constructor(startDate: moment.Moment, endDate: moment.Moment) {
    this.sessionStartDate = startDate;
    this.sessionEndDate = endDate;
  }
}
