import {ChangeDetectionStrategy, Component} from '@angular/core';
import {Navigation, Router} from "@angular/router";

@Component({
  selector: 'app-bulk-email-summary',
  templateUrl: './bulk-email-summary.component.html',
  styleUrls: ['./bulk-email-summary.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class BulkEmailSummaryComponent {

  title: string;
  bulkEmailSummary: string[];
  emailDate: Date;

  constructor(private router: Router) {

    // Get the NavigationExtras (set in the component where the navigation started).
    const navigation = this.router.getCurrentNavigation();
    const state = navigation.extras.state as {
      title: string,
      bulkEmailSummary: string[],
      emailDate: Date
    };

    this.title = state.title;
    this.bulkEmailSummary = state.bulkEmailSummary;
    this.emailDate = state.emailDate

  }
}
