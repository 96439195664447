<header>
  <div id="header-banner">
    <div id="header-logo"><a href="https://ats.utoronto.ca/"
                             target="_blank"><img alt="U of T Logo"
                                                  src="node_modules/base-styles-staff-bootstrap4/dist/assets/images/uoft_logo_white.svg"></a>
    </div>
    <h1 id="header-title">Design Mode</h1>

  </div><!---->
</header>
<div class="px-4 py-2">
  <ul class="nav nav-pills" >
    <li class="nav-item">
      <a class="nav-link" routerLink="bookings" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Bookings Dashboard</a>
    </li>
    <li class="nav-item">
      <a class="nav-link" routerLink="design" routerLinkActive="active" [routerLinkActiveOptions]="{exact: true}">Design Examples</a>
    </li>
  </ul>
</div>

