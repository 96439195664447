import moment from "moment/moment";
import {AcademicSessionDates} from "./AcademicSessionDates";
import {Moment} from "moment";

export class SessionUtils {

  // Note: Months are 1 indexed, so January is month 1
  readonly JAN = 1;
  readonly APR = 4;
  readonly MAY = 5;
  readonly AUG = 8;
  readonly SEP = 9;
  readonly DEC = 12;

  // Gets the academic session start date and end date.
  /**
   * Gets the academic session start date and end date.
   * If no date is supplied as parameter, then the current date is used to determine the current academic session.
   * If a date is supplied, then that date is used to determine the academic session.
   * @param date The date used to determine the academic session.
   */
  getSessionStartEndDates(date?: Date): AcademicSessionDates {
    // const now = moment();
    // const now = moment([2023, 11, 22]);
    const aDate = !date ? moment() : moment(date);

    let sessionStartDate: Moment;
    let sessionEndDate: Moment;

    const month = aDate.month() + 1; // Note: the moment() month starts from 0.
    const year = aDate.year();

    switch (month) {
      case 1:
      case 2:
      case 3:
      case 4:
        sessionStartDate = moment([year, this.JAN, 1], "YYYY-MM-DD");
        sessionEndDate = moment([year, this.APR, 30], "YYYY-MM-DD");
        break;
      case 5:
      case 6:
      case 7:
      case 8:
        sessionStartDate = moment([year, this.MAY, 1], "YYYY-MM-DD");
        sessionEndDate = moment([year, this.AUG, 31], "YYYY-MM-DD");
        break;
      case 9:
      case 10:
      case 11:
      case 12:
        sessionStartDate = moment([year, this.SEP, 1], "YYYY-MM-DD");
        sessionEndDate = moment([year, this.DEC, 31], "YYYY-MM-DD");
        break;
      default:
    }

    return new AcademicSessionDates(sessionStartDate, sessionEndDate);
  }
}
