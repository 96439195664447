
<div *ngIf="displayAsTitle === true">
  <!-- Test Definition ID -->
  <div class="row">
    <div class="col-12">
      <strong>Test Definition ID: </strong>
      {{testDefinition?.testDefinitionId}}
    </div>
  </div>
  <!-- Course, Section -->
  <div class="row">
    <div class="col-12">
      <strong>Course, Section: </strong>
      {{testDefinition?.academicActivityCode}}
      {{testDefinition?.sectionCode}}
      {{testDefinition?.teachMethod}}{{testDefinition?.sectionNr}}
    </div>
  </div>
  <!-- Test Type -->
  <div class="row">
    <div class="col-12">
      <strong>Test Type: </strong>
      {{testDefinition?.testSubType}} {{testDefinition?.testType}}
    </div>
  </div>
  <!-- Assessment Date -->
  <div class="row">
    <div class="col-12">
      <strong>Assessment Date: </strong> {{testDefinition?.classStartDate}}
    </div>
  </div>
</div>
<div *ngIf="displayAsTitle === false">
  <div class="row">
    <div class="col-12">
      <strong>
        {{testDefinition?.academicActivityCode}}
        {{testDefinition?.sectionCode}}
        {{testDefinition?.teachMethod}}
        {{testDefinition?.sectionNr}},

        TestDefinition ID: {{testDefinition?.testDefinitionId}}
        ({{testDefinition?.classStartDate}},
             {{testDefinition?.testSubType}} {{testDefinition?.testType}})
      </strong>
    </div>
  </div>

</div>

