import {Component, OnInit} from '@angular/core';
import {NgbModal} from "@ng-bootstrap/ng-bootstrap";
import {
  StudentSearchModalComponent
} from "../../../app-student-profile/student-search-modal/student-search-modal.component";
import {Router} from "@angular/router";
import {
  ProvisionStudentModalComponent
} from "../../../booking/components/provision-student-modal/provision-student-modal.component";
import {UploadFilesComponent} from "../../../upload-files/upload-files.component";

/**
 * Shows the tools URLs in every page header.
 */
@Component({
  selector: 'app-masthead-tools',
  templateUrl: './masthead-tools.component.html',
  styleUrls: ['./masthead-tools.component.scss']
})
export class MastheadToolsComponent implements OnInit {

  constructor(private modalService: NgbModal, private router: Router) { }

  ngOnInit(): void {
  }

  openStudentSearchModal() {
    const modalRef = this.modalService.open(StudentSearchModalComponent).result.then((atsStudentId) => {

        if (atsStudentId) {
          this.router.navigateByUrl('student-profile/' + atsStudentId);
        }
      }, () => {
      });
  }

  openStudentProvisionModal() {
    const modalRef = this.modalService.open(ProvisionStudentModalComponent).result.then((rosiStudentId) => {

      if (rosiStudentId) {
        // this.router.navigateByUrl('student-profile/' + rosiStudentId);
      }
    }, () => {
    });
  }

}
