import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {faCaretDown} from '@fortawesome/free-solid-svg-icons/faCaretDown';
import {Column} from "../../common/types/column";

@Component({
  selector: 'app-columns-to-search',
  templateUrl: './columns-to-search.component.html',
  styleUrls: ['./columns-to-search.component.scss']
})
export class ColumnsToSearchComponent implements OnInit {

  @Input()
  activeColumns: Column[];

  @Output()
  changeColumns: EventEmitter<any> = new EventEmitter<any>();

  formGroup: UntypedFormGroup;

  selectAll: UntypedFormControl;

  buttonText: string;

  constructor(private fb: UntypedFormBuilder ) { }

  ngOnInit(): void {

    this.setButtonText();

    this.formGroup = this.fb.group({});

    this.selectAll = new UntypedFormControl(true);

    // if user check "select all" button, set all column true. If uncheck "select all", set all column false
    this.selectAll.valueChanges.subscribe((value: boolean) => {
      if (value){
        for (const activeColumn of this.activeColumns) {
          this.formGroup.patchValue({
            [activeColumn.name]: true,
          });
        }
      }else {
        for (const activeColumn of this.activeColumns) {
          this.formGroup.patchValue({
            [activeColumn.name]: false,
          });
        }
      }
    });


    for (const activeColumn of this.activeColumns) {
      this.formGroup.addControl(activeColumn.name, new UntypedFormControl(activeColumn.isInclude));
    }

    this.formGroup.valueChanges.subscribe((value: object) => {
      let noSelectedColumns = 0;
      let buttontext = '';
      for (const activeColumn of this.activeColumns) {
        if (value[activeColumn.name]) {
        //  activeColumn.includeInSearch();
          activeColumn.isInclude = true;
          noSelectedColumns = noSelectedColumns + 1;
          buttontext = activeColumn.name;
        } else {
       //   activeColumn.excludeFromSearch();
          activeColumn.isInclude = false;
        }
      }

      if (noSelectedColumns === this.activeColumns.length) {
        this.buttonText = 'All Columns';
      } else if (noSelectedColumns === 0) {
        this.buttonText = 'No Columns Selected';
      } else if (noSelectedColumns !== 1) {
        this.buttonText = noSelectedColumns + ' Columns Selected';
      } else {
        this.buttonText = buttontext;
      }

      // send an event to parent to filter
      this.changeColumns.emit();
    });

  }

  reset(){
    this.selectAll.setValue(true);
  }

  // to set button text based on the data in local storage
  setButtonText() {

    let amountOfActiveColumns = 0;
    for (const activeColumn of this.activeColumns) {
      if (activeColumn.isInclude) {
        amountOfActiveColumns = amountOfActiveColumns + 1;
      }
    }

    if (amountOfActiveColumns === this.activeColumns.length) {
      this.buttonText = 'All Columns';
    } else if (amountOfActiveColumns === 0) {
      this.buttonText = 'No Columns Selected';
    } else if (amountOfActiveColumns !== 1) {
      this.buttonText = amountOfActiveColumns + ' Columns Selected';
    } else {
      this.buttonText = '';
    }
  }

}
