import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {MatSort} from "@angular/material/sort";
import {MatLegacyTableDataSource as MatTableDataSource} from "@angular/material/legacy-table";
import {SimpleBooking} from "../../model/types/simple-booking";
import {SelectionModel} from "@angular/cdk/collections";
import {Booking} from "../../model/types/booking";

@Component({
  selector: 'app-selectable-bookings-table',
  templateUrl: './selectable-bookings-table.component.html',
  styleUrls: ['./selectable-bookings-table.component.scss']
})
export class SelectableBookingsTableComponent implements OnInit {

  @Input()
  bookingsDataSource: MatTableDataSource<SimpleBooking>;

  @Input()
  selectable: boolean;

  @Output()
  outputEvent = new EventEmitter<SimpleBooking[]>();

  @ViewChild(MatSort, {static: true}) sort?: MatSort;

  selection = new SelectionModel<SimpleBooking>(true, []);

  bookingsColumns1 = ["select", "appointmentId", "bookingId", "studentName", "studentRosiNo", 'scheduledStartDate', 'scheduledStartTime',
    'scheduledEndTime', 'location'];
  bookingsColumns2 = ["appointmentId", "bookingId", "studentName", "studentRosiNo", 'scheduledStartDate', 'scheduledStartTime',
    'scheduledEndTime', 'location'];

  selectedBookings: SimpleBooking[];
  validBookingsAmount = 0; // use this value to check if all valid bookings are selected

  constructor() { }

  ngOnInit(): void {
    this.bookingsDataSource.sortingDataAccessor = (data: SimpleBooking, sortHeaderId: string) => {
      if (sortHeaderId === 'appointmentId') {
        return data.clockworkAppointmentId.toString();
      }
      else if (sortHeaderId === 'bookingId') {
        return data.bookingId.toString();
      }
      else if (sortHeaderId === 'studentName') {
        return data.studentInfo.firstName + ' ' + data.studentInfo.lastName;
      }
      else if (sortHeaderId === 'studentRosiNo') {
        return data.studentInfo.rosiStudentId ? data.studentInfo.rosiStudentId.toString() : "";
      }
      else if (sortHeaderId === 'scheduledStartDate') {
        return data.scheduledStartDate === null ? '' : data.scheduledStartDate.toString();
      }
      else if (sortHeaderId === 'scheduledStartTime') {
        return data.scheduledStartTime === null ? '' : data.scheduledStartTime.toString();
      }
      else if (sortHeaderId === 'scheduledEndTime') {
        return data.scheduledEndTime === null ? '' : data.scheduledEndTime.toString();
      }
      else if (sortHeaderId === 'location') {
        return this.getLocation(data);
      }
      else {
        return '';
      }

    };

    this.bookingsDataSource.sort = this.sort;
    this.validBookingsAmount = this.bookingsDataSource.data.filter(this.isBookingSelectable).length;
  }

  /** Whether the number of selected elements matches the total number of available rows. */
  isAllSelected() {
    const numSelected = this.selection.selected.length;
 //   const numRows = this.bookingsDataSource.data.length; // if don't consider if row is available to be selected
    const numRows = this.validBookingsAmount;
    return numSelected === numRows;
  }

  /** Selects all rows if they are not all selected; otherwise clear selection. */
  masterToggle() {
    this.isAllSelected() ?
      this.selection.clear() :
      this.bookingsDataSource.data.forEach(row => {
        if (this.isBookingSelectable(row )) {
          this.selection.select(row);
        }
      });

    this.getSelectedBookings();

  }

  // change each row
  changeItem(row) {
    this.selection.toggle(row);
    this.getSelectedBookings();
  }

  isBookingSelectable(booking: SimpleBooking): boolean {
    return booking.clockworkAppointmentId !== null;
  }

  // get all selected rows and send out
  getSelectedBookings() {
    this.selectedBookings = [];
    this.selection.selected.forEach(s => this.selectedBookings.push(s));
    this.outputEvent.emit(this.selectedBookings);
  }

  getLocation(booking: SimpleBooking) {
    if (booking.inExamCentre) {
      return "Exam Centre";
    } else {
      return "Elsewhere";
    }
  }

}
