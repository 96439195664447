import { Injectable } from '@angular/core';
import {AuthConfig, OAuthService} from "angular-oauth2-oidc";
import {Environment} from "../../../environment";
import {LogService} from "./log-service.service";
import {UserRole} from "../../model/types/user-role";
import {AsyncSubject, Observable} from "rxjs";

@Injectable({
  providedIn: 'root'
})
export class AuthService {

  private isDesginatesAdmin = false;

  private loggedInSubject: AsyncSubject<boolean> = new AsyncSubject<boolean>();

  constructor(private oauthService: OAuthService, private logger: LogService) {

    const authConfig = new AuthConfig();

    // Url of the Identity Provider
    authConfig.issuer = 'https://login.microsoftonline.com/78aac226-2f03-4b4d-9037-b46d56c55210/v2.0';

    authConfig.responseType = 'code';

    // URL of the SPA to redirect the user to after login
    authConfig.redirectUri = window.location.origin + '/index.html';

    // The SPA's id. The SPA is registered with this id at the auth-server
    authConfig.clientId = Environment.clientId;

    // set the scope for the permissions the client should requestdd
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    authConfig.scope = 'openid profile email';

    authConfig.strictDiscoveryDocumentValidation = false;

    this.oauthService.configure(authConfig);
  }

  public login(): void {

    // this.oauthService.loadDiscoveryDocumentAndTryLogin()
    this.oauthService.loadDiscoveryDocumentAndLogin()
      .catch((err) => { this.logger.error('error logging in: ' + err); })
      .then(result => {
        if (!this.oauthService.hasValidAccessToken()) {
          this.oauthService.initCodeFlow();
          this.oauthService.setupAutomaticSilentRefresh();

        } else {
          this.oauthService.setupAutomaticSilentRefresh();

          this.configureRoles();

          // this.logger.debug(' id token = ' + this.oauthService.getIdToken());
          // this.logger.debug(' access token = ' + this.oauthService.getAccessToken());
          this.loggedInSubject.next(true);
          this.loggedInSubject.complete();
        }
      });
  }

  public hasValidIdToken(): boolean {
    return this.oauthService.hasValidIdToken();
  }

  public whenLoggedIn(): Observable<boolean> {
    return this.loggedInSubject;
  }

  public logout(): void {
    this.oauthService.logOut();
  }

  public isUserDesginatesAdmin(): boolean {
    return this.isDesginatesAdmin;
  }

  private configureRoles() {
    const roles = "roles";
    const rolesDetail = this.oauthService.getIdentityClaims()[roles];
    console.log("==> Roles: " + rolesDetail);
    if (Object.values(rolesDetail).indexOf(UserRole.designatesAdminRole) > -1) {
      this.isDesginatesAdmin = true;
    } else {
      this.isDesginatesAdmin = false;
    }
  }
}
