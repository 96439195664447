<!-- Accommodations -->
<form [formGroup]="form">
  <div class="row">
    <div class="col-12">
      <h3 >Accommodations</h3>
      <div class=" mt-4">
        <strong>Accommodations valid until</strong>: {{expiryDate | date: 'yyyy-MM-dd h:mm a'}}<br/>
        The following accommodations apply to this assessment unless unchecked.
      </div>
    </div>

    <div class="col-12 mt-4" *ngIf="nonOptionalAccommodations && nonOptionalAccommodations.length > 0">
      <h4>Required Accommodations</h4>
      <div *ngFor="let accomm of nonOptionalAccommodations">
        <div class="form-inline" *ngIf="!accomm.optional">
          <input type="checkbox" id="{{accomm.id}}" disabled checked="checked"/>&nbsp;
          <label class="form-label" for="{{accomm.id}}"> {{accomm.description}}</label>
        </div>
      </div>
    </div>

    <div class="col-12 mt-4" *ngIf="optionalAccommodations && optionalAccommodations.length > 0">
      <h4>Optional Accommodations</h4>
      <ng-container formArrayName="optionalAccom">
        <div class="form-inline" *ngFor="let accom of optionalAccom.controls; let i=index">
          <ng-container [formGroupName]="i">
            <input id="{{accom.id}}" type="checkbox" formControlName="selected"
                   (blur)="onTouched()" (change)="optionalAccommChanged(accom)">&nbsp;
            <label class="form-label" for="{{accom.id}}"> {{accom.value.description}}</label>
          </ng-container>
        </div>
      </ng-container>
    </div>
  </div>
</form>
