<!-- header -->
<div class="margin-top-4-rem">
  <h1>Upload Test Definitions</h1>
</div>

<div class="margin-top-4-rem upload-files card card-body">
  <div>
    <h2>CSV File</h2>
    <div class="margin-top-2-rem">
      <p class="">Select and upload a CSV file of test definitions.</p>
      <p class="margin-bottom-0">Ensure there are no null or empty rows and the following fields are formatted as follows:</p>
      <p class="margin-bottom-0"><strong>Date:</strong> yyyy-mm-dd e.g., 2023-05-19</p>
      <p class="margin-bottom-0"><strong>Time:</strong> hh:mm:ss e.g., 01:30:00</p>
      <p><strong>Section:</strong> Course Meeting Section & Number (with no spaces) e.g., LEC0101</p>
    </div>
  </div>

  <form #ngForm="ngForm" novalidate>
    <div>
      <sis-file-upload name="csv"
                       [(ngModel)]="csvFile"
                       #csvFileNgModel="ngModel"
                       [acceptedFileTypes]="acceptedFileTypes"
                       [ariaErrorMessage]="(ngForm.submitted && csvFileNgModel.invalid)
                          ? 'csv-file-errors'
                          : ''"
                       [maxFileSize]="5000000" (viewChange)="fileChange($event)"
      >
      </sis-file-upload>
    </div>

    <div id="csv-file-errors" class="margin-top-2-rem error-message">
      <ng-container
        *ngIf="csvFileNgModel.invalid
            && csvFileNgModel.errors! as errors">
        <p *ngIf="errors['required']">Please select a file.</p>
        <p *ngIf="errors['size']">
          Please upload a file with a size less than 5MB.
        </p>
        <p *ngIf="errors['type']">Please upload a CSV file.</p>
      </ng-container>
    </div>

    <div class="text-danger" *ngIf="successfullyUploaded === false">
      Failed to upload the file:
      <br/>
      {{fileUploadResp.fileProcessingError}}
    </div>

    <app-spinner id="spinner"></app-spinner>
    <div class="text-info margin-top-2-rem " *ngIf="successfullyUploaded === true && updatedFile">
      <div class="alert alert-info">
        <p class="margin-bottom-0">Test Definition(s) Successfully Created.</p>
        <p class="margin-bottom-0">Session code: {{fileUploadResp.sessionCode}}</p>
        <p class="margin-bottom-0">Number of CSV records processed: {{fileUploadResp.numbOfCsvRecords}}</p>
        <p class="margin-bottom-0">Number of records to create test definitions from: {{fileUploadResp.numOfRecsProcessed}}</p>
        <p class="margin-bottom-0">Number of test definitions created: {{fileUploadResp.numOfTestDefCreated}}</p>
        <p class="margin-bottom-0">Number of courses not found in ROSI: {{fileUploadResp.coursesNotFoundInDb.length}}</p>
        <p>Number of test definitions failed to be created: {{fileUploadResp.failedTestDefs.length}}:</p>
      </div>

      <div class="alert alert-danger margin-top-2-rem" *ngIf="(fileUploadResp.coursesNotFoundInDb.length + fileUploadResp.failedTestDefs.length) > 0 ">
        <p class="margin-bottom-0"><strong>Error(s) in the file:</strong> We found {{fileUploadResp.coursesNotFoundInDb.length + fileUploadResp.failedTestDefs.length}} error(s) in the file.
          <button class="btn btn-link" *ngIf="!showErrorsDetail" (click)="showDetail()">Show error(s)</button></p>
        <p><strong>Note:</strong> Test definitions may fail to be created  if there are duplicates.</p>
        <div *ngIf="showErrorsDetail">
          <div *ngIf="fileUploadResp.coursesNotFoundInDb.length > 0">
            <p class="margin-bottom-0"><strong>Number of courses not found in ROSI: {{fileUploadResp.coursesNotFoundInDb.length}}</strong></p>
            <div class="" *ngFor="let courseNotFound of fileUploadResp.coursesNotFoundInDb">
              <ul>
                <li>
                  {{courseNotFound.courseCode}}-{{courseNotFound.sectionCode}}:
                  {{courseNotFound.teachMethod}} {{courseNotFound.sectionNo}}
                  {{courseNotFound.assessmentDate}} {{courseNotFound.assessmentTime}} {{courseNotFound.assessmentDuration}}
                </li>
              </ul>
            </div>
          </div>
          <div *ngIf="fileUploadResp.failedTestDefs.length > 0">
            <p class="margin-bottom-0"><strong>Number of test definitions failed to be created: {{fileUploadResp.failedTestDefs.length}}:</strong></p>
            <div class="" *ngFor="let failedTesDef of fileUploadResp.failedTestDefs">
              <ul>
                <li>
                  <!--                    {{failedTesDef.testType}}, {{failedTesDef.testSubtype}}, {{failedTesDef.assessmentType}},-->
                  {{failedTesDef.courseDto.code}}-{{failedTesDef.courseDto.sectionCode}}:
                  {{failedTesDef.courseDto.teachMethod}} {{failedTesDef.courseDto.sectionNo}}
                  {{failedTesDef.assessmentDate}} {{failedTesDef.assessmentTime}} {{failedTesDef.assessmentDuration}}
                </li>
              </ul>
            </div>
          </div>
          <div>
            <button class="btn btn-link" (click)="showLess()">Show less</button>
          </div>
        </div>
      </div>
    </div>
    <div class="margin-top-4-rem">
      <button class="btn btn-sm btn-primary" (click)="submitFile(ngForm)"
              [disabled]=" disableUpload()" [ngStyle]="{'background-color':
              disableUpload() ? 'grey' : ''}">Upload</button>
    </div>
  </form>
</div>
<div class="margin-top-4-rem">
  <a routerLink="/test-definitions">Back to Test Definitions Dashboard</a>
</div>
