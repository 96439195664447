import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {AbstractControl, UntypedFormControl} from "@angular/forms";

@Component({
  selector: 'app-student-search-modal',
  templateUrl: './student-search-modal.component.html',
  styleUrls: ['./student-search-modal.component.scss']
})
export class StudentSearchModalComponent implements OnInit {

  @Input()
  student: AbstractControl;

  constructor(public activeModal: NgbActiveModal) {
    this.student = new UntypedFormControl('');
  }

  ngOnInit(): void {
  }

  viewProfile() {
    // close the modal and sent the rosiStudentId to the caller
    this.activeModal.close(this.student.value.atsStudentId);
  }

}
