import {AfterViewInit, Component, ElementRef, OnInit, ViewChild, ViewChildren} from '@angular/core';
import {
  AbstractControl,
  FormControlName,
  UntypedFormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators
} from "@angular/forms";
import {GenericValidator} from "./generic-validator";
import {fromEvent, merge, Observable} from "rxjs";
import {distinctUntilChanged} from "rxjs/operators";
import {ModelService} from "../model/model.service";
import {Course} from "../model/types/course";
import {ActivatedRoute} from "@angular/router";
import {ManualBooking} from "../model/types/manual-booking";
import {CourseSection} from "../model/types/course-section";
import {LogService} from "../common/services/log-service.service";
import {SelectedTestDef} from "../model/types/selected-test-def";
import {ToastrService} from "ngx-toastr";
import {StudentName} from '../model/types/student-name';
import {StudentSearch} from '../model/types/student-search';
import {AccommodationSearch} from "../model/types/accommodation-search";
import {Accommodation} from "../model/types/accommodation";
import {AccommodationSelection} from "../model/types/accommodation-selection";
import {TypeUtils} from "../common/types/type-utils";
import {TestDefinitionSource} from "../common/types/test-definition-source";
import {ToastService} from "@easi-sis/core";

@Component({
  selector: 'app-manual-booking-creation',
  templateUrl: './manual-booking-creation.component.html',
  styleUrls: ['./manual-booking-creation.component.scss']
})
export class ManualBookingCreationComponent implements OnInit, AfterViewInit {

  @ViewChildren(FormControlName, {read: ElementRef}) formInputElements: ElementRef[];

  @ViewChild('accommoComponent')
  accommoComponent: any;

  validationMessages: { [key: string]: { [key: string]: string } };

  displayMessage: { [key: string]: string } = {};


  public courseInfo: string;

  public instructorsFullNames: string;

  // Contains course section info
  selectedCourseSectionObj: CourseSection;
  // Retrieved student courses (from back-end)
  courses: Course[] = [];

  testDefinitionsByCourse: SelectedTestDef[];
  testDefinitionsFilteredByTestSubType: SelectedTestDef[];

  newTestDefinition = false;

  manualBookingForm: UntypedFormGroup;
  basicTestDefinition: UntypedFormGroup;
  testDefinitionId: UntypedFormControl;

  nonOptionalAccommodations: Accommodation[] = [];

  optionalAccommodations: AccommodationSelection[] = [];

  expireDate: Date;

  // Test types (i.e. Test, Final Exam).
  types: string[];

  // Test subtypes for Test or Final Exam types (i.e. Standard, Make-up, Deferred, Pop Quiz).
  subtypes: string[];

  // Test subtypes for Test type
  testSubtypes: string[];

  // Test subtypes for Final Exam type
  finalExamSubtypes: string[];

  // Assessment types (i.e. Test, Quiz, Other, Exam).
  assessmentTypes: string[];

  // Assessment types for Test type
  testAssessmentTypes: string[];

  // Assessment Types for Final Exam type
  finalExamAssessmentTypes: string[];

  bookingBeingCommitted = false;

  private genericValidator: GenericValidator;

  typeUtils: TypeUtils;

  constructor(private formBuilder: UntypedFormBuilder, private toast: ToastrService, private toastService: ToastService,
              private modelService: ModelService, private logger: LogService) {


    this.validationMessages = {
      student: {
        required: 'Student name is required.'
      },
      course: {
        required: 'Course Selection is required'
      },
      termType: {
        required: 'Test Type is required'
      },
      assessmentOnCampus: {
        required: 'Class Location is required'
      }
    };

    this.genericValidator = new GenericValidator(this.validationMessages);

    this.typeUtils = new TypeUtils();

  }

  ngOnInit(): void {

    this.manualBookingForm = this.formBuilder.group({
      student: [null, [Validators.required, Validators.minLength(5)]],
      course: [null, [Validators.required, Validators.minLength(5)]],
      assessmentOnCampus: [null, Validators.required]
    });

    this.testDefinitionId = new UntypedFormControl(null, Validators.required);

    this.basicTestDefinition = this.formBuilder.group({
      currentSessionInd: [true, Validators.required],

      testType: [null, Validators.required],
      testSubtype: [null, Validators.required],
      assessmentType: [null],
      requestedAssessmentDate: [null, Validators.required],
      requestedAssessmentStartTime: [null, Validators.required],
      requestedAssessmentDuration: [null, Validators.required]
    });

    /**
     * Reset all the fields following "Select Session" if the value is changed.
     *
     * Possible solutions not to trigger ...valueChanges.subscribe() when values form fields are set or patched:
     *  1). use pipe(skip(1))
     *        i.e. this.basicTestDefinition.get("currentSessionInd").valueChanges.pipe(skip(1)).subscribe();
     *  2). use {onlySelf: true}
     *        i.e. this.basicTestDefinition.patchValue({testType: null,...},  }, {onlySelf: true});
     *  3). use {emitEvent: false}
     *        i.e. this.basicTestDefinition.patchValue({testType: null,...},  }, {emitEvent: false});
     */
    this.basicTestDefinition.get("currentSessionInd").valueChanges.subscribe(() => {

      this.manualBookingForm.patchValue({
        course: null,
        assessmentOnCampus: null
      }, {emitEvent: false});

      this.courses = [];
    });

    // Filter the test definitions by test subtype
    this.basicTestDefinition.controls.testSubtype.valueChanges.subscribe(() => {
      this.testDefinitionId.setValue(null);
      if (this.testDefinitionsByCourse) {
        this.testDefinitionsFilteredByTestSubType =
          this.testDefinitionsByCourse
            .filter(testDefinition => testDefinition.testSubtype === this.basicTestDefinition.controls.testSubtype.value);
      }
    });

    // To avoid making API calls twice, check if the value is really changed
    this.manualBookingForm.controls.course.valueChanges
      .pipe(distinctUntilChanged((a, b) => JSON.stringify(a) === JSON.stringify(b)))
      .subscribe(courseSection => {
        // reset the values for all the input should happen after entering course
        this.testDefinitionId.setValue(null);
        this.newTestDefinition = false;
        this.resetDateTime();
        this.manualBookingForm.controls.assessmentOnCampus.setValue(null);

        // put this guard so that even if the user keys in the whole course code (the course is valid now) in
        // the course input column, the following action will not run. The user has to select the course section option.
        if (typeof (courseSection) === 'object' && courseSection != null) {
          this.courseInfo = courseSection.code + " " + courseSection.sectionCode
                                 + ", " + courseSection.teachMethod + " " + courseSection.sectionNo;

          // Getting existing test definitions for courseSection
          if (this.manualBookingForm.controls.course.valid) {
            this.selectedCourseSectionObj = courseSection;

            // Get active test definitions for course section and test type (term assessment or final exam)
            this.modelService.getActiveTestDefinitions(
                                        courseSection.code,
                                        courseSection.sectionCode,
                                        courseSection.sessionCode,
                                        courseSection.teachMethod,
                                        courseSection.sectionNo,
                                        this.basicTestDefinition.controls.testType.value)
              .subscribe(data => {
                this.testDefinitionsByCourse = data;

                // Filter the test definitions type by test definition subtype
                this.testDefinitionsFilteredByTestSubType = data.filter((testDefinition: SelectedTestDef) => {

                  if (this.basicTestDefinition.controls.testType.value === this.typeUtils.DB_TYPE_TEST) {
                    // for Term Assessment, filter by test subtype directly
                    return testDefinition.testSubtype === this.basicTestDefinition.controls.testSubtype.value;

                  } else if (this.basicTestDefinition.controls.testType.value === this.typeUtils.DB_TYPE_FINAL_EXAM) {

                    // for Final Exam, standard type is mapped to standard
                    if (this.basicTestDefinition.controls.testSubtype.value === this.typeUtils.DB_SUBTYPE_STANDARD) {
                      return testDefinition.testSubtype === this.typeUtils.DB_SUBTYPE_STANDARD;

                    } else if (this.basicTestDefinition.controls.testSubtype.value === this.typeUtils.EXAM_DEFERRED_TYPE_REGULAR
                          || this.basicTestDefinition.controls.testSubtype.value === this.typeUtils.EXAM_DEFERRED_TYPE_SPECIAL) {
                      // for Final Exam, regular deferred and special deferred are mapped to deferred
                      return testDefinition.testSubtype === this.typeUtils.DB_SUBTYPE_DEFERRED;
                    }
                  }
                });
              });

            // Get course section instructors
            this.modelService.getRosiCourseInstructors(
                                        courseSection.code,
                                        courseSection.sectionCode,
                                        courseSection.sessionCode,
                                        courseSection.teachMethod,
                                        courseSection.sectionNo)
              .subscribe(res => {
                this.instructorsFullNames = Array.isArray(res) ? res.map(z => z.firstName + ' ' + z.lastName).join(', ') : '';
              });

            // Get accommodations for this course
            this.getAccommodations();
          }
        }
    });

    /**
     * Get a list of: test types, test subtypes and assessment types.
     * [{"testType":"Final Exam","testSubTypes":["Standard", "Deferred"], assessmentTypes: ["Exam"]},
     *  {"testType":"Test","testSubTypes":["Standard", "Deferred", "Makeup", "Pop Quiz"],
     *                     "assessmentTypes":["Quiz", "Test", "Other"]}]
     */
    this.modelService.getTestTypes().subscribe(testTypes => {

      // Test types (i.e. 'Test' or 'Final Exam')
      this.types = testTypes.map(type => type.testType);
      // - sort to display 'Test' first.
      this.types = this.types.sort((a, b) => a > b ? -1 : 1);

      // Test subtypes (i.e. Standard, Make-up, Deferred, Pop Quiz)
      // and Assessment Types (i.e. Standard, Make-up, Deferred, Pop Quiz)
      testTypes.forEach(type => {
        if (type.testType === this.typeUtils.DB_TYPE_TEST) {
          this.testSubtypes = type.testSubTypes;
          this.testAssessmentTypes = type.assessmentTypes;

        } else if (type.testType === this.typeUtils.DB_TYPE_FINAL_EXAM) {
        //  this.finalExamSubtypes = type.testSubTypes;
          // replace subType "Deferred" with "Regular Deferred" and "Special Deferred"
          this.finalExamSubtypes = [];
          type.testSubTypes.forEach( subType => {
            if (subType !== this.typeUtils.DB_SUBTYPE_DEFERRED) {
              this.finalExamSubtypes.push(subType);
            }
          });
          this.finalExamSubtypes.push(this.typeUtils.EXAM_DEFERRED_TYPE_REGULAR);
          this.finalExamSubtypes.push(this.typeUtils.EXAM_DEFERRED_TYPE_SPECIAL);

          this.finalExamAssessmentTypes = type.assessmentTypes;
        }
      });
    });
  }

  retrieveCourses(): void {
    if (this.manualBookingForm.controls.student.valid) {
      const student = this.manualBookingForm.controls.student.value;
      this.getCourses(student, this.basicTestDefinition.controls.currentSessionInd.value);
    }
  }

  /**
   * Finds the subtypes based on the selected type ('Test' or 'Final Exam').
   * i.e. of subtypes: Standard, Make-up, Deferred, Pop Quiz.
   * @param type: string   The type of test (Test or Final Exam).
   */
  findSubtypes(type: string) {

    if (!type) {
      this.subtypes = [];
      this.assessmentTypes = [];

    } else if (type === this.typeUtils.DB_TYPE_TEST) {
      this.subtypes = this.testSubtypes;
      this.assessmentTypes = this.testAssessmentTypes;
      this.basicTestDefinition.controls.testSubtype.setValue(null);

    } else if (type === this.typeUtils.DB_TYPE_FINAL_EXAM) {
      this.subtypes = this.finalExamSubtypes;
      this.basicTestDefinition.controls.testSubtype.setValue(null);
      this.assessmentTypes = this.finalExamAssessmentTypes;
    }
  }

  /**
   * Gets and returns the subtype label to be shown to users, based on the subtype, as stored in the database.
   * @param subtype : string   The test subtype.
   *
   * Note: Should store the display subtype name in the database!
   */
  getSubtypeLabel(subtype: string): string {
    let subtypeLabel = '';

    if (subtype === this.typeUtils.DB_SUBTYPE_STANDARD) {
      subtypeLabel = this.basicTestDefinition.controls.testType.value === 'Final Exam' ?
                                                              'Standard Exam' : 'Standard Assessment';
    } else if (subtype === this.typeUtils.DB_SUBTYPE_MAKEUP) {
      subtypeLabel = 'Make-up Assessment';
    } else if (subtype === this.typeUtils.DB_SUBTYPE_DEFERRED) {
      subtypeLabel = 'Deferred Assessment';
    } else if (subtype === this.typeUtils.DB_SUBTYPE_POP_QUIZ) {
      subtypeLabel = 'Pop Quiz';
    } else if (subtype === this.typeUtils.EXAM_DEFERRED_TYPE_REGULAR) {
      subtypeLabel = 'Regular Deferred Exam Booking (Standard Exam this term but for a course enrolled in a previous term)';
    } else if (subtype === this.typeUtils.EXAM_DEFERRED_TYPE_SPECIAL) {
      subtypeLabel = 'Special Deferred Exam';
    }

    return subtypeLabel;
  }

  onStudentSelected(selectedStudent: StudentName) {
    this.logger.debug(`=> Typeahead selected student: ${JSON.stringify(selectedStudent)}`,
                                                            `${this.constructor.name}.onStudentSelected()`);
    this.basicTestDefinition.controls.testType.setValue(null);
    this.basicTestDefinition.controls.testSubtype.setValue(null);
    this.manualBookingForm.controls.course.reset("");

    // Update ATS student accommodations from clockwork
    this.modelService.updateAccommodationsFromClockwork(selectedStudent.rosiStudentId).subscribe(res => {
      this.getAccommodations();
    });

    if (this.basicTestDefinition.get("currentSessionInd").value) {
      this.retrieveCourses();
    }
  }

  onCourseSelected(courseSelected: CourseSection) {
    this.logger.debug(`### => Course Section Selected: ${JSON.stringify(courseSelected)}`,
                                                                `${this.constructor.name}.onCourseSelected()`);
  }

  onSubmit() {
    this.bookingBeingCommitted = true;
    const booking: ManualBooking = Object.assign(new ManualBooking(), this.manualBookingForm.value);
    // ensure an existing test definition was selected or a new one specified
    const selectedTestDef: AbstractControl = this.testDefinitionId;

    if (selectedTestDef.value) {
      booking.testDefinitionId = selectedTestDef.value;
    } else {
      // if user select exising test definition, need to create a basicTestDefinition to hold the test type
      const basicTestDefinition: AbstractControl = this.manualBookingForm.get("basicTestDefinition");

      if (!basicTestDefinition.valid) {
        this.toast.error("Specify an existing test definition or create a new test definition.", "Failure");
        return;
      }
    }
    if (this.manualBookingForm.controls.assessmentOnCampus.value === true) {
      booking.inExamCentre = true;
      booking.online = false;
    } else if (this.manualBookingForm.controls.assessmentOnCampus.value === false) {
      booking.inExamCentre = false;
      booking.online = true;
    }

    booking.accommodations = this.accommoComponent.nonOptionalAccommodations;
    this.accommoComponent.optionalAccommodations.forEach(optionalAccommodation => {
      if (optionalAccommodation.isSelected) {
        booking.accommodations.push(optionalAccommodation.accommodation);
      }
    });

    // TODO: For now we hardcode assessment type as it is not shown on the front-end for use to select one!
    // Default Assessment Type to 'Test' for test type 'Test' and to 'Exam' for test type 'Final Exam'(ATS-1298)
    if (!selectedTestDef.value) {
      // set default value for some test definition properties
      booking.basicTestDefinition.classLocation = "";
      booking.basicTestDefinition.testDefSource = TestDefinitionSource.MANUAL_BOOKING;

      if (this.basicTestDefinition.controls.testType.value === this.typeUtils.DB_TYPE_TEST) {
        booking.basicTestDefinition.assessmentType = this.typeUtils.DB_TEST_ASSESSMENT_TYPE;
      } else if (this.basicTestDefinition.controls.testType.value === this.typeUtils.DB_TYPE_FINAL_EXAM) {
        booking.basicTestDefinition.assessmentType = this.typeUtils.DB_EXAM_ASSESSMENT_TYPE;
      }
    }

    // reorganize the test subtype and exam deferred type
    if (this.basicTestDefinition.controls.testSubtype.value === this.typeUtils.EXAM_DEFERRED_TYPE_REGULAR) {
      if (!selectedTestDef.value) {
        booking.basicTestDefinition.testSubtype = this.typeUtils.DB_SUBTYPE_DEFERRED;
      }
      booking.examDeferredType = this.typeUtils.EXAM_DEFERRED_TYPE_REGULAR;
    } else if ( this.basicTestDefinition.controls.testSubtype.value === this.typeUtils.EXAM_DEFERRED_TYPE_SPECIAL ) {
      if (!selectedTestDef.value) {
        booking.basicTestDefinition.testSubtype = this.typeUtils.DB_SUBTYPE_DEFERRED;
      }
      booking.examDeferredType = this.typeUtils.EXAM_DEFERRED_TYPE_SPECIAL;
    }

    // Add sub-session code to the session code (to be saved in database)
    if (booking.course.subSessionCode) {
      booking.course.sessionCode = booking.course.sessionCode.concat(booking.course.subSessionCode);
    }

    // console.log("######### booking: " + JSON.stringify(booking));

    this.modelService.createTestDefinitions(booking).subscribe(() => {
      this.toastService.show({type: 'success', action: 'Successfully created Booking'});
      this.logger.debug("Test Booking is created successfully for the student with Ats_student_id",
        booking.student.atsStudentId);
      this.reset();
    },
      (error: any) => {
        this.logger.debug("error happened when creating booking ", error);
        this.bookingBeingCommitted = false;
      });
  }


  onSelectedExistingTestDefinition() {
    // reset the date, time, and duration that may have been input
    this.resetDateTime();

    this.manualBookingForm.controls.assessmentOnCampus.setValue(null);
    if (this.testDefinitionId) {
      // make sure the create new panel is hidden
      this.newTestDefinition = false;
      this.manualBookingForm.removeControl("basicTestDefinition");
    }
    else {
      // selected nothing
      this.newTestDefinition = true;
      this.manualBookingForm.addControl('basicTestDefinition', this.basicTestDefinition);
    }
  }

  resetDateTime() {
    this.basicTestDefinition.controls.requestedAssessmentDate.setValue(null);
    this.basicTestDefinition.controls.requestedAssessmentStartTime.setValue(null);
    this.basicTestDefinition.controls.requestedAssessmentDuration.setValue(null);
  }

  onCreateNewTestDefinition() {
    this.manualBookingForm.controls.assessmentOnCampus.reset();
    if (this.testDefinitionId) {
      this.testDefinitionId.reset();
      this.newTestDefinition = true;
      this.manualBookingForm.addControl('basicTestDefinition', this.basicTestDefinition);
    }
  }
  /**
   * Get all student courses from the back-end, based on the selected student from typeahead and the 'current' or
   * other session selected.
   */
  getCourses(student: StudentName, currentSessionInd: boolean) {
    if (!student || !student.rosiStudentId) {
      // tslint:disable-next-line:max-line-length
      this.logger.error(`=> There is no student or student.rosiStudentId. This data should come from Search for Student typeahead child component.`
                            , `${this.constructor.name}.getCourses()`);
      return;
    }

    const rosiStudentId = student.rosiStudentId;

    // Retrieve student courses based on ROSI student ID (student number).
    this.logger.debug(`=> Get courses for student: ${rosiStudentId}`, `${this.constructor.name}.getCourses()`);

    const params = new StudentSearch();
    // tslint:disable-next-line:radix
    params.rosiStudentId = parseInt(rosiStudentId);
    params.currentSessionInd = currentSessionInd;

    this.modelService.getStudentCourses(params).subscribe( value => {
      // value is of type Course[]
      this.courses = value;
    });
  }

  // TODO: is it needed?
  ngAfterViewInit(): void {
    const controlBlurs: Observable<any>[] = this.formInputElements
      .map((formControl: ElementRef) => fromEvent(formControl.nativeElement, 'blur'));

    // Merge the blur event observable with the valueChanges observable
    // so we only need to subscribe once.
    merge(this.manualBookingForm.statusChanges, ...controlBlurs).subscribe(value => {
      this.displayMessage = this.genericValidator.processMessages(this.manualBookingForm);
    });
  }

  getAccommodations() {
    if (this.manualBookingForm.controls.student.valid && this.manualBookingForm.controls.course.valid) {
      const courseSection = this.manualBookingForm.controls.course.value;

      const accommodationSearch = new AccommodationSearch(
                                                          this.manualBookingForm.controls.student.value.rosiStudentId,
                                                          courseSection.code,
                                                          courseSection.sectionCode,
                                                          courseSection.sessionCode,
                                                          courseSection.teachMethod,
                                                          courseSection.sectionNo);
      // Get accommodations
      this.modelService.getAccommodationsByStudentAndCourse(accommodationSearch)
        .subscribe(response => {
          if (response) {
            this.expireDate = response.expiryDate;
            this.optionalAccommodations = [];
            this.nonOptionalAccommodations = [];
            response.options[Object.keys(response.options)[0]].forEach(accommodation => {
              if (accommodation.optional) {
                this.optionalAccommodations.push(new AccommodationSelection( accommodation, true));
              } else {
                this.nonOptionalAccommodations.push(accommodation);
              }
            });
          }
      });
    }
  }

  reset(): void {

    // reset the value of the properties that were selected/decided by user input, which includes all the properties
    // of these components except test types and the propertied which values are assigned in the callback function
    // of this.modelService.getTestTypes()
    this.courseInfo = "";
    this.instructorsFullNames = "";
    this.selectedCourseSectionObj = null;
    this.courses = [];
    this.testDefinitionsByCourse = [];
    this.testDefinitionsFilteredByTestSubType = [];
    this.newTestDefinition = false;
    this.nonOptionalAccommodations = [];
    this.optionalAccommodations = [];
    this.expireDate = null;
    this.subtypes = [];
    this.assessmentTypes = [];
    this.bookingBeingCommitted = false;

    this.manualBookingForm.reset();
    this.testDefinitionId.reset();
    this.basicTestDefinition.reset();
  }

  isSubmitBookingButtonDisable(): boolean {
    return (!(this.manualBookingForm.valid && (this.testDefinitionId.valid || this.basicTestDefinition.valid)
      && (this.nonOptionalAccommodations.length > 0 || this.optionalAccommodations.length > 0 ) ) ) || this.bookingBeingCommitted;
  }
}
