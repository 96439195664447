import {Injectable} from '@angular/core';
import {ModelService} from "../../model/model.service";
import {StudentInfo} from "../../model/types/studentInfo";
import {Observable} from "rxjs";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class StudentProfileResolverService  {

  constructor(private model: ModelService) { }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StudentInfo> | StudentInfo {
    const atsStudentId: string = route.params.id;

    // Important:
    // Do not subscribe to the getStudentInfoByRosiId(); the resolver will take care of that and provide the router with the fetched data.
    // Router config: { path: [path], component: [component], resolve: {model: [resolver]} }
    // Data from the getStudentInfo() will be passed to the 'model' from configured in config: resolve: {model: [resolver]}.
    return this.model.getStudentInfo(atsStudentId);
  }
}
