<div class="card-body">
  <h3 class="card-title">Current Assessment Writing Details</h3>
  <div class="card-text">
    <div class="row">
      <div class="col"><strong>Start Date</strong></div>
      <div class="col"><strong>Start Time</strong></div>
      <div class="col"><strong>Assessment Location</strong></div>
    </div>
    <div class="row">
      <div class="col">{{testDef.assessmentScheduleDetails.assessmentDate}}</div>
      <div class="col">{{testDef.assessmentScheduleDetails.assessmentTime}}</div>
      <div class="col">{{assessmentLocation}}</div>
    </div>

    <br/>
    <div class="row">
      <div class="col"><strong>Duration</strong></div>
      <div class="col"><strong>End Time</strong></div>
      <div class="col"><strong>Class Location</strong></div>
    </div>
    <div class="row">
      <div class="col">{{testDef.assessmentScheduleDetails.assessmentDuration}} minutes</div>
      <div class="col">{{testDef.assessmentScheduleDetails.assessmentEndTime}}</div>
      <div class="col">{{testDef.assessmentLocationDetails.classLocation}}</div>
    </div>
  </div>
  <div *ngIf="showEditButton">
    <button class="btn btn-sm btn-primary" (click)="editDetails()">Edit Details</button>
  </div>
</div>
