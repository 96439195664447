Design mode root page
<br />

<a routerLink="checkbox-drop-down-demo">Checkbox Drop down demo</a>
<hr />

<h5> Please click below button to select building and seat.</h5>
<hr />

<div >
  <button (click)="showSuccess()" type="button" >
    Toast: Success
  </button>
</div>
