import {Component, OnInit, Output} from '@angular/core';
import {StudentInfo} from "../model/types/studentInfo";
import {ActivatedRoute} from "@angular/router";
import {LogService} from "../common/services/log-service.service";
import {StudentEmergencyContact} from "../model/types/studentEmergencyContact";
import {Address} from "../model/types/address";

@Component({
  selector: 'app-app-student-profile',
  templateUrl: './app-student-profile.component.html',
  styleUrls: ['./app-student-profile.component.scss']
})
export class AppStudentProfileComponent {

  studentInfo: StudentInfo;

  constructor(private activatedRoute: ActivatedRoute) {
    // The component gets the data from the route (ActivatedRoute) 'data.model'.
    // The 'model' from 'data.model' is from module config:  { path: [path], component: [component], resolve: {model: [resolver]} }
    this.activatedRoute.data.subscribe((data: {model: StudentInfo}) => {
      this.studentInfo = data.model;
    });
  }

}
