<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7 ">
<!--    <div>-->
<!--      <app-breadcrumb></app-breadcrumb>-->
<!--    </div>-->
    <div class="masthead-header">
      <h1>Designates</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>
<div class="designates-dashboard">
<!--  <h3 class="margin-top-30-px">Designates</h3>-->

  <a class="btn btn-primary" [ngClass]="{'disabled': !isUserDesginatesAdmin}" routerLink="/assign-designate" >Assign Designates</a>

  <br/><br/>
  <h4>Division/Unit Designates</h4>

  <!-- OrgDesigateTableComponent component -->
  <app-org-desigate-table [isUserDesginatesAdmin]="isUserDesginatesAdmin"></app-org-desigate-table>

  <br/><br/>
  <h4>Course Designates</h4>

  <!-- CourseDesigateTableComponent component -->
  <app-course-desigate-table [isUserDesginatesAdmin]="isUserDesginatesAdmin"></app-course-desigate-table>

</div>
