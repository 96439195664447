import {Component, Input, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {ModelService} from '../model/model.service';
import {DatePipe} from '@angular/common';
import {StudentBooking} from "../model/types/student-booking";
import {StudentInfo} from "../model/types/studentInfo";

@Component({
  selector: 'app-breadcrumb',
  templateUrl: './app-breadcrumb.component.html',
  styleUrls: ['./app-breadcrumb.component.scss']
})
export class AppBreadcrumbComponent implements OnInit {

  @Input()
  studentInfo: StudentInfo;

  breadcrumbs: string[] = [];

  @Input()
  booking: StudentBooking;

  constructor(private router: Router, private modelService: ModelService, private datePipe: DatePipe) {
    this.updateBreadcrumbs(this.router);
  }

  ngOnInit() {
  }

  updateBreadcrumbs(router: Router) {
    router.events.subscribe(
      e => {
        if (e instanceof NavigationEnd) {
        this.convertURLtoBreadcrumbs(router.url);
        }
      }
    );
  }

  convertURLtoBreadcrumbs(url: string) {
    // Resetting the list every time url changes.
    this.breadcrumbs = [];
    this.breadcrumbs.push('Bookings Dashboard');
    if (url.length > 0) {
      url.trim();
      const pieces: string[] = url.slice(1).split('/');

      switch (pieces[0]) {
        case 'student-profile':
          setTimeout(() => {
            this.breadcrumbs.push(this.studentInfo.firstName + ' ' + this.studentInfo.lastName);
          }, 0);
          break;
        case 'student-booking':
          setTimeout(() => {
            this.breadcrumbs.push( this.booking.testType
              + (this.booking.scheduledWriting.startDate ? (" ("
                + this.datePipe.transform(this.booking.scheduledWriting.startDate, "yyyy-MM-dd") + ")") : ' ')
              + ": " + this.booking.studentInfo.firstName + " " + this.booking.studentInfo.lastName);
          }, 0);
          break;
        case 'student-creation':
          this.breadcrumbs.push('Student Creation');
          break;
        case 'manual-booking':
           this.breadcrumbs.push('Manual Booking Creation');
           break;
        case 'settings':
          this.breadcrumbs[0] = 'Settings';
          // tslint:disable-next-line:no-conditional-assignment
          if (pieces[1] === "designate-dashboard") {
            this.breadcrumbs.push('Assign Designates');
          }
          if (pieces[1] === "student-creation") {
            this.breadcrumbs.push('Create Student');
          }
          break;

        default :
          if (this.breadcrumbs[0] != null) {
            this.breadcrumbs.pop();
          }
      }
    }
  }

}

