import {Component, Inject, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {Person} from '../../model/types/person';

import {
  PhonebookPerson,
  PhonebookPhone,
  PhonebookPosition,
  PhonebookSearch
} from "../../model/types/phonebook/PhonebookSearch";
import {environment} from "../../../environments/environment";
import {ActivatedRoute} from "@angular/router";
import {PHONEBOOK_API, PhonebookApi} from "../../common/services/phonebook-api";

@Component({
  selector: 'app-student-advisor',
  templateUrl: './app-student-advisor.component.html',
  styleUrls: ['./app-student-advisor.component.scss']
})
export class AppStudentAdvisorComponent implements OnChanges {

  @Input()
  advisor: Person;

  public advisorPhoneNumber: string;

  constructor(@Inject(PHONEBOOK_API) private phonebookApi: PhonebookApi) {

  }

  ngOnChanges(changes: SimpleChanges): void {

    if (this.advisor && this.advisor.email) {
      this.phonebookApi.getPhonebookSearchByEmail(this.advisor.email).subscribe(value => {
          if (value.personList && value.personList.length) {
            const person: PhonebookPerson = value.personList[0];

            if (person.positionList && person.positionList.length) {

              person.positionList.some((position: PhonebookPosition) => {
                if (position.phoneList) {

                  return position.phoneList.some((phonebookPhone: PhonebookPhone) => {
                    if (phonebookPhone.type === "Office")  {
                      this.advisorPhoneNumber = phonebookPhone.phoneNum;
                      return true;
                    }
                    else {
                      return false;
                    }
                  });

                }
                else {
                  return false;
                }
              });

            }
          }
        });
    }
  }

}
