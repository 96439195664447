import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {Accommodation} from "../../../model/types/accommodation";
import {AccommodationSelection} from "../../../model/types/accommodation-selection";
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormArray,
  UntypedFormBuilder,
  UntypedFormGroup,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";

@Component({
  selector: 'app-accom',
  templateUrl: './accom.component.html',
  styleUrls: ['./accom.component.scss'],
  providers: [
    {
      // make it visible to the form APIs
      provide: NG_VALUE_ACCESSOR,
      useExisting: AccomComponent,
      multi: true
    },
    {
      // make the validator visible to the form APIs
      provide: NG_VALIDATORS,
      useExisting: AccomComponent,
      multi: true
    }
  ]
})
export class AccomComponent implements OnInit, OnChanges, ControlValueAccessor, Validator {

  @Input()
  expiryDate: Date;

  @Input()
  nonOptionalAccommodations: Accommodation[];

  @Input()
  optionalAccommodations: AccommodationSelection[];

  form: UntypedFormGroup;

  constructor(private fb: UntypedFormBuilder) { }

  ngOnInit(): void {

    this.form = new UntypedFormGroup({
      optionalAccom: this.fb.array([
        // this._optionalAccomGroup()
      ])
    });
  }

  ngOnChanges(changes: SimpleChanges): void {
    // const nonOptAccom: SimpleChange = changes['nonOptionalAccommodations'];
    const optAccom: SimpleChange = changes['optionalAccommodations'];

    if (optAccom && optAccom.currentValue && optAccom.currentValue !== optAccom.previousValue) {
      // clear the array controls before rebuilding it;
      if (this.form) {
        this.optionalAccom.clear();

        // Add optional accommodations form controls
        optAccom.currentValue.forEach((oa: AccommodationSelection) => {
          this.addOptionalAccom(oa.accommodation, oa.isSelected);
        });
      }
    }
  }

  optionalAccommChanged(accommCtrl) {
    this.optionalAccommodations.forEach((oa: AccommodationSelection) => {
      if (oa.accommodation.type === accommCtrl.value.type) {
        oa.isSelected = accommCtrl.value.isSelected;
      }
    });
  }

  get optionalAccom() {
    return this.form.get('optionalAccom') as UntypedFormArray;
  }

  addOptionalAccom(accommodation: Accommodation, isSelected: boolean) {
    this.optionalAccom.push(this._optionalAccomGroup(accommodation, isSelected));
  }

  _optionalAccomGroup(accom: Accommodation, isSelected: boolean): UntypedFormGroup {
    return this.fb.group({
      id: [accom.id],
      type: [accom.type],
      clockworkId: [accom.clockworkId],
      optional: [accom.optional],
      description: [accom.description],
      category: [accom.category],
      selected: [isSelected]
    });
  }

  // callback methods
  onChange: any = () => {};
  onTouched: any = () => {};

  // - CVA: Write value to display in view (model -> View)
  writeValue(value: any): void {
    if (value) {
      this.form.setValue(value, {emitEvent: false});
    }
    if (value === null) {
      this.form.reset();
    }
  }

  // - CVA: Callback method for value changes in DOM (View -> Model)
  //   (you can save a callback function - fn so you can call when value changes on the DOM side).
  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.form.valueChanges.subscribe(fn);
  }

  // - CVA: Callback method for toggling "touched" property (View -> Model); usually onBlur.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // - CVA: Enable/disable programmatically element in view (Optional method)
  setDisabledState(isDisabled: boolean): void {
    // isDisabled ? this.form.disable() : this.form.enable();
  }

  // -- Validator interface methods --

  // - Validator:
  validate(control: AbstractControl): ValidationErrors | null {
    return this.form.valid ? null : { form: {valid: false }};
  }

  // - Validator:
  registerOnValidatorChange(fn: () => void): void {
  }

}
