<div class="emailing-filter">

  <!-- Row 1: labels -->
  <div class="row margin-top-2-rem align-items-center">
    <!-- Filter by Course, Section -->
    <div class="col-2">
      <label class="form-label" for="course">Filter by Course, Section</label>
    </div>
    <!-- Filter by ATS Test Definition ID -->
    <div class="col-2">
      <label class="form-label" for="testDefId">Filter by ATS Test Definition ID</label>
    </div>
  </div>

  <!-- Row 1: controls -->
  <div class="row align-items-top align-items-center">
    <!-- control: Course, Section -->
    <div class="col-2">
      <input id="course" class="form-control" type="text" [formControl]="courseCtrl"
             placeholder="E.g.: ECO101H LEC0101">
    </div>
    <!-- control: ATS Test Definition ID -->
    <div class="col-2">
      <input id="testDefId" class="form-control" type="text" [formControl]="atsTestDefIdCtrl"
             placeholder="E.g.: 1234">
    </div>
  </div>

  <!-- Row 2: labels -->
  <div class="row margin-top-2-rem align-items-center">
    <!-- Filter by Number of Bookings -->
    <div class="col-2">
      <label class="form-label" for="numberOfBookings">Filter by Number of Bookings</label>
    </div>
    <!-- Filter by CIS Submission Status -->
    <div class="col-2">
      <label class="form-label" for="cisSubmissionStatus">Filter by CIS Submission Status</label>
    </div>
    <!-- Filter by Last Email Sent -->
    <div class="col-2">
      <label class="form-label" for="lastEmailSent">Filter by Last Email Sent</label>
    </div>
  </div>

  <!-- Row 2: controls -->
  <div class="row align-items-top align-items-center">
    <!-- control: Number of Bookings -->
    <div class="col-2">
      <select class="form-select" area-label="Select options" [formControl]="numberOfBookingsCtrl" id="numberOfBookings">
        <option value=''>All Options</option>
        <option value='0'>0 Bookings</option>
        <option value='1'>1 or More Bookings</option>
      </select>
    </div>
    <!-- control: CIS Submission Status -->
    <div class="col-2">
      <select class="form-select" area-label="Select options" [formControl]="cisSubmissionStatusCtrl" id="cisSubmissionStatus">
        <option value=''>All Statuses</option>
        <option *ngFor="let cisSubmissionStatusVal of Utils.cisSubmissionStatusVals" [ngValue]="cisSubmissionStatusVal">
          {{Utils.cisSubmissionStatusMap.get(cisSubmissionStatusVal)}}
        </option>
      </select>
    </div>

    <!-- control: Last Email Sent -->
    <div class="col-2">
      <select class="form-select" area-label="Select options" [formControl]="instructorEmailStatusCtrl" id="lastEmailSent">
        <option value=''>All Options</option>
        <option *ngFor="let emailStatusVal of Utils.emailReminderVals" [ngValue]="emailStatusVal">
          {{Utils.emailReminderMap.get(emailStatusVal)}}
        </option>
      </select>
    </div>

    <!-- button: Reset Filters -->
    <div class="row margin-top-2-rem align-items-center">
      <div class="col-2">
        <sis-button visualType="secondary" size="medium" (clicked)="resetFilter()">Reset Filters</sis-button>
      </div>
    </div>

  </div>

</div>

