import {Injectable} from '@angular/core';
import {OAuthService, OAuthStorage, AuthConfig} from 'angular-oauth2-oidc';
import {LogService} from './log-service.service';
import {AsyncSubject, BehaviorSubject, Observable, Observer} from 'rxjs';
import {UserRole} from "../../model/types/user-role";
import {Environment} from "../../../environment";

@Injectable({
  providedIn: 'root'
})
export class AuthenticationService {

  public loggedInSucceeded = false;

  private isDesginatesAdmin = false;

  private loggedInSubject: AsyncSubject<boolean> = new AsyncSubject<boolean>();

  private removeHttpTokenIndicatorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  private showLogOffPageIndicatorSubject: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);

  constructor(private oauthService: OAuthService,
              private storage: OAuthStorage,
              private logger: LogService) {

    const authConfig = new AuthConfig();
    // Url of the Identity Provider
    authConfig.issuer = 'https://login.microsoftonline.com/78aac226-2f03-4b4d-9037-b46d56c55210/v2.0';

    authConfig.responseType = 'code';

    // URL of the SPA to redirect the user to after login
    authConfig.redirectUri = window.location.origin + '/index.html';

    // The SPA's id. The SPA is registered with this id at the auth-server
    authConfig.clientId = Environment.clientId;

    // set the scope for the permissions the client should requestdd
    // The first three are defined by OIDC. The 4th is a usecase-specific one
    authConfig.scope =  'openid profile email';

    authConfig.strictDiscoveryDocumentValidation = false;

    this.oauthService.configure(authConfig);

  }

  public isLoggedIn(): boolean {
    return this.loggedInSucceeded;
  }

  public whenLoggedIn(): Observable<boolean> {
    return this.loggedInSubject;
  }

  public login(): Observable<boolean> {

    if (this.loggedInSucceeded) {
      return this.loggedInSubject;
    }

    const idToken = this.storage.getItem('id_token');

    let requiresLogin = true;

    if (idToken) {

      const expiration = this.oauthService.getIdTokenExpiration();

      const expirationDate = new Date(expiration);
      const now = new Date();

      if (now > expirationDate) {
          // token is expired.
          requiresLogin = true;
      }
      else {
        // can use the token.
        requiresLogin = false;
      }
    }

    if (requiresLogin) {
      this.oauthService.loadDiscoveryDocumentAndTryLogin()
        .catch(err => {
          this.logger.error('error logging in: ' + err);
        })
        .then(result => {
          if (!this.oauthService.hasValidAccessToken()) {
            this.oauthService.initCodeFlow();
          } else {
            // this.logger.debug(' id token = ' + this.oauthService.getIdToken());
            // this.logger.debug(' access token = ' + this.oauthService.getAccessToken());
            this.loggedInSucceeded = true;
            this.configureRoles();
            this.loggedInSubject.next(true);
            this.loggedInSubject.complete();
          }
        });
    }
    else {

      this.configureRoles();

      this.loggedInSucceeded = true;
      this.loggedInSubject.next(true);
      this.loggedInSubject.complete();
    }
    return this.loggedInSubject;
  }

  public isUserDesginatesAdmin(): boolean {
    return this.isDesginatesAdmin;
  }

  public isHttpHeadTokenRemoved(): Observable<boolean> {

    return this.removeHttpTokenIndicatorSubject.asObservable();
  }

  public setHttpHeadTokenRemoved(value: boolean): void {

    this.removeHttpTokenIndicatorSubject.next(value);
  }

  public doesShowLogOffPage(): Observable<boolean> {

    return this.showLogOffPageIndicatorSubject.asObservable();
  }

  public setShowLogOffPage(value: boolean): void {

    this.showLogOffPageIndicatorSubject.next(value);
  }

  /**
   *  destroy token and also remove token from Http head
   */
  public logOffAndRemoveHttpHeadToken(): void {
    this.setHttpHeadTokenRemoved(true);
    this.oauthService.logOut(true);
    this.loggedInSucceeded = false;
  }

  /**
   *  destroy token, remove token from Http head and show log off page
   */
  public logOffRemoveHttpTokenAndShowLogOffPage(): void {
    this.logOffAndRemoveHttpHeadToken();
    this.setShowLogOffPage(true);
  }

  public reLogin(): void {
    this.logOffAndRemoveHttpHeadToken();
    this.login();
  }

  private configureRoles() {
    const roles = "roles";
    const rolesDetail = this.oauthService.getIdentityClaims()[roles];
    if (Object.values(rolesDetail).indexOf(UserRole.designatesAdminRole) > -1) {
      this.isDesginatesAdmin = true;
    }
    else {
      this.isDesginatesAdmin = false;
    }
  }
}
