import {Component, Input, OnChanges, OnInit, SimpleChange} from '@angular/core';
import {StudentInfo} from '../../model/types/studentInfo';
import {PhoneType} from '../../model/types/phoneType';
import {Address} from "../../model/types/address";
import {LogService} from "../../common/services/log-service.service";
import {Phone} from "../../model/types/phone";

@Component({
  selector: 'app-student-information',
  templateUrl: './app-student-information.component.html',
  styleUrls: ['./app-student-information.component.scss']
})
export class AppStudentInformationComponent implements OnInit, OnChanges {

  @Input()
  studentInfo: StudentInfo;

  // formatted mailing addresses
  mailingAddress: string;
  // formatted permanent addresses
  permanentAddress: string;

  homePhone: Phone;
  mobilePhone: Phone;
  workPhone: Phone;
  otherPhone: Phone;


  constructor(private logger: LogService) {
  }

  ngOnInit(): void {

    if (this.studentInfo.phones) {
      this.studentInfo.phones.forEach(phone => {
       switch (phone.type) {
         case PhoneType.Mobile:
           this.mobilePhone = phone;
           break;
         case PhoneType.Home:
           this.homePhone = phone;
           break;
         case PhoneType.Work:
           this.workPhone = phone;
           break;
         case PhoneType.Other:
           this.otherPhone = phone;
           break;
       }
      });
    }
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}): void {
    // monitor input (StudentInfo) changes
    const changedInput = changes.studentInfo;

    // get mailing address and permanent address objects from input data when it arrives from async operation
    if (changedInput && changedInput.currentValue) {
      const mailingAddr: Address = changedInput.currentValue.mailingAddress;
      const permanentAddr: Address  = changedInput.currentValue.permanentAddress;

      // format the addresses for display
      this.mailingAddress = !mailingAddr ? '' : this.formatAddress(mailingAddr);
      this.permanentAddress = !permanentAddr ? '' : this.formatAddress(permanentAddr);
    }
  }

  formatAddress(address: Address): string {
    if (!address)  { return ''; }

    let addr = '';
    addr += !address.streetAddress ? '' : address.streetAddress;
    addr += !address.postalCode ? '' : ', ' + address.postalCode;
    addr += !address.city ? '' : ', ' + address.city;
    addr += !address.country ? '' : ', ' + address.country;

    return addr;
  }

  getContent(phone: Phone): string {
    let numberEnt = "";
    if (phone.extension) {
      numberEnt = phone.number + ": " + phone.extension;
    } else {
      numberEnt = phone.number;
    }

    let detailMsg = "";
    if (phone.detailedMsg) {
      detailMsg = "(Detailed messages OK)";
    }

    let note = "";
    if (phone.note) {
      note = "<br><Strong>Note: </Strong>" + phone.note;
    }

    return numberEnt + detailMsg + note;
  }

}
