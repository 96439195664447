import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator,
  ValidatorFn,
  Validators
} from "@angular/forms";
import {NgbTimeAdapter, NgbTimepickerConfig} from "@ng-bootstrap/ng-bootstrap";
import {TimepickerAdapterService} from "../../services/timepicker-adapter.service";

/**
 * Custom Form Control (by using ControlValueAccessor - CVA).
 * CVA is a bridge between View and Model:
 *      View (input type='text') <---CVA---> Model (FormControl)
 *
 * Note: We need to provide this component (RfTimepickerComponent) as an NG_VALUE_ACCESSOR
 *       in order for the form control directives like ngModel or formControlName to
 *       see our value accessor. ngModel will inject the value accessor token.
 *
 *                 NgControl
 *                    |
 *      ----------------------------------
 *     |             |                   |
 *  NgModel  FormControlDirective  FormControlName
 */
@Component({
  selector: 'app-rf-timepicker',
  templateUrl: './rf-timepicker.component.html',
  styleUrls: ['./rf-timepicker.component.scss'],
  providers: [
    NgbTimepickerConfig,
    {
      provide: NgbTimeAdapter,
      useClass: TimepickerAdapterService
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RfTimepickerComponent,
      multi: true
    },
    {
      provide: NG_VALIDATORS,
      useExisting: RfTimepickerComponent,
      multi: true
    }
  ]
})
export class RfTimepickerComponent implements OnInit,  ControlValueAccessor, Validator {

  @Input() isRequired = true; // set default 'true'

  time: UntypedFormControl = new UntypedFormControl('');

  constructor(config: NgbTimepickerConfig) {

    // Inject the value accessor (works)
    // - sed when injecting @Self() public controlDir: NgControl in the constructor. No need of providers[] in that case.
    // controlDir.valueAccessor = this;

    // timepicker configuration
    config.meridian = true;
    config.spinners = false;
    config.hourStep = 1;
    config.minuteStep = 10;
  }

  ngOnInit(): void {

    // Inject the validators (doesn't work)
    // - used when injecting @Self() public controlDir: NgControl in the constructor. No need of providers[] in that case.
    // const control = this.controlDir.control;
    // const validators: ValidatorFn[] = control.validator ? [control.validator, Validators.required] : [Validators.required];
    // control.setValidators(validators);
    // control.updateValueAndValidity();

    // Set up validators conditionally
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    this.time.setValidators(validators);
    this.time.updateValueAndValidity();
  }


  // -- ControlValueAccessor interface methods --

  // callback methods
  onChange: any = () => {};
  onTouched: any = () => {};

  // - CVA: Write value to display in view (model -> View)
  writeValue(value: any): void {
    if (value) {
      this.time.setValue(value);
    }
    if (value === null) {
      this.time.reset();
    }
  }

  // - CVA: Callback method for value changes in DOM (View -> Model)
  //   (you can save a callback function - fn so you can call when value changes on the DOM side).
  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.time.valueChanges.subscribe(fn);
  }

  // - CVA: Callback method for toggling "touched" property (View -> Model); usually onBlur.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // - CVA: Enable/disable programmatically element in view (Optional method)
  setDisabledState(isDisabled: boolean): void {
  }

  // -- Validator interface methods --

  // - Validator:
  validate(control: AbstractControl): ValidationErrors | null {
    return this.time.valid ? null : { time: {valid: false }};
  }

  // - Validator:
  registerOnValidatorChange(fn: () => void): void {
  }

}
