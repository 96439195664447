export enum ValidationRuleType {
  AM_ONLY,
  PM_ONLY,
  END_6PM,
  START_11AM,
  START_12PM,
  DOUBLE_BOOKINGS,
  ONE_BOOKING_PER_DAY,
  TWO_HOURS_BETWEEN_BOOKINGS,
  SEAT_DOUBLE_BOOKED= 'SEAT_DOUBLE_BOOKED'
}
