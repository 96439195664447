// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  mode: 'local',
  restUrl: `http://${location.hostname}:4200/ats`,
  restHost: 'api-np.easi.utoronto.ca',
  // restUrl: 'https://api-np.easi.utoronto.ca/ats/dev',
  clientId: 'df8d1b74-9178-4881-a160-9b7c62f98fdd',
  sharepointBaseUrl: 'https://utoronto.sharepoint.com/:f:/r/sites/test-TES/Shared%20Documents/DEV/'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
