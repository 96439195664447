import { Injectable } from '@angular/core';
import {ModelService} from "../../model/model.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {WholeTestDefinition} from "../../model/types/whole-test-definition";

@Injectable({
  providedIn: 'root'
})
export class TestDefinitionResolverService {

  constructor(private modelService: ModelService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<WholeTestDefinition> | WholeTestDefinition {
    const testDefinitionId: number = route.params.id;
    return this.modelService.getTestDefinitionByTestDefinitionId(testDefinitionId);
  }
}
