<!-- CARD: Test Definition Details -->
<div class="card-body">
  <h3 class="card-title">Test Definition Details: Exam ID {{testDef.clockworkExamId}}</h3>
  <div class="card-text">
    <div ><strong>Test Type: &nbsp;</strong>{{testType}}</div>
    <div ><strong>Assessment Type: </strong>
      {{testSubtype}}</div>
    <div><strong>Course, Section: </strong>{{courseAndSection}}</div>
    <div><strong>Faculty: &nbsp;</strong>{{faculty}}</div>
    <br/>
  </div>
  <div *ngIf="showEditButton">
    <button class="btn btn-sm btn-primary" (click)="editType()" [disabled]="!isTypeEditable" type="submit"
            [ngClass]="{'disabled': !isTypeEditable}">
      <span *ngIf="!isTypeEditable" ngbTooltip="{{typeNotEditableMessage}}" placement="auto">Edit Type</span>
      <span *ngIf="isTypeEditable">Edit Type</span>
    </button>
  </div>
</div>
