import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {LogService} from './log-service.service';

/**
 * Service: DurationpickerAdapterService.
 * Desc:
 *   This service is used by the [DurationpickerComponent] for converting
 *   the data model to and from the format required by the ngb-timepicker.
 */
@Injectable({
  providedIn: 'root'
})
export class DurationpickerAdapterService implements NgbTimeAdapter<number> {

  HOUR = 60;

  constructor(private logger: LogService) { }

  /**
   * NgbTimeAdapter  method.
   * Converts from number (i.e. 90 minutes) to timepicker structure (i.e. {hour: 1, minute: 30: second: 00}).
   */
  fromModel(value: number): NgbTimeStruct {
    if (!value) {
      return null;
    }

    // this.logger.debug(`${value}`, `${this.constructor.name}.fromModel()`);

    return this.toHoursMins(value);
  }

  /**
   * NgbTimeAdapter method .
   * Converts from timepicker structure (i.e. {hour: 1, minute: 30: second: 00}) to number (i.e. 90).
   */
  toModel(value: NgbTimeStruct): number {
    if (!value) {
      return null;
    }

    // this.logger.debug(`${JSON.stringify(value)}`, `${this.constructor.name}.toModel()`);

    return this.toMins(value);
  }

  private toHoursMins(mins) {
    if (!mins) {
      return {hour: 0, minute: 0, second: 0};
    }

    return {
      hour: Math.floor(mins / this.HOUR),
      minute:  mins % this.HOUR,
      second: 0
    };
  }

  private toMins(value: NgbTimeStruct): number {
    if (!value) {
      return null;
    }

    return value.hour !== null && value.minute !== null ? ((value.hour || 0) * this.HOUR) + (value.minute || 0) : null;
  }
}
