<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{modalTitle}}
 </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  Are you sure you want to cancel the following booking:
  <ul>
    <li>Course, Section: {{booking.courseString}}</li>
    <li>Student: {{booking.studentInfo.firstName}} {{booking.studentInfo.lastName}}</li>
    <li>Class Writing Start Date: {{booking.classWriting.startDate}}</li>
    <li>Class Writing Start Time: {{convertTimeToDate(booking.classWriting.startTime) | date: 'h:mm a'}}</li>
    <li>Scheduled Start Date: {{booking.scheduledWriting.startDate}}</li>
    <li>Scheduled Start Time: {{convertTimeToDate(booking.scheduledWriting.startTime) | date: 'h:mm a'}}</li>
  </ul>
  <strong>{{cancelUncancelReason}}</strong>
  <div class="">
    <textarea [formControl]="bookingNotes" placeholder="{{placeholder}}"></textarea>
  </div>
</div>
<!-- Buttons -->
<div class="modal-footer">
  <!-- Cancel/Undo Cancellation booking button. Possible values: 'Booked', 'Cancelled' -->
  <button type="button" class="btn btn-primary" (click)="activeModal.close(modalButtonVal)">
    {{modalButton}}
  </button>&nbsp;
  <!-- Close modal button -->
  <button type="button" class="btn btn-link" (click)="activeModal.close('close')">
    Close
  </button>&nbsp;
</div>


