import { Component, OnInit } from '@angular/core';
import {WholeTestDefinition} from "../model/types/whole-test-definition";
import {ActivatedRoute, Router} from "@angular/router";
import {LogService} from "../common/services/log-service.service";
import {AccommodationInstructions} from "../model/types/accommodation-instructions";
import {TestDefinition} from "../model/types/test-definition";
import {ConsentMessage} from "../common/types/consent-message";
import {Schedule} from "../model/types/schedule";
import {ActivityLogType} from "../common/types/activity-log-type.enum";

@Component({
  selector: 'app-app-test-definition',
  templateUrl: './app-test-definition.component.html',
  styleUrls: ['./app-test-definition.component.scss']
})
export class AppTestDefinitionComponent implements OnInit {

  wholeTestDefinition: WholeTestDefinition;
  testDefinition: TestDefinition;
  testDetails: AccommodationInstructions;

  querrusConsentMessage = ConsentMessage.Quercus_Consent_Yes;
  querrusNotConsentMessage = ConsentMessage.Quercus_Consent_No;

  classWriting: Schedule;

  activityLogType: ActivityLogType = ActivityLogType.TEST_DEFINITION;


  // Do not remove the tabs from the DOM when navigating between them.
  removeTabOnHide = false;

  constructor(private activeRoute: ActivatedRoute, private logger: LogService) {

    this.activeRoute.data.subscribe(data => {
      this.wholeTestDefinition = data.model as WholeTestDefinition;
      if (this.wholeTestDefinition) {
        this.testDefinition = this.wholeTestDefinition.testDefinition;
        this.testDetails = this.wholeTestDefinition.testDetails;

        if (this.testDefinition) {
          this.classWriting = new Schedule(this.testDefinition.classStartTime,
            this.testDefinition.classEndTime, this.testDefinition.testDuration, null);
          this.classWriting.startDate = this.testDefinition.classStartDate;
          this.classWriting.endDate = this.testDefinition.classEndDate;
          //  this.classWriting.assessmentTimingInd = this.testDetails.assessmentTimingInd.trim();
        }
      }
    });
  }

  ngOnInit(): void {
  }

  getCourseDesc(): string {
    let desc = "";
    if (this.testDefinition) {
      desc = this.testDefinition.academicActivityCode + " " + this.testDefinition.sectionCode
        + ", " + this.testDefinition.teachMethod + " " + this.testDefinition.sectionNr;
    }

    return desc;
  }

}
