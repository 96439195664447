import {DateOption} from "./dateOption";
import {Column} from "./column";
import {PeriodCourseSelector} from "../../model/types/periodCourseSelector";

export class DashboardStoredData {
  periodCourseSelector: PeriodCourseSelector;
  textFilter: string;
  displayedColumns: Column[];

  /*regularStatusFilter: string;
  issueStatusFilter: string;
  iconStatusFilter: string;*/

  constructor() {
  }
}
