import {Component, Input} from '@angular/core';
import {InstructorEmailContacts} from "../instructor-email-contacts";
import {TestDefinition} from "../../model/types/test-definition";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";

@Component({
  selector: 'app-test-def-info',
  templateUrl: './test-def-info.component.html',
  styleUrls: ['./test-def-info.component.scss']
})
export class TestDefInfoComponent {

  @Input()
  testDefinition: TestDefinition;

  @Input()
  displayAsTitle: boolean = true;

}
