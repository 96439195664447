import {AfterViewChecked, ChangeDetectorRef, Component, Inject, Input, OnDestroy, OnInit} from '@angular/core';
import {AbstractControl, UntypedFormArray, UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ModelService} from "../../model/model.service";
import {ActivityOfferOrg} from "../../model/types/activity-offer-org";
import {EmailPreference} from "../../common/types/email-preference.enum";
import {AssignmentLevel} from "../../common/types/assignment-level.enum";
import {LogService} from "../../common/services/log-service.service";
import {Designate} from "../../model/types/designate";
import {DesignateAssignment} from "../../model/types/designate-assignment";
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {CustomValidators} from "../../validator/custom-validators";
import {Subscription} from "rxjs";
import {PhonebookPerson, PhonebookPhone, PhonebookPosition} from "../../model/types/phonebook/PhonebookSearch";
import {PHONEBOOK_API, PhonebookApi} from "../../common/services/phonebook-api";
import {ToastService} from "@easi-sis/core";

/**
 * This component is used as Edit Modal as well.
 */

@Component({
  selector: 'app-assign-designate',
  templateUrl: './assign-designate.component.html',
  styleUrls: ['./assign-designate.component.scss']
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AssignDesignateComponent implements OnInit, AfterViewChecked, OnDestroy {

  /* When used as modal, this input comes from the DesignateDashboardComponent. */
  @Input()
  desigAssignment: DesignateAssignment;

  @Input()
  showInModal = false;

  @Input()
  isUserDesginatesAdmin;

  // The template form holding all FormControls.
  @Input()
  designateForm: UntypedFormGroup;

  // Designation Expiry date
  minDesignationExpiryDate;

  formSubmitted = false;

  // Activity Offer Orgs
  undergradOrgs: ActivityOfferOrg[];
  gradOrgs: ActivityOfferOrg[];
  OrgsSelectedByFaculties: ActivityOfferOrg[];

  // Faculties
  undergradFaculties: string[];
  gradFaculties: string[];

  // Undergraduate or graduate faculties (based on the Level of Study selected).
  faculties: string[];
  // The departments corresponding to a selected faculty.
  departments: string[];
  coursePrefixes: string[];

  // Enumerations
  emailPrefEnum = EmailPreference;
  assignmentLevelEnum = AssignmentLevel;

  // All other subscriptions will be added to this one;
  // by unsubscribing this one all the children subscription will be unsubscribed
  private subscription: Subscription = new Subscription();

  constructor(@Inject(PHONEBOOK_API) private phonebookApi: PhonebookApi,
              private formBuilder: UntypedFormBuilder,
              public activeModal: NgbActiveModal,
              private readonly changeDetectorRef: ChangeDetectorRef,
              private toastService: ToastService,
              private modelService: ModelService,
              private logger: LogService) {
  }

  ngAfterViewChecked(): void {
    // Added to get rid of the error when dynamically change the value of the end date
    this.changeDetectorRef.detectChanges();
  }

  ngOnInit(): void {

    // Set minimum date (note: month start from 0 in javascript date)
    const now = new Date();
    this.minDesignationExpiryDate = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};


    this.designateForm = this.formBuilder.group({
      assignmentLevel: ['', Validators.required],
      course: ['', CustomValidators.conditionalValidator(
        () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.COURSE, Validators.required)],
      org: this._orgGroup(),
      coursePrefix: [''],
      designates: this.formBuilder.array([this._designateGroup()])
    });

    // Set the form value to be displayed in the modal
    if (this.showInModal) {
      this.designateForm.setValue(this.desigAssignment);
    }

    this.getActivityOfferOrgs();

    // Necessary in order to trigger conditional validation when the radio-button is toggled
    const subscription$: Subscription = this.designateForm.get('assignmentLevel').valueChanges.subscribe(value => {
      console.log("--> assignmentLevel val: " + value);
      this.logger.debug(`=> assignmentLevel changed. Value: ${value}`, `${this.constructor.name}.ngOnInit()`);

      // When the course/department is changed, reset the course or department values,
      // as they are kept in the model from previous selections and you have to type to update the view
      if (value === AssignmentLevel.COURSE) {
        this.designateForm.get('course').setValue('');
      } else if (value === AssignmentLevel.DEPARTMENT) {
        this.designateForm.get('org.facultyCode').setValue('');
        this.designateForm.get('org.departmentCode').setValue('');
        this.designateForm.get('org.graduateInd').setValue('');
      }

      // Needed to be called in order to update the conditional validators for these fields
      this.designateForm.get('course').updateValueAndValidity();
      this.designateForm.get('org.facultyCode').updateValueAndValidity();
      this.designateForm.get('org.departmentCode').updateValueAndValidity();
      this.designateForm.get('org.graduateInd').updateValueAndValidity();
    });

    // add the child subscription to current one
    this.subscription.add(subscription$);

    this._updateEndDateCtrl();
  }

  ngOnDestroy(): void {
    // Unsubscribe all children subscriptions
    this.subscription.unsubscribe();
  }


  /** Sets the validators and recalculates the value and validation status for designation expiry control */
  _updateEndDateCtrl() {
    const designationExpiryInd = 'designationExpiryInd';
    const endDate = 'endDate';
    const designatesArray = this.designateForm.get('designates') as UntypedFormArray;

    designatesArray.controls.forEach((formGroup: UntypedFormGroup) => {
      const desigExpIndCtrl: AbstractControl = formGroup.controls[designationExpiryInd];
      const endDateCtrl: AbstractControl = formGroup.controls[endDate];

      // Set the validators
      endDateCtrl.clearValidators();
      endDateCtrl.setValidators([Validators.minLength(10),
                                 CustomValidators.conditionalValidator(() => desigExpIndCtrl.value === true, Validators.required)]);

      // Recalculates the value and validation status of the control.
      const subscription$: Subscription = desigExpIndCtrl.valueChanges.subscribe((value: boolean) => {
        if (value === false) {
          endDateCtrl.setValue('');
        }
        endDateCtrl.updateValueAndValidity();
      });
      // add the child subscription to current one
      this.subscription.add(subscription$);
    });
  }

  // Faculty/Department group
  _orgGroup(): UntypedFormGroup {
    // ATS_ORG table fields
    return this.formBuilder.group({
      facultyCode: ['', CustomValidators.conditionalValidator(
        () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.DEPARTMENT, Validators.required)],
      departmentCode: ['', CustomValidators.conditionalValidator(
        () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.DEPARTMENT, Validators.required)],
      graduateInd: ['', CustomValidators.conditionalValidator(
        () => this.designateForm.get('assignmentLevel').value === this.assignmentLevelEnum.DEPARTMENT, Validators.required)],

      id: [''],
      version: ['']
    });
  }

  // Designate group
  _designateGroup(): UntypedFormGroup {

    const singleDesignateGroup = this.formBuilder.group({
      // ATS_DESIGNATE table fields
      utorId: ['', Validators.required],
      email: ['', CustomValidators.email()],

      utorIdInput: ['',
        CustomValidators.conditionalValidator(
          () => singleDesignateGroup.get('selectedByUtorIdNotByEmail').value === true, Validators.required)],
      emailInput: ['', [CustomValidators.email(),
        CustomValidators.conditionalValidator(
          () => singleDesignateGroup.get('selectedByUtorIdNotByEmail').value === false, Validators.required)]],

      // alternate phone number
      phoneNumber: ['', CustomValidators.phone()],
      // alternate email address
      emailAddress: ['', CustomValidators.email()],

      // - not shown in form
      id: [''],
      version: [''],
      // - read only in form
      firstName: [''],
      lastName: [''],
      phone: [''],
      invalidUtorIdMsg: [''],
      invalidEmailMsg: [''],
      selectedByUtorIdNotByEmail: [],

      // ATS_ORG_DESIGNATE and ATS_COURSE_SECTION_DESIGNATE tables common fields
      daRoleInd: ['', Validators.required],
      emailPreference: ['', Validators.required],
      effectiveDate: [''],
      designationExpiryInd: ['', Validators.required],
      endDate: [''], // designate valid until date (end date)

      // - not shown in form
      orgDesignateId: [''],
      courseDesignateId: [''],
      onlyEmailDesignate: [''],
      orgDesignateVersion: [''],
      courseDesignateVersion: ['']
    });

    singleDesignateGroup.get("selectedByUtorIdNotByEmail").valueChanges.subscribe(value => {
      singleDesignateGroup.get("utorIdInput").updateValueAndValidity();
      singleDesignateGroup.get("emailInput").updateValueAndValidity();
    });

    return singleDesignateGroup;
  }

  _filterOrgsByLevelOfStudy(orgs: ActivityOfferOrg[], levelOfStudy: string): ActivityOfferOrg[] {
    if (!Array.isArray(orgs) || !orgs.length || !levelOfStudy) {
      return [];
    }
    return orgs.filter(org => levelOfStudy === 'U' ? org.faculty !== 'SGS' : org.faculty === 'SGS');
  }

  // Returns message to display in toaster when saveDesignateAssignment() method succeeds.
  _getSaveToasterMsg(assignmentLevel: string) {
    const bindingLevel = (assignmentLevel === this.assignmentLevelEnum.COURSE) ? 'course' : 'unit' + '.';
    return 'Designate(s) have been assigned for this '.concat(bindingLevel);
  }

  /* Only a course or an org can be assigned designates, not both; nullify the other one. */
  _sanitizeFormData(form: UntypedFormGroup) {
    if (form.value.assignmentLevel === AssignmentLevel.COURSE) {
      form.value.org = null;
    } else if (form.value.assignmentLevel === AssignmentLevel.DEPARTMENT) {
      form.value.course = null;
    }
  }

  /**
   * Triggered when the Level of Study is selected or changed.
   * Find and set the faculties, base on the level of study (undergraduate or graduate faculties).
   * @param graduateInd The level of study (Undergraduate or Graduate).
   */
  selectFaculties(graduateInd) {
    // faculty and department drop-downs default values
    const resetVal = '';

    // refresh faculties and reset the selected faculty code
    this.faculties = (graduateInd === false) ? this.undergradFaculties : this.gradFaculties;
    this.designateForm.get('org.facultyCode').setValue(resetVal);

    // refresh departments and reset the selected department code
    const selectedFaculty =  this.designateForm.get('org.facultyCode').value;
    this.selectDepartments(selectedFaculty, graduateInd);
    this.designateForm.get('org.departmentCode').setValue(resetVal);
  }

  /**
   * Based on the faculty code and level of study selected in UI, find the faculty departments.
   * @param faculty       The selected faculty code (i.e. ARTSC);
   * @param graduateIndVal  The level of study (U - Undergraduate or G - Graduate);
   */
  selectDepartments(faculty: string, graduateIndVal: boolean): void {
    if (graduateIndVal) {
      this.OrgsSelectedByFaculties = this.gradOrgs.filter(org => org.coSecondaryOrgCode === faculty);
    } else {
      this.OrgsSelectedByFaculties = this.undergradOrgs.filter(org => org.faculty === faculty);
    }

    this.departments = Array.from(new Set(this.OrgsSelectedByFaculties.map((obj: ActivityOfferOrg) => obj.department))).sort();
  }

  selectCoursePrefixes(department: string) {
    const coursePrefixes = new Set<string>();
    this.OrgsSelectedByFaculties.filter(org => org.department === department)
      .forEach(org => org.coursePrefixes.forEach(coursePrefix => coursePrefixes.add(coursePrefix)));
    this.coursePrefixes = Array.from(coursePrefixes).sort();
  }

  /* Help method to used in template to get the value of the levelOfStudy. */
  get gradOrUndergrad(): string {
    const graduateIndVal = this.designateForm.get('org.graduateInd').value;
    return (graduateIndVal === true) ? 'Graduate' : (graduateIndVal === false) ? 'Undergraduate' : '';
  }

  get graduateIndVal(): boolean {
    return this.designateForm.get('org.graduateInd').value;
  }


  /**
   * Access the FormArray control.
   * Note:
   * Because the returned control is of the type AbstractControl, we need
   * to provide an explicit type to access the method syntax for the form array instance.
   */
  get designates(): UntypedFormArray {
    return this.designateForm.get('designates') as UntypedFormArray;
  }

  /** Adds a designated group to the designates array. */
  addDesignate(): void {
    this.designates.push(this._designateGroup());

    this._updateEndDateCtrl();
  }

  /** Removes a designated group form the designates array, based on the array index. */
  removeDesignate(index: number): void {
    this.designates.removeAt(index);
  }

  /**
   * Back-end call.
   * Retrieves the organizations (faculties and departments),
   * organizes them in undergraduate and graduates and sorts them.
   */
  getActivityOfferOrgs() {
    const subscription$: Subscription = this.modelService.getActivityOfferOrgs().subscribe(orgs => {
      // ActivityOfferOrg: Faculty, Department, Co-secondary org list
      this.undergradOrgs = this._filterOrgsByLevelOfStudy(orgs, 'U');
      this.gradOrgs = this._filterOrgsByLevelOfStudy(orgs, 'G');

      this.undergradFaculties = Array.from(new Set(this.undergradOrgs.map((obj: ActivityOfferOrg) => obj.faculty))).sort();
      this.gradFaculties = Array.from(new Set(this.gradOrgs.map((obj: ActivityOfferOrg) => obj.coSecondaryOrgCode))).sort();
    });
    // add the child subscription to current one
    this.subscription.add(subscription$);
  }

  /**
   * Back-end call.
   * Retrieves the designate from the back-end based on the utorId
   * and sets a validation message if the designate was not found.
   */
  validateDesignate(utorIdOrEmail, selectedByUtorIdNotByEmail, index: number) {

    // validate the utorId only if it's valid
    if (selectedByUtorIdNotByEmail && this.designateForm.get('designates.' + index + '.utorIdInput').invalid) {
      return;
    }

    // validate the email only if it's valid
    if (!selectedByUtorIdNotByEmail && this.designateForm.get('designates.' + index + '.emailInput').invalid) {
      return;
    }

    // - backend call to get the designate
    const subscription$: Subscription = this.modelService.validateDesignate(utorIdOrEmail)
      .subscribe((res: Designate) => {
      const isUtorIdValid = (res !== null);

      const utorIdValidMsg = 'Not a valid staff UTORid';
      const emailValidMsg = 'Email does not exist';

      const utorId: AbstractControl = this.designateForm.get('designates.' + index + '.utorId');
      const firstName: AbstractControl = this.designateForm.get('designates.' + index + '.firstName');
      const lastName: AbstractControl = this.designateForm.get('designates.' + index + '.lastName');
      const email: AbstractControl = this.designateForm.get('designates.' + index + '.email');
      const phone: AbstractControl = this.designateForm.get('designates.' + index + '.phone');
      const invalidUtorIdMsg: AbstractControl = this.designateForm.get('designates.' + index + '.invalidUtorIdMsg');
      const invalidEmailMsg: AbstractControl = this.designateForm.get('designates.' + index + '.invalidEmailMsg');


      if (isUtorIdValid === true) {
        utorId.setValue(res.utorId);
        firstName.setValue(res.firstName.trim());
        lastName.setValue(res.lastName.trim());
        email.setValue(res.email.trim());
        // call Phonebook API to get phone number
        this.phonebookApi.getPhonebookSearchByEmail(res.email).subscribe(phonebookSearch => {
          let phoneNumber = "";
          let departmentId;

          if (phonebookSearch.personList && phonebookSearch.personList.length) {
            const person: PhonebookPerson = phonebookSearch.personList[0];
            if (person.positionList && person.positionList.length) {

              // get phoneNumber
              person.positionList.some((position: PhonebookPosition) => {
                if (position.phoneList) {
                  console.log("position.phoneList: ", JSON.stringify(position.phoneList));
                  return position.phoneList.some((phonebookPhone: PhonebookPhone) => {
                    if (phonebookPhone.type === "Office")  {
                      phoneNumber = phonebookPhone.phoneNum;
                      return true;
                    }
                    else {
                      return false;
                    }
                  });

                }
                else {
                  return false;
                }
              });

              if (phoneNumber === "") {
                // get department id
                person.positionList.some((position: PhonebookPosition) => {
                  if (position.departmentId !== null) {
                    departmentId = position.departmentId;
                    return true;
                  } else {
                    return false;
                  }
                }) ;
                // get department phone number by department id
                if (departmentId !== null) {
                  this.phonebookApi.getPhonebookDataByDepartmentId(departmentId)
                    .subscribe(value => {
                      if (value.details && value.details.length > 0) {
                        value.details.some(detail => {
                          if (detail.phoneList && detail.phoneList.length > 0) {
                            return detail.phoneList.some (eachPhone => {
                              if (eachPhone.type === 'Office') {
                                phone.setValue(eachPhone.phoneNum);
                                return true;
                              } else {
                                return false;
                              }
                            });
                          }
                        });
                      }

                    });
                }
              }else {
                phone.setValue(phoneNumber);
              }
            }
          }
        });

        invalidUtorIdMsg.setValue('');
        invalidEmailMsg.setValue('');
      } else {
        utorId.setValue('');
        firstName.setValue('');
        lastName.setValue('');
        email.setValue('');

        invalidUtorIdMsg.setValue(utorIdValidMsg);
        invalidEmailMsg.setValue(emailValidMsg);
      }
    });
    // add the child subscription to current one
    this.subscription.add(subscription$);
  }

  /**
   * Back-end call.
   * Save the edited designate assignment data.
   * Only the binding data can be edited (i.e. Role). No new org/course or designate(s) can be entered.
   * The data is edited in a modal.
   */
  saveEditedDesigAssignment() {
    // It cannot be selected both org and course; set to null the not selected one.
    this._sanitizeFormData(this.designateForm);

    this.logger.debug(`=> Form value: ${JSON.stringify(this.designateForm.value)}`,
                                     `${this.constructor.name}.saveEditedDesigAssignment()`);

    const subscription$: Subscription = this.modelService.saveDesignateAssignment(this.designateForm.value).subscribe(() => {
      // successfully edited; close the modal and send assessment level (org or course) to the modal opener.
      this.activeModal.close({assessmentLevel: this.designateForm.value.assignmentLevel});
      const msg = 'Edited designate have been successfully saved.';
      this.toastService.show({type: 'success', action: msg});
    });
    // add the child subscription to current one
    this.subscription.add(subscription$);
  }

  /**
   * Back-end call.
   * Save the designate assignment data.
   * New org/course, designate(s) and designate binding data
   * (i.e. Communication Permissions, Role, Designation Expiry) can be entered.
   * The data is not entered in a modal.
   */
  saveDesignateAssignment() {
    this.formSubmitted = true;
    // Mark all controls as touched in order to show the error messages for the not touched form controls
    this.designateForm.markAllAsTouched();

    // It cannot be selected both org and course; set to null the not selected one.
    this._sanitizeFormData(this.designateForm);

    this.logger.debug(`=> Form value: ${JSON.stringify(this.designateForm.value)}`, `${this.constructor.name}.saveDesignateAssignment()`);

    // submit form only if it's valid
    if (this.designateForm.valid) {
      const subscription$: Subscription = this.modelService.saveDesignateAssignment(this.designateForm.value).subscribe(() => {
        const msg = this._getSaveToasterMsg(this.designateForm.value.assignmentLevel);
        this.toastService.show({type: 'success', action: msg});
        this._resetForm();
        this.formSubmitted = false;
      });
      // add the child subscription to current one
      this.subscription.add(subscription$);
    }
  }

  /* Resets the designate form fields and leaves only one desigante in the array. */
  _resetForm() {
    // reset to just one designate if there is more than one
    if (this.designates.length > 1) {
      for (let i = this.designates.length - 1; i > 0; i--) {
        this.removeDesignate(i);
      }
    }

    // reset all form fields
    this.designateForm.reset();
  }

  /* Utility function: Recursively loops through the controls and display every control validity. */
  logValidityByKeyValuePairs(group: UntypedFormGroup): void {
    Object.keys(group.controls).forEach((key: string) => {
      const abstrCtrl = group.get(key);
      if (abstrCtrl instanceof UntypedFormGroup) {
        this.logValidityByKeyValuePairs(abstrCtrl);
      } else if (abstrCtrl instanceof UntypedFormArray) {
        for (const ctrl of abstrCtrl.controls) {
          if (ctrl instanceof UntypedFormGroup) {
            this.logValidityByKeyValuePairs(ctrl);
          }
        }
      } else {
        console.log('*** Key: ' + key + ', Value: ' + abstrCtrl.value + ', Valid: ' + abstrCtrl.valid);
      }
    });
  }



}
