/**
 * Note: This enumeration must be a cat-copy of ClockworkFailureReason.java.
 */
export enum UpdateBookingFailureReason {

  BOOKING_STATUS_COULD_NOT_BE_UPDATED = 'BOOKING_STATUS_COULD_NOT_BE_UPDATED',
  BOOKING_NOTE_COULD_NOT_BE_ADDED = 'BOOKING_NOTE_COULD_NOT_BE_ADDED',
  BOOKING_NOTE_COULD_NOT_BE_UPDATED = 'BOOKING_NOTE_COULD_NOT_BE_UPDATED',
  INVALID_STATUS = 'INVALID_STATUS',

  UNKNOWN_REASON = 'UNKNOWN_REASON'
}
