import { Component, OnInit } from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {UntypedFormControl} from "@angular/forms";
import {ModelService} from "../../../model/model.service";

@Component({
  selector: 'app-provision-student-modal',
  templateUrl: './provision-student-modal.component.html',
  styleUrls: ['./provision-student-modal.component.scss']
})
export class ProvisionStudentModalComponent implements OnInit {

  rosiStudentNumber: UntypedFormControl;

  successfullyProvisioned: boolean;

  constructor(public activeModal: NgbActiveModal, private modelService: ModelService) {
    this.rosiStudentNumber = new UntypedFormControl('');
  }

  ngOnInit(): void {
    this.successfullyProvisioned = true;
  }

  provisionStudent(rosiStudentNumber) {
    console.log("=> rosiStudentNumber: " + rosiStudentNumber);
    this.modelService.provisionStudent(rosiStudentNumber).subscribe(
      res => {
        this.successfullyProvisioned = res;
        console.log("=> Successfully provisioned:", res);

        if (this.successfullyProvisioned) {
          this.activeModal.close();
        }
      },
      err => {
        console.log("=> HTTP Error", err);
      },
      () => console.log('HTTP request completed.')
    );
  }

}
