import { Injectable } from '@angular/core';

/**
 * Service: SpinnerService
 * Desc:
 *    The service 'showSpinner' property is used by the spinner component to decide when to be shown or hidden.
 *    The 'showSpinner' property is set by the CallInProgressInterceptor interceptor by calling the service methods.
 */
@Injectable({
  providedIn: 'root'
})
export class SpinnerService {

  showSpinner: boolean;

  constructor() {}

  attachSpinner(): void {
    this.showSpinner = true;
  }

  removeSpinner(): void {
    this.showSpinner = false;
  }
}
