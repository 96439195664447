<mat-table [dataSource]="bookingsDataSource" matSort class="mat-elevation-z8 table-bookings">
  <!-- Checkbox Column -->
  <ng-container  matColumnDef="select" *ngIf="selectable">
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef>
      <mat-checkbox (change)="$event ? masterToggle() : null"
                    [checked]="selection.hasValue() && isAllSelected()"
                    [indeterminate]="selection.hasValue() && !isAllSelected()">Select
      </mat-checkbox>
    </th>
    <td class="mat-column-position" mat-cell *matCellDef="let row">
      <mat-checkbox (change)="$event ? changeItem(row) : null" [disabled] ="!isBookingSelectable(row)"
                    [checked]="selection.isSelected(row)">
      </mat-checkbox>
    </td>
  </ng-container>

  <ng-container matColumnDef="appointmentId">
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Clockwork Appointment ID</th>
    <td class="mat-column-position" *matCellDef="let element">{{element.clockworkAppointmentId}}</td>
  </ng-container>
  <ng-container matColumnDef="bookingId">
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>ATS Booking ID</th>
    <td class="mat-column-position" *matCellDef="let element">{{element.bookingId}}</td>
  </ng-container>
  <ng-container matColumnDef="studentName">
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Student</th>
    <td class="mat-column-position" *matCellDef="let element">
      <a routerLink="/student-profile/{{element.studentInfo.atsStudentId}}">{{element.studentInfo.firstName}} {{element.studentInfo.lastName}}</a></td>
  </ng-container>
  <ng-container matColumnDef="studentRosiNo">
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Student No.</th>
    <td class="mat-column-position" *matCellDef="let element" >{{element.studentInfo.rosiStudentId}}</td>
  </ng-container>
  <ng-container matColumnDef="scheduledStartDate" >
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
        cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Current Scheduled Start Date</th>
    <td class="mat-column-position" *matCellDef="let element">{{element.scheduledStartDate}}</td>
  </ng-container>
  <ng-container matColumnDef="scheduledStartTime" >
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
        cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Current Scheduled Start Time</th>
    <td class="mat-column-position" *matCellDef="let element">{{element.scheduledStartTime | convertFrom24To12Format}}</td>
  </ng-container>
  <ng-container matColumnDef="scheduledEndTime" >
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
        cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Current Scheduled End Time</th>
    <td class="mat-column-position" *matCellDef="let element">{{element.scheduledEndTime | convertFrom24To12Format}}</td>
  </ng-container>
  <ng-container matColumnDef="location" >
    <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
        cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Current Assessment Writing Location</th>
    <td class="mat-column-position" *matCellDef="let element">{{getLocation(element)}}</td>
  </ng-container>

  <div *ngIf="selectable">
    <tr mat-header-row *matHeaderRowDef="bookingsColumns1; sticky: true"></tr>
    <tr mat-row *matRowDef="let row; columns: bookingsColumns1"></tr>
  </div>
  <div *ngIf="!selectable">
    <tr mat-header-row *matHeaderRowDef="bookingsColumns2; sticky: true"></tr>
    <tr mat-row   *matRowDef="let row; columns: bookingsColumns2" [ngClass]="{'highlight-line': row.notValid}"></tr>
  </div>
</mat-table>
