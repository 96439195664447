
<!-- The RemoveDesigModalComponent template -->

<div class="remove-designate">
  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Remove Designate</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <!-- Modal header -->
  <div class="modal-body">
    <!-- DesigBindingComponent component -->
    <app-desig-binding [desigAssignment]="desigAssignment"></app-desig-binding>
    <div class="row">
      <div class="col">Are you sure you want to remove the designate?</div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer">
    <!-- Remove Designate button -->
    <button type="button" class="btn btn-sm btn-primary" (click)="removeDesignate(desigAssignment)">
      Remove Designate
    </button>
    <!-- Cancel hyperlink -->
    <button type="button" class="btn btn-sm btn-link" (click)="activeModal.close('Cancel click')">
      Cancel
    </button>
  </div>
</div>
