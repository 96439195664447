export enum ColumnName {
  // dashboard table columns
  COURSE_SECTION = 'Course & Section',
  COURSE = 'Course',
  SECTION = 'Section',
  CLASS_START_DATE = 'Class Start Date',
  CLASS_START_TIME = 'Class Start Time',
  CLASS_END_DATE = 'Class End Date',
  CLASS_END_TIME = 'Class End Time',
  SCHEDULED_START_DATE = 'Scheduled Start Date',
  SCHEDULED_START_TIME = 'Scheduled Start Time',
  SCHEDULED_END_DATE = 'Scheduled End Date',
  SCHEDULED_END_TIME = 'Scheduled End Time',
  STUDENT_NAME = 'Student',
  ACCOMMODATIONS = 'Accommodations',
  TIMING_TYPE = 'Timing Type',
  SEAT = 'Seat',
  TYPE = 'Type',
  DIVISION_UNIT = 'Division Unit',
  UNIT = 'Unit',
  STUDENT_NUMBER = 'Student Number',
  CLOCKWORK_APPOINTMENT_ID = 'Clockwork Appointment ID',
  EXAM_ID = 'Exam ID',
  ASSESSMENT_LOCATION = 'Assessment Location',
  CLASS_LOCATION = 'Class Location',
  SOURCE = 'Source',
  ACTIVE = 'Active',
  NUMBER_OF_BOOKINGS = 'Number of Bookings',
  DURATION = 'Duration',
  BREAK_MINUTES = 'Break Minutes',
  LOCATION = 'Student Writing Location',
  TEST_DEFINITION_ID = 'ATS Test Definition ID',
  BOOKING_ID = 'ATS Booking ID',
  BOOKING_STATUS = 'Status',


  // Designate table columns
  LEVEL = 'Level',
  DIVISION = 'Division', // used in booking dashboard as well
  DEPARTMENT = 'Department',
  COURSE_CODE = 'Course Code',
  SECTION_CODE = 'Section Code',
  SESSION_CODE = 'Session Code',
  DESIGNATE = 'Designate',
  PERMISSIONS = 'Permissions',
  PREFIX = 'Prefix',
  VALID_UNTIL = 'Valid Until',
  NAME_UTORid = 'Name (UTORid)',
}
