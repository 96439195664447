/**
 * Designates status values.
 * Used in 'Filter by Valid Status' drop-down.
 */
export enum DesignateStatusValue {

  ALL_DESIGNATES = "status_allDesignates",
  CURRENTLY_VALID = "status_currentlyValid",
  EXPIRED = "status_expired"

}
