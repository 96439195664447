import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import {HTTP_INTERCEPTORS} from '@angular/common/http';

import {DesignHttpRequestInterceptor} from './design.http.service';
import {HttpRequestInterceptor} from "../interceptor";
import {DesignComponent} from './design.component';
import {Router, RouterModule, Routes} from '@angular/router';
import {DesignModuleSpaceComponent} from './design-module-space/design-module-space.component';
import {AppModule} from '../app.module';
import {AppComponent} from '../app.component';
import {CheckboxDropDownDemoComponent} from './checkbox-drop-down-demo/checkbox-drop-down-demo.component';
import {MatLegacyMenuModule as MatMenuModule} from '@angular/material/legacy-menu';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {Environment} from "../../environment";
import {CommonModule} from "@angular/common";

const routes: Routes = [
  {path: 'design', component: DesignModuleSpaceComponent},
  {path: 'design/checkbox-drop-down-demo', component: CheckboxDropDownDemoComponent},
];

@NgModule({
    declarations: [
        DesignComponent,
        DesignModuleSpaceComponent,
        CheckboxDropDownDemoComponent
    ],
    imports: [
        BrowserModule,
        AppModule,
        RouterModule.forChild(routes),
        MatMenuModule,
        NgbModule,
        CommonModule
    ],
    providers: [
        {
            provide: HTTP_INTERCEPTORS,
            // The shake tree will remove the dead code. No need to remove the unused DesignHttpRequestInterceptor in prod
            useClass: Environment.mode === 'design' ? DesignHttpRequestInterceptor : HttpRequestInterceptor,
            multi: true
        }
    ],
    bootstrap: [AppComponent, DesignComponent]
})
export class DesignModule { }
