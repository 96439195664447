
<div class="rf-duration-time-picker">
  <fieldset [disabled]="disabled">
    <ngb-timepicker [formControl]="duration"
                    (blur)="onTouched()">
    </ngb-timepicker>
  </fieldset>
</div>
<app-validation-message [control]="duration"></app-validation-message>

<!--<p> {{ duration.value | json }}</p>-->
<!--<p> {{ duration.status }}</p>-->

