<div class="contact-detail">
  <form [formGroup]="contactDetailForm">

    <h3>Course Contact {{contactArrayIndex + 1}}</h3>

    <!-- Contact Type -->
    <div class="mt-3">
      <div><strong>Contact {{contactArrayIndex + 1}} Type</strong></div>
      <select class="form-select" area-label="Select options" formControlName="contactType">
        <option selected value="">Select Option</option>
        <option *ngFor="let contactType of contactTypes" [ngValue]="contactType.value">
          {{contactType.label}}
        </option>
      </select>
    </div>
    <app-validation-message [control]="contactType"></app-validation-message>

    <!-- Contact Name -->
    <div class="mt-3">
      <label class="form-label" for="contact-name">Contact {{contactArrayIndex + 1}} Name</label>
      <input class="form-control" type="text" placeholder="E.g.: Undergraduate Office or Johnny Acorn" id="contact-name"
             formControlName="contactName">
    </div>
    <app-validation-message [control]="contactName"></app-validation-message>

    <!-- Contact Email Address -->
    <div class="mt-3">
      <label class="form-label" for="contact-email">Contact {{contactArrayIndex + 1}} Email Address</label>
      <input class="form-control" type="email" placeholder="E.g.: johnny.acorn@utoronto.ca" id="contact-email"
             formControlName="contactEmail">
    </div>
    <app-validation-message [control]="contactEmail"></app-validation-message>

    <!-- Contact Expiry Date -->
    <div class="mt-3">
      <div><strong>Expiry Date for Contact {{contactArrayIndex + 1}} (Optional)</strong></div>
      <div>If there is no expiry date, please leave this blank.</div>
      <app-datepicker formControlName="expiryDate"></app-datepicker>
    </div>
    <app-validation-message [control]="expiryDate"></app-validation-message>

    <!-- Remove Contact button -->
    <div class="mt-3">
      <sis-button *ngIf="(editContacts && totalContacts > 0) || (!editContacts && totalContacts > 1)"
                  visualType="destructive" size="small"
                  (click)="deleteContact(contactArrayIndex)">
        Remove Contact {{contactArrayIndex + 1}}
      </sis-button>
    </div>
    <br/>
  </form>
</div>
