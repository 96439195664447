
<!-- Which reminder email would you like to send for this assessment? -->
<div>
  <div><strong>Which reminder email would you like to send for this assessment?</strong></div>
  <div class="form-check">
    <input class="form-check-input" type="radio" id="emailReminderTypeCisSub"
           [value]="EmailReminderType.TO_INPUT_CIS_SUBMISSION" [formControl]="emailReminderTypeCtrl">
    <label class="form-check-label" for="emailReminderTypeCisSub" >
      {{Utils.emailReminderTypeMap.get(EmailReminderType.TO_INPUT_CIS_SUBMISSION)}}
    </label>
  </div>
  <!-- Department/Division -->
  <div class="form-check">
    <input class="form-check-input" type="radio" id="emailReminderTypeApplyAcc"
           [value]="EmailReminderType.TO_APPLY_ACCOMMODATIONS" [formControl]="emailReminderTypeCtrl">
    <label for="emailReminderTypeApplyAcc" class="form-check-label">
      {{Utils.emailReminderTypeMap.get(EmailReminderType.TO_APPLY_ACCOMMODATIONS)}}
    </label>
  </div>
</div>
