<!-- Application footer (Contains: 'University of Toronto. All rights reserved.' statement and some external links) -->
<div class="app-footer">
  <div class="app-footer-content">
    <i class="fa far fa-copyright" aria-hidden="true"></i> University of Toronto.  All rights reserved. |
    <a href="https://www.utoronto.ca/accessibility/" target="_blank">Accessibility <i class="fa fa-external-link-alt"></i></a> |
    <a href="https://www.utoronto.ca/contacts/" target="_blank">Contact Us <i class="fa fa-external-link-alt"></i></a> |
    <a href="https://www.utoronto.ca/privacy/" target="_blank">Security & Privacy <i class="fa fa-external-link-alt"></i></a> |
    <span id="git-rev-sig" aria-hidden="true">ATS version: {{commitId}}</span>
  </div>
</div>
