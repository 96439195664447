<div class="card  card-body">
  <h2 class="mt-4" >Advisor</h2>
  <div class="row mt-4">
    <div class="col-12 card-text">
      <div class="row">
        <div class="col-4"><strong>Advisor</strong></div>
      </div>
      <div class="row">
        <div class="col-4">{{advisor?.firstName}} {{advisor?.lastName}}</div>
      </div>
      <div class="row mt-2">
        <div class="col-4"><strong>Email</strong></div>
      </div>
      <div class="row">
        <div class="col-4"><a href="mailto:{{advisor?.email}}">{{advisor?.email}}</a></div>
      </div>
      <div class="row mt-2">
        <div class="col-4"><strong>Phone</strong></div>
      </div>
      <div class="row">
        <div class="col-4">{{advisorPhoneNumber}}</div>
      </div>
    </div>
  </div>
</div>
