import {Component, Input, ViewChild} from '@angular/core';
import {FormControl} from "@angular/forms";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {CourseLevelOfInstr} from "../../model/types/course-level-of-instr";

@Component({
  selector: 'app-course-prefix-typeahead',
  templateUrl: './course-prefix-typeahead.component.html',
  styleUrls: ['./course-prefix-typeahead.component.scss']
})
export class CoursePrefixTypeaheadComponent {

  @Input()
  coursePrefixList: CourseLevelOfInstr[];

  @Input()
  courseCtrl: FormControl;

  @ViewChild('instance', { static: true })
  instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  search = (text$: Observable<string>): Observable<CourseLevelOfInstr[]> => {
    const debouncedText$: Observable<string> = text$.pipe(debounceTime(200), distinctUntilChanged());
    const inputFocus$: Subject<string> = this.focus$;
    const clicksWithClosedPopup$: Observable<string> = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      debounceTime(200),
      distinctUntilChanged(),
      map((term: string) => term.length < 1 ? [] :
        this.coursePrefixList?.filter((p) => p.course.toLowerCase().startsWith(term.toLowerCase())))
    );
  }

  formatter = (result: CourseLevelOfInstr) => !result ? '' : `${result.course.trim()}`;
}
