import {Component, Input, OnChanges, OnInit, SimpleChange, SimpleChanges} from '@angular/core';
import {Schedule} from '../../../model/types/schedule';
import {LogService} from '../../../common/services/log-service.service';
import {AssessmentTimingInd} from "../../types/assessment-timing-ind";
import {A} from "@angular/cdk/keycodes";

@Component({
  selector: 'app-schedule-card',
  templateUrl: './schedule-card.component.html',
  styleUrls: ['./schedule-card.component.scss']
})
export class ScheduleCardComponent implements OnInit, OnChanges {

  constructor(private logger: LogService) {
  }

  @Input()
  classWriting: Schedule;

  @Input()
  scheduledWriting: Schedule;

  duration: string;

  // Translate the assessment timing indicator to tooltip text.
  timingIndTooltipMap = new Map([
    [AssessmentTimingInd.Strict,   'Students must start and submit within a fixed start time and end time on a provided date.'],
    [AssessmentTimingInd.Fixed,    'Students can start at any time within a date range but have a fixed duration once they begin.'],
    [AssessmentTimingInd.Flexible, `The duration provided by the professor is an estimated length to complete the assessment.
                                    Students can work on the assessment within a given date range and submit it by the provided day and time.`]
  ]);

  ngOnInit(): void {
    this.logger.debug(JSON.stringify(this.classWriting), 'ScheduleCardComponent.classWriting');
    this.logger.debug(JSON.stringify(this.scheduledWriting), 'ScheduleCardComponent.scheduledWriting');
  }

  ngOnChanges(changes: SimpleChanges): void {
    const change: SimpleChange = changes['classWriting'];

    // if the 'classWriting' input changed, translate assessment timing indicator
    if (change && change.currentValue !== change.previousValue) {

      // Build duration text to show in Duration column: i.e. 178 minutes (Flexible)
      const durationInMinutes = !this.classWriting.duration ? 'N/A' : this.classWriting.duration + ' minutes';
      this.duration = durationInMinutes + ' (' + this.mapAssessmentTimingIndToText(this.classWriting) + ')';
    }
  }

  public convertTimeStrToDate(value) {
    if (!value) {
      return null;
    }

    if (value.split) {
      const parts = value.split(':');
      const date = new Date();
      date.setHours(parseInt(parts[0], 10));
      date.setMinutes(parseInt(parts[1], 10));
      date.setSeconds(parseInt(parts[2], 10));
      return date;
    } else {
      return null;
    }
  }

  // Map assessment timing indicator (one letter; i.e T, D, S) to a text (i.e. Strict, Fixed, Flexible).
  private mapAssessmentTimingIndToText(classWriting: Schedule): string {
    if (!classWriting || !classWriting.assessmentTimingInd) {
      return 'N/A';
    }

    let assessmentTimingIndDesc = '';
    const ind = classWriting.assessmentTimingInd;

    if (AssessmentTimingInd.Strict === ind) {
      assessmentTimingIndDesc = 'Strict';
    } else if (AssessmentTimingInd.Fixed === ind) {
      assessmentTimingIndDesc = 'Fixed';
    } else if (AssessmentTimingInd.Flexible === ind) {
      assessmentTimingIndDesc = 'Flexible';
    } else {
      throw new Error('assessmentTimingInd value [' + ind + '] is not valid.');
    }

    return assessmentTimingIndDesc;
  }

}
