<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Activity Log</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  <!-- Test def info -->
  <div class="margin-top-2-rem">
    <app-test-def-info [testDefinition]="testDefinition"></app-test-def-info>
  </div>

  <br/>
  <table class="table">
    <thead>
    <tr>
      <th scope="col">Date & Time</th>
      <th scope="col">User</th>
      <th scope="col">Description</th>
      <th scope="col">Email Recipients</th>
    </tr>
    </thead>
    <tbody>
    <tr *ngFor="let log of notificationLog$ | async">
      <td>{{log.sentEmailTimestamp  | date:'yyyy-MM-dd \'at\' hh:mm a'}}</td>
      <td>{{log.senderUtorId}}</td>
      <td>{{log.description}}</td>
      <td>
        <div *ngFor="let email of log.emailList">{{email}}</div>
      </td>
    </tr>
    </tbody>
  </table>


</div>
