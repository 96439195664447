import {Injectable} from '@angular/core';
import {ModelService} from '../../model/model.service';
import {RestDataSourceService} from '../../model/data-sources/rest-data-source.service';
import { ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import {StudentBooking} from '../../model/types/student-booking';
import {Observable} from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class StudentBookingResolverService  {

  constructor(private modelService: ModelService) {
  }

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<StudentBooking> | StudentBooking {
    const bookingId: number = route.params.id;
    return this.modelService.getStudentBooking(bookingId);
  }

}
