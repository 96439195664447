import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, ParamMap, Router} from "@angular/router";
import {EmailReminder} from "../email-reminder.enum";
import {ModelService} from "../../model/model.service";
import {LogService} from "../../common/services/log-service.service";
import {InstructorEmailContacts} from "../instructor-email-contacts";
import {FormControl, FormGroup, Validators} from "@angular/forms";
import {TestDefinition} from "../../model/types/test-definition";
import {EmailContactRec} from "../email-contact-rec.intf";
import {SelectedEmailContacts} from "../selected-email-contacts";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {ToastService} from "@easi-sis/core";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {SendEmailModalComponent} from "../send-email-modal/send-email-modal.component";
import {EmailResp} from "../email-resp";
import {EmailHttpStatusCode} from "../email-http-status-code.enum";

@Component({
  selector: 'app-send-email',
  templateUrl: './send-email.component.html',
  styleUrls: ['./send-email.component.scss']
})
export class SendEmailComponent implements OnInit {

  sendEmailFormGroup: FormGroup;

  // Radio-button control to choose the type of email reminder - mandatory.
  emailReminderTypeCtrl = new FormControl('', [Validators.required]);

  // Params received from email dashboard (parent)
  emailType: string; // initial, 1-day or 3-day reminder (e.g. ONE_DAY_REMINDER, INITIAL_REMINDER)
  testDefId: string;

  // Page title, based on email type
  title: string;

  // The text to be display on the send email button; based on email type
  sendEmailButtonText: string;

  testDefinition: TestDefinition;
  localContactsNotes: string;

  emailContactRec: EmailContactRec[] = [];

  errorMsg: string;
  infoMsg: string;

  testDefHasContacts: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private modalService: NgbModal,
              private toastService: ToastService,
              private model: ModelService,
              private logger: LogService) {}

  ngOnInit(): void {

    this._processQueryParams();
  }

  // Find instructors, coordinators and local contacts for the assessment and format them for display
  findAllCourseContacts(testDefId: string, emailType: string): void {

    if (!testDefId) {
      return;
    }

    this.logger.debug(`=> Calling find all course contacts with testDefId: ${testDefId}`, `${this.constructor.name}`);

    const allContacts$ = this.model.findAllCourseContacts(testDefId);

    allContacts$.subscribe((instructorEmailContacts: InstructorEmailContacts) => {

      this.testDefinition = instructorEmailContacts.testDefinition;
      this.localContactsNotes = instructorEmailContacts.localContactsNotes;

      this.emailContactRec = this.Utils.buildTestDefContactsTableRecords(testDefId, instructorEmailContacts, emailType);

      this.testDefHasContacts = this.emailContactRec && this.emailContactRec.length > 0;
      this.infoMsg = this.testDefHasContacts ? '' : this.Utils.infoMsg_NO_CONTACTS_FOUND_FOR_TEST_DEF;

      this.sendEmailButtonText = this.Utils.getSendEmailPageButtonText(this.emailType);
    });
  }

  sendEmail() {

    const selectedEmailContacts: SelectedEmailContacts = this.Utils.buildBackendResp(
                              this.testDefinition, this.emailType, this.emailReminderTypeCtrl, this.emailContactRec);

    // If none of the contacts sending action is 'To', return.
    if (selectedEmailContacts.contacts.length === 0) {
      this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;
      return;
    }

    // -- Check with user if still wants to send email
    // Open a modal for user confirmation of sending emails.
    let modalRef = this._openModal();
    modalRef.componentInstance.testDefinition = this.testDefinition;
    modalRef.componentInstance.emailType = this.emailType;
    modalRef.componentInstance.emailReminderType = this.emailReminderTypeCtrl.value;

    // Process the user response
    modalRef.result.then(
      (result: string) => {

        // User response, from the modal (Yes, Send Email).
        if (result === 'yes') {
          this.model.sendEmail(selectedEmailContacts).subscribe(
            (resp: EmailResp) => {

              if (resp.statusCode === this.StatusCode.ACCEPTED
                     || resp.statusCode === this.StatusCode.NO_EMAIL_ADDRESSES) {

                let msg = this._createSuccessToastMsg();

                // Successfully sent the emails: navigate to the instructor emailing dashboard
                this.router.navigateByUrl('/instructor-emailing').then(() => {
                });

                // Show success toast message
                this.toastService.show({type: 'success', action: msg});

              } else if (resp.statusCode === this.StatusCode.NO_SENDING_ACTION_TO) {
                // - No contacts with sending action 'TO'
                this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;

              } else if (resp.statusCode === this.StatusCode.SEND_EMAIL_ERROR) {
                // - General error message
                this.errorMsg = this.Utils.errorMsg_SEND_EMAIL_ERROR;

              } else {
                // - Error message when trying to send emails without having any set to 'TO'.
                this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;
              }
            },
            (error) => {
              this.logger.debug(`### ERROR: ${JSON.stringify(error)}`, `${this.constructor.name}`);
            });
        }
      }
    );

  }

  _createSuccessToastMsg(): string {
    // return this.Utils.createSuccessToastMsg(this.emailType, this.emailReminderTypeCtrl, this.testDefinition);
    return this.Utils.createSuccessToastMsg(this.emailType, this.emailReminderTypeCtrl, this.testDefinition);
  }

  _processQueryParams() {
    this.activatedRoute.queryParamMap.subscribe( (params: ParamMap) => {
      this.testDefId = params.get('testDefId');
      this.emailType = params.get('emailType');

      this.logger.debug(`=> QueryParams: testDefId: ${this.testDefId}, emailType: ${this.emailType}`, `${this.constructor.name}`);

      this.title = this.Utils.getSendEmailPageTitle(this.emailType);

      this.findAllCourseContacts(this.testDefId, this.emailType);
    });
  }

  // Open a modal
  _openModal(): NgbModalRef {
    return this.modalService.open(SendEmailModalComponent);
  }

  protected readonly EmailReminder = EmailReminder;
  protected readonly StatusCode = EmailHttpStatusCode;
  protected readonly Utils = InstructorEmailingUtils;
}
