import { Injectable } from '@angular/core';
import {ModelService} from "../../model/model.service";
import { ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import {Observable} from "rxjs";
import {AccommodationChoices} from "../../model/types/accommodation-choices";

// TODO: NOT-USED. To be removed later if this method won't be needed. For now I commented out the call.
@Injectable({
  providedIn: 'root'
})
export class ManualBookingResolverService  {

  constructor(private modelService: ModelService) {}

  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot):
                                               Observable<AccommodationChoices> | Promise<AccommodationChoices> | AccommodationChoices {

    // return this.modelService.getAccommodationChoices();
    return null;
  }
}
