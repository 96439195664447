<!-- Masthead -->
<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7">
    <div class="masthead-header">
      <h1>Bookings Dashboard</h1>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>
<div class="row margin-top-spacer-4">
  <div class="col-3">
    <button id="createTestDefinition" class="btn btn-primary" (click)="createBooking()">Create Booking</button>
  </div>
</div>

<div class="margin-top-spacer-4">
  <app-period-selector [selectDateLabel]="'Select Scheduled Writing Start Date(s)'" [studentIdNeeded]="true" [storedSelector]="storedData.periodCourseSelector"
                       (outer)="getBookingsByPeriod($event)"></app-period-selector>
</div>

<div *ngIf="bookingsAmountExceedLimit">
  <div class="margin-top-40-px">
    <h2>Bookings (0)</h2>
  </div>
  <div class="margin-top-40-px">
    <p class="font-weight-bold">
      It looks like there were too many results. Please try the following:
    </p>
    <div>
      <ul>
        <li>Narrow down your results by reducing the date range selected.</li>
      </ul>
    </div>
  </div>
</div>
<!--can't use *ngIf below as the sorting will not work-->
<div [hidden]="bookingsAmountExceedLimit">
  <div class="margin-top-40-px">
    <h2>Bookings ({{bookingsAmount}})</h2>
  </div>

  <!-- Filter bookings -->
  <div class="row align-items-top align-items-center">
    <div class="col-5 text-filter pl-0">
      <label class="form-label" for="textInput">Filter by one or more words</label>
      <div class="input-group mr-2">
        <input type="text" id="textInput"  class="form-control" [value]="textFilter"
               autocomplete="off" (keyup)="applyTextFilter($event)" placeholder="E.g: PSY312 scribe">
        <app-columns-to-search #columnsToSearch [activeColumns]="displayedColumns" (changeColumns)="applyFilter()" ></app-columns-to-search>
      </div>
    </div>
    <div class="col-2 reset-filter">
      <button type="button" class="btn btn-outline-secondary btn-block reset-button font-size-16px height-4rem" (click)="reset()">Reset Filters</button>
    </div>
  </div>

  <mat-table matTableExporter [dataSource]="bookingsDataSource" matSort
             cdkDropList cdkDropListOrientation="horizontal" (cdkDropListDropped)="tableDrop($event)"
             class="table table-container table-size mt-4 mat-elevation-z8">

    <!--<ng-container matColumnDef="status" sticky>
      <th mat-header-cell *matHeaderCellDef>Status</th>
      <td mat-cell *matCellDef="let element">
          <div *ngIf="element.conflicts.length>0">
            <div class="conflict-item" *ngFor="let conflict of element.conflicts">{{conflict.description}}</div><br/>
          </div>
        <a routerLink="/student-booking/{{element.id}}">{{element.statusDesc}}</a>
        <i class="ml-1 fas fa-paperclip fa-fw" *ngIf="isFileAttached(element)"
           ngbTooltip="File(s) attached" container="body" placement="bottom"></i>
        <i class="ml-1 far fa-check-circle fa-fw" *ngIf="isQuercusAccessGranted(element)"
           ngbTooltip="Quercus access granted" container="body" placement="bottom"></i>
        <i class="ml-1 far fa-times-circle fa-fw" *ngIf="isQuercusAccessDenied(element)"
           ngbTooltip="Quercus access denied" container="body" placement="bottom"></i>
        <i class="ml-1 fas fa-globe fa-fw" *ngIf="isToBeCompletedOnline(element)"
           ngbTooltip="To be completed online" container="body" placement="bottom"></i>
        <i class="ml-1 fas fa-school fa-fw" *ngIf="isOnlineToBeCompletedInPerson(element)"
           ngbTooltip="To be completed in-person" container="body" placement="bottom"></i>&ndash;&gt;
      </td>
    </ng-container>-->

    <ng-container matColumnDef="courseSection">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Course, Section</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.courseSection}}</td>
    </ng-container>

    <ng-container matColumnDef="type" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Type</th>
      <td class="mat-column-position" *matCellDef="let element">{{getBookingTestType(element)}} </td>
    </ng-container>

    <ng-container matColumnDef="clockworkAppointmentId" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Clockwork Appointment ID</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.clockworkAppointmentId}}</td>
    </ng-container>

    <ng-container matColumnDef="examId" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Exam ID</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.clockworkExamId}}</td>
    </ng-container>

    <ng-container matColumnDef="classStartDate">
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class Start Date</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classStartDate}}</td>
    </ng-container>

    <ng-container matColumnDef="classStartTime" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class Start Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classStartTime | convertFrom24To12Format}}</td>
    </ng-container>

    <ng-container matColumnDef="classEndTime" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Class End Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.classEndTime | convertFrom24To12Format}}</td>
    </ng-container>

    <ng-container matColumnDef="scheduledStartDate" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Scheduled Start Date</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.scheduledStartDate}}</td>
    </ng-container>

    <ng-container matColumnDef="scheduledEndDate" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Scheduled End Date</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.scheduledEndDate}}</td>
    </ng-container>

    <ng-container matColumnDef="scheduledStartTime" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Scheduled Start Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.scheduledStartTime | convertFrom24To12Format}}</td>
    </ng-container>

    <ng-container matColumnDef="scheduledEndTime" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Scheduled End Time</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.scheduledEndTime | convertFrom24To12Format}}</td>
    </ng-container>

    <ng-container matColumnDef="duration" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Duration</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.duration}}</td>
    </ng-container>

    <ng-container matColumnDef="breakMinutes" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Break Minutes</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.clockworkBreaksDuration}}</td>
    </ng-container>

    <ng-container matColumnDef="location" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Student Writing Location</th>
      <td class="mat-column-position" *matCellDef="let element">{{getLocation(element)}}</td>
    </ng-container>

    <ng-container matColumnDef="student" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Student</th>
      <td class="mat-column-position" *matCellDef="let element">
        <a routerLink="/student-profile/{{element.student.atsStudentId}}" >{{element.student.firstName}} {{element.student.lastName}}</a>
      </td>
    </ng-container>

    <ng-container matColumnDef="studentNumber" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Student Number</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.student.rosiStudentId}}</td>
    </ng-container>

    <!--<ng-container  matColumnDef="accom" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header [disabled]="accomColumnSortDisable">
        <div class="line-block">
          <span class="font-size-14px" href="javascript:void(0)"(click)="enableSort()">Accom.</span><br>
          <span><a class="font-size-14px" href="javascript:void(0)"
                   (click)="switchShowOrHideAccom()" [innerText]="showOrHideAccomString()"></a></span>
        </div>
      </th>
      <td class="mat-column-position" *matCellDef="let element">
        <ul class="margin-left-negative20" *ngIf="showAccomDetails">
          <li *ngFor="let accom of sortAccommodations(element.accommodationList)">
            {{accom.description}}
          </li>
        </ul>
        &lt;!&ndash;below template is for the tooltip&ndash;&gt;
        <ng-template #accomsContent>
          <ul class="accom-tooltip">
            <li *ngFor="let accom of sortAccommodations(element.accommodationList) ">
              {{accom.description}}
            </li>
          </ul>
        </ng-template>
        <span class="underline-dotted" *ngIf="!showAccomDetails" [ngbTooltip]="accomsContent" tooltipClass="accom-width"
              placement="bottom" [innerText]="getAccomHead(element.accommodationList)">
        </span>
      </td>
    </ng-container>-->

    <!--<ng-container matColumnDef="seat" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header>Seat</th>
      <td class="mat-column-position" *matCellDef="let element">
          <div class="my-1">
            <seat-selector [selectedSpace]="element.space" [isOnline]="element.online" [doesWorkIndependently]="true"
                           [rooms]="rooms"
                           (submitSpace)="setSpace($event, element.id)" (setOnline)="setOnline(element.id)"
                           (resetSpace)="resetSpace(element.id)">
            </seat-selector>
          </div>
      </td>
    </ng-container>-->

    <ng-container matColumnDef="testDefinitionId" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">ATS Test Definition ID</th>
      <td class="mat-column-position" *matCellDef="let element">
        <a routerLink="/test-definition/{{element.testDefinitionId}}">{{element.testDefinitionId}}</a></td>
    </ng-container>

    <ng-container matColumnDef="bookingId" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">ATS Booking ID</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.id}}</td>
    </ng-container>

    <ng-container matColumnDef="bookingStatus" >
      <th class="mat-column-position" mat-header-cell *matHeaderCellDef mat-sort-header
          cdkDrag cdkDragLockAxis="x" cdkDragBoundary="mat-header-row">Status</th>
      <td class="mat-column-position" *matCellDef="let element">{{element.bookingStatus}}</td>
    </ng-container>

    <ng-container matColumnDef="view" [sticky]="true" [stickyEnd]="true">
      <th class="mat-column-position"  mat-header-cell *matHeaderCellDef></th>
      <td class="mat-column-position"  *matCellDef="let element">
        <div class="ut-margin-top-20px">
          <button class="btn btn-sm btn-primary" (click)="viewBooking(element.id)">
            View & Edit
          </button>
        </div>
      </td>
    </ng-container>

    <tr mat-header-row *matHeaderRowDef="bookingsColumns; sticky: true " ></tr>
    <tr mat-row *matRowDef="let row; columns: bookingsColumns"></tr>

  </mat-table>

  <div class="margin-top-80-px">
    <mat-paginator
                   [pageSize]="25"
                   [pageSizeOptions]="[25, 50, 100]" showFirstLastButtons>
    </mat-paginator>
  </div>

  <div *ngIf="bookingsDataSource && bookingsDataSource.filteredData.length === 0">
    No results found
  </div>

</div>
<!--Added here temporary for putting CIS messages in the queue-->
<br/>
