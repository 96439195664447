import {Component, Input, OnInit} from '@angular/core';
import {AbstractControl} from "@angular/forms";
import {ValidationService} from "../validation-service";

@Component({
  selector: 'app-validation-msg',
  template: `<div *ngIf="errorMsg !== null"
                  [ngClass]="warning === true ? 'warning' : 'error'">
               {{errorMsg}}
             </div>
            `,
  styleUrls: ['./validation-msg.component.scss']
})
export class ValidationMsgComponent implements OnInit {

  @Input() control: AbstractControl;
  @Input() warning = false;
  @Input() customErrorMsgs: {[key: string]: string};

  constructor() { }

  ngOnInit(): void {
  }

  /**
   * Returns a validation message if the input control contains errors, null otherwise.
   */
  get errorMsg(): string | null {

    if (this.control && this.control.hasOwnProperty('errors')) {
      for (const propertyName in this.control.errors) {
         if (this.control.errors.hasOwnProperty(propertyName) && this.control.errors) {
        // if (this.control.errors.hasOwnProperty(propertyName) && (this.control.dirty || this.control.touched) && this.control.errors) {
          // If custom error message was supplied, use that message, otherwise use the standard one
          if (this.customErrorMsgs && this.customErrorMsgs.hasOwnProperty(propertyName)) {
            return this.customErrorMsgs[propertyName];
          }
          return ValidationService.getValidatorErrorMessage(propertyName, this.control.errors[propertyName]);
        }
      }
    }
    return null;
  }
}
