<div>
  <div class="note-head">
    <h3 class="ut-margin-bottom-20px">Booking Notes</h3>
  </div>

  <!-- Info message - displayed if there aren't  booking notes -->
  <div *ngIf="!notes">
    <sis-notice type="information">
      You can view and edit the latest Booking Notes in Clockwork.
    </sis-notice>
  </div>

  <!-- Info Message - displayed if there are booking notes -->
  <div *ngIf="notes">
    <sis-notice type="information">
      The Booking Notes that appear here are  only from the ATS system.
      You can view and edit the latest Booking Notes in Clockwork.
    </sis-notice>
  </div>

  <div *ngFor="let note of notes">
    <br/>
    <p class="zero-margin-bottom">{{note}}</p>
  </div>
</div>

