import {Component, Input, OnInit} from '@angular/core';
import {Person} from '../../../model/types/person';
import {LogService} from '../../../common/services/log-service.service';

@Component({
  selector: 'app-course-contacts-card',
  templateUrl: './course-contacts-card.component.html',
  styleUrls: ['./course-contacts-card.component.scss']
})
export class CourseContactsCardComponent implements OnInit {

  @Input()
  instructors: Person[];

  @Input()
  department: Person;

  constructor(private logger: LogService) {
  }

  ngOnInit(): void {
    this.logger.debug(JSON.stringify(this.instructors), 'CourseContactsCardComponent.instructor');
    this.logger.debug(JSON.stringify(this.department), 'CourseContactsCardComponent.department');
  }
}
