import {Component, forwardRef, Input} from '@angular/core';
import {ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR} from "@angular/forms";
import {NgbDateAdapter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DatepickerAdapterService} from "../../services/datepicker-adapter.service";

@Component({
  selector: 'app-datepicker',
  templateUrl: './datepicker.component.html',
  styleUrls: ['./datepicker.component.scss'],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: DatepickerAdapterService
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => DatepickerComponent),
      multi: true
    }
  ]
})
export class DatepickerComponent implements ControlValueAccessor {

  @Input()
  minDate: NgbDateStruct;
  @Input()
  maxDate: NgbDateStruct;

  date = new FormControl('');

  isDisabled: boolean;
  changed = (value) => {};
  touched = () => {};

  // Set into datepicker the date received from the parent.
  writeValue(value: string): void {
    if (value != null) {
      this.date.setValue(value);
    } else {
      this.date.reset();
    }
  }

  // When the date changed, send it to the parent by calling the registered callback function: this.change().
  registerOnChange(fn: any): void {
    this.changed = fn;
    this.date.valueChanges.subscribe(val => {
      this.changed(val);
    });
  }

  registerOnTouched(fn: any): void {
    this.touched = fn;
  }

  setDisabledState(isDisabled: boolean): void {
    this.isDisabled = isDisabled;
  }

}
