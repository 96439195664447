<div>
  <!-- Table with contacts -->
  <div class="margin-top-2-rem">
    <div class="row">
      <div class="col-12">
        <table class="table">
          <thead>
          <tr>
            <th scope="col">Sending Action</th>
            <th scope="col">Contact Name</th>
            <th scope="col">Contact Type</th>
            <th scope="col">Email Address</th>
          </tr>
          </thead>
          <tbody>
          <ng-container *ngFor="let contactRec of emailContactRec">
            <tr>
              <td>
                <select class="form-select" area-label="Select options" [formControl]="contactRec.sendingAction" id="sendingActionId">
                  <option *ngFor="let sendingActionVal of Utils.sendingActionVals" [ngValue]="sendingActionVal">
                    {{Utils.sendingActionMap.get(sendingActionVal)}}
                  </option>
                </select>
              </td>
              <td>{{contactRec.contactName}}</td>
              <td>{{contactRec.contactType.includes('ROSI') ? contactRec.contactType : Utils.contactTypeMap.get(contactRec.contactType)}}</td>
              <td>{{contactRec.emailAddress}}</td>
            </tr>
          </ng-container>
          </tbody>
        </table>
      </div>
    </div>
  </div>

</div>
