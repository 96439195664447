import {Component, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator, Validators, ValidatorFn
} from "@angular/forms";
import {NgbTimeAdapter, NgbTimepickerConfig} from "@ng-bootstrap/ng-bootstrap";
import {DurationpickerAdapterService} from "../../services/durationpicker-adapter.service";

/**
 * Component: RfDurationpickerComponent
 *
 * The component implements the Angular ControlValueAccessor and Validator interfaces.
 * That makes the component a reusable nested form component.
 * Doing this we get the following benefits:
 *  - the component can be set as in the main [formGroup] as any other AbstractControl element (i.e. <input...> element);
 *  - can have it's own validator and the validation state will be  sent/merged with the parent validation,
 *    that meaning if the component sub-form will be invalid the parent form will be invalid as well;
 *
 *  Inputs:
 *     formControlName: AbstractControl - Mandatory. A form control;
 *     minDate: boolean                 - Optional. If set the datepicker allows selecting only dates after minDate;
 *
 * Usage:
 *    <app-rf-durationpicker formControlName="[duration]"
 *                           [readonly]="[true|false]">
 *    </app-rf-durationpicker>
 */
@Component({
  selector: 'app-rf-durationpicker',
  templateUrl: './rf-durationpicker.component.html',
  styleUrls: ['./rf-durationpicker.component.scss'],
  providers: [
    NgbTimepickerConfig,
    {
      provide: NgbTimeAdapter,
      useClass: DurationpickerAdapterService
    },
    {
      // make it visible to the form APIs
      provide: NG_VALUE_ACCESSOR,
      useExisting: RfDurationpickerComponent,
      multi: true
    },
    {
      // make the validator visible to the form APIs
      provide: NG_VALIDATORS,
      useExisting: RfDurationpickerComponent,
      multi: true
    }
  ]
})
export class RfDurationpickerComponent implements OnInit, ControlValueAccessor, Validator {

  @Input()
  disabled = false;

  @Input() isRequired = true;

  duration = new UntypedFormControl('');

  constructor(public config: NgbTimepickerConfig) {
    config.meridian = false;
    config.spinners = false;
    config.minuteStep = 5;
    config.readonlyInputs = false;
    config.disabled = false;
  }

  ngOnInit(): void {

    // Set up validators conditionally
    const validators: ValidatorFn[] = [];
    if (this.isRequired) {
      validators.push(Validators.required);
    }
    this.duration.setValidators(validators);
    this.duration.updateValueAndValidity();
  }

  // callback methods
  onChange: any = () => {};
  onTouched: any = () => {};

  // - CVA: Write value to display in view (model -> View)
  writeValue(value: any): void {
    if (value) {
      this.duration.setValue(value);
      // this.duration.setValue(value, {emitEvent: false});
    }
    if (value === null) {
      this.duration.reset();
    }
  }

  // - CVA: Callback method for value changes in DOM (View -> Model)
  //   (you can save a callback function - fn so you can call when value changes on the DOM side).
  registerOnChange(fn: any): void {
    // this.onChange = fn;
    this.duration.valueChanges.subscribe(fn);
  }

  // - CVA: Callback method for toggling "touched" property (View -> Model); usually onBlur.
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // - CVA: Enable/disable programmatically element in view (Optional method)
  setDisabledState(isDisabled: boolean): void {
    // isDisabled ? this.duration.disable() : this.duration.enable();
  }

  // -- Validator interface methods --

  // - Validator:
  validate(control: AbstractControl): ValidationErrors | null {
    return this.duration.valid ? null : { time: {valid: false }};

    // return Validators.required(control);
  }

  // - Validator:
  registerOnValidatorChange(fn: () => void): void {
  }
}
