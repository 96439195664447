/*
 * work as a global variant holding environment properties reading from relative environment setting file,
 */
export class Environment {
  static production: boolean;
  static mode: string;
  static restHost: string;
  static restUrl: string;
  static clientId: string;
  static sharepointBaseUrl: string;

  static isRelease: boolean;
}
