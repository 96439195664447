import moment from "moment";
import {SingleDesignateAssignment} from "../model/types/single-designate-assignment";
import {DesignateStatusValue} from "./designate-status-value.enum";

/**
 * Util static methods use in Designates.
 */
export class DesignatesUtil {

  /**
   * Determine the label to return based on designate expiry date.
   * @param endDate:string  The date until designate is valid.
   */
  static getDesignateExpiryDateLabel(endDate: Date): string {
    const val: string = !endDate ? null : moment(endDate).format('YYYY-MM-DD');

    return DesignatesUtil.getDesignExpiryDateLabel(val);
  }

  /**
   * Determine the label to return based on designate expiry date.
   * The label will be used for displaying in the Valid Until column the designate tables.
   * @param endDate:string  The date until designate is valid.
   */
  static getDesignExpiryDateLabel(endDate: string): string {
    let val = '';
    const currentDate = moment().startOf('day');
    const designationExpiryDate = !endDate ? null : moment(endDate);

    if (!designationExpiryDate) {
      val = 'Indefinite';
    } else if (designationExpiryDate.isBefore(currentDate)) {
      val = 'Expired';
    } else {
      val = endDate;
    }
    return val;
  }

  /**
   * Filter the Division/Unit Designates or Course Designate tables by the valid statuses
   * shown in the drop-down box: 'All Designates', 'Currently Valid', 'Expired'.
   * Returns true if the record (represented by the SingleDesignateAssignment object) should
   * be shown in the table(s), false otherwise.
   *
   * @param data: SingleDesignateAssignment  The object represents a row in the designate table.
   * @param filter  The designate status (i.e. DesignateStatus.CURRENTLY_VALID).
   */
  static filterDesignateByStatus(data: SingleDesignateAssignment, filter: string): boolean {

    let filterDesignate: boolean;

    const currentDate = moment().startOf('day');
    const designationExpiryDate = !data.designate.endDate ? null : moment(data.designate.endDate);

    switch (filter) {
      case DesignateStatusValue.ALL_DESIGNATES:
        // no filtering needed;
        filterDesignate = true;
        break;
      case DesignateStatusValue.CURRENTLY_VALID:
        // filter valid designates: designates with no expiry date and designates with expiry date equal or after the current date;
        filterDesignate = designationExpiryDate === null || !designationExpiryDate.isBefore(currentDate);
        break;
      case DesignateStatusValue.EXPIRED:
        // filter expired designates: designates with expiry date before current date;
        filterDesignate = designationExpiryDate !== null && designationExpiryDate.isBefore(currentDate);
        break;
      default:
        filterDesignate = false;
    }
    return filterDesignate;
  }

  /** Translate email and role permissions to text. */
  static getPermissions(data: SingleDesignateAssignment): string {
    let result = '';

    if (data.designate.emailPreference === 'A') {
      result = "All Communication";
    }
    if (data.designate.emailPreference === 'L') {
      result = "Late Communication";
    }
    if (data.designate.daRoleInd) {
      result = result + " " + "Accommodation Admin Role";
    }
    if (!data.designate.daRoleInd) {
      result = result + " " + "Instructor Role";
    }
    result = result.trim();

    return result;
  }

}
