import {Component, forwardRef, Input, OnInit} from '@angular/core';
import {
  AbstractControl,
  ControlValueAccessor,
  UntypedFormControl,
  NG_VALIDATORS,
  NG_VALUE_ACCESSOR,
  ValidationErrors,
  Validator
} from "@angular/forms";
import {Subscription} from "rxjs";
import {NgbDateAdapter, NgbDateStruct} from "@ng-bootstrap/ng-bootstrap";
import {DatepickerAdapterService} from "../../services/datepicker-adapter.service";

/**
 * Component: RfDatepickerComponent
 *
 * The component implements the Angular ControlValueAccessor and Validator interfaces.
 * That makes the component a reusable nested form component.
 * Doing this we get the following benefits:
 *  - the component can be set as in the main [formGroup] as any other AbstractControl element (i.e. <input...> element);
 *  - can have it's own validator and the validation state will be  sent/merged with the parent validation,
 *    that meaning if the component sub-form will be invalid the parent form will be invalid as well;
 *
 *  Inputs:
 *     formControlName: AbstractControl - Mandatory. A form control;
 *     minDate: boolean                 - Optional. If set the datepicker allows selecting only dates after minDate;
 *
 * Usage:
 *    <app-rf-datepicker formControlName="endDate"
 *                       [minDate]="minDate" [maxDate]="maxDate">
 *    </app-rf-datepicker>
 */
@Component({
  selector: 'app-rf-datepicker',
  templateUrl: './rf-datepicker.component.html',
  styleUrls: ['./rf-datepicker.component.scss'],
  providers: [
    {
      provide: NgbDateAdapter,
      useClass: DatepickerAdapterService
    },
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => RfDatepickerComponent),
      multi: true
    }, {
      provide: NG_VALIDATORS,
      useExisting: forwardRef(() => RfDatepickerComponent),
      multi: true
    }
  ]
})
export class RfDatepickerComponent implements OnInit, ControlValueAccessor, Validator {

  // Minimum and maximum selectable dates in calendar.
  @Input() minDate: NgbDateStruct;
  @Input() maxDate: NgbDateStruct;

  date: UntypedFormControl = new UntypedFormControl('');

  subscriptions: Subscription[] = [];

  constructor() {}

  ngOnInit(): void {
  }

  onChange: any = () => {};
  onTouched: any = () => {};

  // -- ControlValueAccessor interface methods --
  // - ControlValueAccessor method
  // Write value to display in view
  writeValue(value: any): void {
    // console.log('-> writeValue: val: ' + JSON.stringify(value)); // value comes from BE like: 2020-11-21
    if (value) {
      // this.value = value; // calls set value()
      this.date.setValue(value, {emitEvent: false});
    }
    if (value === null) {
      this.date.reset();
    }
  }

  // - ControlValueAccessor method
  // Callback for value changes in DOM
  registerOnChange(fn: any): void {
    this.date.valueChanges.subscribe(fn);
  }

  // - ControlValueAccessor method
  // Callback for toggling "touched" property
  registerOnTouched(fn: any): void {
    this.onTouched = fn;
  }

  // - ControlValueAccessor method
  // Enable/disable element in view
  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.date.disable() : this.date.enable();
  }
  // -- end of ControlValueAccessor methods --

  // -- Validator interface methods --
  // - Validator method
  registerOnValidatorChange(fn: () => void): void {
  }

  // - Validator method
  validate(control: AbstractControl): ValidationErrors | null {
    // Validation is done in parent, so always return valid from here.
    return null;
  }
  // -- end of Validator methods --

}
