import {AssessmentTimingInd} from "../../booking/types/assessment-timing-ind";

export class Schedule {

  startDate: Date;
  endDate: Date;
  location: string;
  assessmentTimingInd: AssessmentTimingInd;
  assessmentTimingIndDesc: string;

  constructor(public startTime: Date,
              public endTime: Date,
              public duration: number,
              public seat: string) {
  }

}
