
<!-- Template for CourseSearchComponent. (Search for Course typeahead) -->
<div [formGroup]="parentForm">
  <label class="form-label" for="searchCourse">Search for Course</label>
  <input type="text" id="searchCourse" class="form-control" formControlName="course"
         [ngbTypeahead]="searchForCourse" [resultFormatter]="courseFormatter" [inputFormatter]="courseFormatter"
         (selectItem)="onSelectedCourse($event)"
         (click)="click$.next($any($event).target.value)"
         #instance="ngbTypeahead"
         placeholder="Type course code">
  <span class="text-danger">{{validationMessage}}</span>
</div>

<!-- Display a spin while waiting for the student async call or a message if there are no results returned -->
<div *ngIf="searching">
  <i class="fa fa-spinner fa-spin fa-x fa-fw" aria-hidden="true"></i>
  <span class="sr-only">Searching</span>
</div>
<div *ngIf="searchFailed">
  <span class="sr-only">Sorry, suggestions could not be loaded.</span>
</div>
