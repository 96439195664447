<div class="course-contacts-dashboard">
  <h1>Manage Course Contacts</h1>

  <p>You can view, edit, and filter for existing contacts for a subject area, course, or meeting section.</p>
  <p>
    You can add new course contacts for subject areas, courses, or meeting sections. If a contact already exists for
    a subject area, course, or meeting section, you can add another contact to it by editing the existing contact details.
  </p>
  <button id="createTestDefinition" class="btn btn-primary"
          (click)="addEmailContact()">Add New Course Contact</button>
  <br/><br/>

  <h2>Course Contacts</h2>

  <app-course-contacts-filter [dataSource]="dataSource"></app-course-contacts-filter>
  <br/>

  <div class="email-dashboard-table">
    <table mat-table [dataSource]="dataSource" table-container matSort class="table">

      <!-- Subject Area, Course or Meeting Section -->
      <ng-container matColumnDef="Course">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Subject Area, Course, or Meeting Section </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'less-visible': element.hide}">
          {{element.course.course}}
          {{element.course?.sectionCode}}
          {{element.course?.teachMethod}}
          {{element.course?.sectionNumber}}
        </td>
      </ng-container>

      <!-- Level of Study -->
      <ng-container matColumnDef="Level of Study">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Level of Study </th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'less-visible': element.hide}"> {{element.levelOfStudy}}</td>
      </ng-container>


      <!-- === Contacts array start === -->

      <!-- Contact Name -->
      <ng-container matColumnDef="Contact Name">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Name </th>
        <td mat-cell *matCellDef="let element"> {{element.contactName}}</td>
      </ng-container>

      <!-- Contact Type -->
      <ng-container matColumnDef="Contact Type">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Contact Type </th>
        <td mat-cell *matCellDef="let element"> {{element.contactType}}</td>
      </ng-container>

      <!-- Email Address -->
      <ng-container matColumnDef="Email Address">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Email Address </th>
        <td mat-cell *matCellDef="let element"> {{element.emailAddress}}</td>
      </ng-container>

      <!-- Valid Until -->
      <ng-container matColumnDef="Valid Until">
        <th mat-header-cell *matHeaderCellDef mat-sort-header> Valid Until </th>
        <td mat-cell *matCellDef="let element">
          <span [ngClass]="{'expired-date': element.expiredDate}">
            {{element.validUntil}}
            <span *ngIf="element.expiredDate"><br>(Expired)</span>
          </span>
        </td>
      </ng-container>

      <!-- === Contacts array end === -->

      <!-- Notes -->
      <ng-container matColumnDef="Notes">
        <th mat-header-cell *matHeaderCellDef> Notes </th>
        <td class="notes" mat-cell *matCellDef="let element" [ngClass]="{'less-visible': element.hide}"> {{element.notes}}</td>
      </ng-container>

      <!-- Edit buttons -->
      <ng-container matColumnDef=" ">
        <th mat-header-cell *matHeaderCellDef></th>
        <td mat-cell *matCellDef="let element" [ngClass]="{'less-visible': element.hide}">
          <sis-button visualType="primary" size="small" (clicked)="editContactDetails(element)">Edit Contact Details</sis-button>
        </td>
      </ng-container>

      <tr mat-header-row *matHeaderRowDef="displayColumns"></tr>
      <tr mat-row *matRowDef="let row; columns: displayColumns;"></tr>

    </table>

    <div class="">
      <mat-paginator
        [pageSize]="25"
        [pageSizeOptions]="[25, 50]" showFirstLastButtons>
      </mat-paginator>
    </div>

  </div>
</div>

<!-- Back to Instructor Emailing -->
<div class="mt-3">
  <a [routerLink]="['/instructor-emailing']">Back to Instructor Emailing</a>
</div>
