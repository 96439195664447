import {Component, Input, OnInit} from '@angular/core';
import {INavigateAway} from '../../../common/types/inavigate-away';
import {ModelService} from "../../../model/model.service";
import {ActivatedRoute} from "@angular/router";
import {AccommodationInstructions} from "../../../model/types/accommodation-instructions";
import {AidTypes} from "../../../model/types/aid-types";
import {ScantronVersions} from "../../../model/types/scantron-versions";
import {AudioVisualComps} from "../../../model/types/audio-visual-comps";
import {OnlineAssessmentTypes} from "../../../model/types/OnlineAssessmentTypes";
import {LogService} from "../../../common/services/log-service.service";
import {Environment} from "../../../../environment";
import {ConsentMessage} from "../../../common/types/consent-message";
import {AssessmentCompletionInd} from "../../../common/types/assessment-completion-ind.enum";
import {WorkflowStatusCode} from "../../../common/types/workflow-status-code.enum";
import {AssessmentTimingInd} from "../../types/assessment-timing-ind";

@Component({
  selector: 'app-test-details-tab',
  templateUrl: './test-details-tab.component.html',
  styleUrls: ['./test-details-tab.component.scss']
})
export class TestDetailsTabComponent implements OnInit, INavigateAway {

  @Input()
  testDetails: AccommodationInstructions;

  @Input()
  NotShowBookingInfo: boolean;


  aidMap: Map<number, string> = new Map();
  scantronMap: Map<number, string> = new Map();
  audiovisualMap: Map<number, string> = new Map();
  onlineAssessmentTypeMap: Map<number, string> = new Map();

  schedulingCompleted: boolean;
  detailsCompleted: boolean;
  printingOptionsCompleted: boolean;

  // The sharepoint folder where the uploaded test definition files are stored
  sharepointFilesFolder: string;

  // The student test booking Id, as in the database, from the URL
  studentTestBookingId: string;

  querrusConsentMessage = ConsentMessage.Quercus_Consent_Yes;
  querrusNotConsentMessage = ConsentMessage.Quercus_Consent_No;

  // Variable reference to our Enum
  assessmentCompletionInd = AssessmentCompletionInd;
  assessmentTimingInd = AssessmentTimingInd;

  // tslint:disable-next-line:no-shadowed-variable
  constructor(private route: ActivatedRoute, private model: ModelService,
              private logger: LogService) {

  }

  ngOnInit(): void {
    if (this.NotShowBookingInfo) {
      this.logger.debug('test details: ', JSON.stringify(this.testDetails));
      this._checkCompleteness(this.testDetails);
      this.setSharepointFolder(this.testDetails);
    } else {
      this.route.params.subscribe(routeParams => {
        this.studentTestBookingId = routeParams.id;

        if (this.studentTestBookingId) {
          this.model.getTestDetails(this.studentTestBookingId).subscribe(res => {
            this.testDetails = res;
            this._checkCompleteness(res);
            this.setSharepointFolder(res);
          });
        }
      });
    }


    // TODO: NOT-USED. To be removed later if this method won't be needed.
    // this.model.getAccommodationChoices().subscribe(res => {
    //   this.createAidMap(res.aidTypes);
    //   this.createScantronMap(res.scantronVersions);
    //   this.createAudiovisualMap(res.audiovisualComps);
    //   this.createOnlineAssessmentTypeMap(res.onlineAssessmentTypes);
    // });
  }

  /**
   * NavigateAway interface method.
   * Returns true if there is no unsaved data, false otherwise.
   */
  canNavigateAway(): boolean {
    return true;
  }

  setSharepointFolder(testDetails: AccommodationInstructions): void {
    if (!testDetails.requiresFileUpload
      || !Array.isArray(testDetails.files)
      || !testDetails.files.length
      || !testDetails.files[0].documentLocation
      || !testDetails.files[0].documentLocation.length) {
      return;
    }

    // A file location looks like: "documentLocation": /SLP/2020-09-22/PHM110H1-F-LEC-0101/ATS-2020-07-07.pdf
    const aDocumentLocation = testDetails.files[0].documentLocation;
    // get the location without the file name
    const sharepointFolder = aDocumentLocation.substring(0, aDocumentLocation.lastIndexOf('/'));

    this.sharepointFilesFolder = Environment.sharepointBaseUrl + sharepointFolder;
    this.logger.debug(`=> Sharepoint folder: ${this.sharepointFilesFolder}`, `${this.constructor.name}`);
  }


  // What kind of paper size is needed
  // Note: apparently in CIS we set only LEGAL or LETTER
  printPageSize(pageSize: string): string {
    return pageSize === 'LEGAL' ? 'Legal (8.5" x 14")' : pageSize === 'LETTER' ? 'Letter (8.5" x 11")' : 'N/A';
  }

  // On which side should the page be printed
  printSide(twoSided: boolean) {
    return twoSided === true ? 'Print 2-sided' : twoSided === false ? 'Print 1-sided' : 'N/A';
  }

  // Should be printed colour or black-and-white
  // Note: apparently in CIS we set only COLOR or BLACK_AND_WHITE
  printColour(colourSettings: string) {
    return colourSettings === 'COLOR' ? 'Full Colour' : colourSettings === 'BLACK_AND_WHITE' ? 'Black & White' : 'N/A';
  }

  createAidMap(aids: AidTypes[]): void {
    if (!Array.isArray(aids)) { return; }

    // populate the map
    this.aidMap.clear();
    aids.forEach(aid => this.aidMap.set(aid.aidTypeId, aid.aidType));
  }

  createScantronMap(scantrons: ScantronVersions[]): void {
    if (!Array.isArray(scantrons)) { return; }

    // populate the map
    this.scantronMap.clear();
    scantrons.forEach(sc => this.scantronMap.set(sc.scantronVersionId, sc.scantronVersion));
  }

  createAudiovisualMap(audiovisuals: AudioVisualComps[]) {
    if (!Array.isArray(audiovisuals)) { return; }

    // populate the map
    this.audiovisualMap.clear();
    audiovisuals.forEach(av => this.audiovisualMap.set(av.audiovisualCompId, av.audiovisualComp));
  }

  createOnlineAssessmentTypeMap(onlineAssessmentTypes: OnlineAssessmentTypes[]) {
    if (!Array.isArray(onlineAssessmentTypes)) { return; }

    // populate the map
    this.onlineAssessmentTypeMap.clear();
    onlineAssessmentTypes.forEach(oat => this.onlineAssessmentTypeMap.set(oat.onlineAssessmentTypeId, oat.onlineAssessmentType));
  }

  _checkCompleteness(testDetails: AccommodationInstructions): void {
    console.log("=> workflowStatus: " + JSON.stringify(testDetails.workflowStatus));

    this.schedulingCompleted = this._isSchedulingCompleted(testDetails);
    this.detailsCompleted = this._areTestDetailsCompleted(testDetails);
    this.printingOptionsCompleted = this._arePrintingOptionsCompleted(testDetails);
  }

  _isSchedulingCompleted(details: AccommodationInstructions): boolean {
    return this._areTestDetailsCompleted(details);
  }

  _areTestDetailsCompleted(details: AccommodationInstructions): boolean {
    return details.workflowStatus !== null &&
              (details.workflowStatus.statusCode === WorkflowStatusCode[WorkflowStatusCode.NEEDS_FILES]
               || details.workflowStatus.statusCode === WorkflowStatusCode[WorkflowStatusCode.SUBMITTED_TO_ATS_NO_FILES]
               || details.workflowStatus.statusCode === WorkflowStatusCode[WorkflowStatusCode.SUBMITTED_TO_ATS_WITH_FILES]);
  }

  _arePrintingOptionsCompleted(details: AccommodationInstructions): boolean {
    return details.workflowStatus != null
              && details.workflowStatus.statusCode === WorkflowStatusCode[WorkflowStatusCode.SUBMITTED_TO_ATS_WITH_FILES];
  }

}
