<div class="test-details">

  <!-- TODO: NOT-USED. To be removed later if this method won't be needed. -->
  <!-- Buttons -->
<!--  <div class="mt-3">-->
<!--    &lt;!&ndash; View File(s) button &ndash;&gt;-->
<!--    <a *ngIf="testDetails && testDetails.requiresFileUpload && testDetails.files.length"-->
<!--       class="btn btn-primary" href="{{sharepointFilesFolder}}"-->
<!--       role="button" target="_blank" rel="noopener">-->
<!--      View File(s)-->
<!--    </a>-->
<!--  </div>-->

  <!-- Test details found -->
  <div *ngIf="testDetails?.workflowStatus?.statusCode !== 'INSTRUCTOR_REJECTED'">

    <!-- TODO: NOT-USED. To be removed later if this method won't be needed. -->
    <!-- >>> Test Details from Instructor <<< -->
<!--    <div class="test-details-from-instructor">-->
<!--      <h3>Test Details from Instructor</h3>-->

<!--      &lt;!&ndash; *** Scheduling *** &ndash;&gt;-->
<!--      <div class="mt-3">-->
<!--        <h4>Scheduling</h4>-->
<!--        &lt;!&ndash; Schedule is complete &ndash;&gt;-->
<!--        <div *ngIf="schedulingCompleted === true">-->
<!--          <ul>-->
<!--            &lt;!&ndash; Is this an online assessment? &ndash;&gt;-->
<!--            <li *ngIf="testDetails.assessmentOnlineInd === true">-->
<!--              Online-->
<!--            </li>-->

<!--            &lt;!&ndash; Online Type Dropdown &ndash;&gt;-->
<!--            <li *ngIf="testDetails.assessmentOnlineInd === true && testDetails.onlineAssessmentTypeId">-->
<!--              {{onlineAssessmentTypeMap.get(testDetails.onlineAssessmentTypeId)}}-->
<!--              <span *ngIf="testDetails.quercusPermissionToAtsInd === true">{{querrusConsentMessage}}</span>-->
<!--              <span *ngIf="testDetails.quercusPermissionToAtsInd === false">{{querrusNotConsentMessage}}</span>-->
<!--            </li>-->

<!--            &lt;!&ndash; How is this assessment being completed? &ndash;&gt;-->
<!--            <li *ngIf="testDetails.assessmentCompletionInd" [ngSwitch]="testDetails.assessmentCompletionInd">-->
<!--              &lt;!&ndash; Online &ndash;&gt;-->
<!--              <span *ngSwitchCase="assessmentCompletionInd.ONLINE">-->
<!--                Students will complete this exclusively online-->
<!--              </span>-->
<!--              &lt;!&ndash; Take Home &ndash;&gt;-->
<!--              <span *ngSwitchCase="assessmentCompletionInd.TAKE_HOME">-->
<!--                Students will complete this assessment by downloading/printing the assessment and uploading their-->
<!--                submission-->
<!--              </span>-->
<!--              &lt;!&ndash; In Person &ndash;&gt;-->
<!--              <span *ngSwitchCase="assessmentCompletionInd.IN_PERSON">-->
<!--                Students will complete this online assessment in person-->
<!--              </span>-->
<!--            </li>-->

<!--            &lt;!&ndash; Timing and Submission (Strict, Fixed or Flexible &ndash;&gt;-->
<!--            <li *ngIf="testDetails.assessmentTimingInd" [ngSwitch]="testDetails.assessmentTimingInd">-->
<!--              <span *ngSwitchCase="assessmentTimingInd.Strict">Strict</span>-->
<!--              <span *ngSwitchCase="assessmentTimingInd.Fixed">Fixed</span>-->
<!--              <span *ngSwitchCase="assessmentTimingInd.Flexible">Flexible</span>-->
<!--            </li>-->

<!--            &lt;!&ndash; Is there a mandatory lecture before or after this assessment? &ndash;&gt;-->
<!--            <li *ngIf="testDetails.lecBeforeOrAfterAssessmentInd && testDetails.lecBeforeOrAfterAssessmentInd !== 'N'">-->
<!--              {{testDetails.lecBeforeOrAfterAssessmentInd === 'B' ?-->
<!--              'Lecture before the assessment' : 'Lecture after the assessment, student must return by '}}-->
<!--                                        {{testDetails.lectureAfterReturnToClassBy | date:'yyyy-MM-dd h:mm a'}}-->
<!--            </li>-->

<!--            &lt;!&ndash; Is there a group component for this assessment? &ndash;&gt;-->
<!--            <li *ngIf="testDetails.groupComponentForAssessmentInd === true">-->
<!--              Group component, student must return by-->
<!--                                       {{testDetails.groupComponentReturnToClassBy | date:'yyyy-MM-dd h:mm a'}}-->
<!--            </li>-->

<!--            &lt;!&ndash; Special Instructions to Accommodated Testing Services (Optional) &ndash;&gt;-->
<!--            <li *ngIf="testDetails.specialInstructions">-->
<!--              Special Instruction: {{testDetails.specialInstructions}}-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        &lt;!&ndash; Schedule is incomplete &ndash;&gt;-->
<!--        <div *ngIf="schedulingCompleted === false">-->
<!--          No scheduling details have been submitted yet.-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; *** Test Details *** &ndash;&gt;-->
<!--      <div class="mt-3">-->
<!--        <h4>Test Details</h4>-->
<!--        &lt;!&ndash; Details are complete &ndash;&gt;-->
<!--        <div *ngIf="detailsCompleted === true">-->
<!--          &lt;!&ndash; Are aids permitted? &ndash;&gt;-->
<!--          <ul *ngIf="testDetails.aidPermittedInd === true">-->
<!--            <li *ngFor="let aid of testDetails.accommodationAids; let i = index">-->
<!--              Aid {{i + 1}}: {{aidMap.get(aid.aidTypeId)}} <span *ngIf="aid.aidDetails">, {{aid.aidDetails}}</span>-->
<!--            </li>-->
<!--          </ul>-->

<!--          &lt;!&ndash; Are questions answered on Scantron multiple choice sheets? &ndash;&gt;-->
<!--          <ul *ngIf="testDetails.scantronMultipleChoiceInd === true">-->
<!--            <li>-->
<!--              Scantron: {{scantronMap.get(testDetails.scantronVersionId)}}-->
<!--                                       <span *ngIf="testDetails.scantronDetails">, {{testDetails.scantronDetails}}</span>-->
<!--            </li>-->
<!--          </ul>-->

<!--          &lt;!&ndash; Is an audiovisual component required? / Audiovisual Component dropdown &ndash;&gt;-->
<!--          <ul *ngIf="testDetails.audiovisualRequiredInd === true">-->
<!--            <li *ngFor="let av of testDetails.accommodationAudiovisuals; let i = index">-->
<!--              &lt;!&ndash; AV {#}: {AV type/"Other" input} &ndash;&gt;-->
<!--              AV {{i + 1}}: {{audiovisualMap.get(av.audiovisualCompId)}}-->
<!--                                   <span *ngIf="av.audiovisualDetailsForOther">, {{av.audiovisualDetailsForOther}}</span>-->
<!--              <br/>-->
<!--              &lt;!&ndash; AV {#} - How long to show each slide/clip: {input} &ndash;&gt;-->
<!--              <span *ngIf="av.audiovisualHowLongToShow">-->
<!--                  AV {{i + 1}} - How long to show each slide/clip: {{av.audiovisualHowLongToShow}}-->
<!--                <br/>-->
<!--              </span>-->
<!--              &lt;!&ndash; AV {#} - How many times to show each slide/clip: {Input} &ndash;&gt;-->
<!--              <span *ngIf="av.audiovisualHowManyTimesToShow">-->
<!--                  AV {{i + 1}} - How many times to show each slide/clip: {{av.audiovisualHowManyTimesToShow}}-->
<!--                <br/>-->
<!--              </span>-->
<!--              &lt;!&ndash; AV {#} - Total length: {input} &ndash;&gt;-->
<!--              <span *ngIf="av.audiovisualLength">-->
<!--                 AV {{i + 1}} - Total length: {{av.audiovisualLength}}-->
<!--                <br/>-->
<!--              </span>-->
<!--              &lt;!&ndash; AV {#} - When to administer: {input} &ndash;&gt;-->
<!--              <span *ngIf="av.audiovisualWhenShouldBeAdmin">-->
<!--                 AV {{i + 1}} - When to administer: {{av.audiovisualWhenShouldBeAdmin}}-->
<!--                <br/>-->
<!--              </span>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        &lt;!&ndash; Details are incomplete &ndash;&gt;-->
<!--        <div *ngIf="detailsCompleted === false">-->
<!--          No test details have been submitted yet.-->
<!--        </div>-->
<!--      </div>-->

<!--      &lt;!&ndash; Printing Options &ndash;&gt;-->
<!--      <div class="mt-3">-->
<!--        <h4>Printing Options</h4>-->
<!--        &lt;!&ndash; Printing options are complete &ndash;&gt;-->
<!--        <div *ngIf="printingOptionsCompleted === true">-->
<!--          &lt;!&ndash; Do you have a file to upload? &ndash;&gt;-->
<!--          &lt;!&ndash; -Yes, file upload &ndash;&gt;-->
<!--          <div *ngIf="testDetails.requiresFileUpload === true">-->
<!--            <ul>-->
<!--              <li *ngFor="let file of testDetails.files; let i = index">-->
<!--                File {{i + 1}}: {{file.documentName}}-->
<!--              </li>-->
<!--            </ul>-->
<!--          </div>-->
<!--          &lt;!&ndash; -No, no file upload &ndash;&gt;-->
<!--          <div *ngIf="testDetails.requiresFileUpload === false">-->
<!--            No file uploaded.-->
<!--          </div>-->
<!--          &lt;!&ndash; print related options &ndash;&gt;-->
<!--          <ul *ngIf="testDetails.printOptions">-->
<!--            &lt;!&ndash; crowdmark &ndash;&gt;-->
<!--            <li *ngIf="testDetails.printOptions.crowdMark === true">Crowdmark</li>-->
<!--            &lt;!&ndash; page size &ndash;&gt;-->
<!--            <li *ngIf="testDetails.printOptions.pageSize !== null">{{printPageSize(testDetails.printOptions.pageSize)}}</li>-->
<!--            &lt;!&ndash; one or two sided &ndash;&gt;-->
<!--            <li *ngIf="testDetails.printOptions.twoSided !== null">{{printSide(testDetails.printOptions.twoSided)}}</li>-->
<!--            &lt;!&ndash; colour settings: color or black-and-white &ndash;&gt;-->
<!--            <li *ngIf="testDetails.printOptions.colorSettings !== null">{{printColour(testDetails.printOptions.colorSettings)}}</li>-->
<!--          </ul>-->
<!--          &lt;!&ndash; Video URL &ndash;&gt;-->
<!--          <ul *ngIf="testDetails.links && testDetails.links.length">-->
<!--            <li *ngFor="let link of testDetails.links; let i = index">-->
<!--              URL {{i + 1}}: <a href="{{link.documentLocation}}" target="_blank" rel="noopener">{{link.documentLocation}}</a>-->
<!--            </li>-->
<!--          </ul>-->
<!--        </div>-->
<!--        &lt;!&ndash; Printing options are incomplete &ndash;&gt;-->
<!--        <div *ngIf="printingOptionsCompleted === false">-->
<!--          No printing options have been submitted yet.-->
<!--        </div>-->
<!--      </div>-->
<!--    </div>-->

    <br/>
    <!-- >>> Test Details from Student <<< -->
    <div class="test-details-from-student" *ngIf="this.NotShowBookingInfo !== true">
      <h3>Test Details from Student</h3>
      <div *ngIf="testDetails">
        <ul>
          <!-- Is this assessment being completed online? -->
          <li *ngIf="testDetails.bookingCompletedOnline === true">Student indicated that assessment being completed online</li>
          <li *ngIf="testDetails.bookingCompletedOnline === false">Student indicated that assessment is not online</li>
          <!-- Would you like to complete this online assessment in person with ATS? -->
          <li *ngIf="testDetails.bookingInPersonWithAts === true">In person with ATS</li>
        </ul>
      </div>
      <div *ngIf="!testDetails">
        No test details from student have been submitted yet.
      </div>
    </div>
  </div>

  <!-- To get the text from UX -->
  <div *ngIf="testDetails?.workflowStatus?.statusCode === 'INSTRUCTOR_REJECTED'">
    [The test definition was rejected by the instructor in CIS!]
  </div>

</div>
