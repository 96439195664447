/**
 * Holds possible error values returned by the backend when the call to delete a test definition fails.
 * Note: this enum is the same as the one used on tha backend (Java web service).
 */
export enum DeleteTestDefinitionError {

  CLOCKWORK_DEL_TEST_DEF_HAS_BOOKINGS_ERR = "CLOCKWORK_DEL_TEST_DEF_HAS_BOOKINGS_ERR",
  CLOCKWORK_DEL_TEST_DEF_NOT_IN_CLOCKWORK_ERR = "CLOCKWORK_DEL_TEST_DEF_NOT_IN_CLOCKWORK_ERR",
  CLOCKWORK_DEL_TEST_CANNOT_DELETE_RELATED_INFO_ERR = "CLOCKWORK_DEL_TEST_CANNOT_DELETE_RELATED_INFO_ERR",
  CLOCKWORK_DEL_TEST_DEF_CALL_ERR = "CLOCKWORK_DEL_TEST_DEF_CALL_ERR"
}
