import { Component } from '@angular/core';

@Component({
  selector: 'design-root',
  templateUrl: './design.component.html',
  styleUrls: ['./design.component.scss']
})
export class DesignComponent {
  title = 'ats-front-end';
}
