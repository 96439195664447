/**
 * Designates status names.
 * Used in the 'Filter by Valid Status' drop-down.
 * These are the drop-down labels users will see.
 */
export enum DesignateStatusName {

  ALL_DESIGNATES = 'All Designates',
  CURRENTLY_VALID = 'Currently Valid',
  EXPIRED = 'Expired'
}
