/**
 * Stores all the validation messages in this central location,
 * so all validation messages are consistent application-wide.
 */
export class ValidationService {

  /**
   * Given the name of the error and optionally the value(s)
   * from the error object, it returns a validation error message.
   *
   * @param validatorName    The name of the standard or custom error (i.e. 'required', 'email').
   * @param validatorValue   Optional value from validation errors (i.e. 'requiredLength' from minlength validation errors).
   * @return                 A validation error message.
   */
  static getValidatorErrorMessage(validatorName: string, validatorValue?: any): string {

    const config = {
      required: 'A response is required',
      minlength: `Minimum length ${validatorValue.requiredLength} is required`,
      email: 'A valid email address is required',
      phone: 'A 10 digit number is required',
      date: 'A valid date is required'
    };

    return config[validatorName];
  }
}
