import {Component, EventEmitter, Input, OnChanges, OnInit, Output, SimpleChange, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from "@angular/forms";
import {merge, Observable, Subject} from "rxjs";
import {debounceTime, distinctUntilChanged, filter, map} from "rxjs/operators";
import {CourseSection} from "../../model/types/course-section";
import {Course} from "../../model/types/course";
import {LogService} from "../../common/services/log-service.service";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";

/**
 * Component: CourseSearchComponent
 * Desc:
 *    Typeahead component which selects a course section based on the user entered characters.
 * Input:
 *    courses: Course[] - The courses with their course sections the student is enrolled in.
 * Output:
 *      selectedCourseSection: EventEmitter<CourseSection>  - An event with CourseSection payload is issued.
 */

@Component({
  selector: 'app-course-search',
  templateUrl: './course-search.component.html',
  styleUrls: ['./course-search.component.scss']
})
export class CourseSearchComponent implements OnInit, OnChanges {

  @Input()
  courses: Course[];

  @Input()
  selectedCourse: AbstractControl;

  @Output()
  courseSelected = new EventEmitter<CourseSection>();

  @ViewChild('instance', {static: true})
  instance: NgbTypeahead;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();


  searching = false;
  searchFailed = false;

  // Converted courses to course sections (a course potentially has three course sections)
  courseSections: CourseSection[] = [];

  constructor(private logger: LogService) {
    this.selectedCourse = new UntypedFormControl('', Validators.required);
  }

  ngOnInit(): void {
  }

  ngOnChanges(changes: {[propKey: string]: SimpleChange}) {
    const studentCourses: SimpleChange = changes.courses;

    if (studentCourses && studentCourses.currentValue) {
      if (studentCourses.currentValue.length) {
        // this.logger.debug(`=> @Input - courses: ${JSON.stringify(studentCourses.currentValue)}`,
        //                                                        `${this.constructor.name}.ngOnChanges()`);

        this.courseSections = this.convertToCourseSections(this.courses);
      }else {
        this.courseSections = [];
      }
    }
  }

  /**
   * Filters the student courses to be shown in typeahead based on the user entered text.
   * @param text$  The user input search string.
   */
  searchForCourse = (text$: Observable<string>) => {
    const debouncedText$ = text$.pipe(debounceTime(200), distinctUntilChanged());
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));
    const inputFocus$ = this.focus$;

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      map(term => term.length === 0 ? []
        : this.courseSections.filter(v => v.code.toLowerCase().indexOf(term.toLowerCase()) > -1
          || v.sectionCode.toLowerCase().indexOf(term.toLowerCase()) > -1
          || v.teachMethod.toLowerCase().indexOf(term.toLowerCase()) > -1
          || v.sectionNo.toLowerCase().indexOf(term.toLowerCase()) > -1
        )
      )
    );
  }

  /**
   * A function that converts an item from the result list to a string.
   * In the case of [inputFormatter] typeahead directive the string is displayed in the <input> field.
   * In the case og [resultFormatter] typeahead directive the string is displayed in the popup.
   *
   * @param section  The CourseSection object to be converted to a string.
   */
  courseSectionFormatter = (section: CourseSection) =>
    !section ? '' : [section.code, section.sectionCode?.trim() + ",", section.teachMethod, section.sectionNo,
                                                  " (" + section.sessionCode + section.subSessionCode + ")"].join(' ')

  /**
   * Event handler - triggered when a course section is selected from the typeahead suggestions.
   * @param item  An object containing the selected CourseSection object.
   */
  onSelectedCourseSection(item: any) {
    this.logger.debug(`=> Typeahead selected course section: ${JSON.stringify(item)}`,
                                                      `${this.constructor.name}.onSelectedCourseSection()`);
    const selectedCourseSectionObj: CourseSection = item.item;
    this.selectedCourse.setValue(selectedCourseSectionObj);
    /*this.selectedCourseSection.emit(selectedCourseSectionObj);*/

    this.courseSelected.emit(selectedCourseSectionObj);
  }

  /* Create a course section objects for every meeting section of a course. Return an array of all course sections of all courses. */
  private convertToCourseSections(courses: Course[]): CourseSection[] {
    const courseSections: CourseSection[] = [];

    // If there are no courses, there is nothing to convert; return an empty array.
    if (!courses || !courses.length) {
      return courseSections;
    }

    // Create a course section object for every meeting section of a course.
    courses.forEach(course => {

      // - primary meeting section (usually lectures)
      if (course.primaryTeachMethod && course.primaryTeachMethod.trim().length) {
        courseSections.push(
          new CourseSection(course.code, course.sectionCode, course.sessionCode,
            course.primaryTeachMethod, course.primarySectionNo, course.deliveryMode, course.campusCode,
            course.primaryOrgCode, course.secondaryOrgCode, course.coSecondaryOrgCode, course.subSessionCode));
      }
      // - secondary meeting section (usually tutorials)
      if (course.secondaryTeachMethod1 && course.secondaryTeachMethod1.trim().length) {
        courseSections.push(
          new CourseSection(course.code, course.sectionCode, course.sessionCode,
            course.secondaryTeachMethod1, course.secondarySectionNo1, course.deliveryMode, course.campusCode,
            course.primaryOrgCode, course.secondaryOrgCode, course.coSecondaryOrgCode, course.subSessionCode));
      }
      // - tertiary meeting section (usually practicals)
      if (course.secondaryTeachMethod2 && course.secondaryTeachMethod2.trim().length) {
        courseSections.push(
          new CourseSection(course.code, course.sectionCode, course.sessionCode,
            course.secondaryTeachMethod2, course.secondarySectionNo2, course.deliveryMode, course.campusCode,
            course.primaryOrgCode, course.secondaryOrgCode, course.coSecondaryOrgCode, course.subSessionCode));
      }
    });

    return courseSections;
  }

}
