import {Schedule} from "./schedule";
import {Accommodation} from "./accommodation";

export class EditBookingDetails {

  testBookingId: number;
  scheduleWriting: Schedule;
  accommodations: Accommodation[];
  online: boolean;
  inExamCentre: boolean;
  spaceId: number;

  constructor() {}
}
