import {InjectionToken} from '@angular/core';
import {Observable} from 'rxjs';
import {StudentInfo} from '../types/studentInfo';
import {StudentBooking} from '../types/student-booking';
import {ActivityLog} from '../types/activityLog';
import {Person} from '../types/person';
import {Bookings} from '../types/bookings';
import {PrintTestMaterial} from "../types/print-test-material";
import {Accommodation} from '../types/accommodation';
import {Course} from "../types/course";
import {StudentAccommodations} from '../types/student-accommodations';
import {ManualBooking} from "../types/manual-booking";
import {SelectedTestDef} from "../types/selected-test-def";
import {AccommodationConflict} from "../types/accommodation-conflict";
import {StudentName} from '../types/student-name';
import {StudentSearch} from '../types/student-search';
import {StaffNotes} from "../types/staff-notes";
import {AccommodationInstructions} from "../types/accommodation-instructions";
import {AccommodationChoices} from "../types/accommodation-choices";
import {EditBookingDetails} from "../types/edit-booking-details";
import {DesignateAssignment} from "../types/designate-assignment";
import {ActivityOfferOrg} from "../types/activity-offer-org";
import {Room} from "../types/room";
import {Designate} from "../types/designate";
import {TestType} from "../types/testType";
import {SpaceOrOnline} from "../types/space-or-online";
import {AccommodationSearch} from "../types/accommodation-search";
import {StudentCourseAccommodations} from "../types/student-course-accommodations";
import {TestDefinitions} from "../types/test-definitions";
import {WholeTestDefinition} from "../types/whole-test-definition";
import {CourseBookings} from "../types/course-bookings";
import {TestDef} from "../types/test-def";
import {PeriodCourseSelector} from "../types/periodCourseSelector";
import {SimpleBooking} from "../types/simple-booking";
import {UpdatedBookingStatus} from "../types/updated-booking-status";
import {CourseContacts} from "../types/course-contacts";
import {CourseByLevelOfInstr} from "../types/course-by-level-of-instr";
import {AtsResp} from "../types/ats-resp";
import {SelectedEmailContacts} from "../../instructor-emailing/selected-email-contacts";
import {NotificationLog} from "../../instructor-emailing/notification-log";

/**
 * Token used in ModelService constructor to inject the required DataSource
 *    i.e. (@Inject(DATA_SOURCE) private dataSource: DataSource
 * The same tokes must be used to define the provider
 *    i.e. { provide: DATA_SOURCE, useClass: RestDataSourceService }
 *          or { provide: DATA_SOURCE, useClass: StaticDataSourceService }
 */
export const DATA_SOURCE = new InjectionToken<DataSource>("dataSource");

/**
 * Interface: DataSource
 * Desc:
 *    Implemented by the RestDataSourceService and StaticDataSourceService services.
 *
 * Note:
 *    The DataSource interface must be implemented by both the above mentioned services in order to be able to switch between them.
 */
export interface DataSource {

  getAccommodations(): Observable<Accommodation[]>;

  getTodayBookings(): Observable<Bookings>;

  getTodayTestDefinitions(): Observable<TestDefinitions>;

  // TODO: might not be needed
  getStudentBookings(): Observable<StudentBooking[]>;

  getStudentBooking(id: number): Observable<StudentBooking>;

  getActivityLogsForTestBooking(testBookingId: number): Observable<ActivityLog[]>;

  getActivityLogsForTestDefinition(testDefinitionId: number): Observable<ActivityLog[]>;

  getAdvisors(): Observable<Person[]>;

  getPrintTestMaterials(): Observable<PrintTestMaterial[]>;

  getStudentCourses(params: StudentSearch): Observable<Course[]>;

  getCourseSections(courseCode: string, testDefSubtype: string): Observable<Course[]>;

  getStudentInfo(atsStudentId: string): Observable<StudentInfo>;

  getAccommodatedStudentNames(searchVal: string): Observable<StudentName[]>;

  getAccommodationChoices(): Observable<AccommodationChoices>;

  createTestDefinitions(booking: ManualBooking);

  getAccommodationsByStudentId(studentId: string): Observable<StudentAccommodations>;

  getAccommodationsByStudentAndCourse(accommodationSearch: AccommodationSearch): Observable<StudentCourseAccommodations>;

  updateAccommodationsFromClockwork(rosiStudentNumber: string);

  getCourseSpecificAccommodationsByBookingId(bookingId: number): Observable<StudentCourseAccommodations>;

  getActiveTestDefinitions(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string, testType: string): Observable<SelectedTestDef[]>;

  getRosiCourseInstructors(courseCode: string, sectionCode: string, sessionCode: string,
                           teachMethod: string, sectionNr: string): Observable<any>;

  getBookingsByStudentId(studentId: string): Observable<Bookings>;

  getBookingsGroupsByStudentId(studentId: string): Observable<CourseBookings[]>;

  getAccommodationConflicts(bookingId: string): Observable<AccommodationConflict[]> ;

  saveStaffNotes(staffNotes: StaffNotes): Observable<ActivityLog[]>;

  getTestDetails(testBookingId: string): Observable<AccommodationInstructions>;

  updateStudentBooking(updatedBookingDetails: EditBookingDetails);

  getDesignateAssignments(assignmentLevel: string): Observable<DesignateAssignment[]>;

  saveDesignateAssignment(designates: DesignateAssignment);

  deleteDesignateAssignment(designateBindingId: string, assignmentLevel: string);

  getActivityOfferOrgs(): Observable<ActivityOfferOrg[]>;

  getRosiCourses(searchVal: string): Observable<Course[]>;

  validateDesignate(utorIdOrEmail: string): Observable<Designate>;

  getRooms(): Observable<Room[]>;

  getBookingsByDateRange(startEndDate: string[]): Observable<Bookings>;

  getTestDefinitionsByDateRange(startEndDate: string[]): Observable<TestDefinitions>;

  getTestDefinitionsByDate(assessmentDate: string): Observable<TestDefinitions>;

  getInstrEmailingTestDefs(assessmentDate: string): Observable<TestDefinitions>;

  findAllCourseContacts(testDefId: string): Observable<any>;

  sendEmail(selectedContacts: SelectedEmailContacts);

  getNotificationLog(testDefId: number): Observable<NotificationLog[]>;

  getTestTypes(): Observable<TestType[]>;

  updateBookingSpace(selectedSpace: SpaceOrOnline): Observable<SpaceOrOnline>;

  markAsException(bookingValidationId: number);

  undoMarkConflictAsException(bookingValidationId: number);

  getTestDefinitionByTestDefinitionId(testDefinitionId: number): Observable<WholeTestDefinition>;

  provisionStudent(rosiStudentNumber: number): Observable<boolean>;

  getVersion(): Observable<any>;

  uploadExams(file: any): Observable<any>;

  getCourses(coursePrefix: string): Observable<Course[]>;

  createTestDef(testDef: TestDef);

  getTestDef(testDefId: number): Observable<TestDef>;

  getTestDefByExamId(examId: number): Observable<TestDef>;


  updateTestDef(testDef: TestDef);

  updateTestDefWithTypes(testDef: TestDef);

  getTestDefinitions(selector: PeriodCourseSelector): Observable<TestDefinitions>;

  getBookings(selector: PeriodCourseSelector): Observable<Bookings>;

  getBookingsByTestDefinitionId(testDefinitionId: number): Observable<SimpleBooking[]>;

  moveBookingsToOtherTestDefinitions(bookings: SimpleBooking[]): Observable<StudentInfo[]>;

  updateBookingStatus(bookingStatus: UpdatedBookingStatus): Observable<string>;

  exportTestDefToClockwork(testDefinitionId: number): Observable<number>;

  deleteTestDef(testDefinitionId: number): Observable<string>;

  saveCourseContacts(courseContacts: CourseContacts, editContacts: boolean): Observable<AtsResp>;

  getCourseContacts(courseContacts: CourseContacts): Observable<CourseContacts>;

  deleteCourseContacts(courseContacts: CourseContacts): Observable<any>;

  getCourseContactList(): Observable<CourseContacts[]>;

  getCoursePrefByLevelOfInstr(): Observable<CourseByLevelOfInstr>;

  getCourseByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr>;

  getCourseMeetingSecByLevelOfInstr(courseCodePrefix: string): Observable<CourseByLevelOfInstr>;
}
