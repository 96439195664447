
<!-- Template for CourseSearchComponent. (Search for Course typeahead) -->
<div>
  <input type="text" id="filterInput1" class="form-control" [formControl]="selectedCourse"
         [ngbTypeahead]="searchForCourse" [resultFormatter]="courseSectionFormatter" [inputFormatter]="courseSectionFormatter"
         (selectItem)="onSelectedCourseSection($event)"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         #instance="ngbTypeahead"
         placeholder="Filter">
</div>
