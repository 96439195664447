
<div class="course-prefix-typeahead">
  <input type="text" id="course-prefix-typeahead" class="form-control"
         [formControl]="courseCtrl"
         [ngbTypeahead]="search"
         [resultFormatter]="formatter"
         [inputFormatter]="formatter"
         (focus)="focus$.next($any($event).target.value)"
         (click)="click$.next($any($event).target.value)"
         [editable]="false"
         #instance="ngbTypeahead"
         placeholder="Type ahead">
</div>
