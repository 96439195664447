<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7 ">
    <div>
      <app-breadcrumb></app-breadcrumb>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools  col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>

<h1 class="margin-top-30-px">Settings</h1>
<table class="table border">
  <thead>
    <tr>
      <th scope="col">Name</th>
      <th scope="col">Description</th>
      <th scope="col"></th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>Assign Designates</td>
      <td>Assign designates to act on behalf of meeting sections, divisions, and/or units.</td>
      <td><a class="btn btn-primary" routerLink="/settings/designate-dashboard">Manage</a></td>
    </tr>
  </tbody>
</table>

