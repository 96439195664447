<div ngbDropdown class="d-inline-block">
  <button class="btn btn-primary pr-5" id="dropdownForm1" ngbDropdownToggle>{{getSelectionStatus()}}</button>
  <div ngbDropdownMenu aria-labelledby="dropdownForm1">

    <form [formGroup]="checkboxFormGroup">
      <pre *ngIf="debugSelections">{{checkboxFormGroup.value | json}}</pre>
      <div>
        <label class="form-label px-2">
          <input type="checkbox" formControlName="{{selectAllOption.key}}" [(ngModel)]="selectAllOption.value">
          All {{selectAllOption.displayName}}
        </label>
        <label class="form-label px-4" *ngFor="let option of options">
          <input type="checkbox" formControlName="{{option.key}}" [(ngModel)]="option.value"> {{option.displayName}}
        </label>
      </div>
    </form>
  </div>
</div>
