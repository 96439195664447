import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ContactDetails} from "../../model/types/contact-details";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";

@Component({
  selector: 'app-delete-course-contacts-modal',
  templateUrl: './delete-course-contacts-modal.component.html',
  styleUrls: ['./delete-course-contacts-modal.component.scss']
})
export class DeleteCourseContactsModalComponent {

  @Input() title: string;
  @Input() isLastContactRemoval: boolean = false;
  @Input() contactDetails: ContactDetails;
  @Input() course: string;

  contactTypeMap: Map<string, string> = InstructorEmailingUtils.contactTypeMap;

  constructor(public activeModal: NgbActiveModal) {}


}
