<div>
  <!-- Masthead -->
  <div class="row">
    <!-- Masthead Header -->
    <div class="page-header col-7">
      <div class="masthead-header">
        <h1>{{title}}</h1>
      </div>
    </div>
    <!-- Masthead Tools -->
    <div class="page-tools col-5">
      <app-masthead-tools></app-masthead-tools>
    </div>
  </div>

  <!-- Display Test Definition info vertically, as title -->
  <app-test-def-info [testDefinition]="testDefinition"
                     [displayAsTitle]="true">
  </app-test-def-info>

  <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER" class="margin-top-2-rem">
    <strong>Note: </strong>All fields are required unless otherwise specified.
  </div>

  <div class="margin-top-2-rem">The email will be sent to selected course contacts from test.exam@utoronto.ca.</div>
  <div *ngIf="testDefHasContacts">
    <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER" class="margin-top-2-rem">
      <h2 class="margin-top-2-rem">1. Select Type of Reminder email</h2>
    </div>

    <!-- Which reminder email would you like to send for this assessment? -->
    <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER" class="row">
      <div class="col-12">
        <div *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER">
          <app-type-of-reminder-email [emailReminderTypeCtrl]="emailReminderTypeCtrl"></app-type-of-reminder-email>
        </div>
      </div>
    </div>

    <h2 class="margin-top-2-rem">
      <span *ngIf="emailType === EmailReminder.ONE_DAY_REMINDER">2. </span>
      Choose Course Contacts & Select Sending Action
    </h2>

    <div class="row">
      <div class="col-7">
        {{Utils.getChooseCourseContactsText(emailType)}}
      </div>
    </div>

    <!-- Local Contacts course notes -->
    <div class="margin-top-2-rem">
      <div class="row">
        <div class="col-9">
          <strong>Course Contact Notes:</strong>
        </div>
        <div class="col-9">
          {{localContactsNotes || 'None'}}
        </div>
      </div>
    </div>

    <!-- Table with contacts -->
    <div>
      <div class="row">
        <div class="col-9">
      <app-send-email-table [emailContactRec]="emailContactRec" [sendEmailFormGroup]="sendEmailFormGroup"></app-send-email-table>
        </div>
      </div>
    </div>

    <!-- Send email button -->
    <div class="margin-top-2-rem">
      <sis-button
        visualType="primary"
        size="medium"
        (clicked)="sendEmail()"
        [popoverBody]="emailType === EmailReminder.ONE_DAY_REMINDER && !emailReminderTypeCtrl.valid ?
        'To send the email, please ensure you’ve selected the reminder email you would like to send.' : ''">
        {{sendEmailButtonText}}
      </sis-button>
    </div>

  </div>

  <!-- Info message -->
  <div *ngIf="!testDefHasContacts" class="margin-top-2-rem">
    <sis-notice
      type="information"
      introductoryText="{{this.Utils.infoMsg_TITLE_NO_CONTACTS_FOUND_FOR_TEST_DEF}}:">
      <span [innerHTML]="infoMsg"></span>
    </sis-notice>
  </div>

  <!-- Error message -->
  <div *ngIf="errorMsg" class="margin-top-2-rem">
    <sis-notice
      type="warning"
      introductoryText="Something went wrong:">
      <span [innerHTML]="errorMsg"></span>
    </sis-notice>
  </div>

  <!-- Back to Instructor Emailing -->
  <div class="margin-top-2-rem">
    <a [routerLink]="['/instructor-emailing']">Back to Instructor Emailing Dashboard</a>
  </div>

</div>
