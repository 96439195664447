import {DateOptionDescription} from "./dateOptionDescription";
import moment, {Moment} from "moment";
import {SessionUtils} from "./SessionUtils";
import {AcademicSessionDates} from "./AcademicSessionDates";

export class DateOption {
  // tslint:disable-next-line:max-line-length
  static readonly TODAY = new DateOption( DateOptionDescription.TODAY, moment(), moment());
  static readonly NEXT_DAY = new DateOption( DateOptionDescription.NEXT_DAY, moment().add(1, "day"), moment().add(1, "day"));
  // tslint:disable-next-line:max-line-length
  static readonly CURRENT_WEEK = new DateOption( DateOptionDescription.CURRENT_WEEK, moment().startOf('isoWeek'), moment().endOf('isoWeek'));
  static readonly CURRENT_MONTH = new DateOption( DateOptionDescription.CURRENT_MONTH, moment().startOf('month'), moment().endOf('month'));
  static readonly CUSTOM_DATE_RANGE = new DateOption( DateOptionDescription.CUSTOM_DATE_RANGE, moment(), moment());

  static readonly CURRENT_SESSION = new DateOption( DateOptionDescription.CURRENT_SESSION,
                                                    new SessionUtils().getSessionStartEndDates().sessionStartDate,
                                                    new SessionUtils().getSessionStartEndDates().sessionEndDate);

  private constructor(public readonly description: DateOptionDescription, public  startDate: Moment, public  endDate: Moment) {
  }



}



