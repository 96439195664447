import {Component, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {AbstractControl, UntypedFormControl, Validators} from "@angular/forms";
import {CourseSection} from "../../model/types/course-section";
import {merge, Observable, of, Subject} from "rxjs";
import {catchError, debounceTime, distinctUntilChanged, filter, switchMap, tap} from "rxjs/operators";
import {NgbTypeahead} from "@ng-bootstrap/ng-bootstrap";
import {LogService} from "../../common/services/log-service.service";
import {ModelService} from "../../model/model.service";
import {Course} from "../../model/types/course";

@Component({
  selector: 'app-http-course-search',
  templateUrl: './http-course-search.component.html',
  styleUrls: ['./http-course-search.component.scss']
})
export class HttpCourseSearchComponent implements OnInit {

  @Input()
  course: AbstractControl;

  @Output()
  courseSelected = new EventEmitter<Course>();

  @ViewChild('instance', {static: true})
  instance: NgbTypeahead;

  searching = false;
  searchFailed = false;

  focus$ = new Subject<string>();
  click$ = new Subject<string>();

  // Converted courses to course sections (a course potentially has three course sections)
  courseSections: CourseSection[] = [];

  constructor(private modelService: ModelService, private logger: LogService) {
    this.course = new UntypedFormControl('', Validators.required);
  }

  ngOnInit(): void {
  }

  /**
   * Filters the students to be shown in typeahead based on the user entered text.
   * Type-ahead tasks:
   *   - listen for data from input;
   *   - debounce (as not to send off API requests for every keystroke, but instead wait for a break in keystroke);
   *   - don't send a request if the value stays the same (rapidly hit a character, then backspace, for instance);
   *   - send the back-end only if at list 8 search characters were entered;
   *
   * @param text$  The user input search string.
   */
  searchForCourse = (text$: Observable<string>) => {

    const debouncedText$ = text$.pipe(debounceTime(300), distinctUntilChanged());
    const inputFocus$ = this.focus$;
    const clicksWithClosedPopup$ = this.click$.pipe(filter(() => !this.instance.isPopupOpen()));

    return merge(debouncedText$, inputFocus$, clicksWithClosedPopup$).pipe(
      filter(x => x.length > 7),
      // filter(x => x.length > 2),
      tap(() => this.searching = true),
      switchMap(term => this.modelService.getCourses(term.toUpperCase()).pipe(
          tap(() => this.searchFailed = false),
          catchError(() => {
            this.searchFailed = true;
            return of([]);
          })
        ),
      ),
      tap(() => this.searching = false)
    );
  }

  /**
   * A function that converts an item from the result list to a string.
   * In the case of [inputFormatter] typeahead directive the string is displayed in the <input> field.
   * In the case og [resultFormatter] typeahead directive the string is displayed in the popup.
   *
   * @param course  The Course object to be converted to a string.
   */
  courseSectionFormatter = (course: Course) =>
    !course ? '' : [course.code, course.sectionCode?.trim() + ",", course.teachMethod, course.sectionNumber,
                                                 " (" + course.sessionCode + ")"].join(' ')



  /**
   * Event handler - triggered when a course is selected from the typeahead suggestions.
   * @param item  An object containing the selected CourseSection object.
   */
  onSelectedCourse(item: any) {
    this.logger.debug(`=> Typeahead selected course section: ${JSON.stringify(item)}`,
      `${this.constructor.name}.onSelectedCourseSection()`);

    const selectedCourseSectionObj: CourseSection = item.item;

    this. courseSelected.emit(item.item);
  }
}
