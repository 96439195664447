import {Injectable, InjectionToken} from '@angular/core';

// export const LOG_SERVICE = new InjectionToken("logger");

export enum LogLevel {
  DEBUG, INFO, WARNING, ERROR
}

/**
 * Service: LogService
 * Desc:
 *    As apparently Angular doesn't have a log service (like AngularJS $log) I added this simple logging service, for now.
 *    In the future will be improved to set the log level based on the environment where it is deployed.
 *
 * Usage:
 *    Just inject the logging service in the constructor (private logger: LogService) and start using it like:
 *       i.e. logger.info(`=> bookingId: ${bookingId}`, 'BookingComponent') display on console: [BookingComponent] (INFO): => bookingId: 1
 *
 * Note:
 *    This service should be used everywhere in the app in order to be able later,
 *    when needed, to change the log level or suppress it all together.
 */
@Injectable({
  providedIn: 'root'
})
export class LogService {
  minimumLevel: LogLevel;

  constructor() {}

  debug(message: string, objName?: string) {
    this.logMessage(LogLevel.DEBUG, message, objName);
  }

  info(message: string, objName?: string) {
    this.logMessage(LogLevel.INFO, message, objName);
  }

  warn(message: string, objName?: string) {
    this.logMessage(LogLevel.WARNING, message, objName);
  }

  error(message: string, objName?: string) {
    this.logMessage(LogLevel.ERROR, message, objName);
  }

  logMessage(level: LogLevel, message: string, objName?: string) {
    // LogLevel should be defined by the service provider. If not defined set it to DEBUG level. */
    if (!this.minimumLevel) {
      this.minimumLevel = LogLevel.DEBUG;
    }

    if (level >= this.minimumLevel) {
      if (!objName) { objName = 'Message'; }
      console.log(`[${objName}] (${LogLevel[level]}): ${message}`);
    }
  }
}
