<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <p *ngIf="isLastContactRemoval">
    This is the last contact for {{course}}. <strong>By removing this contact, you will no longer see contact
    information and will return to Course Contacts table.</strong>
  </p>

  Are you sure you want to remove the following contact for {{course}} courses?
  <ul>
    <li>Type: {{contactTypeMap.get(contactDetails.contactType)}}</li>
    <li>Name: {{contactDetails.contactName}}</li>
    <li>Email Address: {{contactDetails.contactEmail}}</li>
    <li>Expiry Date: {{contactDetails.expiryDate || 'None'}}</li>
  </ul>
</div>
<!-- Buttons -->
<div class="modal-footer">
  <!-- Cancel/Undo Cancellation booking button. Possible values: 'Booked', 'Cancelled' -->
  <button type="button" class="btn btn-primary" (click)="activeModal.close('remove')">
    Yes, Remove Contact
  </button>&nbsp;
  <!-- Close modal button -->
  <button type="button" class="btn btn-link" (click)="activeModal.close('close')">
    Cancel
  </button>&nbsp;
</div>



