import {ContactDetails} from "./contact-details";
import {CourseLevelOfInstr} from "./course-level-of-instr";

export class CourseContacts {

  // CourseLevel: SUBJECT_AREA, COURSE_CODE or COURSE_MEETING_SECTION
  courseLevel: string;

  levelOfStudy: string;

  course: CourseLevelOfInstr;

  contacts: ContactDetails[];

  additionalInfo?: string;
}
