import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

@NgModule({
  declarations: [],
  imports: [
    CommonModule
  ],
  // providers: [
  //   ModelService,
  //   // { provide: DATA_SOURCE, useClass: RestDataSourceService },
  //   { provide: DATA_SOURCE, useClass: StaticDataSourceService }
  // ]
})
export class ModelModule { }
