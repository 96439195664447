<div class="page-masthead row page-head">
  <!-- Masthead Header -->
  <div class="page-header col-7">
    <div>
      <app-breadcrumb [studentInfo]="studentInfo"></app-breadcrumb>
    </div>
  </div>
  <!-- Masthead Tools -->
  <div class="page-tools col-5">
    <app-masthead-tools></app-masthead-tools>
  </div>
</div>
<!--UX design: 40px with the breadcrumb, put 30px to Tools bar to realize it-->
<h1 class="margin-top-30-px">{{studentInfo.firstName}} {{studentInfo.lastName}}</h1>
<div *ngIf="studentInfo.atsStudentId" class="page-content mt-5" >

  <!-- Schedule and Course Contacts cards -->

  <div class="row">
    <div class="col-12">
      <app-student-information [studentInfo]="studentInfo"></app-student-information>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col-6">
      <app-student-advisor [advisor]="studentInfo.advisor"></app-student-advisor>
    </div>
    <div class="col-6">
      <app-student-emergency-contact
        [emergencyContact]="studentInfo.emergencyContact"></app-student-emergency-contact>
    </div>
  </div>
  <h2 class="mt-5">Accommodations</h2>
  <div class="row mt-4">
    <div class="col-12">
      <app-student-accommodations-tabs [studentInfo]="studentInfo"></app-student-accommodations-tabs>
    </div>
  </div>
</div>
