import { Injectable } from '@angular/core';
import {PhonebookPerson, PhonebookPhone, PhonebookPosition, PhonebookSearch} from "../../model/types/phonebook/PhonebookSearch";
import {HttpClient} from "@angular/common/http";
import {Observable} from "rxjs";
import {Environment} from "../../../environment";
import {PhonebookApi} from "./phonebook-api";

@Injectable({
  providedIn: 'root'
})
export class PhonebookApiImpl implements PhonebookApi{
  hostname: string = Environment.restHost;

  constructor(private httpClient: HttpClient) {}

  getPhonebookSearchByEmail(email: string): Observable<PhonebookSearch> {
    const encodeEmail = encodeURIComponent(email.trim());

    return this.httpClient.get<PhonebookSearch>(`https://${this.hostname}/PhonebookSearchRESTSvc/search?personal=${encodeEmail}&personal_criteria_type=email_address&campus_names=St.%20George%2CMississauga%2CScarborough&department=`);
  }

  getPhonebookDataByDepartmentId(departmentId: string): Observable<any> {

    return this.httpClient.get<any>(`https://${this.hostname}/PhonebookSearchRESTSvc/department/${departmentId}`);


  }

}
