<!-- Scheduled Writing -->
<div class="row">
  <div class="col-12">
    <h3 class="ut-padding-top-40px ut-margin-bottom-20px">Scheduled Writing</h3>
  </div>
</div>

<form novalidate [formGroup]="bookingForm">
  <fieldset disabled="disabled">
  <ng-container formGroupName="scheduledWriting">

    <!-- *** Strict timing *** -->
    <div>
      <div class="row">
        <!-- Start Date -->
        <div class="col-2" id="startDate">
          <label class="form-label">Scheduled Date</label>
          <app-rf-datepicker formControlName="startDate"
                             [ngClass]="{highLight: hasDateConflict}" >
          </app-rf-datepicker>
        </div>
        <!-- Start Time -->
        <div class="col-2" id="startTime">
          <label class="form-label">Scheduled Start Time</label>
          <app-rf-timepicker formControlName="startTime"
                             [ngClass]="{highLightField: hasStartTimeConflict}">
          </app-rf-timepicker>
        </div>
        <!-- End Time -->
        <div class="col-2" id="endTime">
          <label class="form-label">Scheduled End Time</label>
          <app-rf-timepicker formControlName="endTime"
                             [ngClass]="{highLightField: hasEndTimeConflict}">
          </app-rf-timepicker>
        </div>
        <!-- Duration -->
        <div class="col-2">
          <label class="form-label">Duration</label>
          <app-rf-durationpicker formControlName="duration" [disabled]="true" [isRequired]="false"></app-rf-durationpicker>
        </div>
      </div>
    </div>

    <!-- *** Fixed / Flexible time *** -->
    <!--<div *ngIf="booking.classWriting.assessmentTimingInd === timingInd.Fixed
                  || booking.classWriting.assessmentTimingInd === timingInd.Flexible">
      <div class="row">
        &lt;!&ndash; Start Date &ndash;&gt;
        <div class="col-2">
          <label class="form-label">Scheduled Start Date</label>
          <app-rf-datepicker formControlName="startDate"
                             [ngClass]="{highLight: hasDateConflict}" >
          </app-rf-datepicker>
        </div>
        &lt;!&ndash; Start Time &ndash;&gt;
        <div class="col-2">
          <label class="form-label">Scheduled Start Time</label>
          <app-rf-timepicker formControlName="startTime"
                             [ngClass]="{highLightField: hasStartTimeConflict}">
          </app-rf-timepicker>
        </div>
      </div>
      <div class="row w-100 ut-margin-top-10px">
        &lt;!&ndash; End Date &ndash;&gt;
        <div class="col-2">
          <label class="form-label">Scheduled Due By Date</label>
          <app-rf-datepicker formControlName="endDate"></app-rf-datepicker>
        </div>
        &lt;!&ndash; End Time &ndash;&gt;
        <div class="col-2">
          <label class="form-label">Scheduled Due By Time</label>
          <app-rf-timepicker formControlName="endTime"
                             [ngClass]="{highLightField: hasEndTimeConflict}">
          </app-rf-timepicker>
        </div>
      </div>
      <div class="row w-100 mt-2">
        &lt;!&ndash; Duration / Intended Duration &ndash;&gt;
        <div class="col-2">
          <label class="form-label">
            <span *ngIf="booking.classWriting.assessmentTimingInd === timingInd.Fixed">Fixed</span>
            <span *ngIf="booking.classWriting.assessmentTimingInd === timingInd.Flexible">Intended</span>
            Duration
          </label>
          <app-rf-durationpicker formControlName="duration"></app-rf-durationpicker>
        </div>
      </div>
    </div>-->

  </ng-container>

  <div >
    <!-- Writing Location for Online Assessment -->
    <div class="font-weight-bold mt-4">Student has indicated to write this online assessment in person with ATS</div>
    <div class="form-group">
      <!-- In person with ATS -->
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input class="form-check-input" type="radio" id="inExamCentreTrue"
                   [value]="true" value="true" formControlName="inExamCentre" name="inExamCentre">
            <label for="inExamCentreTrue" class="form-check-label">In person with ATS</label>
          </div>
        </div>
      </div>
      <!-- Elsewhere -->
      <div class="row">
        <div class="col">
          <div class="form-check">
            <input class="form-check-input" type="radio" id="inExamCentreFalse"
                   [value]="false" value="false" formControlName="inExamCentre" name="inExamCentre">
            <label for="inExamCentreFalse" class="form-check-label">Elsewhere</label>
          </div>
        </div>
      </div>
    </div>

    <!-- Conflict -->
    <!--<div role="alert" class="mt-4">
      <app-conflict [booking]="nonSeatDoubleBookedConflicts" (setHighlightFields)="setHighlightFields($event)"
                    (resetFields)="resetFields()">
      </app-conflict>
    </div>-->

    <!-- Seat selector -->
    <!--<div class="row">
      <div class="col-12">
        <div class="font-weight-bold mt-4">Select seat</div>
        <seat-selector [selectedSpace]="booking.space"
                       [isOnline]="booking.online"
                       [doesWorkIndependently]="false"
                       [rooms]="rooms"
                       [hasSeatConflict]="hasSeatConflict"
                       (submitSpace)="getSpace($event)"
                       (setOnline)="setOnline()"
                       (resetSpace)="resetSpace()"></seat-selector>
      </div>
    </div>-->

    <!-- Seat Double Booked Conflict -->
    <!--<div role="alert" class="mt-4">
      <app-conflict [booking]="seatDoubleBookedConflicts" (setHighlightFields)="setHighlightFields($event)"
                    (resetFields)="resetFields()">
      </app-conflict>
    </div>-->

    <!-- Accommodations -->
    <div class="row mt-4">
      <div class="col-12">
        <app-accom [expiryDate]="expireDate" #accommoComponent
                   [nonOptionalAccommodations]="nonOptionalAccommodations"
                   [optionalAccommodations]="optionalAccommodations"
                   formControlName="accommodations">
        </app-accom>
      </div>
    </div>

    <!-- Submit form -->
<!--    <div class="row mt-4">-->
<!--      <div class="col-12">-->
<!--        <button class="btn btn-primary" type="submit" (click)="submitBooking()">-->
<!--          Submit-->
<!--        </button>-->
<!--      </div>-->
<!--    </div>-->
  </div>

  </fieldset>
</form>
