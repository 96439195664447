import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';
import {environmentDesign} from "./environments/environment.design";
import {environmentDev} from "./environments/environment.dev";
import {environmentProd} from "./environments/environment.prod";
import {environmentQa} from "./environments/environment.qa";
import {environmentTest} from "./environments/environment.test";
import {environmentUat} from "./environments/environment.uat";
import {DesignModule} from './app/design/design.app.module';
import {Environment} from "./environment";


fetch('./assets/config/environment.json')
  .then(response => {
    return response.json();
  })
  .then(data => {

    switch (data.environment) {
      case "design":
        setEnvironment(environmentDesign);
        break;
      case "dev":
        setEnvironment(environmentDev);
        break;
      case "prod":
        setEnvironment(environmentProd);
        break;
      case "qa":
        setEnvironment(environmentQa);
        break;
      case "test":
        setEnvironment(environmentTest);
        break;
      case "uat":
        setEnvironment(environmentUat);
        break;
      case "release":
        setEnvironment(environmentProd);
        Environment.isRelease = true;
        break;
      default:
        setEnvironment(environment);
    }

    if (Environment.production) {
      enableProdMode();
    }

    if (Environment.mode === 'design') {
      platformBrowserDynamic().bootstrapModule(DesignModule)
        .catch(err => console.error(err));
    } else {
      platformBrowserDynamic().bootstrapModule(AppModule)
        .catch(err => console.error(err));
    }

  })
  .catch(error => {
    console.log('error', error);
  });


function setEnvironment(anyEnvironment: any) {
  Environment.production = anyEnvironment.production;
  Environment.mode = anyEnvironment.mode;
  Environment.restHost = anyEnvironment.restHost;
  Environment.restUrl = anyEnvironment.restUrl;
  Environment.clientId = anyEnvironment.clientId;
  Environment.sharepointBaseUrl = anyEnvironment.sharepointBaseUrl;
}

