import {Component, OnInit} from '@angular/core';
import {ActivatedRoute, NavigationExtras, ParamMap, Router} from "@angular/router";
import {LogService} from "../../common/services/log-service.service";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {EmailReminder} from "../email-reminder.enum";
import {FormControl} from "@angular/forms";
import {InstructorEmailContacts} from "../instructor-email-contacts";
import {ModelService} from "../../model/model.service";
import {TestDefinition} from "../../model/types/test-definition";
import {EmailContactRec} from "../email-contact-rec.intf";
import {SelectedEmailContacts} from "../selected-email-contacts";
import {ToastService} from "@easi-sis/core";
import {EmailHttpStatusCode} from "../email-http-status-code.enum";

@Component({
  selector: 'app-send-bulk-email',
  templateUrl: './send-bulk-email.component.html',
  styleUrls: ['./send-bulk-email.component.scss']
})
export class SendBulkEmailComponent implements OnInit {

  // Params received from email dashboard (parent)
  emailType: string; // initial, 1-day or 3-day reminder (e.g. ONE_DAY_REMINDER, INITIAL_REMINDER)
  testDefIds: string[];

  testDefinition: TestDefinition;
  emailContactRec: EmailContactRec[] = [];
  localContactsNotes: string;

  // A list of test definitions with courses four which emails were sent to contacts
  bulkEmailSummary: string[] = [];

  // The current index in the testDefIds array. Keeps track of the current test definition viewed in table.
  currentTestDefIndex: number = 0;
  maxTestDefIndex: number;

  emailReminderTypeCtrl: FormControl[] = [];
  currentEmailReminderTypeCtrl: FormControl;

  // Page title, based on email type
  title: string;
  // The text to be display on the send email button; based on email type
  sendEmailButtonText: string;

  errorMsg: string;
  infoMsg: string;

  testDefHasContacts: boolean;

  sendingEmails: boolean;

  constructor(private activatedRoute: ActivatedRoute,
              private router: Router,
              private toastService: ToastService,
              private model: ModelService,
              private logger: LogService) {}

  ngOnInit(): void {

    this._processQueryParams();
  }

  findAllCourseContacts(testDefId: string, emailType: string): void {

    if (!testDefId) {
      return;
    }

    this.logger.debug(`=> Calling find all course contacts with testDefId: ${testDefId}`, `${this.constructor.name}`);

    // Do a call to the back-end to get test definition contacts (instructor, coordinator and local).
    const allContacts$ = this.model.findAllCourseContacts(testDefId);

    allContacts$.subscribe((instructorEmailContacts: InstructorEmailContacts) => {

      this.testDefinition = instructorEmailContacts.testDefinition;
      this.localContactsNotes = instructorEmailContacts?.localContactsNotes;

      // Build the array of contact records to be shown for a given test definition.
      this.emailContactRec = this.Utils.buildTestDefContactsTableRecords(testDefId, instructorEmailContacts, emailType);

      // If the test def doesn't have contacts, set a warning message and add that info to the summary record
      let testDefHasNoContactsText = '';
      this.infoMsg = '';
      this.testDefHasContacts = this.emailContactRec && this.emailContactRec.length > 0;
      if (!this.testDefHasContacts) {
        testDefHasNoContactsText = ' [No Emails Sent]';
        this.infoMsg = this.Utils.infoMsg_NO_CONTACTS_FOUND_FOR_TEST_DEF;
      }

      // Set the text on the Send Email button.
      this._setSendEmailButtonText(this.testDefHasContacts);

      // Create a record to be shown in the Summary of Emails Sent page.
      // e.g. : ANT100Y1 Y LEC0101 (Test Definition ID: 6384) [- no email sent as not contacts were found!]
      const emailSummaryRec =
        `${this.Utils.formatTestDefinitionForSorting(this.testDefinition)} (Test Definition ID: ${testDefId}) ${testDefHasNoContactsText}`;
      this.bulkEmailSummary.push(emailSummaryRec);

      // Scroll to beginning of the 'Selected Test Definition' header.
      const element = document.getElementById("selected-test-def-info");
      element.scrollIntoView();
    });

  }

  _processQueryParams() {
    this.activatedRoute.queryParamMap.subscribe( (params: ParamMap) => {
      const testDefIdsAsStr = params.get('selectedTestDefIds');

      this.testDefIds = testDefIdsAsStr.split(',');
      this.emailType = params.get('emailType');

      this.logger.debug(`=> QueryParams: testDefIds: ${this.testDefIds}, emailType: ${this.emailType}`,
                                       `${this.constructor.name}`);

      this.title = this.Utils.getSendEmailPageTitle(this.emailType);

      // Initialize the test definitions array current and max indexes.
      this.currentTestDefIndex = 0;
      this.maxTestDefIndex = this.testDefIds.length - 1;

      // For one day reminder, populate the radio-button form controls, for all test definitions.
      if (this.testDefIds && this.emailType === this.EmailReminder.ONE_DAY_REMINDER) {
        this.testDefIds.forEach(() => {
          this.emailReminderTypeCtrl.push(new FormControl(''));
        });

        // Set the type of email reminder ('to input CIS submission' or to 'to apply accommodations').
        this.currentEmailReminderTypeCtrl = this.emailReminderTypeCtrl[this.currentTestDefIndex];
      }

      this.findAllCourseContacts(this.testDefIds[this.currentTestDefIndex], this.emailType);

    });

    // Note: Used here to scroll up to the first test def from the bulk
    // Scroll to beginning of the 'Selected Test Definition' header.
    const element = document.getElementById("selected-test-def-info");
    element.scrollIntoView();
  }

  sendBulkEmail() {

    // If none of the contacts have sending action set to 'To', return.
    if (this.emailContactRec.length === 0) {
      this._goToNextTextDefinition();
      return;
    }

    const pageSelectedEmailContacts: SelectedEmailContacts = this.Utils.buildBackendResp(
                       this.testDefinition, this.emailType, this.currentEmailReminderTypeCtrl, this.emailContactRec);

    // If none of the contacts sending action is 'To', return.
    if (pageSelectedEmailContacts.contacts.length === 0) {
      this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;
      return;
    }

    this.sendingEmails = true;

    this.model.sendEmail(pageSelectedEmailContacts).subscribe(
      (resp) => {

        this.sendingEmails = false;

        if (resp.statusCode === this.StatusCode.ACCEPTED
            || resp.statusCode === this.StatusCode.NO_EMAIL_ADDRESSES) {

          this._goToNextTextDefinition();

          // Reset the error message
          this.errorMsg = '';

            let msg = this._createSuccessToastMsg();

            // Show success toast message
            this.toastService.show({type: 'success', action: msg});

        } else if (resp.statusCode === this.StatusCode.NO_SENDING_ACTION_TO) {
          // - No contacts with sending action 'TO'
          this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;

        } else if (resp.statusCode === this.StatusCode.SEND_EMAIL_ERROR) {
          // - General error message
          this.errorMsg = this.Utils.errorMsg_SEND_EMAIL_ERROR;

        } else {
          // - Error message when trying to send emails without having any set to 'TO'.
          this.errorMsg = this.Utils.errorMsg_NO_SENDING_ACTION_HAS_TO;
        }

      },
      (error) => {
        this.sendingEmails = false;
        this.logger.debug(`### ERROR: ${JSON.stringify(error)}`, `${this.constructor.name}`);
      });
  }

  _createSuccessToastMsg(): string {
    return this.Utils.createSuccessToastMsg(this.emailType, this.currentEmailReminderTypeCtrl, this.testDefinition);
  }

  _goToNextTextDefinition() {

    ++this.currentTestDefIndex;
    this.findAllCourseContacts(this.testDefIds[this.currentTestDefIndex], this.emailType);

    if (!this.testDefIds && this.emailType === EmailReminder.ONE_DAY_REMINDER) {
      this.currentEmailReminderTypeCtrl = this.emailReminderTypeCtrl[this.currentTestDefIndex];
      this.currentEmailReminderTypeCtrl.setValue(this.emailReminderTypeCtrl[this.currentTestDefIndex - 1].value);
    }

    if (this.currentTestDefIndex > this.maxTestDefIndex) {
      this._goToBulkEmailSummary();
    }
  }

  _goToBulkEmailSummary() {

    const navigationExtras: NavigationExtras = {
      state: {
        title: this.title,
        bulkEmailSummary: this.bulkEmailSummary,
        emailDate: this.testDefinition.classStartDate
      }
    };

    const url = '/instructor-emailing/bulk-email-summary';
    this.router.navigate([url], navigationExtras);
  }

  _setSendEmailButtonText(textDefHasContacts: boolean) {

    if (this.currentTestDefIndex === this.maxTestDefIndex) {
      this.sendEmailButtonText = textDefHasContacts ?
                                    'Send Email & View Summary' : 'Skip & View Summary';

    } else {
      this.sendEmailButtonText = textDefHasContacts ?
                                    'Send Email & Go to Next Test Definition' : 'Skip & Go to Next Test Definition';
    }
  }

  protected readonly EmailReminder = EmailReminder;
  protected readonly StatusCode = EmailHttpStatusCode;
  protected readonly Utils = InstructorEmailingUtils;
}
