import { Component, Input, OnInit } from '@angular/core';


@Component({
  selector: 'app-booking-notes-tab',
  templateUrl: './booking-notes-tab.component.html',
  styleUrls: ['./booking-notes-tab.component.scss']
})
export class BookingNotesTabComponent implements OnInit {

  @Input()
  notes: string[];

  constructor() { }

  ngOnInit(): void {}

}
