import {Injectable} from "@angular/core";
import {DashboardStoredData} from "../types/dashboard-stored-data";
import {StorageProperty} from "../../model/types/storage-property";
import {StorageService} from "./storage.service";


@Injectable({
  providedIn: 'root'
})
export class StorageImpl implements StorageService{

  private localStorage = window.localStorage;

  private sessionStorage = window.sessionStorage;

  getLocalStorageDashboardFilters(property: StorageProperty): DashboardStoredData {
    const jsonData =  this.localStorage.getItem(property);
    return JSON.parse(jsonData);
  }

  getSessionStorageDashboardFilters(property: StorageProperty): DashboardStoredData {
    const jsonData =  this.sessionStorage.getItem(property);
    return JSON.parse(jsonData);
  }

  saveLocalStorageDashboardFilters(property: StorageProperty, data: DashboardStoredData): void {
    const jsonData = JSON.stringify(data);
    this.localStorage.setItem(property, jsonData);
  }

  saveSessionStorageDashboardFilters(property: StorageProperty, data: DashboardStoredData): void {
    const jsonData = JSON.stringify(data);
    this.sessionStorage.setItem(property, jsonData);
  }
}
