import {Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {ModelService} from "../model/model.service";
import {FormControl, NgForm, NgModel} from "@angular/forms";
import {FileUploadResp} from "../model/types/file-upload-resp";
import {SpinnerService} from "../common/services/spinner.service";

@Component({
  selector: 'app-upload-files',
  templateUrl: './upload-files.component.html',
  styleUrls: ['./upload-files.component.scss']
})
export class UploadFilesComponent implements OnInit {

  constructor(public activeModal: NgbActiveModal, private modelService: ModelService, public spinner: SpinnerService) {
  }

  csvFile: any;
  acceptedFileTypes = {csv: 'text/csv' };

  successfullyUploaded: boolean;

  fileUploadResp: FileUploadResp;

  updatedFile: any;

  fileSubmitted = false;

  showErrorsDetail = false;

  ngModel: any;

  ngForm: any;

  @ViewChild("csvFileNgModel") csvFileNgModel: NgModel;

  ngOnInit(): void {

  }

  isCSVFileNull(): boolean {
    return this.csvFile == null;
  }

  submitFile(f: NgForm) {

    this.fileSubmitted = true;
    this.spinner.showSpinner = true;

    /**
     * resp: FileUploadResp example:
     * {"sessionCode":"20231","numbOfCsvRecords":3,"numOfRecsProcessed":3,"numOfTestDefCreated":2,
     *  "coursesNotFoundInDb":[],"primeSectionsNotFoundInDb":[],
     *  "failedTestDefs":[{"testDefId":null,"testType":"Final Exam","testSubtype":"Standard",
     *                     "assessmentType":"Exam","onCampusInd":true,"classLocation":"EX-100",
     *                     "assessmentDate":"2023-04-25","assessmentTime":"09:00:00","assessmentDuration":300,
     *                     "testDefSource":"LSM",
     *                     "courseDto":{"code":"ARC251H1","sectionCode":"S","sessionCode":"20231",
     *                                  "teachMethod":"LEC","sectionNo":"0101","deliveryMode":"sugarXXX","campus":"STG",
     *                                  "primaryOrgCode":"ARCLA","secondaryOrgCode":"ARCLA","coSecondaryOrgCode":"   "}
     *                   }],
     *  "fileProcessingError":null
     * }
     */
    this.modelService.uploadExams(f.value.csv).subscribe(
      (resp: FileUploadResp) => {

        this.fileUploadResp = resp;

        this.spinner.showSpinner = false;

        if (resp.fileProcessingError) {
          this.successfullyUploaded = false;

        } else {
          this.successfullyUploaded = true;
          console.log("=> Successfully uploaded:", resp);

          console.log("+> Number of records processed: " + resp.numOfRecsProcessed);

          // if (this.successfullyUploaded) {
          //   this.activeModal.close();
          // }
        }
      },
      (err: any) => {
        console.log("=> HTTP Error", err);
        this.successfullyUploaded = true;
        this.spinner.showSpinner = false;
      }
    );
  }

  disableUpload(): boolean {
    return !this.updatedFile || ( this.updatedFile && this.fileSubmitted) || this.csvFileNgModel.invalid;
  }

  fileChange(updatedFile: object): void {
    this.updatedFile = updatedFile;
    this.fileSubmitted = false;
    this.successfullyUploaded = null;
  }

  showDetail(): void {
    this.showErrorsDetail = true;
  }

  showLess(): void {
    this.showErrorsDetail = false;
  }
}
