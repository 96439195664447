import {StorageProperty} from "../../model/types/storage-property";
import {DashboardStoredData} from "../types/dashboard-stored-data";
import {InjectionToken} from "@angular/core";

export const STORAGE_SERVICE = new InjectionToken<StorageService>("storageService");

export interface StorageService {

  getLocalStorageDashboardFilters(property: StorageProperty): DashboardStoredData;

  saveLocalStorageDashboardFilters(property: StorageProperty, data: DashboardStoredData): void;

  getSessionStorageDashboardFilters(property: StorageProperty): DashboardStoredData;

  saveSessionStorageDashboardFilters(property: StorageProperty, data: DashboardStoredData): void;

}
