import {Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild} from '@angular/core';
import {UntypedFormBuilder, UntypedFormGroup, Validators} from "@angular/forms";
import {ToastrService} from "ngx-toastr";
import {LogService} from "../../../common/services/log-service.service";
import {ModelService} from "../../../model/model.service";
import {StudentBooking} from "../../../model/types/student-booking";
import {Schedule} from "../../../model/types/schedule";
import {Accommodation} from "../../../model/types/accommodation";
import {AccommodationSelection} from "../../../model/types/accommodation-selection";
import {Space} from "../../../model/types/space";
import {Room} from "../../../model/types/room";
import {EditBookingDetails} from "../../../model/types/edit-booking-details";
import {INavigateAway} from "../../../common/types/inavigate-away";
import {ValidationRuleType} from "../../../common/types/validation-rule-type.enum";
import {AssessmentTimingInd} from "../../types/assessment-timing-ind";
import {TimepickerAdapterService} from "../../../common/services/timepicker-adapter.service";
import {DatePattern} from "../../../common/types/date-pattern";
import {ToastService} from "@easi-sis/core";

@Component({
  selector: 'app-stud-booking-tab',
  templateUrl: './stud-booking-tab.component.html',
  styleUrls: ['./stud-booking-tab.component.scss']
})
export class StudBookingTabComponent implements OnInit, OnDestroy, INavigateAway {

  // Booking for a given student
  @Input()
  booking: StudentBooking;

  // The template form holding all FormControls.
  @Input()
  bookingForm: UntypedFormGroup;

  @ViewChild('accommoComponent')
  accommoComponent: any;

  @Output()
  hasConflict: EventEmitter<boolean> = new EventEmitter<boolean>();

  @Output()
  updateBookingLog = new EventEmitter();

  // Enumerations
  validationRuleType = ValidationRuleType;
  timingInd = AssessmentTimingInd;

  seatDoubleBookedConflicts: StudentBooking;
  nonSeatDoubleBookedConflicts: StudentBooking;

  nonOptionalAccommodations: Accommodation[] = [];
  optionalAccommodations: AccommodationSelection[] = [];
  expireDate: Date;

  // Conflict related
  hasStartTimeConflict: boolean;
  hasEndTimeConflict: boolean;
  hasDateConflict: boolean;
  hasSeatConflict: boolean;

  // for seat-selector widget
  public rooms: Room[];

  selectedSpaceId: number;

  // Used in form submission
  formSubmitted = false;

  updatedBookingDetails: EditBookingDetails = new EditBookingDetails();

  /**
   * Keeps track if the Schedule data changes. Used by the guard which checks if data was saved before leaving the page.
   * (date: Date, location: string, startTime: Date, endTime: Date, duration: string, seat: string).
   */
  originalScheduledWriting: Schedule = new Schedule(null, null, null, null);

  constructor(private formBuilder: UntypedFormBuilder,
              private toastr: ToastrService, private toastService: ToastService,
              private logger: LogService,
              private modelService: ModelService,
              public timepickerAdaptor: TimepickerAdapterService) {
  }


  ngOnInit(): void {

    this.bookingForm = this.formBuilder.group({
      testBookingId: [''],
      inExamCentre: [''],
      online: [''],
      scheduledWriting: this.formBuilder.group({
        startDate: ['', Validators.pattern(DatePattern.YYYY_MM_M_DD_D)],
        startTime: ['', Validators.required],
        endDate: [''],
        endTime: [''],
        duration: [''],
        location: [''],
        seat: [''],
        assessmentTimingInd: ['']
      }),
      accommodations: ['']
    });

    this.bookingForm.get('inExamCentre').setValue(this.booking.inExamCentre);
    this.bookingForm.get('online').setValue(this.booking.online);
    this.bookingForm.get('scheduledWriting').setValue(this.booking.scheduledWriting);

    // update assessment duration
    this.updateDuration();

    this.init();
  }

  /**
   * Watch the start/end time value changes and update the duration for Strict timing indicator.
   * @private
   */
  private updateDuration() {
    this.bookingForm.get('scheduledWriting.startTime').valueChanges.subscribe(startTimeVal => {
      const endTimeVal = this.bookingForm.get('scheduledWriting.endTime').value;
      this.setDuration(startTimeVal, endTimeVal);
    });

    this.bookingForm.get('scheduledWriting.endTime').valueChanges.subscribe(endTimeVal => {
      const startTimeVal = this.bookingForm.get('scheduledWriting.startTime').value;
      this.setDuration(startTimeVal, endTimeVal);
    });
  }

  // Set the duration for fixed timing as the difference between end time and start time
  private setDuration(startTimeVal: string, endTimeVal: string): void {
    if (!this.booking.classWriting.assessmentTimingInd || this.booking.classWriting.assessmentTimingInd === this.timingInd.Strict) {
      this.bookingForm.get('scheduledWriting.duration').setValue(this.timeInMin(endTimeVal) - this.timeInMin(startTimeVal));
    }
  }

  // convert time from HH:mm to total number of minutes
  private timeInMin(val: string): number {
    return this.timepickerAdaptor.getTimeInMin(val);
  }

  // Splits the bookings conflicts in two: Seat Double Booked conflicts and non seat double booked conflicts.
  splitConflictsForDisplay() {
    this.seatDoubleBookedConflicts = Object.assign({}, this.booking);
    this.seatDoubleBookedConflicts.conflicts =
      this.seatDoubleBookedConflicts.conflicts.filter(value => value.validationRuleType === this.validationRuleType.SEAT_DOUBLE_BOOKED);

    this.hasSeatConflict = this.seatDoubleBookedConflicts.conflicts && this.seatDoubleBookedConflicts.conflicts.length > 0;

    this.nonSeatDoubleBookedConflicts = Object.assign({}, this.booking);
    this.nonSeatDoubleBookedConflicts.conflicts =
      this.nonSeatDoubleBookedConflicts.conflicts.filter(value => value.validationRuleType !== this.validationRuleType.SEAT_DOUBLE_BOOKED);
  }

  ngOnDestroy(): void {
  }

  /**
   * NavigateAway interface method.
   * Returns true if there is no unsaved data, false otherwise.
   */
  canNavigateAway(): boolean {
    const formDataChangedPredicate = (prop) => this.booking.scheduledWriting[prop] !== this.originalScheduledWriting[prop];
    const formDataChanged = Object.keys(this.booking.scheduledWriting).some(formDataChangedPredicate);

    // Object.keys(this.booking.scheduledWriting).forEach(
    //             prop => this.logger.debug(`-------> Property: ${prop}: ${JSON.stringify(this.booking.scheduledWriting)}`));

    return !formDataChanged;
  }

  init() {
    // Copy the scheduled writing data in order to keep track of the data changes
    Object.assign(this.originalScheduledWriting, this.booking.scheduledWriting);

    // Imp: Need to be set for the case no seat is selected in order to send to the backend the right value!
    this.selectedSpaceId = this.booking.space.id;

    // get the conflicts soon after submitting the booking change
    this.modelService.getAccommodationConflicts(this.booking.id.toString()).subscribe(response => {
      this.booking.conflicts = response;
      if (this.booking.conflicts.length > 0) {
        this.splitConflictsForDisplay();
        this.hasConflict.emit(true);
      }
    });

    // Initialise optional accommodations
    this.modelService.getCourseSpecificAccommodationsByBookingId(this.booking.id).subscribe(response => {
      this.expireDate = response?.expiryDate;

      // always clean accommodations before adding element to avoid duplicated items shown in UI
      this.optionalAccommodations = [];
      this.nonOptionalAccommodations = [];
      if (response?.options) {
        const key = Object.keys(response.options)[0];
        response.options[key].forEach(accommodation => {
          // response.options[Object.keys(response.options)[0]].forEach(accommodation => {
          // for optional accommodation, check if this accommodation was selected in this booking and show in UI if it was selected
          if (accommodation.optional) {
            let isSelected = false;
            for (const bookingAccommodation of  this.booking.accommodations) {
              if (bookingAccommodation.optional && bookingAccommodation.id === accommodation.id ) {
                isSelected = true;
                break;
              }
            }
            if (isSelected) {
              this.optionalAccommodations.push(new AccommodationSelection( accommodation, true));
            } else {
              this.optionalAccommodations.push(new AccommodationSelection( accommodation, false));
            }
          } else {
            this.nonOptionalAccommodations.push(accommodation);
          }
        });
      }
    });

    // TODO: NOT-USED. To be removed later if this method won't be needed.
    // get rooms to pass them to seat-selector widget
    // this.modelService.getRooms().subscribe( rooms => {
    //   this.rooms = rooms;
    // });
  }

  setOnline() {
    this.logger.debug('setIsOnline() of student-booking-tab is called');
    this.booking.online = true;
    this.selectedSpaceId = null;
  }

  setHighlightFields(fields: string[]) {
    fields.forEach(field => {
      if (field.includes('START')) { this.hasStartTimeConflict = true;}
      if (field.includes('END')) { this.hasEndTimeConflict = true; }
      if (field.includes('DATE')) { this.hasDateConflict = true; }
      if (field.includes('SEAT')) { this.hasSeatConflict = true; }
    });
  }

  resetFields() {
    this.hasDateConflict = false;
    this.hasEndTimeConflict = false;
    this.hasStartTimeConflict = false;
    this.hasSeatConflict = false;
  }

  getSpace(space: Space) {
    this.selectedSpaceId = space.id;
    this.booking.online = false;
  }

  resetSpace() {
    this.selectedSpaceId = null;
    this.booking.online = false;
  }

  submitBooking() {
    this.logger.debug(`=> Form value: ${JSON.stringify(this.bookingForm.value)}`, `${this.constructor.name}.submitBooking()`);

    this.formSubmitted = true;
    if (this.bookingForm.valid) {
      this.formSubmitted = false;

      // Data successfully saved. Reset the scheduled writing.
      this.originalScheduledWriting = this.booking.scheduledWriting;

      this.updatedBookingDetails.testBookingId = this.booking.id;
      this.updatedBookingDetails.inExamCentre = this.bookingForm.get('inExamCentre').value;
      this.updatedBookingDetails.scheduleWriting = this.bookingForm.get('scheduledWriting').value;
      this.updatedBookingDetails.spaceId = this.selectedSpaceId;
      this.updatedBookingDetails.online = this.bookingForm.get('online').value;
      this.updatedBookingDetails.accommodations = this.accommoComponent.nonOptionalAccommodations;
      const optAccoms: any[] = this.bookingForm.get('accommodations').value.optionalAccom || [];
      if (optAccoms) {
        optAccoms.forEach(optAccom => {
          if (optAccom.selected === true) {
            delete optAccom.selected;
            this.updatedBookingDetails.accommodations.push(optAccom);
          }
        });
      }

      // Set the assessment timing ind
      if (!this.booking.classWriting.assessmentTimingInd) {
        this.updatedBookingDetails.scheduleWriting.assessmentTimingInd = this.timingInd.Strict;
      } else {
        this.updatedBookingDetails.scheduleWriting.assessmentTimingInd = this.booking.classWriting.assessmentTimingInd;
      }

      // this.logger.debug(`NNNNN=> Form value: ${JSON.stringify(this.updatedBookingDetails)}`, `${this.constructor.name}.submitBooking()`);

      this.modelService.updateStudentBooking(this.updatedBookingDetails).subscribe(() => {
        this.toastService.show({type: 'success', action: 'Form submitted successfully.'});

        // To Initialize the component, instead of entire page. Which would help to run the error checks.
        // as the booking object here was not refreshed after submitting the change (the booking object was passed from dashboard, in order
        // to show the updated optional accommodations, refresh the accommodations before the initializing
        this.booking.accommodations = this.updatedBookingDetails.accommodations;
        this.init();
        // call parent component's method and let parent component to call activityLog module to update log
        this.updateBookingLog.emit();
      });

      // Display a read toast if the form is submitted and there are validation errors.
      if (this.formSubmitted && this.bookingForm.invalid) {
        this.toastr.error("There are validation problems with the form.", "Error");
      }
    }
  }

}
