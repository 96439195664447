import {Component, Input, OnInit} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {DesignateAssignment} from "../../model/types/designate-assignment";
import {ModelService} from "../../model/model.service";
import {Designate} from "../../model/types/designate";
import {AssignmentLevel} from "../../common/types/assignment-level.enum";
import {ToastService} from "@easi-sis/core";

/**
 * Modal used to remove the designate from org or course binding.
 */

@Component({
  selector: 'app-remove-desig-modal',
  templateUrl: './remove-desig-modal.component.html',
  styleUrls: ['./remove-desig-modal.component.scss']
})
export class RemoveDesigModalComponent implements OnInit {

  /* The DesignateAssignment to populate the modal template  */
  @Input()
  desigAssignment: DesignateAssignment;

  // Enumerations
  assignmentLevel = AssignmentLevel;

  constructor(public activeModal: NgbActiveModal,
              private toastService: ToastService,
              private modelService: ModelService) { }

  ngOnInit(): void {
  }

  removeDesignate(desigAssignment: DesignateAssignment) {
    // we always remove just one designate
    const designate: Designate = desigAssignment.designates[0];

    const designateBindingId: number =
      (desigAssignment.assignmentLevel === this.assignmentLevel.COURSE) ?
                                  designate.courseDesignateId : designate.orgDesignateId;

    // Do a backend call to remove the designate binding
    this.modelService.deleteDesignateAssignment(designateBindingId.toString(), desigAssignment.assignmentLevel)
      .subscribe(res => {
        // successfully deleted; close the modal and send assessment level (org or course) to the modal opener.
        this.activeModal.close({assessmentLevel: desigAssignment.assignmentLevel});
        this.toastService.show({type: 'success', action: 'Designate have been successfully removed.'});
      });
  }

}
