import {Component, inject, OnInit, DestroyRef} from '@angular/core';
import {FormArray, FormControl, FormGroup, Validators} from "@angular/forms";
import {ContactDetailComponent} from "../contact-detail/contact-detail.component";
import {CourseLevel} from "../course-level.enum";
import {CourseContacts} from "../../model/types/course-contacts";
import {ModelService} from "../../model/model.service";
import {CourseByLevelOfInstr} from "../../model/types/course-by-level-of-instr";
import {LevelOfStudy} from "../level-of-study.enum";
import {CourseLevelOfInstr} from "../../model/types/course-level-of-instr";
import {ActivatedRoute, Router} from "@angular/router";
import {InstructorEmailingUtils} from "../instructor-emailing-utils";
import {takeUntilDestroyed} from "@angular/core/rxjs-interop";
import {NgbModal, NgbModalRef} from "@ng-bootstrap/ng-bootstrap";
import {
  DeleteCourseContactsModalComponent
} from "../delete-course-contacts-modal/delete-course-contacts-modal.component";
import {LogService} from "../../common/services/log-service.service";
import {ContactDetails} from "../../model/types/contact-details";
import {ToastService} from "@easi-sis/core";


@Component({
  selector: 'app-course-contacts',
  templateUrl: './course-contacts.component.html',
  styleUrls: ['./course-contacts.component.scss']
})
export class CourseContactsComponent implements OnInit {

  emailContactsForm?: FormGroup;

  // enum
  CourseLevel = CourseLevel;
  LevelOfStudy = LevelOfStudy;

  // Holds the course prefixes (for grad, undergrad and both) retrieved from backend.
  coursePrefixes: CourseByLevelOfInstr;

  // Passed as input to typeahead. 'Level of Study' dependent.
  coursePref: CourseLevelOfInstr[];

  levelOfStudyVal: string;

  editContacts: boolean;
  courseContacts: CourseContacts;

  formattedCourse: string;

  courseAlreadyExistsErrorMsg: string = '';

  levelOfStudyMap: Map<string, string> = InstructorEmailingUtils.levelOfStudyMap;

  destroyRef = inject(DestroyRef);

  constructor(private router: Router,
              private activatedRoute: ActivatedRoute,
              private modalService: NgbModal,
              private toastService: ToastService,
              private modelService: ModelService,
              private logger: LogService) {}

  ngOnInit(): void {

    this._processCourseContactsParams();

    // Generate the form controls
    this._generateEmailContactForm();

    // Retrieve course prefixes from backend
    this.modelService.getCoursePrefByLevelOfInstr()
      .subscribe((coursePrefixes: CourseByLevelOfInstr) => {
        this.coursePrefixes = coursePrefixes;
    });

    // Monitor 'Level of Study' value changes
    this.levelOfStudy?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (levelOfStudy: string) => {
        this._setCoursePrefixes(levelOfStudy);
        this.course.setValue('');
      });

    // Monitor 'Course Level (Subject Area, Course Code, Meeting Section) value changes.
    this.courseLevel?.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(
      (levelOfStudy: string) => this.course.setValue(''));
  }

  // Sets the course prefixes for typeahead. Called when 'Level of Study' changes.
  _setCoursePrefixes(levelOfStudy: string) {
    if (levelOfStudy === LevelOfStudy.UNDERGRADUATE) {
      this.coursePref = this.coursePrefixes?.undergraduateCourses;
      this.levelOfStudyVal = LevelOfStudy.UNDERGRADUATE;

    } else if (levelOfStudy === LevelOfStudy.GRADUATE) {
      this.coursePref = this.coursePrefixes?.graduateCourses;
      this.levelOfStudyVal = LevelOfStudy.GRADUATE;

    } else if (levelOfStudy === LevelOfStudy.BOTH) {
      this.coursePref = this.coursePrefixes?.graduateUndergraduateCourses;
      this.levelOfStudyVal = LevelOfStudy.BOTH;
    }
  }

  // Generates course contact email form with controls
  _generateEmailContactForm(): void {
    this.emailContactsForm = new FormGroup<any>({
      courseLevel: new FormControl('', [Validators.required]),
      levelOfStudy: new FormControl('', [Validators.required]),
      course: new FormControl('', [Validators.required]),
      contacts: new FormArray([
        ContactDetailComponent.addContactDetails() as FormGroup
      ]),
      additionalInfo: new FormControl('')
    });
  }

  // -- FormControl properties --
  get levelOfStudy() {
    return this.emailContactsForm?.get('levelOfStudy') as FormControl;
  }
  get courseLevel(): FormControl {
    return this.emailContactsForm?.get('courseLevel') as FormControl;
  }

  get course(): FormControl {
    return this.emailContactsForm?.get('course') as FormControl;
  }

  get contactArray() {
    return this.emailContactsForm?.get('contacts') as FormArray;
  }

  // Open a modal and pass in the data to be displayed
  _openModal(course: string, contactDetails: ContactDetails): NgbModalRef {
    const modalRef = this.modalService.open(DeleteCourseContactsModalComponent);
    modalRef.componentInstance.course = course;
    modalRef.componentInstance.contactDetails = contactDetails;
    return modalRef;
  }

  /** Add new course contact to the form */
  addContact() {
    this.contactArray?.push(ContactDetailComponent.addContactDetails());
  }

  /** Delete contact from the form; if last contact is deleted than delete record from the persistent store */
  deleteContact(index: number) {

    // it's the last contact; user has to confirm the removal
    const contactDetails: ContactDetails = Object.assign(new ContactDetails(), this.contactArray?.at(index).value);
    const courseContacts: CourseContacts = Object.assign(new CourseContacts(), this.emailContactsForm.value);
    const course: CourseLevelOfInstr =  courseContacts.course;
    const formattedCourse = InstructorEmailingUtils.formatCourse(course);

    let modalRef: NgbModalRef = null;
    // Open a modal for user confirmation of deletion, for EDIT only.
    if (this.editContacts === true) {
      modalRef = this._openModal(formattedCourse, contactDetails);
    }

    // -- NOT LAST contact removal --
    if (this.contactArray?.length > 1) {

      if (this.editContacts === true) {
        // Remove contact from array. Show modal, for user response, only for EDIT operation.
        modalRef.componentInstance.title = 'Remove Course Contact';
        modalRef.componentInstance.isLastContactRemoval = false;

        // Process the user response
        modalRef.result.then(
          (result: string) => {

            // User response, from the modal ('remove' or 'cancel').
            if (result === 'remove') {

              // remove contact from array
              this.contactArray?.removeAt(index);
            }
          }
        );
      } else {
        // remove contact from array during ADD operation
        this.contactArray?.removeAt(index);
      }

      // -- LAST contact removal --
    } else if (this.contactArray?.length === 1) {

      // NOTE: the link to remove the last contact will be shown only for EDIT, not for ADD,
      //       that's why no check is done for the edit type.
      modalRef.componentInstance.title = 'Remove Last Course Contact';
      modalRef.componentInstance.isLastContactRemoval = true;

      // Process the user response
      modalRef.result.then(
        (result: string) => {

          // User response, from the modal ('remove' or 'cancel').
          if (result === 'remove') {

            this.modelService.deleteCourseContacts(courseContacts).subscribe((result) => {
              // Successfully deleted: navigate to the instructor emailing dashboard
              this.router.navigateByUrl('/instructor-emailing/manage-course-contacts').then(r => {});

              const msg = `Success! You have successfully removed the entire course contact for
                                                     ${InstructorEmailingUtils.formatCourse(courseContacts.course)}.`;
              this.toastService.show({type: 'success', action: msg});
            });
          }
        },
        (reason) => {
          this.logger.debug(`=> Reason: ${JSON.stringify(reason)}`, `${this.constructor.name}`);
        }
      );
    }

  }

  /** Receive Edit Course Contacts params, from Instructor Emailing Dashboard, and get the data from DB. */
  _processCourseContactsParams() {
    this.activatedRoute.queryParamMap.subscribe((params) => {
        const edit = params.get('edit');
        this.editContacts = edit === 'true' ? true : false;

      const courseCd = params.get('course');
      const sectionCode = params.get('sectionCode');
      const teachMethod = params.get('teachMethod');
      const sectionNumber = params.get('sectionNumber');
      const levelOfStudy = params.get('levelOfStudy');

      const paramsReceived: string = `edit: ${edit}, course: ${courseCd}, sectionCode: ${sectionCode},
                        teachMethod: ${teachMethod}, sectionNumber: ${sectionNumber}, levelOfStudy: ${levelOfStudy}`;
      console.log("@@@=> params paramsReceived: " + paramsReceived);

        if (this.editContacts === true) {
          const courseContacts = new CourseContacts();

          const course = new CourseLevelOfInstr();
          course.course = courseCd;
          course.sectionCode = sectionCode;
          course.teachMethod = teachMethod;
          course.sectionNumber = sectionNumber;

          courseContacts.course = course;
          courseContacts.levelOfStudy = levelOfStudy;

          this.formattedCourse = InstructorEmailingUtils.formatCourse(course);

          this._retrieveCourseContacts(courseContacts);
        }

    });
  }

  _retrieveCourseContacts(courseContacts: CourseContacts) {
    this.modelService.getCourseContacts(courseContacts)
      .subscribe((data: CourseContacts) => {
        this.courseContacts = data;

        this.formattedCourse = InstructorEmailingUtils.formatCourse(this.courseContacts.course);

        // Add contacts for all retrieved contacts
        for (let i = 0; i < this.courseContacts.contacts.length - 1; i++) {
          this.addContact();
        }

        this.emailContactsForm.setValue(this.courseContacts);
      });
  }

  _buildCourseExistsErrorMsg() {
    const courseLevelVal = this.courseLevel.value;
    let courseLevel: string;
    let code: string;

    if (this.CourseLevel.SUBJECT_AREA === courseLevelVal) {
      courseLevel = 'subject area';
      code = '';
    } else if (this.CourseLevel.COURSE_CODE === courseLevelVal) {
      courseLevel = 'course';
      code = ' code';
    } else if (this.CourseLevel.COURSE_MEETING_SECTION === courseLevelVal) {
      courseLevel = 'course meeting section';
      code = ' code';
    }

    return `A course contact already exist for this ${courseLevel}${code}.
           To add additional contacts, please find the existing ${courseLevel}  in the Course Contact table
           and edit contact details.`;
  }

  /** Submit the course contacts form to be saved in the persistent store */
  saveCourseContacts(): void {
    console.log(this.emailContactsForm.value);

    this.courseAlreadyExistsErrorMsg = '';

    const courseContacts = Object.assign(new CourseContacts(), this.emailContactsForm.value);
    console.log("==> courseContacts model: " + JSON.stringify(courseContacts));

    this.modelService.saveCourseContacts(courseContacts, this.editContacts)
      .subscribe((resp) => {

      if (resp.success) {
        // Successfully saved: navigate to the instructor emailing dashboard
        this.router.navigateByUrl('/instructor-emailing/manage-course-contacts').then(r => {});

        // Show a toast with success message.
        const addedOrEdited: string = this.editContacts ? 'edited' : 'added';
        const msg: string = `You have successfully ${addedOrEdited} course contacts for
                                            ${InstructorEmailingUtils.formatCourse(courseContacts.course)}.`;
        this.toastService.show({type: 'success', action: msg});

      } else if (!resp.success) {
        if (resp.errorCode === 'COURSE_CONTACT_ALREADY_EXISTS') {
          // - build the error message
          this.courseAlreadyExistsErrorMsg = this._buildCourseExistsErrorMsg();
          // - scroll to the error message area
          const element = document.getElementById("courseArea");
          element.scrollIntoView();
        }
      }
    });
  }
}
