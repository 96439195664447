<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">Send {{modalTitle}}
  </h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">

  Are you sure you want to send the {{modalTitle}} {{displayReminderType}} for the following test definition?
  <ul>
    <li>{{testDefInfo}}</li>
  </ul>
</div>
<!-- Buttons -->
<div class="modal-footer">
  <!-- Send email button -->
  <sis-button
    visualType="primary"
    size="medium"
    (clicked)="activeModal.close('yes')">
    Yes, Send Email
  </sis-button>
  &nbsp;
  <!-- Close modal button -->
  <button type="button" class="btn btn-link" (click)="activeModal.close('close')">
    Cancel
  </button>&nbsp;
</div>




