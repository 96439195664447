export enum StatusDesc {
  // REQUEST_PENDING = 'Needs Approval',
  CANCELLED_BY_ATS = 'Cancelled (ATS)',
  CANCELLED_BY_STUDENT = 'Cancelled (Student)',
  NEEDS_SUBMITTING = 'Unsubmitted',
  NEEDS_FILE = 'Details Received',
  SUBMITTED_TO_ATS_NO_FILES = 'No Files Required',
  SUBMITTED_TO_ATS_WITH_FILES = 'Files Received',
  INSTRUCTOR_REJECTED = 'Instructor Rejected',

  ACCOM_CONFLICT = 'Accom.Conflict',
  ACCOM_UPDATE = 'Accom.Update',
  SEAT_DOUBLE_BOOKED = 'Seat Double Booked',
  STUDENT_DOUBLE_BOOKED = 'Student Double Booked',

  FILE_ATTACHED = 'File(s) Attached',
  BE_COMPLETED_ONLINE = 'To Be Completed Online',
  ONLINE_BE_COMPLETED_IN_PERSON = 'Online, To Be Completed In-Person',
  QUERCUS_ACCESS_GRANTED = 'Quercus Access Granted',
  QUERCUS_ACCESS_DENIED = 'Quercus Access Denied'
}
