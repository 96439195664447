import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {DateOption} from "../../types/dateOption";
import {UntypedFormControl, Validators} from "@angular/forms";
import {DatePattern} from "../../types/date-pattern";
import moment from "moment";
import {DateFormatConstants} from "../../types/dateFormatConstants";
import {PeriodCourseSelector} from "../../../model/types/periodCourseSelector";

@Component({
  selector: 'app-period-selector',
  templateUrl: './period-selector.component.html',
  styleUrls: ['./period-selector.component.scss']
})
export class PeriodSelectorComponent implements OnInit {

  @Input() selectDateLabel: string;
  @Input() studentIdNeeded: boolean;
  @Input() storedSelector: PeriodCourseSelector;


  // tslint:disable-next-line:max-line-length
  dateOptions: DateOption[] = [DateOption.TODAY, DateOption.NEXT_DAY, DateOption.CURRENT_WEEK, DateOption.CURRENT_MONTH,
                               DateOption.CURRENT_SESSION, DateOption.CUSTOM_DATE_RANGE];
  public dateOption = new UntypedFormControl(DateOption);

  // the default date in 2 datepickers
  public startDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));
  public endDate = new UntypedFormControl('', Validators.pattern(DatePattern.YYYY_MM_M_DD_D));
  public course = new UntypedFormControl('');
  public sectionCode = new UntypedFormControl('');
  public teachingMethod = new UntypedFormControl('');
  public sectionNr = new UntypedFormControl('');
  public studentId = new UntypedFormControl('');

  @Output() private outer = new EventEmitter<PeriodCourseSelector>();

  constructor() { }

  ngOnInit(): void {
    this.dateOption.setValue(DateOption.TODAY);
    this.setDatePickersDefaultDate();

    if (this.storedSelector) {
      this.dateOptions.forEach(option => {
        if (option.description === this.storedSelector.dateOption.description) {
          this.dateOption.setValue(option);
        }
      });

      this.startDate.setValue(this.storedSelector.startDate);
      this.endDate.setValue(this.storedSelector.endDate);
      this.course.setValue(this.storedSelector.courseCode);
      this.sectionCode.setValue(this.storedSelector.sectionCode);
      this.teachingMethod.setValue(this.storedSelector.teachMethod);
      this.sectionNr.setValue(this.storedSelector.sectionNr);
      if ( this.studentIdNeeded) {
        this.studentId.setValue(this.storedSelector.studentId);
      }
    }
  }

  reset(): void {
    this.dateOption.setValue(DateOption.TODAY);
    this.setDatePickersDefaultDate();
    this.course.reset("");
    this.sectionCode.reset("");
    this.teachingMethod.reset("");
    this.sectionNr.reset("");
    this.studentId.reset("");

    const start = DateOption.TODAY.startDate.format(DateFormatConstants.YYYYMMDD);
    const end = DateOption.TODAY.endDate.format(DateFormatConstants.YYYYMMDD);

    if (this.studentIdNeeded) {
      this.outer.emit(new PeriodCourseSelector(DateOption.TODAY, start, end, this.course.value, this.sectionCode.value,
        this.teachingMethod.value, this.sectionNr.value, this.studentId.value));
    } else {
      this.outer.emit(new PeriodCourseSelector(this.dateOption.value, start, end, this.course.value, this.sectionCode.value,
        this.teachingMethod.value, this.sectionNr.value));
    }

  }

  setDatePickersDefaultDate() {
    this.startDate.setValue(this.dateOption.value.startDate.format(DateFormatConstants.YYYYMMDD));
    this.endDate.setValue(this.dateOption.value.endDate.format(DateFormatConstants.YYYYMMDD));
  }

  changeDateOption() {
    this.setDatePickersDefaultDate();

    /*if (this.dateOption.value !== DateOption.CUSTOM_DATE_RANGE) {
      this.sendOutPeriod();
    }*/
  }

  sendOutPeriod() {
    const dateRangeStartDate = moment(this.startDate.value);
    const dateRangeEndDate = moment(this.endDate.value);

    if (dateRangeStartDate <= dateRangeEndDate) {
      const start = dateRangeStartDate.format(DateFormatConstants.YYYYMMDD);
      const end = dateRangeEndDate.format(DateFormatConstants.YYYYMMDD);

      if (this.studentIdNeeded) {
        this.outer.emit(new PeriodCourseSelector(this.dateOption.value, start, end, this.course.value, this.sectionCode.value,
          this.teachingMethod.value, this.sectionNr.value, this.studentId.value));
      } else {
        this.outer.emit(new PeriodCourseSelector(this.dateOption.value, start, end, this.course.value, this.sectionCode.value,
          this.teachingMethod.value, this.sectionNr.value));
      }
    }
  }

}
