import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {TestDef} from "../../model/types/test-def";
import {TypeUtils} from "../../common/types/type-utils";

@Component({
  selector: 'app-class-writing-schedule-card',
  templateUrl: './class-writing-schedule-card.component.html',
  styleUrls: ['./class-writing-schedule-card.component.scss']
})
export class ClassWritingScheduleCardComponent implements OnInit {

  @Input()
  testDef: TestDef;

  @Input()
  showEditButton: boolean;

  @Output()
  outputEvent = new EventEmitter<any>();

  assessmentLocation = "";

  typeUtils = new TypeUtils();

  constructor() { }

  ngOnInit(): void {
    this.assessmentLocation = this.testDef.assessmentLocationDetails.onCampusInd === false ?
      this.typeUtils.OFF_CAMPUS : this.testDef.assessmentLocationDetails.onCampusInd === true ? this.typeUtils.ON_CAMPUS : '';
  }

  editDetails(): void {
    this.outputEvent.emit();
  }
}
