<h1>Checkbox Drop Down Demo</h1>

The name of the component is: <b>app-checkbox-drop-down</b>

<h2>Configuration Options</h2>
<div class="col-6">
  <table class="table table-striped">
    <thead>
    <tr>
      <th>Option</th>
      <th>Description</th>
    </tr>
    </thead>
    <tr>
      <td>debugSelections</td>
      <td>Set to true to see the json version of the selections on the drop down panel</td>
    </tr>
    <tr>
      <td>options</td>
      <td>Array of <b>CheckOption</b> objects</td>
    </tr>
    <tr>
      <td>selectAllOption</td>
      <td>Single <b>CheckOption</b> representing the all option. The name should be like 'Option' or 'Column'. The
        pluralization comes from the <b>app-checkbox-drop-down</b> widget itself.
      </td>
    </tr>

  </table>
</div>
<h2>Demo</h2>

<div class="row">
  <div class="col">
    <app-checkbox-drop-down [debugSelections]="false" [options]="options"
                            [selectAllOption]="selectAllOption"></app-checkbox-drop-down>
  </div>
</div>

