import {Component, Input, OnInit} from '@angular/core';
import {BasicModalContent} from '../../types/basic-modal-content';
import {NgbActiveModal} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-basic-modal',
  templateUrl: './basic-modal.component.html',
  styleUrls: ['./basic-modal.component.scss']
})
export class BasicModalComponent implements OnInit {

  @Input()
  modalContent: BasicModalContent;

  constructor(public activeModal: NgbActiveModal) { }

  ngOnInit(): void {
  }

}
