import { Component, OnInit } from '@angular/core';
import { VERSION } from '../../environments/version';

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
  styleUrls: ['./app-footer.component.scss']
})
export class AppFooterComponent implements OnInit {
  public commitId: string;

  constructor() { }

  ngOnInit() {
    this.commitId = VERSION.hash;
  }

}
