import {Component, Input, OnInit} from '@angular/core';
import {StudentEmergencyContact} from "../../model/types/studentEmergencyContact";

@Component({
  selector: 'app-student-emergency-contact',
  templateUrl: './app-student-emergency-contact.component.html',
  styleUrls: ['./app-student-emergency-contact.component.scss']
})
export class AppStudentEmergencyContactComponent implements OnInit {

  @Input()
  emergencyContact: StudentEmergencyContact;

  constructor() {}

  ngOnInit(): void {
  }

}
