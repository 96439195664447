<div class="card  card-body">
  <h2 class="mt-4" >Emergency Contact</h2>
  <div class="row mt-4">
    <div class="col-12 card-text">
      <div class="row">
        <div class="col-4"><strong>Emergency Contact</strong></div>
      </div>
      <div class="row">
        <div class="col-4">{{emergencyContact?.firstName}} {{emergencyContact?.lastName}}</div>
      </div>
      <div class="row">
        <div class="col-4 mt-2"><strong>Relation</strong></div>
      </div>
      <div class="row">
        <div class="col-4">{{emergencyContact?.relation || ''}}</div>
      </div>
      <div class="row">
        <div class="col-4 mt-2"><strong>Phone</strong></div>
      </div>
      <div class="row">
        <div class="col-4">{{emergencyContact?.phone?.number}}</div>
      </div>
    </div>
  </div>

</div>
