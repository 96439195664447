import {Component, Input} from '@angular/core';
import {NgbActiveModal} from "@ng-bootstrap/ng-bootstrap";
import {TestDef} from "../../model/types/test-def";

@Component({
  selector: 'app-delete-test-def-modal',
  templateUrl: './delete-test-def-modal.component.html',
  styleUrls: ['./delete-test-def-modal.component.scss']
})
export class DeleteTestDefModalComponent {

  @Input()
  testDefinition: TestDef;

  @Input()
  numberOfBookings: number;

  constructor(public activeModal: NgbActiveModal) { }
}
