<!-- CARD: Test Definition Details -->
<div class="card-body">
  <h3 class="card-title">Export Test Definition</h3>
  <div class="card-text">
    <div>
      Test definitions must be exported to Clockwork before bookings can be moved to them.
    </div>
  </div>
  <br/><br/>

  <div>
    <button class="btn btn-sm btn-primary"
            (click)="exportTestDefToClockwork()"
            [disabled]="testDefinition?.clockworkExamId" type="submit"
            [ngClass]="{'disabled': testDefinition?.clockworkExamId}">
      <span *ngIf="testDefinition?.clockworkExamId"
            ngbTooltip="Test Definition already exported to Clockwork." placement="auto">Export</span>
      <span *ngIf="!testDefinition?.clockworkExamId">Export</span>
    </button>
  </div>
</div>
