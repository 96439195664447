<div ngbDropdown>
  <button type="button" class="btn btn-primary btn-block left-border-zero-radius font-size-16px" id="dropdownForm" ngbDropdownToggle>{{buttonText}}</button>
    <div ngbDropdownMenu class="dropdown-menu" aria-labelledby="dropdownForm">
      <label class="px-4 d-block non-bold">
        <input id="selectAll" class="form-check-input" type="checkbox" [formControl]="selectAll">
        Select All
      </label>
      <label class="form-label px-4 d-block non-bold" *ngFor="let column of activeColumns" [formGroup]="formGroup">
        <input type="checkbox" [formControlName]="column.name" > {{column.name.charAt(0).toUpperCase() + column.name.slice(1)}}
      </label>
    </div>
</div>
