<div  *ngIf="courseBookingsList">
  <ul ngbNav #nav="ngbNav"   [(activeId)]="active" class="nav-tabs">

    <li [ngbNavItem]="1">
      <a ngbNavLink>Overview</a>
      <ng-template ngbNavContent>
        <h5 class="bold mt-4">Accommodation Overview</h5>
        <div *ngIf="studentAccommodations">
          <div class="mt-2"><strong>Accommodation Valid Until: </strong>
            {{studentAccommodations.expiryDate | date: 'EEEE, MMMM d, y'}}
            at {{studentAccommodations.expiryDate | date: 'h:mm a' }}
          </div>
          <div class="mt-2">
            <strong>Student Declaration: </strong>
            <span *ngIf="studentAccommodations.consentDate">Agreed on {{studentAccommodations.consentDate | date: 'EEEE, MMMM d, y'}}</span>
            <span *ngIf="!studentAccommodations.consentDate">Pending agreement by student for this academic year</span>
          </div>
          <div class="mt-2" *ngIf="this.studentAccommodations.options">
            <ul  *ngFor="let accomm of this.studentAccommodations.options['Default']">
              <li>{{accomm.description}}</li>
            </ul>
          </div>
        </div>
      </ng-template>
    </li>

    <li *ngFor="let courseBookings of courseBookingsList, index as i" [ngbNavItem]="i + 2" (click)="selectBookings(courseBookings)">
      <a ngbNavLink>{{courseBookings.code}} {{courseBookings.sectionCode}}</a>
      <ng-template ngbNavContent>
        <div class="row ">
          <div class="col-12">
            <div *ngIf="accommodations && accommodations.length>0">
              <h5 class="bold mt-4">Accommodations list for this course {{courseBookings.code}} {{courseBookings.sectionCode}} </h5>
              <div>
                <ul *ngFor="let accomm of accommodations">
                  <li>{{accomm.description}}</li>
                </ul>
              </div>
            </div>
            <div *ngIf="!accommodations || accommodations.length===0">
              <span>No course specific accommodation available</span>
            </div>
            <div class="mt-5 accom-table">
              <table mat-table [dataSource]="courseBookings.bookings">
                <ng-container matColumnDef="status" >
                  <th class="mat-column-position" mat-header-cell *matHeaderCellDef><strong>Status</strong></th>
                  <td class="mat-column-position" mat-cell *matCellDef="let booking">
                    <a routerLink="/student-booking/{{booking.id}}">{{booking.statusDesc}}</a>
                  </td>
                </ng-container>

                <ng-container matColumnDef="scheduleDate" >
                  <th class="mat-column-position" mat-header-cell *matHeaderCellDef><strong>Scheduled Date</strong></th>
                  <td class="mat-column-position" mat-cell *matCellDef="let booking"> {{formatScheduledDate(booking)}}</td>
                </ng-container>

                <ng-container matColumnDef="scheduleTime" >
                  <th class="mat-column-position" mat-header-cell *matHeaderCellDef><strong>Scheduled Time</strong></th>
                  <td class="mat-column-position" mat-cell *matCellDef="let booking"> {{booking.scheduledStartTime | convertFrom24To12Format }} - {{
                    booking.scheduledEndTime | convertFrom24To12Format}}</td>
                </ng-container>

                <ng-container matColumnDef="seat" >
                  <th class="mat-column-position" mat-header-cell *matHeaderCellDef><strong>Seat, Room</strong></th>
                  <td class="mat-column-position" mat-cell *matCellDef="let booking">{{formatSeat(booking)}}</td>
                </ng-container>

                <tr mat-header-row *matHeaderRowDef="displayedColumns" ></tr>
                <tr mat-row *matRowDef="let row; columns: displayedColumns;" ></tr>
              </table>
            </div>
          </div>
        </div>
      </ng-template>
    </li>

  </ul>
  <div [ngbNavOutlet]="nav"></div>
</div>
