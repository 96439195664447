
<div class="modal-header">
  <h4 class="modal-title" id="modal-basic-title">{{title}}</h4>
  <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  {{body}}
</div>
<div class="modal-footer">
<!-- Buttons -->
  <button type="button" class="btn btn-primary btn-sm" (click)="activeModal.close(YES_VALUE)">
    {{discardChanges}}
  </button>&nbsp;

  <button type="button" class="btn btn-link btn-sm" (click)="activeModal.close(NO_VALUE)">
    {{continueEditing}}
  </button>&nbsp;
</div>



