
<div class="provision-student">

  <!-- Modal header -->
  <div class="modal-header">
    <h4 class="modal-title" id="modal-basic-title">Provision Student</h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">
    <div class="row">
      <!-- ROSI Student Number -->
      <div class="col-10">
        <label class="form-label" for="rosiStudentNumber">ROSI Student Number</label>
          <input type="text"  class="form-control" [formControl]="rosiStudentNumber" id="rosiStudentNumber" #student>
      </div>
    </div>

    <!-- Error: could not provision the student -->
    <div class="row" *ngIf="student.value && !successfullyProvisioned">
      <!-- Error msg -->
      <div class="col-10">
        <span class="error-msg">This student could not be provisioned.</span>
      </div>
    </div>
  </div>
  <!-- Modal footer -->
  <div class="modal-footer d-flex justify-content-start">
    <!-- Provision Student button -->
    <div>
      <button class="btn btn-sm btn-primary" [disabled]="!rosiStudentNumber.value" (click)="provisionStudent(student.value)">
        Provision Student
      </button>
    </div>
    <!-- Cancel hyperlink -->
    <div>
      <button class="btn btn-sm btn-link" (click)="activeModal.close('')">
        Cancel
      </button>
    </div>
  </div>
</div>
