<div class="assign-designates">

  <!-- Modal header -->
  <div class="modal-header" *ngIf="showInModal === true">
    <h4 class="modal-title" id="modal-basic-title">
      <span *ngIf="isUserDesginatesAdmin">Edit Designate</span>
      <span *ngIf="!isUserDesginatesAdmin">View Designate</span></h4>
    <button type="button" class="close" aria-label="Close" (click)="activeModal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>

  <!-- Modal body -->
  <div class="modal-body">

    <form [formGroup]="designateForm">

      <div *ngIf="showInModal === false">
        <h1>Assign Designates</h1>

        <h2 class="mt-5">1. Identify Course/Unit</h2>

        <!-- Assignment Level: Course or department? -->
        <div class="form-group">
          <div class="row">
            <div class="col">
              <!-- Course -->
              <div class="font-weight-bold mt-4">Do you want to assign a designate to a course or division/unit?</div>
              <div class="form-check">
                <input class="form-check-input" type="radio" id="assignmentLevelC"
                       [value]="assignmentLevelEnum.COURSE" formControlName="assignmentLevel">
                <label class="form-check-label" for="assignmentLevelC" >Course</label>
              </div>
              <!-- Department/Division -->
              <div class="form-check">
                <input class="form-check-input" type="radio" id="assignmentLevelD"
                       [value]="assignmentLevelEnum.DEPARTMENT" formControlName="assignmentLevel">
                <label for="assignmentLevelD" class="form-check-label">Division/Unit</label>
              </div>
              <app-validation-message [control]="designateForm.get('assignmentLevel')"></app-validation-message>
            </div>
          </div>
        </div>

        <!-- Align left 3.0 -->
        <!-- -> Search for Courses -->
        <div *ngIf="designateForm.value.assignmentLevel == assignmentLevelEnum.COURSE">
          <div class="form-group ml-5">
            <div class="row">
              <!-- Search for Course: used async course component -->
              <div class="col-4">
                <!-- TODO: to implement the validation at component level -->
                <app-async-course-search [parentForm]="designateForm"></app-async-course-search>
                <app-validation-message [control]="designateForm.get('course')"></app-validation-message>
              </div>
            </div>
          </div>
        </div>
        <!-- -> Search for Department/Division -->
        <div *ngIf="designateForm.value.assignmentLevel == assignmentLevelEnum.DEPARTMENT">
          <!-- Level of Study: align left 1.0 -->
          <ng-container formGroupName="org">
            <div class="form-group ml-5">
            <div class="row">
              <div class="col">
                <!-- Undergraduate -->
                <div class="font-weight-bold">Level of Study</div>
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="graduateIndFalse"
                         [value]="false" formControlName="graduateInd"
                         (change)="selectFaculties(false)">
                  <label for="graduateIndFalse" class="form-check-label">Undergraduate</label>
                </div>
                <!-- Graduate -->
                <div class="form-check">
                  <input class="form-check-input" type="radio" id="graduateIndTrue"
                         [value]="true" formControlName="graduateInd"
                         (change)="selectFaculties(true)">
                  <label for="graduateIndTrue" class="form-check-label">Graduate</label>
                </div>
                <app-validation-message [control]="designateForm.get('org.graduateInd')"></app-validation-message>
              </div>
            </div>
          </div>
          </ng-container>

          <div class="form-group ml-5">
            <div class="row">
              <!-- Faculty -->
              <ng-container formGroupName="org">
              <div class="col-4">
                <label class="form-label" for="facultyCode">{{gradOrUndergrad}} Division</label>
                <select class="form-control" id="facultyCode" formControlName="facultyCode" #fac
                        (change)="selectDepartments(fac.value, graduateIndVal)">
                  <option value="">-- Select Division --</option>
                  <option *ngFor="let fac of faculties">{{fac}}</option>
                </select>
                <app-validation-message [control]="designateForm.get('org.facultyCode')"></app-validation-message>
              </div>
              <!-- Department -->
              <div class="col-3">
                <label class="form-label" for="departmentCode">{{gradOrUndergrad}} Unit</label>
                <select class="form-control" id="departmentCode" formControlName="departmentCode" #dept
                  (change)="selectCoursePrefixes(dept.value)">
                  <option value="">-- Select Unit --</option>
                  <option *ngFor="let dept of departments">{{dept}}</option>
                </select>
                <app-validation-message [control]="designateForm.get('org.departmentCode')"></app-validation-message>
              </div>
              </ng-container>
              <!-- Course Prefix -->
              <div class="col-5">
                <label class="form-label" for="coursePrefix">Search for Courses Prefix(Optional)</label>
                <select class="form-control" id="coursePrefix" formControlName="coursePrefix">
                  <option value="">-- Select CoursePrefix --</option>
                  <option *ngFor="let coursePrefix of coursePrefixes">{{coursePrefix}}</option>
                </select>
              </div>
            </div>
          </div>
        </div>

        <h2 class="mt-5">2. Select Designate(s)</h2>
      </div>

      <ng-container formArrayName="designates">
        <div *ngFor="let designate of designates.controls; let i = index">

          <div *ngIf="showInModal === true">
            <app-desig-binding [desigAssignment]="desigAssignment"></app-desig-binding>
          </div>

          <ng-container [formGroupName]="i">

            <!-- Make Select Designate(s) not editable -->
            <div *ngIf="showInModal === false">
              <div class="row">
                <div class="col-5 mt-4"><h3>Designate {{i + 1}}</h3></div>
                <div class="col-7 mt-2" *ngIf="designates.controls.length > 1">
                  <button class="btn btn-link" (click)="removeDesignate(i)">Remove Designate</button>
                </div>
              </div>

              <div class="row mt-4">
                <div class="col">
                  <div class="font-weight-bold">Select Designate by UTORid or Email Address</div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="selectedByUtorIdNotByEmailTrue"
                           [value]="true" formControlName="selectedByUtorIdNotByEmail">
                    <label for="selectedByUtorIdNotByEmailTrue" class="form-check-label">UTORid</label>
                  </div>
                  <div class="form-check">
                    <input class="form-check-input" type="radio" id="selectedByUtorIdNotByEmailFalse"
                           [value]="false" formControlName="selectedByUtorIdNotByEmail">
                    <label for="selectedByUtorIdNotByEmailFalse" class="form-check-label">Email Address</label>
                  </div>
                </div>
              </div>

              <!-- UTORid -->
              <div class="row ut-margin-top-20px" *ngIf="designateForm.get('designates.' + i + '.selectedByUtorIdNotByEmail').value === true">
                <div class="form-group col-3">
                  <label class="form-label" for="utorId">UTORid</label>
                  <input type="text" class="form-control" [ngClass]="{borderHighlight : designateForm.get('designates.' + i + '.invalidUtorIdMsg').value === 'Not a valid staff UTORid'}"
                         id="utorId" formControlName="utorIdInput" #utorIdInput>
                  <app-validation-message
                    [control]="designateForm.get('designates.' + i + '.utorIdInput')">
                  </app-validation-message>
                  <!-- Display invalid utorId message if is the case -->
                  <div class="text-danger" *ngIf="designateForm.get('designates.' + i + '.invalidUtorIdMsg').value
                                                  && designateForm.get('designates.' + i + '.invalidUtorIdMsg').value.length">
                    {{designateForm.get('designates.' + i + '.invalidUtorIdMsg').value}}
                  </div>
                </div>
                <div class="form-group col-9 d-flex align-items-end mb-4">
                  <button class="btn btn-outline-primary" (click)="validateDesignate(utorIdInput.value, true, i)">Select</button>
                </div>
              </div>

              <!-- Email -->
              <div class="row ut-margin-top-20px" *ngIf="designateForm.get('designates.' + i + '.selectedByUtorIdNotByEmail').value === false">
                <div class="form-group col-3">
                  <label class="form-label" for="email">Email address</label>
                  <input type="text" class="form-control" [ngClass]="{borderHighlight : designateForm.get('designates.' + i + '.invalidEmailMsg').value === 'Email does not exist'}"
                         id="email" formControlName="emailInput" #emailInput>
                  <app-validation-message
                    [control]="designateForm.get('designates.' + i + '.emailInput')">
                  </app-validation-message>
                  <!--Display invalid email message if is the case-->
                  <div class="text-danger" *ngIf="designateForm.get('designates.' + i + '.invalidEmailMsg').value
                                                  && designateForm.get('designates.' + i + '.invalidEmailMsg').value.length">
                    {{designateForm.get('designates.' + i + '.invalidEmailMsg').value}}
                  </div>
                </div>
                <div class="form-group col-9 d-flex align-items-end">
                  <button class="btn btn-outline-primary" (click)="validateDesignate(emailInput.value, false, i)">Select</button>
                </div>
              </div>


              <!-- Name -->
              <div class="form-group ut-margin-top-20px ut-margin-left-40px" *ngIf="designateForm.get('designates.' + i + '.firstName').value">
                <div class="row">
                  <div class="col--12">
                    <span class="font-weight-bold">Name:</span>
                                    {{designateForm.get('designates.' + i + '.firstName').value}}
                                    {{designateForm.get('designates.' + i + '.lastName').value}}  &nbsp;&nbsp;
                    <span class="font-weight-bold">UTORid:</span>
                                    {{designateForm.get('designates.' + i + '.utorId').value}}  &nbsp;&nbsp;
                    <span class="font-weight-bold">Phone Number:</span>
                    {{designateForm.get('designates.' + i + '.phone').value}}
                  </div>
                </div>
              </div>
            </div>

            <!-- For non-modal display justify the alt email address and alt phone number to the right -->
            <div *ngIf="(showInModal === false && designateForm.get('designates.' + i + '.firstName').value) ||
                  (showInModal !== false && isUserDesginatesAdmin)">
              <div class="row" [ngClass]="{'ut-margin-left-40px': showInModal === false}">
                <div class="form-group col-3">
                  <div class="font-weight-bold">Alternate Email Address (Optional)</div>
                  <input type="text" class="form-control" placeholder="E.g: example@email.com"
                         formControlName="emailAddress">
                  <app-validation-message [warning]="true"
                                          [control]="designateForm.get('designates.' + i + '.emailAddress')">
                  </app-validation-message>
                </div>

                <div class="form-group col-3">
                  <div class="font-weight-bold">Alternate Phone Number (Optional)</div>
                  <input type="text" class="form-control" id="phoneNumber" placeholder="E.g: 416-123-4567"
                         formControlName="phoneNumber">
                  <app-validation-message [warning]="true"
                                          [control]="designateForm.get('designates.' + i + '.phoneNumber')">
                  </app-validation-message>
                </div>
              </div>

              <div [ngClass]="{'ut-margin-left-40px': showInModal === false}">
                <!-- Communication Permissions -->
                <div class="form-group"  >
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold">Communication Permissions</div>
                    </div>
                  </div>
                  <!-- email preference: All emails -->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="emailPreferenceA"
                               [value]="emailPrefEnum.ALL_EMAILS" formControlName="emailPreference">
                        <label for="emailPreferenceA" class="form-check-label">Receive all communication</label>
                      </div>
                    </div>
                  </div>
                  <!-- email preference: Late emails-->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="emailPreferenceL"
                               [value]="emailPrefEnum.LATE_EMAIL_ONLY" formControlName="emailPreference">
                        <label for="emailPreferenceL" class="form-check-label">Receive only late communication</label>
                      </div>
                      <app-validation-message
                        [control]="designateForm.get('designates.' + i + '.emailPreference')">
                      </app-validation-message>
                    </div>
                  </div>
                </div>

                <!-- Role -->
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold">Role</div>
                    </div>
                  </div>
                  <!-- has edit permissions: true -->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="daRoleIndTrue"
                               [value]="true" formControlName="daRoleInd">
                        <label for="daRoleIndTrue" class="form-check-label">Is an accommodation admin role</label>
                      </div>
                    </div>
                  </div>
                  <!-- has edit permissions: false (read access only) -->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="daRoleIndFalse"
                               [value]="false" formControlName="daRoleInd">
                        <label for="daRoleIndFalse" class="form-check-label">Is an instructor role</label>
                      </div>
                      <app-validation-message
                        [control]="designateForm.get('designates.' + i + '.daRoleInd')">
                      </app-validation-message>
                    </div>
                  </div>
                </div>

                <!-- Designation Expiry -->
                <div class="form-group">
                  <div class="row">
                    <div class="col">
                      <div class="font-weight-bold">Designation Expiry</div>
                    </div>
                  </div>
                  <!-- designation expiry ind: true -->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="designationExpiryIndTrue"
                               [value]="true" formControlName="designationExpiryInd"
                               >
                        <label for="designationExpiryIndTrue" class="form-check-label">Designation valid until specified date</label>
                      </div>
                    </div>
                  </div>
                  <!-- designation expiry ind: false -->
                  <div class="row">
                    <div class="col">
                      <div class="form-check">
                        <input class="form-check-input" type="radio" id="designationExpiryIndFalse"
                               [value]="false" formControlName="designationExpiryInd"
                               >
                        <label for="designationExpiryIndFalse" class="form-check-label">Designation indefinite</label>
                      </div>
                      <app-validation-message
                        [control]="designateForm.get('designates.' + i + '.designationExpiryInd')">
                      </app-validation-message>
                    </div>
                  </div>
                </div>
                <div class="row ml-5" *ngIf="designate.value.designationExpiryInd === true">
                  <div class="col-4">
                    <label class="form-label">Designation is valid until:</label>
                    <app-rf-datepicker formControlName="endDate"
                                       [minDate]="minDesignationExpiryDate">
                    </app-rf-datepicker>
                  </div>
                </div>
                <div class="row ml-5">
                  <app-validation-message
                    [control]="designateForm.get('designates.' + i + '.endDate')">
                  </app-validation-message>
                </div>
            </div>
            </div>
          </ng-container>
        </div>
      </ng-container>

      <!-- Do not display this div in edit modal -->
      <div *ngIf="showInModal === false">

        <div class="form-group mt-4">
          <button class="btn btn-link" (click)="addDesignate()">Add Another</button>
        </div>

        <div class="form-group mt-5">
          <button type="submit" class="btn btn-primary"
                  (click)="saveDesignateAssignment()" [ngStyle]="{'background-color': !designateForm.valid? 'grey' : ''}"
                  [disabled]="!designateForm.valid">
            Save Designates
          </button>
        </div>

        <div class="form-group mt-5">
          <a routerLink="/settings/designate-dashboard">Back to Designates Dashboard</a>
        </div>
      </div>
    </form>
  </div>

  <!-- Modal footer -->
  <div class="modal-footer" *ngIf="showInModal === true && isUserDesginatesAdmin">
    <button type="button" class="btn btn-sm btn-primary" (click)="saveEditedDesigAssignment()">Save Changes</button>
    <button type="button" class="btn btn-sm btn-link" (click)="activeModal.close('Save click')">Cancel</button>
  </div>
</div>
