import {BasicTestDefinition} from "./basic-test-definition";
import {StudentName} from "./student-name";
import {CourseSection} from "./course-section";
import {Accommodation} from "./accommodation";

export class ManualBooking {

  student: StudentName;
  course: CourseSection;
  inExamCentre: boolean;
  online: boolean;
  accommodations: Accommodation[];
  testDefinitionId: number;
  basicTestDefinition: BasicTestDefinition;
  assessmentLocation: string;
  examDeferredType: string;


  constructor() {}
}
