import {Injectable} from '@angular/core';
import {NgbTimeAdapter, NgbTimeStruct} from '@ng-bootstrap/ng-bootstrap';
import {LogService} from './log-service.service';

/**
 * Service: TimepickerAdapterService.
 * Desc:
 *   This service is used by the [TimepickerComponent] for converting
 *   the data model to and from the format required by the ngb-timepicker.
 */
@Injectable({
  providedIn: 'root'
})
export class TimepickerAdapterService implements NgbTimeAdapter<string> {

  constructor(private logger: LogService) { }

  /**
   * NgbTimeAdapter  method.
   * Converts from string (i.e. '13:25:00') to timepicker structure (i.e. {hour: 13, minute: 25: second: 00}).
   */
  fromModel(value: string): NgbTimeStruct {
    if (!value) {
      return null;
    }

    // this.logger.debug(`${JSON.stringify(value)}`, `${this.constructor.name}.fromModel()`);

    if (value.split) {
      const durationParts = value.split(':');
      return {
        hour: parseInt(durationParts[0], 10),
        minute: parseInt(durationParts[1], 10),
        second: parseInt(durationParts[2], 10)
      };
    } else {
      return null;
    }
  }

  /**
   * NgbTimeAdapter method .
   * Converts from timepicker structure (i.e. {hour: 13, minute: 25: second: 00}) to string (i.e. '13:25:00').
   */
  toModel(value: NgbTimeStruct): string {
    if (!value) {
      return null;
    }

    // this.logger.debug(`${JSON.stringify(value)}`, `${this.constructor.name}.toModel()`);

    return `${this.pad(value.hour)}:${this.pad(value.minute)}:${this.pad(value.second)}`;
  }

  private pad(i: number): string {
    return i < 10 ? `0${i}` : `${i}`;
  }

  /**
   * Converts time given as a HH:mm:ss string into minutes and returns it.
   * i.e. 13:50 -> 15 * 60 + 30
   * @param value  The time value as in the model (in HH:mm:ss format).
   */
  getTimeInMin(value: string) {
    const valStruct: NgbTimeStruct = this.fromModel(value);
    return valStruct != null ? (valStruct.hour * 60 + valStruct.minute) : 0;
  }

}
